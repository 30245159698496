import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import { greenLockedIcon, long_right_arrow, sms_modal_img, sms_modal_img_mobile } from "../../../common/assets/Images";
import { Button } from "../../../cartSummary/CardSummaryHelper/CartSummaryImport";

export default function EmptyEmailModalFastLane(props) {
    const { 
        showEmptyEmailModal,
        setShowEmptyEmailModal,
    } = props;

    const confirmModal = () => {
        window.location.reload(true);
        setShowEmptyEmailModal(false);
    }

    return (
        <Modal
            show={showEmptyEmailModal}
            centered
            backdrop="static"
            keyboard={false}
            className="existing-email-login-modal"
        >
            <Modal.Body className="existing-email-login-modal-body">
                <div className="existing-email-login-modal-title-container">
                <div className="existing-email-login-modal-title">
                        Sorry, it seems an error has occurred. Let's try again.
                    </div>
                    <div className="existing-email-login-modal-subtitle email">
                        If the issue persists, please don't hesitate to contact us so we can assist you. Thank you!
                    </div>
                </div>
                <div className="existing-email-login-modal-button-container">
                    <button className="existing-email-login-modal-login-button" onClick={confirmModal} >
                        Try Again
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
