import React from "react";
import { chinaFlag, russiaFlag, spanishFlag, unitedStates } from "../../common/assets/Images";

const configCourseId = 611;
const improvOrganisationId= 1001;
const tabOption = {
    tab_1: "tab-1-content", 
    tab_2: "tab-2-content",
    tab_3: "tab-3-content"
}
const LiveChatUrl='https://vue.comm100.com/chatwindow.aspx?siteId=100857&planId=4b4b4c00-0000-0000-0000-008e000189f9'

const paymentNames= {
    stripe:"Stripe",
    tilled: "Tilled",
    paypal:"Paypal",
    authorizeNet:"AUTHORIZE.NET",
    amazon: "Amazon",
    square : "SQUARE"

}

const NATIONAL_LANGUAGE_TEXT = {
  UNITEDSTATE: "UNITEDSTATE",
  SPAIN: "SPAIN",
  RUSSIA: "RUSSIA",
  CHINA: "CHINA",
};


const FLAGS_NATIONALS = {
  UNITEDSTATE: {
    image: <img src={unitedStates} />,
    text: "English",
  },
  SPAIN: {
    image: <img src={spanishFlag} />,
    text: "Español (América Latina)",
  },
  RUSSIA: {
    image: <img src={russiaFlag} />,
    text: "Русский",
  },
  CHINA: {
    image: <img src={chinaFlag} />,
    text: "中文(简体)",
  },
};

const REQUIRE_STATE_TEXT_ERROR = 'State is required';

const SHOW_ERROR_TEXT = "Oops. Something went wrong. Please try again later."

const REGULAR_EPRESSIONS = {
  EMAIL: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}


export const _const = {
  configCourseId,
  tabOption,
  LiveChatUrl,
  improvOrganisationId,
  paymentNames,
  FLAGS_NATIONALS,
  NATIONAL_LANGUAGE_TEXT,
  REQUIRE_STATE_TEXT_ERROR,
  SHOW_ERROR_TEXT,
  REGULAR_EPRESSIONS
};
