import React, { useEffect, useState } from 'react'
import Login from '../login/Login';
import Register from '../register/Register';
import CourseEnrollment from '../course-enrollment/CourseEnrollment';
import { Switch, Route } from 'react-router-dom';
import ForgetPassword from '../forget-password/ForgetPassword';
import ResetPassword from '../reset-password/ResetPassword';
import PaypalLogin from '../paypal-login/PaypalLogin';
import SacramentoCourse from '../course-enrollment/SacramentoCourse';
import StudentRedirectPayment from '../cartSummary/StudentRedirectPayment';
import Steppers from '../common/Steppers';
import CourseLanding from '../landingPage/CourseLanding';
import Footer from './page-layouts/Footer';
import HeaderPublic from './page-layouts/HeaderPublic';
import RegisterFast from "../register/registerNew";
function PublicRouting(props) {

    const [pageStyle, setPageStyle] = useState('')
    const { match, history } = props;

    const activeElement = (e) => {
        let url = history.location.pathname
        if (url === '/register' || url === '/studentcourse-payment') return e
    }

    const activeElementFooter = (e) => {
        let url = history.location.pathname
        if (url === '/' || url === '/register' || url === '/studentcourse-payment') return e
    }

    useEffect(() => {
        setHeaderOption(history.location.pathname);
   

    }, [])

    const setHeaderOption = (path) => {
        switch (path) {
            case routerNames.find(item => item === path):
                setPageStyle('lighten-green')
                break;
            case otherRouterName.find(item => item === path):
                setPageStyle('white');
                break;
            default:
                setPageStyle('1');
                break;
        }
    }
		const validation =
      history.location.pathname !== "/register" &&
      history.location.pathname !== "/registerfast";
			const validationHeader =
        history.location.pathname === "/register" ||
        history.location.pathname === "/registerfast";
				
    return (
      <div className={`pageBackground ${pageStyle}`}>
        {history.location.pathname !== "/courses-landing" ? (
          <>
            {validationHeader ? (
              <HeaderPublic />
            ) : (
              <div className="header-container">
                <HeaderPublic />
              </div>
            )}
          </>
        ) : null}
        <div className={validation ? "bodySection" : ""}>
          {validation && activeElement(<Steppers />)}
          <Switch>
            <Route
              path={`${match.path}courses-landing`}
              component={CourseLanding}
            />
            <Route
              path={`${match.path}course-enrollment`}
              component={CourseEnrollment}
            />
            <Route
              path={`${match.path}sacramento-court`}
              component={SacramentoCourse}
            />
            <Route
              path={`${match.url}studentcourse-payment`}
              component={StudentRedirectPayment}
            />
            <Route
              path={`/:id${match.path}register`}
              component={Register}
            />
            <Route
              path={`${match.path}register`}
              component={Register}
            />
            <Route path={`${match.path}registerfast`} component={RegisterFast} />
            <Route path={`${match.path}paypal-auth`} component={PaypalLogin} />
            <Route
              path={`${match.url}forgot-password`}
              component={ForgetPassword}
            />
            <Route
              path={`${match.url}reset-password`}
              component={ResetPassword}
            />
            <Route path={`${match.path}login`} component={Login} />
            <Route path={`${match.path}`} component={CourseEnrollment} />
          </Switch>
        </div>
        <div className="footer">
          {validation && activeElementFooter(<Footer />)}
        </div>
      </div>
    );
}

export default PublicRouting


export const routerNames = ['/', '/register', '/login', '/home/cart-summary', '/home/az-cart-summary', '/home/fast-lane-cart-summary'];

export const otherRouterName = ['/home/dashboard', '/home/take-survey', '/home/billing', '/home/profile',
    '/home/settings', '/home/course-list'];