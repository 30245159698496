export class DevUrlConstants {
    /* Registration */
    static registrationUrl = '/elmsstudentregistration/studentregistration';
    /* Login */
    static loginUrl = '/elmsstudentlogin/studentlogin';
    static googleSignUrl = '/elmsstudentgooglelogin/studentgooglelogin';
    /* Logout */
    static logoutUrl = '/elmsstudentlogout/studentlogout';
    /* State and Category List */
    static getSteteWithCategoryListUrl = '/improv-course-config/states-reasons/';
    static getSteteListUrl = '/improv-course-config/state/states';
    static getCourseList = "/elmsstudentcourses/courses";
    static getPaymentClientToken = "/elmsstudentpaymentclienttoken/paymentclienttoken";
    static checkoutUrl = "/elmsstudentpaymentcheckout/studentpaymentcheckout";
    static parentCheckoutUrl = "/elmsstudentparentpaymentcheckout/parentpaymentcheckout";
    static getCourseInfoDetails = '/elmscourseinfodetail/courseinformation';
    static getCoursePriceDetails = '/elmsstudentcoursepricedetails/courseprice';
    static getStudentDetails = '/elmsstudentdetails/studentdetails';
    static getFlexifieldsList = '/elmsstudentcourseflexifields/flexifields';
    static saveflexifield = '/elmsstudentsavecourseflexifields/flexifields';
    static saveDiversionFeeFlexiField = '/elmsstudentsavediversionflexifields/diversion-flexifields';

    /* Dashboard */
    static dashboardListOfChaptersUrl = '/elmsstudentcoursedashboard/coursedashboard';
    static chapterLaunch = '/elmsstudentchapterlaunch/chapterLaunch';
    static multiCourseDashboardUrl = '/elmsstudentcoursesdashboard/coursesdashboard';
    static upsellcoursesUrl = '/elmsstudentupsellcourses/upsellcourses';
    static resetCourseUrl = '/elmsstudentresetcourse/resetcourse';
    /* SecurityQuestion */
    static getSecurityQuestionsUrl = '/elmsstudentsecurityquestions/studentsecurityquestions';
    static saveSecurityQuestionUrl = '/elmsstudentsavesecurityquestion/savestudentsecurityquestions';
    static getRandomSecurityQuestionUrl = '/elmsstudentrandomsecurityquestions/randomsecurityquestion';
    /*Chapter Quiz */
    static getChapterQuizListUrl = '/elmsstudentchapterquizandfinalquiz/studentquizlaunch';
    static updateChapterStatus = '/elmsstudentchapterstatusupdate/chapterstatusupdate';
    static saveFinalQuizResult = '/elmsstudentsavefinalquiz/studentfinalquiz';
    static chapter_CourseStatusUpdateUrl = '/elmsstudentchapterandcoursestatusupdate/statusupdate';
    /*My Orders*/
    static getStudentPurchaseDetails = '/elmsstudentpurchasedcourses/studentpurchasedcourses';
    static changePasswordUrl = '/elmsstudentchangepassword/changepassword';
    static forgotPasswordUrl = '/elmsstudentforgotpassword/studentforgotpassword';
    static resetPasswordUrl = '/elmsstudentsetpassword/studentsetpassword';
    /* completedmessageUrl */
    static studentCourseCompletedMessageUrl = '/elmsstudentcoursecompletedmessage/studentcoursecompletedmessage';
    /* Discount code check*/
    static getStateWiseDiscountDetailsUrl = '/elmsstudentdiscount/discountCode';
    static giftCertificateUrl = '/elmsstudentgiftcertificate/giftcertificatecode';
    static sacramentoCourtCourseUrl = '/elmsstudentcountycourses/countycourse';
    static studentPaymentRefundUrl = '/elmsstudentpaymentrefund/studentpaymentrefund';
    /* chapter or Final Quiz Validation */
    static chapterFinalQuizValidationUrl = '/elmsstudentvalidatequiz/quiz';
    static typingDnaSave_Verify = "/elmsstudenttypingdnapattern/typingdna";
    static downloadPaymentReceiptUrl = '/improv-student/student-certificate/receipt';
    static emailPaymentReceiptUrl = "/improv-student/student-certificate/send/payment-receipt";

    /* studentfootprint */
    static studentfootprintUrl = '/elmsstudent-footprint/studentfootprint';

    /* support ticket */
    static supportTicketUrl = '/elms-student-support-ticket/studentsupportticket';

    /*Document*/
    static getDocumentCatalogItems = '/improv-course-config/catalog/';
    static getDocumentList = '/improv-student/student-course-document/range?';
    static saveDocument = '/improv-student/student-course-document/';

    static universalCheckoutUrl = '/improv-student/student/upsell/';

    //certificate download
    static certificateDownloadUrl = '/improv-student-course-dashboard/student/student-course-completed-certificate'
}
