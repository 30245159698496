import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function WarningModal(props) {

    const { modalShow = false, handleClose, handleYesEvent } = props;
    const _handleClose = () => handleClose(false);


    return (
        <Modal show={modalShow} onHide={_handleClose} centered backdrop="static" keyboard={false} className=" white-rounded-modal timeExceededModal">
            <Modal.Body>
                <div className="timeExceededModal-body">
                    <h4 className='text-style'>Your Progress will be saved!</h4>
                    <h5 className='text-style'>Continue?</h5>
                </div>
                <div className='pt-1 d-flex'>
                    <Button type='primary' className="blue-btn px-5 mx-auto mt-4 " onClick={handleYesEvent}>Yes</Button>
                    <Button type='primary' className="blue-btn-outline px-5 mx-auto mt-4 " onClick={_handleClose}>No</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default WarningModal;
