import React from 'react'
import { Alert, Modal } from 'react-bootstrap'
import { CheckCircleIcon, LockIcon } from '../../common/assets/Images'

export default function SuccessMessageModal(props) {
    const { successMessage } = props
    return (
        <>
            <Modal show={true} backdrop="static" keyboard={false} id="messageModal" centered>
                <Modal.Body className="messageModal">
                    <Alert variant={"success"}>
                        <span className="success-icon"><CheckCircleIcon /></span>
                        <span className="danger-icon"><LockIcon /></span>
                        {successMessage}
                    </Alert>
                </Modal.Body>
            </Modal>
        </>
    )
}
