export class StageUrlConstant {
    /* Registration */
    static registrationUrl = '/elms-student-registration-staging-non-prod/studentregistration';
    static getOrganisationUrl = '/improv-master/organisation/logo';
    static emailVerificationUrl = "/improv-student/student/email-verification/"
    static emailResendVerificationUrl = "/improv-student/student/resend-verification-code/"

    /* Login */
    static initloginUrl = '/improv-bdi-authentication/student/bdi/login';
    static loginUrl = '/elms-student-login-staging-non-prod/studentlogin';
    static googleSignUrl = '/elms-student-googlelogin-staging-non-prod/studentgooglelogin';
    /* Logout */
    static logoutUrl = '/elms-student-logout-staging-non-prod/studentlogout';
    /* State and Category List */
    static courseMappingUrl = '/improv-course-config/courseconfigurations/'
    static getSteteWithCategoryListUrl = '/improv-course-config/states-reasons/';
    static getSteteListUrl = '/improv-course-config/state/states';
    static getCourseList = "/elms-student-courses-staging-non-prod/courses";
    static getPaymentClientToken = "/elms-student-paymentclienttoken-staging-non-prod/paymentclienttoken";
    static checkoutUrl = "/elms-student-paymentcheckout-staging-non-prod/studentpaymentcheckout";
    static upsellCheckoutUrl = "/improv-student/student/upsell/";
    // static parentCheckoutUrl = "/elms-student-paymentcheckout-staging-non-prod/studentpaymentcheckout";
    static parentCheckoutUrl = "/elms-student-parentpaymentcheckout-staging-non-prod/parentpaymentcheckout";
    static getCourseInfoDetails = '/elms-student-courseinfodetail-staging-non-prod/courseinformation';
    static getCoursePriceDetails = '/elms-student-coursepricedetails-staging-non-prod/courseprice';

    static getStudentDetails = '/elms-student-detail-staging-non-prod/studentdetails';
    static getFlexifieldsList = '/elms-student-courseflexifields-staging-non-prod/flexifields';
    static saveflexifield = '/elms-student-savecourseflexifields-staging-non-prod/flexifields';
    static saveDiversionFeeFlexiField = '/elms-student-save-diversion-flexifields-staging-non-prod/diversion-flexifields';
    /* Dashboard */
    // static dashboardListOfChaptersUrl = '/elms-student-dashboard-staging-non-prod/dashboard';improv-course-config​/configcourseprice​/

    static getUpSellApi = "/improv-course-config/configcourseprice/";
    static saveAZUpsellUrl = "/improv-student/student/upsell/";


    static dashboardListOfChaptersUrl = '/elms-student-course-dashboard-staging-non-prod/coursedashboard';
    static chapterLaunch = '/elms-student-chapter-launch-staging-non-prod/chapterLaunch';
    static multiCourseDashboardUrl = '/elms-student-courses-dashboard-staging-non-prod/coursesdashboard';
    static upsellcoursesUrl = '/elms-student-upsell-courses-staging-non-prod/upsellcourses';
    static resetCourseUrl = '/elms-student-reset-course-staging-non-prod/resetcourse';

    /* SecurityQuestion */

    static getSecurityQuestionsUrl = '/elms-student-securityquestions-staging-non-prod/studentsecurityquestions';
    static saveSecurityQuestionUrl = '/elms-student-savesecurityquestion-staging-non-prod/savestudentsecurityquestions';
    static getRandomSecurityQuestionUrl = '/elms-student-randomsecurityquestions-staging-non-prod/randomsecurityquestion';
    /*Chapter Quiz */
    static getChapterQuizListUrl = '/elms-student-chapter-quiz-and-final-quiz-staging-non-prod/studentquizlaunch';
    static updateChapterStatus = '/elms-student-chapter-status-update-staging-non-prod/chapterstatusupdate';
    static saveFinalQuizResult = '/elms-student-save-final-quiz-staging-non-prod/studentfinalquiz';
    static chapter_CourseStatusUpdateUrl = '/elms-student-chapter-course-status-update-staging-non-prod/statusupdate';

    /*My Orders*/
    static getStudentPurchaseDetails = '/elms-student-purchased-courses-staging-non-prod/studentpurchasedcourses';
    static changePasswordUrl = '/elms-student-change-password-staging-non-prod/changepassword';
    static forgotPasswordUrl = '/elms-student-forgotpassword-staging-non-prod/studentforgotpassword';
    static resetPasswordUrl = '/elms-student-setpassword-staging-non-prod/studentsetpassword';
    /* completedmessageUrl */
    static studentCourseCompletedMessageUrl = '/elms-student-course-completed-message-staging-non-prod/studentcoursecompletedmessage';
    /* Discount code check*/
    static getStateWiseDiscountDetailsUrl = '/elms-student-discount-staging-non-prod/discountCode';
    static giftCertificateUrl = '/elms-student-gift-certificate-staging-non-prod/giftcertificatecode';
    static sacramentoCourtCourseUrl = '/elms-student-county-courses-staging-non-prod/countycourse';
    static studentPaymentRefundUrl = '/elms-student-payment-refund-staging-non-prod/studentpaymentrefund';

    /* chapter or Final Quiz Validation */
    static chapterFinalQuizValidationUrl = '/elms-student-validatequiz-staging-non-prod/quiz';

    static typingDnaSave_Verify = "/elms-student-typingdnapattern-staging-non-prod/typingdna";
    static downloadPaymentReceiptUrl = '/improv-student/student-certificate/receipt';
    static emailPaymentReceiptUrl = "/improv-student/student-certificate/send/payment-receipt";

    /* studentfootprint */
    static studentfootprintUrl = '/elms-student-footprint-staging-non-prod/studentfootprint';

    /* support ticket */
    static supportTicketUrl = '/elms-student-support-ticket-staging/studentsupportticket';

    /* zipperapi/ */
    static zipperUrl = '/zipperapi/';

    /*Document*/
    static getDocumentCatalogItems = '/improv-course-config/catalog/';
    static getDocumentList = '/improv-student/student-course-document/range?';
    static saveDocument = '/improv-student/student-course-document/';
    static saveWebcamUrl = '/improv-student/student-facial-recognition';
    static updateStudentCourseTime = '/improv-student-course-dashboard/student/chapter/time'
    /*evs time spend*/
    static time_managementUrl = '/improv-student/student_course/time_management';
    static universalCheckoutUrl = '/improv-student/student/upsell/';

    /*payment method*/
    static tilledPaymentMethod = '/improv-student/student/course/payment/tilled/paymentMethod/';

    //certificate download
    static certificateDownloadUrl = '/improv-student-course-dashboard/student/student-course-completed-certificate'
}