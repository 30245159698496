import React, { useEffect, useState } from 'react'
import environment from '../../environments/environment'
import { chatBtnClickService } from '../../services/chatBtnClickService';
import { chatCommonService } from '../../services/chatCommonService';

export default function ChatUI() {

    const [universal, setUniversal] = useState([])

    useEffect(() => {
        chatBtnClickService.onMessage().subscribe((res) => {
            let Comm100API = window.Comm100API;
            if (res && res.text && res.text.toString() === 'true' && Comm100API) {
                Comm100API.do('livechat.button.click');
            }
            else {
                if (Comm100API) {
                    Comm100API.do('livechat.button.click');
                }
            }
        })
    }, [])

    useEffect(() => {
        chatCommonService.onMessage().subscribe((res) => {
            setUniversal(res)
        });
    }, [universal])

    return (
        <>
            {
                universal && universal.isComm100Required ? (
                    <>
                        <div id="comm100-button-4b4b4c00-0000-0000-0000-008e000189f9"></div>
                        <div id={environment.comm100ButtonID} className="comm100-icon"></div>
                    </>) : null
            }

        </>

    )
}
