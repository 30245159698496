import React from 'react';
import { createTheme } from "@material-ui/core/styles";
import PropagateLoader from "react-spinners/PropagateLoader";

export const ymdConvert = (object) => {
    if (object === null || object === '') {
        return null;
    }
    else {
        const Param = new Date(object);
        let d = Param.getDate().toString();
        let m = 1 + Number(Param.getMonth())
        let y = Param.getFullYear().toString();
        let monthvalue = (m.length === 1) ? '0' + m.toString() : m.toString();
        let day = (d.length === 1) ? '0' + d : d;
        return y + '-' + monthvalue + '-' + day;
    }
}

export const formLabelsTheme = createTheme({
    overrides: {
        MuiFormLabel: {
            asterisk: {
                "&$error": {
                    color: "#ff0000"
                }
            }
        }
    }
});

export const returnType = (type, name) => {
    switch (type) {
        case 'DATE':
            return 'date';
        case 'DROPDOWN':
            return 'string';
        case 'TEXT':
            return 'string';
        case 'NUMBER':
            if (name === 'SSN' || name.toLowerCase().includes('phone' || 'mobile')) {
                return 'string'
            }
            else {
                return 'number';
            }

        default:
            return null;
    }
}
export const returnValidation = (type, isMandatory, fieldname) => {
    let validationObjectOne = [
        {
            type: "nullable",
            params: ["This field is required"]
        }
    ];
    let validationObjectTwo = [
        {
            params: ["This field is required"]
        },
        {
            type: "min",
            params: [2, "Minimum 2 characters required"]
        },
        {
            type: "max",
            params: [40, "Maximum 40 characters allowed"]
        }
    ];
    let validateAgentEmail = [
        {
            params: ["This field is required"]
        },
        {
            type: "min",
            params: [2, "Minimum 2 characters required"]
        },
        {
            type: "max",
            params: [100, "Maximum 10 characters allowed"]
        }
    ];
    let validateMobileNumber = [
        {
            params: ["This field is required"]
        },
        {
            type: "min",
            params: [10, "Minimum 10 characters required"]
        },
        {
            type: "max",
            params: [10, "Maximum 10 characters allowed"]
        }
    ];
    if (isMandatory) {
        validationObjectOne = [
            {
                type: "required",
                params: ["This field is required"]
            }
        ];
        validationObjectTwo = [
            {
                type: "required",
                params: ["This field is required"]
            },
            {
                type: "min",
                params: [2, "Minimum 2 characters required"]
            },
            {
                type: "max",
                params: [40, "Maximum 40 characters allowed"]
            }
        ];
        validateMobileNumber = [
            {
                type: "required",
                params: ["This field is required"]
            },
            {
                type: "min",
                params: [10, "Minimum 10 characters required"]
            },
            {
                type: "max",
                params: [10, "Maximum 10 characters allowed"]
            }
        ];
        validateAgentEmail = [
            {
                type: "required",
                params: ["This field is required"]
            },
            {
                type: "min",
                params: [2, "Min 2 characters required"]
            },
            {
                type: "max",
                params: [100, "Max 10 characters allowed"]
            }
        ];
    }
    switch (type) {
        case 'DATE':
            return validationObjectOne;
        case 'DROPDOWN':
            return validationObjectOne;
        case 'NUMBER':
            if (fieldname.toLowerCase().includes('phone' || 'mobile')) {
                return validateMobileNumber
            }
            else {
                return validationObjectOne;
            }
        case 'TEXT':
            if (fieldname.toLowerCase().includes('agent' || 'email')) {
                return validateAgentEmail
            }
            else {
                return validationObjectTwo;
            }
        default:
            return [];
    }
}


export const getRefFieldValue = (item) => {
    switch (item.flexiFieldType) {
        case 'TEXT':
            return item.value;
        case 'DROPDOWN':
            return (item.dropdownName ? item.dropdownName : "");
        case 'DATE':
            return ymdConvert(item.value);
        case 'NUMBER':
            return item.value;
        default:
            return null;
    }
}


export const renderTextFieldErrors = (errors, row, touched, confirmDLNumberErrorMsg) => {    
    if (errors[row.id] && touched[row.id]) return (<span className="error">
        {errors[row.id]}      
    </span>);
    return null;
}

export const renderSSNFieldErrors = (errors, row, touched) => {
    if (touched[row.id]) {
        if (row.value.length < 11) {
            if (errors[row.id] == 'This field is required') {
                return (
                    <span className="error">
                        {row.label + ' is required'}
                    </span>
                )
            }
            else {
                return (
                    <span className="error">
                        {'Enter a valid SSN number'}
                    </span>
                )
            }
        }
        else {
            return null;
        }
    }
    else {
        return null;
    }
}

export const renderFieldErrors = (errors, row, touched) => {
    if (errors[row.id] && touched[row.id]) return (<span className="error">
        {errors[row.id] == 'This field is required' ? row.label + ' is required' : ''}
    </span>)

    return null;
}

export const Loader = ({ loader = false }) => {
    if (!loader) return null;
    return <div className="loader"> <PropagateLoader size={15} /></div>;
}

export const FormErrorMessage = ({ errorMessage = "" }) => {
    if (!errorMessage) return null;
    return <span style={{ color: 'red' }}>{errorMessage}</span>;
}

export const nullChecker = (value) => {
    if (value) {
        return value;
    }
    else return null;
}


