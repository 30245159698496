import moment from "moment";
import { affiliateAndGoogleAnalysisCall, getScheduleDate, getScheduleTime, getTrxLine } from "../components/cart-and-checkout/PaymentDetailsHelper";
import { EncryptStorage } from "../services/EncryptStorage";
import { GenericAPIService } from "../services/GenericAPIService";
import { UrlConstants } from "../utils/constants/UrlConstant";

const useBuyCourse = (setStep, setLoader) => {
  const studentId = 166884;
  const buy = (nonceToken, dataCollectorDeviceData) => {
    setLoader(true)
    const giftCertificateCodeId = EncryptStorage.getLocalStorage(
      "giftCertificateCodeId"
    );
    const countyLedgerId = EncryptStorage.getLocalStorage("countyLedgerId");
    const discountAmount = EncryptStorage.getLocalStorage("discountAmount");
    const discountType = EncryptStorage.getLocalStorage("discountType");
    const coursePrice = EncryptStorage.getLocalStorage("totalPrice");
    const studentPaymentTrxLines = EncryptStorage.getLocalStorage(
      "studentPaymentTrxLines"
    );
    if (
      Array.isArray(studentPaymentTrxLines) &&
      studentPaymentTrxLines.length > 0 &&
      discountAmount
    ) {
      if (discountType === "promocode") {
        const priceItem = EncryptStorage.getLocalStorage("promoCodePriceId");
        studentPaymentTrxLines.push(getTrxLine(priceItem, discountAmount));
      } else if (discountType === "giftcertificate") {
        const priceItem = EncryptStorage.getLocalStorage(
          "giftCertificatePriceId"
        );
        studentPaymentTrxLines.push(getTrxLine(priceItem, discountAmount));
      }
    }
    const finalAmount = parseFloat((coursePrice - discountAmount).toFixed(2));

    setCheckoutPayload(
      finalAmount,
      studentPaymentTrxLines,
      giftCertificateCodeId,
      countyLedgerId,
      false,
      nonceToken,
      dataCollectorDeviceData
    );
  };
  const setCheckoutPayload = async (
    finalAmount,
    studentPaymentTrxLines,
    giftCertificateCodeId,
    countyLedgerId,
    amazonpayflag,
    nonceToken,
    dataCollectorDeviceData
  ) => {
    let scheduleDate = getScheduleDate();
    let scheduleTime = getScheduleTime();

    await handleBraintreePaymentItems(
      finalAmount,
      studentPaymentTrxLines,
      scheduleDate,
      scheduleTime,
      giftCertificateCodeId,
      countyLedgerId,
      amazonpayflag,
      nonceToken,
      dataCollectorDeviceData
    );
  };

  const handleBraintreePaymentItems = async (
    finalAmount,
    studentPaymentTrxLines,
    scheduleDate,
    scheduleTime,
    giftCertificateCodeId,
    countyLedgerId,
    amazonpayflag,
    nonceToken,
    dataCollectorDeviceData
  ) => {
    if (amazonpayflag == false) {
      if (nonceToken) {
        /*   this.setState({ errorMessage: '', message: '' }); */
        /*  this.props.setPaymentLoader(true); */
        let paymentDetailsReturn = await setPaymentPayloadStructure(
          finalAmount,
          "braintree",
          nonceToken
        );

        //let paymentDetailsReturn = await this.setPaymentPayloadStructure(finalAmount, "amazon", '');
        if (paymentDetailsReturn) {
          /* const { amazon_order_reference_id } = this.state; */
          let payload = {
            totalAmount: finalAmount,
            paymentGateway: "braintree",
            ...(localStorage.dcscUpsellItem && {
              isDuplicateCertificateRequest: true,
            }),
            authorizedTransactionId: "",
            nonceFromTheClient: nonceToken,
            organisationId: EncryptStorage.getLocalStorage("organisationId"),
            currency: "USD",
            deviceData: dataCollectorDeviceData ? dataCollectorDeviceData : "",
            studentPaymentTrx: paymentDetailsReturn,
            ...(EncryptStorage.getLocalStorage("isAZflow") && {
              isAzPayment: true,
            }),
          };
          
          if (scheduleDate)
            payload = {
              ...payload,
              drivingSchedule: scheduleDate + "T" + scheduleTime + ".000+00:00",
            };
          if (countyLedgerId) payload.countyLedgerId = countyLedgerId;
          savePaymentDetails(payload, amazonpayflag);
        }
      }
    }
    /*  if (amazonpayflag == true) {
            this.setState({ errorMessage: '', message: '' });
            this.props.setPaymentLoader(true);
            //let paymentDetailsReturn = await this.setPaymentPayloadStructure(finalAmount, "braintree", this.state.nonceToken);
            let paymentDetailsReturn = await this.setPaymentPayloadStructure(finalAmount, 'amazon', '');
            debugger;
            if (paymentDetailsReturn) {
                debugger;
                const { amazon_order_reference_id } = this.state;
                let payload = {
                    totalAmount: finalAmount,
                    // paymentGateway: "braintree",
                    paymentGateway: 'amazon',
                    // ...(localStorage.dcscUpsellItem && {
                    //     isDuplicateCertificateRequest: true,
                    // }),
                    authorizedTransactionId: amazon_order_reference_id,
                    // nonceFromTheClient: this.state.nonceToken,
                    organisationId: EncryptStorage.getLocalStorage('organisationId'),
                    currency: 'USD',
                    deviceData: this.state.dataCollectorDeviceData ? this.state.dataCollectorDeviceData : '',
                    studentPaymentTrx: paymentDetailsReturn,
                    ...(EncryptStorage.getLocalStorage('isAZflow') && {
                        isAzPayment: true,
                    }),
                };
                debugger;
                if (scheduleDate)
                    payload = {
                        ...payload,
                        drivingSchedule: scheduleDate + 'T' + scheduleTime + '.000+00:00',
                    };
                if (countyLedgerId) payload.countyLedgerId = countyLedgerId;
                // const upsellItem = EncryptStorage.getLocalStorage('azSeletedCardList')
                // if (upsellItem) {
                //     PaymentHooksForUpsell(upsellItem)
                // }
                this.savePaymentDetails(payload, amazonpayflag);
            }
        }  */
  };

  const setPaymentPayloadStructure = (
    finalAmount,
    braintreePaymentMode,
    nonce
  ) => {
    let studentPaymentTrxLines = [];
    let UpSellPaymentRequest = [];
    const coursePriceDetails =
      EncryptStorage.getLocalStorage("coursePriceDetails");

    if (coursePriceDetails) {
      if (coursePriceDetails.priceItem) {
        const basePriceLineTrx = getTrxLine(
          coursePriceDetails.priceItem,
          coursePriceDetails.basePrice
        );
        studentPaymentTrxLines.push(basePriceLineTrx);
      }

      if (coursePriceDetails.groupPriceTransactions) {
        for (let group of coursePriceDetails.groupPriceTransactions) {
          if (group.coursePriceType.groupName.toLowerCase() === "mail") {
            handleMailPriceGroupItem(group, studentPaymentTrxLines);
          } else if (group.coursePriceType.groupName.toLowerCase() === "bhwl") {
            handleBHWLPriceGroupItem(group, studentPaymentTrxLines);
          } else if (group.selected === true) {
            studentPaymentTrxLines.push(getTrxLine(group, group.price));
          }
        }
      }

      let discountObject = EncryptStorage.getLocalStorage(
        "appliedDiscountObject"
      );
      let discountPrice = EncryptStorage.getLocalStorage("discountPrice");
      if (discountPrice && discountPrice.toString() !== 0 && discountObject) {
        handleDiscount(studentPaymentTrxLines, discountPrice, discountObject);
      }

      let upsellCourseList = localStorage.testSeletedUpsellCourseDetails;
      if (upsellCourseList) {
        upsellCourseList = JSON.parse(upsellCourseList);
        upsellCourseList.map((item) => {
          if (item.configCourseId) {
            for (let configCourseItem of item.configCoursePriceDto) {
              if (configCourseItem.coursePriceType.code === "BASEPR") {
                UpSellPaymentRequest.push({
                  totalAmount: configCourseItem.price,
                  paymentGateway: braintreePaymentMode,
                  // ...(localStorage.dcscUpsellItem && {
                  //     isDuplicateCertificateRequest: true,
                  // }),
                  nonceFromTheClient: nonce,
                  paymentMode: this.state.paymentReference,
                  paymentReference: this.state.paymentReference,
                  student: {
                    studentId: studentId,
                  },
                  configCourse: {
                    configCourseId: item.configCourseId,
                  },
                  studentPaymentTrxLines: [
                    {
                      configCoursePrice: configCourseItem,
                      price: configCourseItem.price,
                    },
                  ],
                });
              }
            }
          }
        });
      }

      let isUpGradeCourseActive = EncryptStorage.getLocalStorage(
        "isUpGradeCourseActive"
      );
      let discountResponseObject = EncryptStorage.getLocalStorage(
        "discountResponseObject"
      );
      let giftCertificateCodeId = null;
      if (discountResponseObject) {
        if (
          discountResponseObject.discount &&
          discountResponseObject.discount.giftCertificateCodeId
        ) {
          giftCertificateCodeId =
            discountResponseObject.discount.giftCertificateCodeId;
        }
      }

      let parentEmailFlow = EncryptStorage.getLocalStorage("parentEmailFlow");
      if (UpSellPaymentRequest.length === 0) {
        return [
          {
            totalAmount: finalAmount,
            paymentGateway: braintreePaymentMode,
            // ...(localStorage.dcscUpsellItem && {
            //     isDuplicateCertificateRequest: true,
            // }),
            nonceFromTheClient: nonce,
            paymentMode: "",
            paymentReference: "",
            student: {
              studentId: studentId,
            },
            configCourse: {
              configCourseId: EncryptStorage.getLocalStorage("configCourseId"),
            },
            ...(parentEmailFlow && {
              studentPaymentTrxId: EncryptStorage.getLocalStorage(
                "studentPaymentTrxId"
              ),
            }),
            ...(giftCertificateCodeId && {
              giftCertificateCodeId: giftCertificateCodeId,
            }),
            studentPaymentTrxLines: [...studentPaymentTrxLines],
          },
        ];
      } else {
        let coursePayDetails = [];
        coursePayDetails.push({
          totalAmount: EncryptStorage.getLocalStorage("totalPrice_"),
          paymentGateway: braintreePaymentMode,
          // ...(localStorage.dcscUpsellItem && {
          //     isDuplicateCertificateRequest: true,
          // }),
          nonceFromTheClient: nonce,
          paymentMode: "card",
          paymentReference: "card",
          ...(parentEmailFlow && {
            studentPaymentTrxId: EncryptStorage.getLocalStorage(
              "studentPaymentTrxId"
            ),
          }),
          student: {
            studentId:
              studentId /* EncryptStorage.getLocalStorage("studentId") */,
          },
          configCourse: {
            configCourseId: EncryptStorage.getLocalStorage("configCourseId"),
          },
          ...(giftCertificateCodeId && {
            giftCertificateCodeId: giftCertificateCodeId,
          }),
          studentPaymentTrxLines: [...studentPaymentTrxLines],
        });
        for (let upsell of UpSellPaymentRequest) {
          coursePayDetails.push(upsell);
        }
        return coursePayDetails;
      }
    }
  };
  const handleDiscount = (
    studentPaymentTrxLines,
    discountPrice,
    discountObject
  ) => {
    let tempconfigCoursePrice = discountObject ? discountObject[0] : [];
    tempconfigCoursePrice["price"] = Number(discountPrice);
    let tempDiscountTrxLines = {
      price: Number(discountPrice),
      configCoursePrice: tempconfigCoursePrice,
    };
    studentPaymentTrxLines.push(tempDiscountTrxLines);
  };
  const handleMailPriceGroupItem = (group, studentPaymentTrxLines) => {
    for (let trans of group.mailPrice.mailItems) {
      if (trans.selected === true) {
        group.tranaction = trans;
        group.price = trans.price;
        studentPaymentTrxLines.push(getTrxLine(trans, trans.price));
      }
    }
  };
  const handleBHWLPriceGroupItem = (group, studentPaymentTrxLines) => {
    if (group.bhwlPrice && group.bhwlPrice.bhwlItems > 0)
      for (let trans of group.bhwlPrice.bhwlItems) {
        if (trans.selected === true) {
          group.tranaction = trans;
          group.price = trans.price;
          studentPaymentTrxLines.push(getTrxLine(trans, trans.price));
        }
      }
  };
  const savePaymentDetails = (payload, amazonpayflag) => {
    localStorage.upsellInitPaymentObject = JSON.stringify(payload);
    if (
      EncryptStorage.getLocalStorage("azPayment") &&
      EncryptStorage.getLocalStorage("azPayment") == "azPayment"
    ) {
      /*   this.azUpSellPayment(payload, amazonpayflag); */
      // this.setPaymentPayloadStructure()
    } else {
      nonAZflowAndInitAZCoursePayment(payload);
    }
  };
  const nonAZflowAndInitAZCoursePayment = (payload) => {
    let _payload = setNonAZflowAndInitAZCoursePaymentPayload(payload);

    _payload.studentPaymentTrx.map((item) => {
      item.studentPaymentTrxLines.map((data) => {
        if (!data.configCoursePrice.hasOwnProperty("price")) {
          data.configCoursePrice.price = data.price;
        }
      });
    });
    /* this.props.setPaymentLoader(true); */
    let checkoutUrl = UrlConstants.checkoutUrl;

    let zeroPaymentOfferDetails = EncryptStorage.getLocalStorage(
      "zeroPaymentOfferDetails"
    );

    if (zeroPaymentOfferDetails) {
      if (zeroPaymentOfferDetails.type == "DISCOUNTCODE") {
        _payload.studentPaymentTrx[0].discountId =
          zeroPaymentOfferDetails.discount.discountId;
      }
    }

    GenericAPIService.customAPIMethod(checkoutUrl, _payload, "POST")
      .then(async (res) => {
        
        setStep(2);
        this.props.setPaymentLoader(false);
        if (res.status.success.toLowerCase() === "success") {
          const { data = "" } = res;
          const { studentCourse = "", configCourse = "" } = data;
          const configCourseId = configCourse
            ? configCourse.configCourseId
            : "";
          const studentCourseId = studentCourse
            ? studentCourse.studentCourseId
            : "";
          EncryptStorage.setToLocalStorage("configCourseId", configCourseId);
          EncryptStorage.setToLocalStorage("studentCourseId", studentCourseId);
          const _message = res.status.message;
          this.setState({ message: _message, disableBtn: true });
          setTimeout(() => {
            if (localStorage.viewPage === "addUpsellCourse") {
              this.props.history.push("/home/course-list");
            } else {
              let isAZflowFlag = EncryptStorage.getLocalStorage("isAZflow");
              if (isAZflowFlag) {
                this.getPersonalInfo();
              } else {
                const element = document.getElementsByClassName(
                  "braintree-method__label--small"
                );
                affiliateAndGoogleAnalysisCall();
                /*  this.props.history.push("/home/view-billing-details"); */
              }
            }
          }, 3000);
        } else {
          const _message = res.status.message;
          this.setState({ errorMessage: _message, paymentFailed: true });
        }
      })
      .catch((err) => {
				 
        
        const _message =
          "Error, While Transaction, if money got debited will be returned with in 4 days";
        /*  this.setState({ errorMessage: _message, paymentFailed: true });
        this.props.setPaymentLoader(false); */
      }).finally(()=>{
				setLoader(false);
			});
  };
  const setNonAZflowAndInitAZCoursePaymentPayload = (payload) => {
    let tempStudentPaymentTrxId = EncryptStorage.getLocalStorage(
      "studentPaymentTrxId"
    );
    let isUpGradeCourseActive = EncryptStorage.getLocalStorage(
      "isUpGradeCourseActive"
    );
    if (isUpGradeCourseActive) {
      let coursePriceDetails =
        EncryptStorage.getLocalStorage("coursePriceDetails");
      if (
        coursePriceDetails &&
        coursePriceDetails.priceItem &&
        coursePriceDetails.priceItem.configCourse &&
        coursePriceDetails.priceItem.configCourse.configCourseId
      ) {
        let tempMainconfigCourseId =
          coursePriceDetails.priceItem.configCourse.configCourseId;
        let _payload = payload;
        let filterStudentPaymentTrx = [];
        filterStudentPaymentTrx = payload.studentPaymentTrx.filter((obj) => {
          if (tempMainconfigCourseId != obj.configCourse.configCourseId) {
            return obj;
          }
        });
        if (filterStudentPaymentTrx.length > 0) {
          filterStudentPaymentTrx[0].configCourse.configCourseId =
            EncryptStorage.getLocalStorage("upgradeConfigCourseId");
        }
        _payload.studentPaymentTrx = filterStudentPaymentTrx;
        payload = _payload;
      }
    }
    if (tempStudentPaymentTrxId) {
      if (payload.studentPaymentTrx.length != 0) {
        let tempData = payload.studentPaymentTrx;
        tempData.map((obj) => {
          obj.studentPaymentTrxId = EncryptStorage.getLocalStorage(
            "studentPaymentTrxId"
          );
          return obj;
        });
        payload.studentPaymentTrx = tempData;
      }
    }
    return payload;
  };

  return {
    buy,
  };
};

export default useBuyCourse;