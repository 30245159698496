import React from 'react'
import { lockKey } from '../../common/assets/Images'

export default function GoDaddySecured() {
    return (
        <div className="secure">
            <img src={lockKey} />
            <div className="agreeText">
                <p>This Website is secured with a GoDaddy.com Web Server Certificate. Transactions<br /> on the site are protected with up to 256-bit Secure Sockets Layer encryption.</p>

            </div>
        </div>
    )
}
