export class Constants {
  static imageFormats = ["jpeg", "png", "jpg", "gif"];
  static categoryFormats = ["jpeg", "png", "jpg"];
  static serverErrorMessage =
    "Oops. Something went wrong. Please try again later.";
  static stateIdError =
    'To continue with the selection of "Other information" you must fill out the state field in your "Profile" section';
}

export class RouteConstants {
  static routes = [
    "/courses-landing",
    "/course-enrollment",
    "/sacramento-court",
    "/studentcourse-payment",
    "/register",
    "/registerfast",
    "/paypal-auth",
    "/forgot-password",
    "/reset-password",
    "/home",
    "/login",
    "/.well-known/apple-developer-merchantid-domain-association",
  ];
}
