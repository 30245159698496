import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import TextInputField from '../common/forms/TextInputField';
import { Formik, ErrorMessage } from 'formik'
import InputSelect from '../common/forms/InputSelect';
import { Schema } from './Schema';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { ToastContainer } from 'react-toastify';
import {
    DateFnsUtils, MuiPickersUtilsProvider,
    KeyboardDatePicker, Accordion, AccordionDetails, AccordionSummary
} from '../common/assets/UIComponents';
import { HOF_Methods, UrlConstants, GenericAPIService, Constants, EncryptStorage }
    from '../common/imports/CommonImport';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import PropagateLoader from "react-spinners/PropagateLoader";
import { GenericAPIServiceWithToken } from '../../services/GenericAPIServiceWithToken';
import ParentVerificationHeader from './layout/ParentVerificationHeader';
import { parentVerificationHelper } from './ParentVerificationHelper';

export default function ParentVerification(props) {

    localStorage.viewPage = 'personal';
    const { history } = props;
    const [stateList, setStateList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [isInvalidZipCode, setIsInvalidZipCode] = useState(false);
    const [isInvalidAddress, setIsInvalidAddress] = useState(false);
    const [personalInfoErrorMessage, setPersonalInfoErrorMessage] = useState('');
    const personalInfoForm = useRef();
    const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 80));
    const [stateErrorMessage, setStateErrorMessage] = useState('');
    const [verifiedMessage, setVerifiedMessage] = useState('')
    const [studentDetails, setStudentDetails] = useState([])
    const [personalInfoInitialValues, setPersonalInfoInitialValues] = useState({
        firstName: '', middleName: '', lastName: '', dateOfBirth: null, gender: '', parentEmail: '',
        mobileNumber: '', street: '', apartment: '', city: '', state: { stateId: '' }, zip: ''
    });

    useEffect(() => {
        let isPersonalInfoState = EncryptStorage.getLocalStorage('isPersonalInfoState')
        if (isPersonalInfoState) {
            EncryptStorage.setToLocalStorage('sessionTime', 'true')
        }
        let backendLogin = EncryptStorage.getLocalStorage('backendLogin');
        if (typeof backendLogin === 'boolean' && backendLogin) {
            getPersonalInfo();
        }
        else {
            const fetchData = () => {
                getPersonalInfo();
                loadStateList();
            }
            fetchData();
            return () => {
                history.go(1);
            }
        }
    }, []);

    const loadStateList = () => {
        const payload = {};
        const url = UrlConstants.getSteteListUrl + '?IsActive=true';
        GenericAPIService.customAPIMethodClone(url, payload, 'GET').then(res => {
            if (HOF_Methods.getStatus(res) === 'success') {
                const { data = [] } = res;
                setStateList(data.reverse())
            } else {
                setStateErrorMessage(res.data.status.message);
            }

        }).catch(err => {
            setLoader(false)
            setPersonalInfoErrorMessage(Constants.serverErrorMessage)
        });
    }

    const getSuccessResponse = (res) => {
        const { data = "" } = res;
        parentVerificationHelper.setLocalStorageValuesForGetPersonalInfo(data);
        let backendLogin = EncryptStorage.getLocalStorage('backendLogin');
        if (typeof backendLogin === 'boolean' && backendLogin) {
            history.push('/home/dashboard');
        }
        const student = data;
        setStudentDetails(data);
        if (student) {
            let tempPersonlInfoInitialValues = parentVerificationHelper.setParentVerificationInitialValues(student);
            setPersonalInfoInitialValues({...tempPersonlInfoInitialValues});
        }
    }

    const getPersonalInfo = () => {
        setLoader(true);
        let studentId = EncryptStorage.getLocalStorage('studentId');
        const payload = {
            "studentId": studentId
        };
        const url = UrlConstants.getStudentDetails;
        GenericAPIService.customAPIMethod(url, payload, 'GET').then(res => {
            setLoader(false);
            if (HOF_Methods.getStatus(res) === 'success') {
                getSuccessResponse(res)
            } else {
                setPersonalInfoErrorMessage(res.status.message);
            }
        }).catch(error => {
            setLoader(false)
            setPersonalInfoErrorMessage(Constants.serverErrorMessage)
        });
    }

    const handleZipCodeEvent = (_zipCode, setFieldValue, values) => {
        setIsInvalidZipCode(false)
        setFieldValue('zip', _zipCode)


    }

    const handleZipCodeClearEvent = (setFieldValue) => {
        setFieldValue('zip', '');
        setIsInvalidZipCode(false);
        setFieldValue('city', '');
    }

    const submittingParentDetails = (newPayloadMap) => {
        let url = UrlConstants.getStudentDetails;
        GenericAPIService.customAPIMethod(url, newPayloadMap, 'PUT').then(res => {
            setLoader(false);
            if (HOF_Methods.getStatus(res) === 'success') {
                navigateToCourseListPage()
            } else {
                setStateErrorMessage(res.data.status.message);
                setLoader(false)
            }
        }).catch(err => {
            setLoader(false)
        });
    }

    const onSubmitPersonalInfo = (values) => {
        const studentId = EncryptStorage.getLocalStorage('studentId')
        let stateCodeSelected = ""
        if (values.state.stateId) {
            const { stateCode = '' } = stateList.find((item) => item.stateId === values.state.stateId);
            stateCodeSelected = stateCode
        }
        let formatDate = parentVerificationHelper.ymdStringConvert(document.getElementsByName('dateOfBirth')[0].value) + 'T08:00:00.000+00:00';
        let payload = parentVerificationHelper.setSubmitPersonalInfoPayload(studentDetails, values, stateCodeSelected, formatDate);
        // let newPayloadMap = {
        //     'student': { ...payload }
        // };
        let newPayloadMap=payload;
        const url =UrlConstants.parentVerificationPostUrl + `?studentId=${studentId}`;
        setLoader(true);
        // "dateOfBirth": "1950-01-31",
        // let verificationPayload = parentVerificationHelper.setSubmitPersonalInfoVerificationPayload(values, stateCodeSelected, formatDate);
        // let verificationApiUrl = 'StudentEvsVerification'
        setVerifiedMessage("")
        GenericAPIService.post(url, newPayloadMap).then(res => {
            if (res?.status.message == "Parent Verified Successfully") {
                setVerifiedMessage("Parent Verified Successfully")
                EncryptStorage.setToLocalStorage("parentVerificationStatus", true)
                navigateToCourseListPage();
                // submittingParentDetails(newPayloadMap)
            }
            else {
                setStateErrorMessage("Parent Identity  Not verified");
                // submittingParentDetails(newPayloadMap)
            }
        }).catch(err => {
            setLoader(false)
        });
    }

    const navigateToCourseListPage = () => {
        const paymentTransactionId = EncryptStorage.getLocalStorage('studentPaymentTrxId');
        if (paymentTransactionId) {
            history.push('/home/dashboard');
        }
        else {
            history.push('/home/course-list');
        }
    }

    return (
        <div className="container">
            {stateErrorMessage && <div className="custom-error"> {stateErrorMessage}</div>}
            <ToastContainer />
            {loader ? <div className="loader"> <PropagateLoader size={15} /></div> : null}
            <div className="personalInfo mt-5">
                <Formik
                    innerRef={personalInfoForm}
                    enableReinitialize={true} onSubmit={onSubmitPersonalInfo}
                    validationSchema={Schema.personalInfoValidationSchema}
                    initialValues={personalInfoInitialValues}>
                    {({ handleSubmit, values, errors, setFieldValue, getFieldProps }) => {
                        return (
                            <div className="faq-content-tab blueCard personalInfo__card">
                                <Accordion expanded={true}>
                                    <AccordionSummary expandIcon={false} aria-controls="panel1bh-content"
                                        id="panel1bh-header">
                                        <ParentVerificationHeader personalInfoInitialValues={personalInfoInitialValues} />
                                    </AccordionSummary>
                                    <AccordionDetails expanded={true}>
                                        <Form noValidate onSubmit={handleSubmit} autoComplete="none" className="form-v2">
                                            <Row className="">
                                                <Col sm={12}>
                                                    {personalInfoErrorMessage ? <span style={{ color: 'red' }}> {personalInfoErrorMessage}</span> : null}
                                                </Col>
                                                <Col sm={12} xl={4} className="mb-3">
                                                    <div className="TextInputField ">
                                                        <TextInputField name="firstName" value={values.firstName} label="First name" type="text" {...getFieldProps('firstName')} required />
                                                        {parentVerificationHelper.getClearIcon(values.firstName, setFieldValue, 'firstName')}
                                                    </div>
                                                </Col>
                                                <Col sm={12} xl={4} className="mb-3">
                                                    <div className="TextInputField">
                                                        <TextInputField name="middleName" value={values.middleName} label="Middle Name" type="text" {...getFieldProps('middleName')} />
                                                        {parentVerificationHelper.getClearIcon(values.middleName, setFieldValue, 'middleName')}
                                                    </div>
                                                </Col>
                                                <Col sm={12} xl={4} className="mb-3">
                                                    <div className="TextInputField">
                                                        <TextInputField required name="lastName" value={values.lastName} label="Last Name" type="text"  {...getFieldProps('lastName')} />
                                                        {parentVerificationHelper.getClearIcon(values.lastName, setFieldValue, 'lastName')}
                                                    </div>
                                                </Col>
                                                <Col sm={12} xl={12}>
                                                    <Row>
                                                        <Col sm={12} xl={4} className="mb-paddingLeft-reset mb-3">
                                                            <div className="TextInputField required">
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <KeyboardDatePicker margin="normal" id="dateOfBirth" disableFuture={true} name="dateOfBirth"
                                                                        label="Birth Date" placeholder="MM-DD-YYYY" inputVariant="standard" format="MM-dd-yyyy"
                                                                        minDate={new Date("1950-01-01T01:00:00")} maxDate={new Date("2005-01-01T01:00:00")}
                                                                        minDateMessage={false} maxDateMessage={false} defaultValue={maxDate}
                                                                        value={values.dateOfBirth ? values.dateOfBirth : null} helperText={''}
                                                                        onChange={value => setFieldValue("dateOfBirth", value)} />
                                                                </MuiPickersUtilsProvider>
                                                                <ErrorMessage component='div' className="error" name="dateOfBirth" />
                                                            </div>
                                                        </Col>
                                                        <Col md={12} xl={4} className="mb-3">
                                                            <div className="TextInputField">
                                                                <TextInputField name="parentEmail" value={values.parentEmail}
                                                                    label="Parent Email" type="email" {...getFieldProps('parentEmail')} />
                                                            </div>
                                                        </Col>
                                                        <Col md={12} xl={4} className="mb-3">
                                                            <div className="TextInputField">
                                                                <NumberFormat variant="standard" label="Mobile Number" name="mobileNumber" placeholder="Mobile Number"
                                                                    customInput={TextField} format="##########"
                                                                    {...getFieldProps('mobileNumber')} />
                                                                {values.mobileNumber.length > 0 ? <i className="TextInputField__icon ">
                                                                    <HighlightOffIcon />
                                                                </i> : null}
                                                                <ErrorMessage component='div' className="error" name="mobileNumber" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={12} xl={8} className="mb-3">
                                                    <div className="TextInputField">
                                                        <TextInputField name="street" value={values.street} required label="Street Address" type="text" {...getFieldProps('street')} />
                                                        {values.street ? <i className="TextInputField__icon clear" onClick={() => { setFieldValue('street', ''); setIsInvalidAddress(false) }}>
                                                            <HighlightOffIcon />
                                                        </i> : null}
                                                        {(isInvalidAddress) && <p className="error">Please provide Valid address </p>}
                                                    </div>
                                                </Col>
                                                <Col md={12} xl={4} className="mb-3">
                                                    <div className="TextInputField">
                                                        <TextField label="Zip code" required
                                                            inputProps={{
                                                                maxLength: 5,
                                                            }}
                                                            name="zip" /**/ value={values.zip} placeholder="Zip code"
                                                            onChange={(e) => { handleZipCodeEvent(e.target.value, setFieldValue, values) }}

                                                        />
                                                        {values.zip ? <i className="TextInputField__icon clear"
                                                            onClick={() => handleZipCodeClearEvent(setFieldValue)}>
                                                            <HighlightOffIcon />
                                                        </i> : null}
                                                        {(isInvalidZipCode) && <p className="error">Please enter valid Zip code</p>}
                                                        <ErrorMessage component='div' className="error" name="zip" />
                                                    </div>
                                                </Col>
                                                <Col xs={6} xl={4} className="mb-paddingLeft-reset mb-3">
                                                    <div className="TextInputField">
                                                        <TextInputField required name="city" value={values.city} label="City" type="text" {...getFieldProps('city')} />
                                                        {parentVerificationHelper.getClearIcon(values.city, setFieldValue, 'city')}
                                                    </div>
                                                </Col>
                                                <Col xs={6} xl={4} className="mb-paddingRight-reset mb-3">
                                                    <div className="TextInputField">
                                                        <InputSelect required name="state.stateId" itemId={"stateId"} variant="standard"
                                                            data={stateList} itemName="stateName" value={values.stateI} label="State" {...getFieldProps('state.stateId')} />
                                                        {stateErrorMessage && !errors.state ? <span style={{ color: 'red' }} >{stateErrorMessage}</span> : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div>
                                                {verifiedMessage === "Identity verified failed." ? <span className='error'>{verifiedMessage}</span> : <span className='success'>{verifiedMessage}</span>}
                                            </div>
                                            <div className="register__button mt-4 mb-2 pb-2 text-center justify-content-center">
                                                <button type="submit" className="btn blue-btn text-uppercase font-weight-bold">SAVE AND CONTINUE</button>
                                            </div>
                                        </Form>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )
                    }}
                </Formik>
            </div>
        </div >
    )
}
