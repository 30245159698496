import React, { useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import { $ } from 'react-jquery-plugin';
import environment from '../../environments/environment';
import { EncryptStorage } from '../../services/EncryptStorage';

export default function ShopperSurvey(props) {

    const [rating, setRating] = useState(0);
    const [informationCardView, setInformationCardView] = useState(false);
    const [studentName] = useState(props.name);
    const [studentEmailID] = useState(props.emailId);
    const [isAgreePolicy, setIsAgreePolicy] = useState(null);
    let baseUrl = environment.baseUrl;

    const handleRating = (rate) => {
        console.log('rate =>' + rate);
        setRating(rate);
    }

    const isAgreePolicyEvent = (e) => {
        setIsAgreePolicy(e.target.checked);
    }

    const submitShopperSurvey = () => {
        if (document.getElementById('agree_customer').checked) {
            let rand = Math.floor((Math.random() * 9999999) + 1);
            let orderid = 'ORDER' + Math.floor((Math.random() * 9999) + 1);
            let CourseSelection = document.getElementById('CourseSelection').value;
            let comments = document.getElementById('comments').value;
            let Overall = rating / 20;
            let url = baseUrl + '/shopperapi/?site=11612&token=HsDc9KQF8VMmPxS&orderid=' + orderid + '&name=' + studentName + '&email=' + studentEmailID + '&disablepopup=0&policylang=eng&Overall=' + Overall + '&email_mandatory=true&comments=' + comments + '&CourseSelection=' + CourseSelection + '&rand=' + rand + '&test=true';
            if (orderid) {
                let settings = {
                    "url": url,
                    "method": "GET",
                    'dataType': "jsonp",
                    "headers": {
                        "accept": "application/json",
                        "Access-Control-Allow-Origin": "*"
                    }
                }
                $.ajax(settings).done(function (data) {
                    directRedirect();
                }).fail(function (data) {
                    directRedirect();
                });
            }
        } else {
            setIsAgreePolicy(false);
        }
    }

    const directRedirect = () => {
        let skipPersonalInfo = EncryptStorage.getLocalStorage("skipPersonalInfo")
        // skip personal information page while comes from the pay later on course eligible is true
        if (skipPersonalInfo) {
            props.props.history.push("/home/dashboard");
        }
        else {
            props.props.history.push("/home/personal-information");
        }
    }

    return (
        <>
            <div id="outer_shopper_approved">
                <div id="shopper_approved" class="sa_start_survey" style={{ top: "0px", display: "block" }}>
                    <div id="sa_outer">
                        <div id="sa_rounded">
                            <div id="sa_header">
                                <img id="sa_close" class="sa_close"
                                    src="https://www.shopperapproved.com/thankyou/images/xbutton.gif"
                                    alt="x" onClick={() => directRedirect()} />
                                <img id="sa_header_img" src="https://www.shopperapproved.com/thankyou/custom/11612.png?ver=1637009137"
                                    alt="Thank You for Your Order" class="" />
                                <span id="sa_header_text"> Thank You for Your Order</span>
                            </div>
                            <div id="sa_ratings">
                                <div class="sa_ratingBox"> How would you rate your overall shopping experience so far?
                                    <Rating onClick={handleRating} ratingValue={rating} />
                                </div>
                                {
                                    (rating !== 0 && rating <= 2) ? (
                                        <div id="sa_lowrating" style={{ display: 'block' }}>
                                            <div class="sa_bottom_br"> It appears from your ratings that you may have an issue that
                                                needs attention. If you left a 1 or 2-Star rating by mistake, please correct it before
                                                continuing. Or, if your rating is correct, please leave us some feedback so that we can
                                                better serve you.
                                            </div>
                                            <a id="stop_rating" class="sa_close">
                                                No thanks. I would rather not participate in rating.
                                            </a>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }
                                <div id="sa_finish_survey">
                                    <div id="sa_comments" class="sa_ratings sa_page">
                                        <div id="sa_comment_descriptions">Please type a quick message about your shopping
                                            experience so far.
                                        </div>
                                        <div>
                                            <textarea id="comments" class="sa_comments sa_countdown sa_countdown50" cols="30"
                                                rows="3" placeholder="Type your message here...">
                                            </textarea>
                                            <div class="sa_feature">
                                                <div id="thanks_txt" style={{ display: "none" }}> </div>
                                            </div>
                                            <span id="sa_thanks_count" style={{ display: "none" }}>
                                                Thank you for your feedback.
                                            </span>
                                        </div>
                                        <div class="sa_bottom_br">
                                            <div class="sa_message" id="sa_comment_message"></div>
                                        </div>
                                        <div id="CourseSelection_2part" class="sa_ratingBox">
                                            <div>Why did you choose this course?</div>
                                            <div class="sa_bottom_br">
                                                <textarea id="CourseSelection" class="sa_comments"
                                                    cols="30" rows="3"></textarea>
                                            </div>
                                        </div>
                                        <div id="sa_tooptin" class="sa_bin">
                                            <div> Should we follow up to make sure you're satisfied with your order? </div>
                                            <label>
                                                <input class="sa_followup" name="sa_followup" value="yes" type="radio"
                                                    onChange={() => setInformationCardView('yes')} /> Yes
                                            </label>
                                            <label>
                                                <input class="sa_followup" name="sa_followup" value="no"
                                                    type="radio" onChange={() => setInformationCardView('no')} /> No
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        informationCardView === 'no' ? (
                                            <div id="sa_last_chance" style={{ display: 'block' }}>Are you sure? There are several benefits to our free follow up
                                                service, including order confirmation, additional rating/review options, and customer
                                                care resolution services should any issues arise.
                                            </div>
                                        ) : (<></>)
                                    }
                                    {
                                        informationCardView ? (
                                            <div id="sa_optin">
                                                <div id="sa_pleaseenter">
                                                    <div> Please enter your information below so we can confirm that you received your
                                                        order and that the transaction went smoothly.
                                                    </div>
                                                    <div class="sa_optin">
                                                        Name
                                                        <input id="sa_name" type="text" value={studentName} readOnly />
                                                    </div>
                                                    <div class="sa_message" id="sa_name_message"></div>
                                                    <div id="sa_emailbox" class="sa_optin">
                                                        Email
                                                        <input id="sa_email" type="text" value={studentEmailID} readOnly />
                                                    </div>
                                                    <div class="sa_message" id="sa_email_message"></div>
                                                </div>

                                                <p id="sa_safeemail" style={{ clear: "both", paddingTop: "10px" }}> <img
                                                    src="https://www.shopperapproved.com/thankyou/images/minicheckmark.jpg"
                                                    alt="Your email is safe." /> <span id="sa_safe">Your email is safe.</span> It will 
                                                      only be used for order confirmation and customer care. </p>

                                                <div style={{ padding: "15px 0" }}>
                                                    <input type="checkbox" id="agree_customer" className='mr-2' onChange={(e) => isAgreePolicyEvent(e)} />
                                                    <label for="agree_customer ">I hereby consent and agree to the 
                                                        <div class="tooltip_wrapper">
                                                            <div class="tooltip_text" style={{ display: "none" }}>
                                                                <p>By submitting a review, you grant permission to Shopper Approved to
                                                                    display and share your name, review, and any content submitted, in
                                                                    an effort to help future IMPROVlearning customers make better buying
                                                                    decisions. Personal information collected or provided in connection
                                                                    with your review is treated as set forth in our Privacy Policy
                                                                    located at <a
                                                                        style={{ color: "white", textDecoration: "underline!important" }}
                                                                        href="https://www.shopperapproved.com/privacy.php"
                                                                        target="_blank">https://www.shopperapproved.com/privacy.php</a><br />
                                                                    and is subject to IMPROVlearning’s Privacy Policy as well. We are
                                                                    not responsible for IMPROVlearning’s privacy practices and you
                                                                    should review IMPROVlearning’s website directly to determine their
                                                                    privacy practices. For any content submitted, you grant Shopper
                                                                    Approved a non-exclusive license to use, copy, modify, delete and/or
                                                                    distribute such content without compensation to you.</p>
                                                                <h4>You also represent and warrant that:</h4>
                                                                <ul>
                                                                    <li>You are an active paying customer of IMPROVlearning.</li>
                                                                    <li>You will not submit any content that is known to you to be
                                                                        false, inaccurate or misleading.</li>
                                                                    <li>You have not been incentivized to leave this review.</li>
                                                                </ul>
                                                            </div>
                                                            <a class="tooltip_link ml-1" href="#">Survey Guidelines.</a>
                                                        </div>
                                                    </label>
                                                </div>
                                                <p id="sa_nametxt" style={{ clear: "both", paddingTop: "10px", display: "none" }}>
                                                    Enter the name you would like displayed on your review. </p>
                                                <div id="policy-check"></div>
                                                {
                                                    isAgreePolicy === false ? (
                                                        <div id="custom_err" style={{ color: 'red', fontWeight: 'bold' }}>
                                                            Please confirm your consent to our Survey Guidelines.
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                <input id="shopper_submit" type="button" class="" value="Submit" onClick={() => submitShopperSurvey()} />
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    <input id="shopper_submit_second" type="button" value="Submit"
                                        style={{ display: "none" }} />

                                </div> <img id="sa_footer_img"
                                    src="https://www.shopperapproved.com/thankyou/images/just-powered-new.png"
                                    alt="Survey Powered by Shopper Approved" />
                                <div style={{ padding: "10px" }}></div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                            <div id="sa_thankyou" class="sa_thankyou_text" style={{ display: "none" }}> Thank you for your feedback.
                                You will receive a follow up survey email with additional rating, review and customer care
                                options. </div>
                            <div id="sa_thankyou_no_email" class="sa_thankyou_text" style={{ display: "none" }}> Thank you for your
                                feedback. </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
