import { BehaviorSubject } from "rxjs";

const subject = new BehaviorSubject();

export const socketDisconnectServiceByCourseStateId = {
    sendMessage: (text) => subject.next({ text: text }),
    clearMessages: () => subject.next(),
    onMessage: () => subject.asObservable(),
};

// export const socketDisconnectServiceByConfigCourseId = {
//     sendMessage: (text) => subject.next({ text: text }),
//     clearMessages: () => subject.next(),
//     onMessage: () => subject.asObservable(),
// };

// export const socketDisconnectServiceByCourseStatus = {
//     sendMessage: (text) => subject.next({ text: text }),
//     clearMessages: () => subject.next(),
//     onMessage: () => subject.asObservable(),
// };