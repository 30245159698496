import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import ViewBilling from '../billing/ViewBilling';
import ParentVerification from '../parent-verification/ParentVerification';
import CustomLoader from './CustomLoader';
import PageNotFound from './page-layouts/PageNotFound';
import { CourseProvider } from '../../context';

const CartSummaryWithSchedule = lazy(() => import('../cartSummary/CartSummaryWithSchedule'));
const TakeSurvey = lazy(() => import('../dashboard/TakeSurvey'));
const MyOrders = lazy(() => import('../my-orders/MyOrders'));
const UpsellCartSummary = lazy(() => import('../cartSummary/UpsellCartSummary'));
const CartSummary = lazy(() => import('../cartSummary/CartSummary'));
const AZCartSummary = lazy(() => import('../cartSummary/AZCartSummary'));
const FastLaneCartSummary = lazy(() => import('../../components/register/FastLane/FastLaneCartSummary'));
const AzRegistrationStatus = lazy(() => import('../az-registration-status/AzRegistrationStatus'));
const Documents = lazy(() => import('../documents/Documents'));
const PersonalInformation = lazy(() => import('../personal-information/PersonalInformation'));
const FastLanePersonalInformation = lazy(() => import('../../components/register/FastLane/FastLaneProfileRegister'));
const SecurityQuestions = lazy(() => import('../security-questions/SecurityQuestions'));
const Syllabus = lazy(() => import('../syllabus/Syllabus'));
const ChapterQuizContainer = lazy(() => import('../chapter-quiz/ChapterQuizContainer'));
const CartAndCheckout = lazy(() => import('../cart-and-checkout/CartAndCheckout'));
const HelpAndSupport = lazy(() => import('../faqs/HelpAndSupport'));
const Upsell = lazy(() => import('../upsell/Upsell'));
const Integration = lazy(() => import('../integration/Integration'));
const SecurityCheck = lazy(() => import('../security-check/SecurityCheck'));
const BreakTime = lazy(() => import('../break/BreakTime'));
const Webcam = lazy(() => import('../dashboard/modals/WebcamModel'));
const InitialWebcamModel = lazy(() => import('../dashboard/modals/InitialWebcamModel'));
const ChangePassword = lazy(() => import('../change-password/ChangePassword'));
const SpecialOffers = lazy(() => import('../special-offers/SpecialOffers'));
const FinalQuizLaunch = lazy(() => import('../final-quiz/FinalQuizLaunch'));
const RefundStatus = lazy(() => import('../refund/RefundStatus'));
const RefundSummary = lazy(() => import('../refund/RefundSummary'));
const TypingPatternSave = lazy(() => import('../typing-verification/TypingPatternSave'));
const TypingPatternVerification = lazy(() => import('../typing-verification/TypingPatternVerification'));
const TypingVerificationMessage = lazy(() => import('../typing-verification/TypingVerificationMessage'));
const ChapterLaunchComponent = lazy(() => import('../course/ChapterLaunchComponent'));
const CourseList = lazy(() => import('../course/CourseList'));
const Dashboard = lazy(() => import('../dashboard/Dashboard'));
const ParentCartSummary = lazy(() => import('../cartSummary/ParentCartSummary'));
const OptConfiguration = lazy(() => import('./otp-configuration/OptConfiguration'));

const Billing = lazy(() => import('../billing/Billing'));
const Profile = lazy(() => import('../personal-information/Profile'));
const Settings = lazy(() => import('../personal-information/Settings'));
const Support = lazy(() => import('../support/Support'));

const FileDocuments = lazy(() => import('../file-documents/FileDocuments'));
const Eligibility = lazy(() => import('../eligibility/Eligibility'));
const AZUpsell = lazy(() => import('../upsell/AZUpsell'));


export default function HomeRouting({ match }) {

    return (
        <>
          <CourseProvider>
            <Suspense fallback={<CustomLoader />}>
                <Switch>
                    <Route path={`${match.url}/cart-summary`} component={CartSummary} />
                    <Route path={`${match.url}/az-cart-summary`} component={AZCartSummary} />
                    <Route path={`${match.url}/upsell-cart-summary`} component={UpsellCartSummary} />
                    <Route path={`${match.url}/fast-lane-cart-summary`} component={FastLaneCartSummary} />
                    <Route path={`${match.url}/az-upsell`} component={AZUpsell} />
                    <Route path={`${match.url}/cart-summary-schedule`} component={CartSummaryWithSchedule} />
                    <Route path={`${match.url}/review-cart-and-checkout`} component={CartAndCheckout} />
                    <Route path={`${match.url}/view-billing-details`} component={ViewBilling} />
                    <Route path={`${match.url}/file-documents`} component={FileDocuments} />
                    <Route path={`${match.url}/eligibility`} component={Eligibility} />
                    <Route path={`${match.url}/az-registration-status`} component={AzRegistrationStatus} />
                    <Route path={`${match.url}/syllabus`} component={Syllabus} />
                    <Route path={`${match.url}/documents`} component={Documents} />
                    <Route path={`${match.url}/myorders`} component={MyOrders} />
                    <Route path={`${match.url}/webcam`} component={Webcam} />
                    <Route path={`${match.url}/initialWebcamModel`} component={InitialWebcamModel} />                    
                    <Route path={`${match.url}/personal-information`} component={PersonalInformation} />
                    <Route path={`${match.url}/fast-lane-personal-information`} component={FastLanePersonalInformation} />
                    <Route path={`${match.url}/parent-verification`} component={ParentVerification} />
                    <Route path={`${match.url}/security-quiz-save`} component={SecurityQuestions} />
                    <Route path={`${match.url}/typing-pattern-save`} component={TypingPatternSave} />
                    <Route path={`${match.url}/typing-pattern-verification`} component={TypingPatternVerification} />
                    <Route path={`${match.url}/typing-verification-message`} component={TypingVerificationMessage} />
                    <Route path={`${match.url}/chapter-quiz`} component={ChapterQuizContainer} />
                    <Route path={`${match.url}/customer-support`} component={HelpAndSupport} />
                    <Route path={`${match.url}/upsell`} component={Upsell} />
                    <Route path={`${match.url}/integration`} component={Integration} />
                    <Route path={`${match.url}/security-quiz-verification`} component={SecurityCheck} />
                    <Route path={`${match.url}/breakTime`} component={BreakTime} />
                    <Route path={`${match.url}/change-password`} component={ChangePassword} />
                    <Route path={`${match.url}/chapter-launch`} component={ChapterLaunchComponent} />
                    <Route path={`${match.url}/course-dashboard`} component={CourseList} />
                    <Route path={`${match.url}/special-offers`} component={SpecialOffers} />
                    <Route path={`${match.url}/final-quiz`} component={FinalQuizLaunch} />
                    <Route path={`${match.url}/refund`} component={RefundStatus} />
                    <Route path={`${match.url}/refund-summary`} component={RefundSummary} />
                    <Route path={`${match.url}/course-list`} component={CourseList} />
                    <Route path={`${match.url}/dashboard`} component={Dashboard} />
                    <Route path={`${match.url}/billing`} component={Billing} />
                    <Route path={`${match.url}/profile`} component={Profile} />
                    <Route path={`${match.url}/settings`} component={Settings} />
                    <Route path={`${match.url}/support`} component={Support} />
                    <Route path={`${match.url}/take-survey`} component={TakeSurvey} />
                    <Route path={`${match.url}/parent-cart-summary`} component={ParentCartSummary} />
                    <Route path={`${match.url}/otp-configuration`} component={OptConfiguration} />
                    <Route component={PageNotFound} />
                </Switch>
            </Suspense>
          </CourseProvider>
        </>
    )
}
