import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  addCartMiniIcon,
  doublePointingGirl,
  greenLockedIcon,
  long_right_arrow,
} from "../../../common/assets/Images";
import { Button } from "../../../cartSummary/CardSummaryHelper/CartSummaryImport";
import PhoneInput, {
  isValidPhoneNumber,
} from "react-phone-number-input";
import InputModalPhone from "react-phone-number-input/input";
import "react-phone-number-input/style.css";

export default function PhoneModalFastLane(props) {
  const {
    modalPhone,
    smsChecked,
    setSmsChecked,
    setModalPhone,
    isPhoneModalVisible,
    setIsPhoneModalVisible,
  } = props;
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false);

  const validPhoneRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  const handleModalIgnore = () => {
    setIsPhoneModalVisible(!isPhoneModalVisible);
  };

  const smsTrigger = () => {
    setSmsChecked(!smsChecked);
  };

  const handleDataModalSubmit = () => {
    if (validateModalEmailPhone()) {
      setIsPhoneModalVisible(!isPhoneModalVisible);
      setIsAlreadyRegistered(true);

      let phoneValue = modalPhone.trim() || "";
      if (phoneValue.length > 0) {
        let firstDigit = modalPhone.charAt(0);
        if (firstDigit === "1") {
          setModalPhone("+" + modalPhone);
        }
      }
    }
  };

  const validateModalEmailPhone = () => {
    let phoneValue = modalPhone.trim() || "";

    if (phoneValue.length === 0) {
      return true;
    } else {
      let firstDigit = modalPhone.charAt(0);
      return (
        validPhoneRegex.test(modalPhone) &&
        phoneValue.length >= 7 &&
        firstDigit !== "0"
      )
    }
  };

  return (
    <Modal
      show={isPhoneModalVisible && !isAlreadyRegistered}
      onHide={handleModalIgnore}
      id="user-data-modal-register"
      className="user-data-modal-register phone-modal"
      centered
    >
      <Modal.Body className="user-data-modal-register-body phone-modal">
        <div>
          <button
            className="user-data-modal-close-button"
            onClick={handleModalIgnore}
          >
            ✖
          </button>
        </div>
        <p className="user-data-modal-title phone-modal">
          Save $5
        </p>
        <div className="user-data-modal-subtitle-container">
          <p className="user-data-modal-subtitle phone-modal-big">
            Unlock $5 Off Your Course!
          </p>
          <p className="user-data-modal-subtitle phone-modal-small">
            Enter Your Mobile Number For A Code.
          </p>
        </div>
        
        <div className="user-data-modal-body-container phone-modal">
          <div className="user-data-modal-data-container phone-modal">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
              className="PhoneInputInput phone-modal"
            >
              <InputModalPhone
                country="US"
                international
                placeholder="Phone number"
                value={modalPhone}
                onChange={setModalPhone}
              />
            </div>
            <span className="error">
              {modalPhone &&
                !isValidPhoneNumber(modalPhone) &&
                "Please enter a valid phone number"}
            </span>
          </div>
        </div>
        <div className="checkbox check__item check-item-config phone-modal">
          <label className="label-container">
            <input
              className="user-data-modal-checkmark-config phone-modal"
              type="checkbox"
              checked={smsChecked}
              id="isStudentOptSms"
              name="isStudentOptSms"
              onChange={() => smsTrigger()}
            />
            <span className="checkmark user-data-modal-checkmark-config phone-modal"></span>
            <span className="user-data-modal-label phone-modal">
              I agree to be contacted by Interactive Education Concepts, Inc/DriverZ via SMS text messaging to receive important course communication.
            </span>
          </label>
        </div>
        <Button
          className="user-data-modal-submit-button phone-modal"
          disabled={modalPhone && !isValidPhoneNumber(modalPhone)}
          onClick={handleDataModalSubmit}
        >
          <img src={addCartMiniIcon} /> Get My Discount
        </Button>
        <img src={doublePointingGirl} alt="Pointing Girl" className="phone-modal-girl-image" />
      </Modal.Body>
    </Modal>
  );
}
