import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { EncryptStorage } from '../../common/imports/CommonImport';
import { createYupSchema } from './yupSchemaCreator';
import * as yup from 'yup';
import { returnType, returnValidation, FormErrorMessage } from './utils';
import FlexiFieldsTitle from './ui/FlexiFieldsTitle';
import NewDatepickerField from './NewFlexiFieldComponents/NewDatepickerField';
import NewTextFlexiField from './NewFlexiFieldComponents/NewTextFlexiField';
import NewNumberFlexField from './NewFlexiFieldComponents/NewNumberFlexField';
import NewDropdownField from './NewFlexiFieldComponents/NewDropdownField';
// import Test from './NewFlexiFieldComponents/Test';

function NewFlexiField(props) {
    const [flexiFields, setFlexiFields] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [validateSchema, setValidateSchema] = useState({});
    const [minDate] = useState(new Date());
    const [otherInfoErrorMessage, setOtherInfoErrorMessage] = useState('');
    const [personalInfoErrorMessage] = useState('');
    const [confirmFieldErrorMessage, setconfirmFieldErrorMessage] = useState({});
    const [isOtherCourt, setIsOtherCourt] = useState(false);
    const [isOtherCourtError, setIsOtherCourtError] = useState(props.emitOtherCourtError);
    const [isCountyActive, setIsCountyActive] = useState(false);
    const [isActiveVehicleTag, setIsActiveVehicleTag] = useState(false);
    const [losAngeles, setLosAngeles] = useState(false);
    const [schemaLosAngles, setSchemaLosAngles] = useState([]);

    useEffect(() => {
        if (props.isFastLane === undefined || props.isFastLane === false)
            return;

        let validCounter = 0;
    
        for (let i = 0; i < flexiFields.length; i++) {
            if(flexiFields[i].value !== "") {
                validCounter++;
            }
        }
        props.setHasAllFlexifieldsFilled(validCounter === flexiFields.length)
    }, [flexiFields]);

    useEffect(() => {
        const fetchData = () => {
            loadFields(props.flexiFields, losAngeles);
        };
        fetchData();
    }, [props.flexiFields]);
    useEffect(() => {
        setFlexiFieldValues(schemaLosAngles, losAngeles);
    }, [losAngeles]);
    useEffect(() => {
        setIsOtherCourtError(props.emitOtherCourtError);
    }, [props.emitOtherCourtError]);

    const loadFields = (items, type) => {
        let tempFlexiFields = items;
        let temp_FlexiFields = [];
        checkIsActiveVehiclFlag(tempFlexiFields);
        if (tempFlexiFields.length > 0) {
            for (let i = 0; i < tempFlexiFields.length; i++) {
                tempFlexiFields[i] = Object.assign(tempFlexiFields[i], {
                    id: tempFlexiFields[i].flexiFieldName.replace(/\s/g, ''),
                    label: tempFlexiFields[i].flexiFieldDescription,
                    type: tempFlexiFields[i].flexiFieldType,
                    validationType: returnType(tempFlexiFields[i].flexiFieldType, tempFlexiFields[i].flexiFieldName.replace(/\s/g, '')),
                    value: tempFlexiFields[i].refFieldValue ? tempFlexiFields[i].refFieldValue : getEmpty(tempFlexiFields[i].flexiFieldName),
                    validations: returnValidation(tempFlexiFields[i].flexiFieldType, tempFlexiFields[i].isMandatoryField, tempFlexiFields[i].flexiFieldName),
                    isMandatoryField: tempFlexiFields[i].isMandatoryField,
                    isValidationRequired: tempFlexiFields[i].isValidationRequired,
                    dropdown: tempFlexiFields[i].flexiFieldName === 'COURT' ? [] : tempFlexiFields[i].dropdown,
                });
                temp_FlexiFields.push(tempFlexiFields[i]);
                if (tempFlexiFields[i].flexiFieldName.replace(/\s/g, '') == 'COURT') {
                    localStorage.flexiFieldCOURTId = tempFlexiFields[i].flexiFieldId;
                }
                if (tempFlexiFields[i].flexiFieldName.toLocaleLowerCase() === 'county') {
                    setIsCountyActive(true);
                }
            }
            setFlexiFieldValues(temp_FlexiFields, type || false);
            setSchemaLosAngles(temp_FlexiFields);
        }
    };

    const checkIsActiveVehiclFlag = tempFlexiFields => {
        if (tempFlexiFields.length > 0) {
            tempFlexiFields.forEach(item => {
                if (item.flexiFieldName === 'VEHICLE_TAG') {
                    setIsActiveVehicleTag(true);
                }
            });
        }
    };

    const setFlexiFieldValues = (temp_FlexiFields, type) => {
        if (temp_FlexiFields.length > 0) {
            let tempInitialValues = {};
            temp_FlexiFields.forEach(item => {
                tempInitialValues[item.id] = item.value || '';
                if (item.isValidationRequired) {
                    tempInitialValues[`CONFIRM${item.id}`] = item.value || '';
                }
            });
            temp_FlexiFields.sort(function (a, b) {
                let keyA = a.label,
                    keyB = b.label;
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            setFlexiFields(temp_FlexiFields);
            setInitialValues(tempInitialValues);
            const yepSchema = {};
            temp_FlexiFields.map((item, index) => {
                if (!type ? item.flexiFieldId !== 161 : item.flexiFieldId) createYupSchema(yepSchema, item);
            });
            let _validateSchema = yup.object().shape(yepSchema);
            setValidateSchema(_validateSchema);
        }
    };

    const getEmpty = flexiFieldName => {
        let res = '';
        if (flexiFieldName == 'VEHICLE_TAG') {
            if (EncryptStorage.getLocalStorage('vehicleTag')) {
                res = EncryptStorage.getLocalStorage('vehicleTag');
            }
        }
        return res;
    };

    const OtherCourtEvent = e => {
        if (e.target.value == '') {
            setIsOtherCourtError(true);
            props.emitOtherCourtErrorEvent(true);
        } else {
            setIsOtherCourtError(false);
            props.emitOtherCourtErrorEvent(false);
        }
    };

    const submitFlexiFieldForm = values => {
        if (document.getElementById('OtherCourt')) {
            if (document.getElementById('OtherCourt').value == '') {
                setIsOtherCourtError(true);
                props.emitOtherCourtErrorEvent(true);
            } else {
                setIsOtherCourtError(false);
                props.emitOtherCourtErrorEvent(false);
                passFlexfieldValue(values);
            }
        } else {
            passFlexfieldValue(values);
        }
    };

    const passFlexfieldValue = values => {
        if (values.COUNTY && values.COURT == 'Other') {
            let tempObj = {
                flexiField: {
                    flexiFieldId: localStorage.flexiFieldCOURTId,
                },
                isActive: true,
                refFieldId: null,
                refFieldValue: document.getElementById('OtherCourt').value,
                configCourseId: EncryptStorage.getLocalStorage('configCourseId'),
            };
            localStorage.OtherCountyObject = JSON.stringify(tempObj);
            delete values.Court;
            delete values.COURT;
            props.onFlexiFieldSubmit(values);
        } else {
            props.onFlexiFieldSubmit(values);
        }
    };

    const getFlexiFieldTitleByVehicleTag = () => {
        return isActiveVehicleTag && <FlexiFieldsTitle action={isActiveVehicleTag} content='Other Information' />;
    };

    const getFlexiFieldTitleByCountyActive = () => {
        if (isCountyActive){
            if(props.isFastLane === true){
                return <FlexiFieldsTitle action={isCountyActive} content='Court referral? These fields are required.' />;
            }
            else {
                return <FlexiFieldsTitle action={isCountyActive} content='If you were referred by a court, these fields are mandatory.' />;
            }
            
        }
        
    };

    const getOtherCourtField = () => {
        if (isOtherCourt) {
            return (
                <div className='field-col'>
                    <input name='OtherCourt' id='OtherCourt' className='custom-form-control' placeholder={'Other Court'} autoComplete='none' onChange={e => OtherCourtEvent(e)} />
                    {getOtherCourtError()}
                </div>
            );
        } else {
            return <></>;
        }
    };

    const getOtherCourtError = () => {
        return isOtherCourtError ? <div class='OtherCourtError pl-0'>Other court is required</div> : <></>;
    };

    return (
        <div>
            {getFlexiFieldTitleByVehicleTag()}
            {getFlexiFieldTitleByCountyActive()}
            <Formik validationSchema={validateSchema} initialValues={initialValues} onSubmit={submitFlexiFieldForm} enableReinitialize={true}>
                {({ handleSubmit, values, handleChange, touched, errors, setFieldValue }) => {
                    return (
                        <Form onSubmit={handleSubmit} noValidate className='form-v2 flexiFieldsForm mt-2'>
                            {/* <Row className="align-items-center rowReset"> */}
                            <div className='field-grid-row'>
                                <>
                                    <FormErrorMessage errorMessage={personalInfoErrorMessage} />
                                </>
                                {flexiFields.map((row, ind) => {
                                    switch (row.flexiFieldType) {
                                        case 'DATE':
                                            return (
                                                <NewDatepickerField
                                                    setFlexiFields={setFlexiFields}
                                                    flexiFields={flexiFields}
                                                    row={row}
                                                    minDate={minDate}
                                                    ind={ind}
                                                    touched={touched}
                                                    setFieldValue={setFieldValue}
                                                    setOtherInfoErrorMessage={setOtherInfoErrorMessage}
                                                    errors={errors}
                                                    findCitationDueDateBeforeSevenDate={props.findCitationDueDateBeforeSevenDate}
                                                    isFastLane={props.isFastLane}
                                                />
                                            );
                                        case 'TEXT':
                                            return (
                                                <NewTextFlexiField
                                                    setFieldValue={setFieldValue}
                                                    confirmFieldErrorMessage={confirmFieldErrorMessage}
                                                    setconfirmFieldErrorMessage={setconfirmFieldErrorMessage}
                                                    flexiFields={flexiFields}
                                                    setFlexiFields={setFlexiFields}
                                                    setOtherInfoErrorMessage={setOtherInfoErrorMessage}
                                                    row={row}
                                                    ind={ind}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleChange={handleChange}
                                                    values={values}
                                                    isFastLane={props.isFastLane}
                                                />
                                            );
                                        case 'NUMBER':
                                            return (
                                                <NewNumberFlexField
                                                    row={row}
                                                    ind={ind}
                                                    handleChange={handleChange}
                                                    setOtherInfoErrorMessage={setOtherInfoErrorMessage}
                                                    errors={errors}
                                                    touched={touched}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    confirmFieldErrorMessage={confirmFieldErrorMessage}
                                                    flexiFields={flexiFields}
                                                    setconfirmFieldErrorMessage={setconfirmFieldErrorMessage}
                                                    setFlexiFields={setFlexiFields}
                                                    isFastLane={props.isFastLane}
                                                />
                                            );
                                        case 'DROPDOWN':
                                            return (
                                                <NewDropdownField
                                                    row={row}
                                                    handleChange={handleChange}
                                                    flexiFields={flexiFields}
                                                    setFieldValue={setFieldValue}
                                                    setFlexiFields={setFlexiFields}
                                                    setOtherInfoErrorMessage={setOtherInfoErrorMessage}
                                                    ind={ind}
                                                    errors={errors}
                                                    touched={touched}
                                                    setIsOtherCourt={setIsOtherCourt}
                                                    callingSchemaFunction={setFlexiFieldValues}
                                                    losAngeles={losAngeles}
                                                    setLosAngeles={setLosAngeles}
                                                    isFastLane={props.isFastLane}
                                                />
                                            );
                                        default:
                                            return;
                                    }
                                })}
                                {getOtherCourtField()}
                            </div>
                            {/* <>
                                    <FormErrorMessage errorMessage={personalInfoErrorMessage} />
                                </>
                                {
                                    flexiFields.map((row, ind) => {
                                        switch (row.flexiFieldType) {
                                            case 'DATE':
                                                return (
                                                    <NewDatepickerField setFlexiFields={setFlexiFields} flexiFields={flexiFields} row={row} minDate={minDate} ind={ind} touched={touched}
                                                        setFieldValue={setFieldValue} setOtherInfoErrorMessage={setOtherInfoErrorMessage} errors={errors} findCitationDueDateBeforeSevenDate={props.findCitationDueDateBeforeSevenDate} />
                                                )
                                            case 'TEXT':
                                                return (
                                                    <NewTextFlexiField setFieldValue={setFieldValue} confirmFieldErrorMessage={confirmFieldErrorMessage} setconfirmFieldErrorMessage={setconfirmFieldErrorMessage}
                                                        flexiFields={flexiFields} setFlexiFields={setFlexiFields} setOtherInfoErrorMessage={setOtherInfoErrorMessage} row={row} ind={ind}
                                                        errors={errors} touched={touched} handleChange={handleChange} values={values} />
                                                )
                                            case 'NUMBER':
                                                return (
                                                    <NewNumberFlexField row={row} ind={ind} handleChange={handleChange} setOtherInfoErrorMessage={setOtherInfoErrorMessage} errors={errors} touched={touched}
                                                        values={values} setFieldValue={setFieldValue} confirmFieldErrorMessage={confirmFieldErrorMessage} flexiFields={flexiFields}
                                                        setconfirmFieldErrorMessage={setconfirmFieldErrorMessage} setFlexiFields={setFlexiFields} />
                                                )
                                            case 'DROPDOWN':
                                                return (
                                                    <NewDropdownField row={row} handleChange={handleChange} flexiFields={flexiFields} setFieldValue={setFieldValue} setFlexiFields={setFlexiFields}
                                                        setOtherInfoErrorMessage={setOtherInfoErrorMessage} ind={ind} errors={errors} touched={touched} setIsOtherCourt={setIsOtherCourt} />
                                                )
                                            default:
                                                return
                                        }
                                    })
                                }
                                {getOtherCourtField()} */}
                            {/* </Row > */}
                            <FormErrorMessage errorMessage={otherInfoErrorMessage} />
                            <>
                                <button type='submit' className='invisible position-absolute' id='flexiFieldFormBtn' name='flexiFieldFormBtn'>
                                    Save and Continue Flex
                                </button>
                            </>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}

export default NewFlexiField;
