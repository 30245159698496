import React from "react";
import warningImg from "../../assets/images/alertImg.svg";
import Loader from "../../assets/images/loader.gif";
const SavingProgressModal = () => {
  return (
    <div className="position-absolute  fixed-top saving-progress-modal">
      <div className="saving-progress-modal__content">
        <div className="saving-progress-modal__header">
          <img src={warningImg} className="warning-icon" />
          <h1>SAVING PROGRESS</h1>
        </div>
        <p>
          Please keep this window open for the time being while we save your
          progress. Please note that if you close this window, your progress may
          be lost.
        </p>
        <img src={Loader} className="" />
      </div>
    </div>
  );
};

export default SavingProgressModal;
