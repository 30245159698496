import React, { useState } from "react";
import { UrlConstants } from "../../common/imports/CommonImport";
import nopath from "../../../assets/images/nopath.png";
import { Rating } from "react-simple-star-rating";
import { check_purple, downwardOrangeArrow, info_circle, } from "../../common/assets/Images";
import { Button } from "react-bootstrap";

export default function BaseCourse(props) {
  const { fixedCoursePriceDetails, showDiscountPrice, fixedCoursePriceData, upsellCourseList, } = props;

  const [rating, setRating] = useState(0);

  const handleRating = (rate) => {
    setRating(4.7);
  };
 const createMarkup = (html) => {
   return { __html: html };
 };
  const calculateSavePercentage = () => {
    if (showDiscountPrice) {
      let tempDiscount = 0;
      tempDiscount = (Number(showDiscountPrice) / Number(fixedCoursePriceDetails.crossOutPrice)) * 100;
      return `${Math.round(tempDiscount)}%`;
    } else {
      return "0%";
    }
  };

  const getCrossOutPrice = () => {
    const newPrice = fixedCoursePriceDetails.crossOutPrice - 0;
    return (fixedCoursePriceDetails) ? newPrice.toFixed(2) : 0.00;
  }

  const getBasePrice = () => {
    const newPrice = fixedCoursePriceDetails.basePrice - 0;
    return (fixedCoursePriceDetails) ? newPrice.toFixed(2) : 0.00;
  }

  const checkInvalidCourses = () => {
    const invalidCourses = ["777", "776"];
    const configCourseId = localStorage.getItem("configCourseId");

    if (configCourseId && invalidCourses.find((element) => element === configCourseId)){
      return false;
    }
    return true;
  }

  return (
    <>
      <div className="course-item">
        <div className="cart-course-img">
          <img
            className="show-lg"
            src={
              fixedCoursePriceData.logoPath
                ? UrlConstants.imageBaseUrl + fixedCoursePriceData.logoPath
                : nopath
            }
            alt="course-img"
          />
          <img
            className="show-sm"
            src={
              fixedCoursePriceData.logoPath
                ? UrlConstants.imageBaseUrl + fixedCoursePriceData.logoPath
                : nopath
            }
            alt="course-img"
          />
        </div>
        <div className="course-desc">
          <div className="course-top">
            <div className="course-text">
              <h2>{fixedCoursePriceData.name}</h2>
              {/* <p>{fixedCoursePriceData.description}</p> */}
              <div
                dangerouslySetInnerHTML={createMarkup(
                  fixedCoursePriceData.description
                )}
              />
            </div>
            {/* <div className="question">
                <img src={info_circle} />
            </div> */}
          </div>
          <div className="course-bottom">
            <div className="price-num">
              {fixedCoursePriceDetails &&
              fixedCoursePriceDetails.crossOutPrice &&
              fixedCoursePriceDetails.crossOutPrice.toString() != "0" ? (
                <div className="crossed" data-uw-rm-sr="crossout-price">
                  ${getCrossOutPrice()}
                </div>
              ) : null}
              <div className="discounted" data-uw-rm-sr="base-price">
                ${getBasePrice()} <span>*</span>
              </div>
            </div>
            <div className="cart-btn-block">
              <div className="added">
                <Button>
                  <img src={check_purple} alt="add to cart" /> Added
                </Button>
              </div>
            </div>
          </div>
        </div>
        {upsellCourseList && checkInvalidCourses() && upsellCourseList.length > 0 && (
          <img
            src={downwardOrangeArrow}
            className="base-course-arrow-downward"
          />
        )}
      </div>
    </>
  );
}
