import * as Yup from 'yup';

const validationSChema = Yup.object({
    emailId: Yup.string().email('Invalid email').required('Please enter your email'),
    password: Yup.string().required('Please enter your password')
        .trim('Invalid password')
        .strict(true)
})

const initialValues = { emailId: '', password: '' };

export const Schema = {
    validationSChema, initialValues
}