import React from  'react';
import { EncryptStorage } from "../../services/EncryptStorage";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const setParentVerificationInitialValues = (student) => {
    return {
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: "",
        emailId: "",
        mobileNumber: "",
        street: '',
        city: "",
        state: { stateId: '' },
        zip: "",
        isStudentOptMail: false,
        isStudentOptSms: false,
        parentEmail: student.parentEmail ? student.parentEmail : ''
    }
}

const ymdStringConvert = (date) => {
    if (!date) return null;
    let value = date;
    return value.split('-')[2] + '-' + value.split('-')[0] + '-' + value.split('-')[1];
}

const setLocalStorageValuesForGetPersonalInfo = (data) => {
    if (data && data.organisation) EncryptStorage.setToLocalStorage('organisationLogo', data.organisation.logoPath);
    if (data && data.studentId) EncryptStorage.setToLocalStorage('studentId', data.studentId);
    if (data && data.defaultStPaymentTrxId) EncryptStorage.setToLocalStorage('studentPaymentTrxId', data.defaultStPaymentTrxId);
}

const setSubmitPersonalInfoPayload = (studentDetails, values, stateCodeSelected, formatDate) => {
    return {
        ...studentDetails,
        "parentFirstName": values.firstName,
        "parentMiddleName": values.middleName,
        "parentDateOfBirth": formatDate ? formatDate.split("T")[0] : '',
        "parentLastName": values.lastName,
        "parentStreet": values.street,
        "parentCity": values.city,
        "parentState": stateCodeSelected,
        "parentZip": values.zip,
        "parentMobileNumber": values.mobileNumber,
        "parentEmail": values.parentEmail,
    };
}

const setSubmitPersonalInfoVerificationPayload = (values, stateCodeSelected, formatDate) => {
    return {
        "firstName": values.firstName,
        "middleName": values.middleName,
        "lastName": values.lastName,
        "street": values.street,
        "city": values.city,
        "state": stateCodeSelected,
        "zipCode": values.zip,
        "dateOfBirth": formatDate ? formatDate.split("T")[0] : '',
        "phoneNumber": values.mobileNumber.replace('-', ''),
        "emailAddress": values.parentEmail
    }
}

const getClearIcon = (value, setFieldValue, fieldName) => {
    return (
        <>
            {value ? <i className="TextInputField__icon clear" onClick={() => setFieldValue(fieldName, '')}>
                <HighlightOffIcon />
            </i> : null}
        </>
    )
}

export const parentVerificationHelper = {
    setParentVerificationInitialValues, ymdStringConvert, setLocalStorageValuesForGetPersonalInfo, 
    setSubmitPersonalInfoPayload, setSubmitPersonalInfoVerificationPayload, getClearIcon
}