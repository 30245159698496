import React from 'react'

export default function ParentVerificationHeader(props) {

    const { personalInfoInitialValues } = props;

    return (
        <div>
            <h3 className="mb-0 ">Parent Verification</h3>
            <div className="custom-temp w-100 mt-3">
                <h3> <strong>Attention Parents : </strong> Before <strong>{` ${personalInfoInitialValues.firstName} ${personalInfoInitialValues.lastName}`}</strong> can start the course, the state of Ohio requires that we verify identity of the enrolled student's parent or guardian. This is the same thing we would have to do if this was an in-person classroom, but luckily for you, we can do this online!</h3>
                <h3>
                    Please fill in the following form with the information as it appears on your driver license. The service we use will verify this information against a public record database. It will not affect your credit and the information you provide will be encrypted and deleted once the verification is complete.</h3>
            </div>
        </div>
    )
}
