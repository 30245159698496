import environment from "../../environments/environment";
import { Paypal_Sandbox_CLIENT_ID } from '../../components/paypal-login/PaypalClientId';
import React from 'react';

const getStatus = (res) => {
    return res.status.success.toLocaleLowerCase();
}
const getConvertedDate = (date) => {
    const dateValue = new Date(date);
    const year = dateValue.getFullYear();
    const month = dateValue.getMonth() > 9 ? dateValue.getMonth() + 1 : `0${dateValue.getMonth() + 1}`;
    const day = dateValue.getDate() > 9 ? dateValue.getDate() : `0${dateValue.getDate()}`;
    return `${year}-${month}-${day}`;
}
const createPaypalButtonMarkup = (btnRef) => {
    const returnUrl = environment.baseUIUrl;
    return {
        __html:
            window.paypal.use(['login'], function (login) {
                login.render({
                    "appid": Paypal_Sandbox_CLIENT_ID,
                    "authend": "sandbox",
                    "scopes": "openid profile email",
                    "containerid": btnRef,
                    "responseType": "code",
                    "locale": "en-us",
                    "buttonType": "LWP",
                    "buttonShape": "pill",
                    "buttonSize": "sm",
                    "fullPage": "true",
                    "returnurl": `${returnUrl}/#/paypal-auth`
                })
            })
    };
}
const paypalButtonBuilder = (btnRef) => {
    return <script dangerouslySetInnerHTML={createPaypalButtonMarkup(btnRef)} async={true} />;
}
export const HOF_Methods = {
    getStatus, paypalButtonBuilder, getConvertedDate
}