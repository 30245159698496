const Alpha_Numeri_UndarScore_Hyphen_Regex = /^[a-zA-Z0-9-_]+$/;
const OnlyNumeric_Regex = /^[0-9\b]+$/;
const OnlyAlpha_Regex = /^[a-zA-Z ]+$/;
const Alpha_Numeri_Regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9 ]*$/;
const RemoveAllSpecialChars_Regex = /[^a-zA-Z0-9]/g;
const singleNumericRegex = /^[0-9]{1,1}$/;
const twoNumericRegex = /^[0-9]{1,2}$/;
const Alpha_Numeri_WithoutCombination_Regex = /^[a-zA-Z0-9 ]+$/;


export const RegexConst = {
    Alpha_Numeri_UndarScore_Hyphen_Regex, OnlyNumeric_Regex,
    Alpha_Numeri_Regex, OnlyAlpha_Regex,Alpha_Numeri_WithoutCombination_Regex,
    RemoveAllSpecialChars_Regex,singleNumericRegex,twoNumericRegex
}