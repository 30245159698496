import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { chatCommonService } from "../../services/chatCommonService";

const checkGreaterValue = (conditionVar, conditionData, returnDataOne, returnDataTwo) => {
  if (conditionVar > conditionData) {
    return returnDataOne;
  }
  else {
    return returnDataTwo;
  }
}

const checkEqualValue = (conditionVar, conditionData, returnDataOne, returnDataTwo) => {
  if (conditionVar === conditionData) {
    return returnDataOne;
  }
  else {
    return returnDataTwo;
  }
}

export default function Steppers(props) {

  const history = useHistory();

  const [current, setCurrent] = useState(1)

  const [isActiveAZFlow, setIsActiveAZFlow] = useState(false)
  const [nonActiveSidebar, setNonActiveSidebar] = useState(true)

  // this hook form feting custom Attribute current updated array and updating to the state veriable
  useEffect(() => {
    chatCommonService.onMessage().subscribe((res) => {
      if (res) {
        const { organisationCustomAttributeMappingList, configCustomAttributeMappingList } = res
        checkConfigCustomAttributeMappingList(configCustomAttributeMappingList);
        checkOrganisationCustomAttributeMappingList(organisationCustomAttributeMappingList)
      }
    });
  }, [props])

  useEffect(() => {
    let url = history.location.pathname

    switch (url) {
      case "/register":
        return setCurrent(2);
      case "/registerfast":
        return setCurrent(2);

      case "/home/cart-summary":
        return setCurrent(3);
      
      case "/home/fast-lane-cart-summary":
        return setCurrent(3);
        
      case "/home/az-cart-summary":
        return setCurrent(3);

      case "/home/review-cart-and-checkout":
        return setCurrent(3);

      case "/home/view-billing-details":
        return setCurrent(4);

      case "/home/file-documents":
        return setCurrent(11);

      case "/home/eligibility":
        return setCurrent(12);

      case "/home/az-registration-status":
        return setCurrent(12);

      default:
        return setCurrent(1);
    }

  }, [])

  const checkConfigCustomAttributeMappingList = (configCustomAttributeMappingList) => {
    configCustomAttributeMappingList && configCustomAttributeMappingList.forEach((item) => {
      if (item.customAttribute.columnName === "AZ_FLOW") {
        setIsActiveAZFlow(true)
      }
    })
  }

  const checkOrganisationCustomAttributeMappingList = (organisationCustomAttributeMappingList) => {
    organisationCustomAttributeMappingList && organisationCustomAttributeMappingList.forEach((item) => {
      if (item.customAttribute.columnName === "REMOVE_FIELDS") {
        setNonActiveSidebar(item.customAttributeValue === 'true' ? false : true)
      }
    })
  }

  return (
    <>
      {nonActiveSidebar && <div className="custom-stepper d-flex justify-content-center" >
        {
          isActiveAZFlow ? (<>
            <div className={`custom-stepper-item text-center before-none ${checkGreaterValue(current, 2, ' fill', '')} ${checkEqualValue(current, 2, ' active', '')}`}>
              <div className="custom-stepper-mark"></div>
              <div className="custom-stepper-title">Registration</div>
            </div>
            <div className={`custom-stepper-item text-center ${checkGreaterValue(current, 3, ' fill', '')} ${checkEqualValue(current, 3, ' active', '')}`}>
              <div className="custom-stepper-mark"></div>
              <div className="custom-stepper-title">Payment</div>
            </div>
            <div className={`custom-stepper-item text-center  ${checkGreaterValue(current, 11, ' fill', '')} ${checkEqualValue(current, 11, ' active', '')}`}>
              <div className="custom-stepper-mark "></div>
              <div className="custom-stepper-title">File Documents</div>
            </div>
            <div className={`custom-stepper-item text-center  ${checkGreaterValue(current, 12, ' fill', '')} ${checkEqualValue(current, 12, ' active', '')}`}>
              <div className="custom-stepper-mark "></div>
              <div className="custom-stepper-title">Eligibility</div>
            </div>
            <div className="custom-stepper-item text-center after-none">
              <div className="custom-stepper-mark "></div>
              <div className="custom-stepper-title">Start Course</div>
            </div>
          </>) : (<>
            <div className={`custom-stepper-item text-center before-none ${checkGreaterValue(current, 1, ' fill', '')} ${checkEqualValue(current, 1, ' active', '')}`}>
              <div className="custom-stepper-mark"></div>
              <div className="custom-stepper-title">Select course</div>
            </div>
            <div className={`custom-stepper-item text-center ${checkGreaterValue(current, 2, ' fill', '')} ${checkEqualValue(current, 2, ' active', '')}`}>
              <div className="custom-stepper-mark"></div>
              <div className="custom-stepper-title">Sign up</div>
            </div>
            <div className={`custom-stepper-item text-center  ${checkGreaterValue(current, 3, ' fill', '')} ${checkEqualValue(current, 3, ' active', '')}`}>
              <div className="custom-stepper-mark "></div>
              <div className="custom-stepper-title">Checkout</div>
            </div>
            <div className={`custom-stepper-item text-center  ${checkGreaterValue(current, 4, ' fill', '')} ${checkEqualValue(current, 4, ' active', '')}`}>
              <div className="custom-stepper-mark "></div>
              <div className="custom-stepper-title">Start course</div>
            </div>
            <div className="custom-stepper-item text-center after-none">
              <div className="custom-stepper-mark "></div>
              <div className="custom-stepper-title">E-certificate in 30 min</div>
            </div>
          </>)
        }
      </div>}
    </>
  );
}
