import environment from "../../../environments/environment";

const profileName = environment.profileName;

//514902773296143
const fbID='953291999423115';
// const fbID = '956318832072208';
//956318832072208

const getStagingClientId = (key) => {
   let clientId;
   switch (key) {
      case "google":
         clientId = "699937520997-rb22b4qth0k9si8p8holhivaeqcq5qu4.apps.googleusercontent.com"; //hareesh developer account for stage
         break;
      case "amazon":
         clientId = "amzn1.application-oa2-client.9b3cb54535d4442e84ce0afd0e9e1c2b"; //client developer account for stage
         break;
      case "facebook":
         clientId = fbID; // client developer account for stage
         break;
      default:
         clientId = "";
         break;
   }
   return clientId;
};

const getProdClientId = (key) => {
   let clientId;
   switch (key) {
      case "google":
         clientId = "699937520997-5cje0k430vbm1mrf96trl272jqcvn0h9.apps.googleusercontent.com"; //hareesh developer account for stage
         break;
      case "amazon":
         clientId = "amzn1.application-oa2-client.9b3cb54535d4442e84ce0afd0e9e1c2b"; //client developer account for stage
         break;
      case "facebook":
         clientId = fbID; // client developer account for stage
         break;
      default:
         clientId = "";
         break;
   }
   return clientId;
};

export const Google_CLIENT_ID =
   profileName === "Stage"
      ? getStagingClientId("google")
      : profileName === "Prod" || profileName === "NewProd"
         ? getProdClientId("google")
         : "361519129605-3v6rloen7p018r655411850jvoan23n2.apps.googleusercontent.com"; // cnw developer account
export const Google_Provider = "google";
export const Amazon_CLIENT_ID = profileName === "Stage" ? getStagingClientId("amazon") : "amzn1.application-oa2-client.157856ceb84948a2bb1313f3dbcc5f4c"; // cnw developer account
export const Amazon_Provider = "amazon";
export const Facebook_CLIENT_ID = profileName === "Stage" ? getStagingClientId("facebook") : profileName === "Prod" || profileName === "NewProd" ? getProdClientId("facebook") : fbID; // thiru developer profile
export const Facebook_Provider = "facebook";
