import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import environment from "../../environments/environment";
import {
  GenericAPIService,
  EncryptStorage,
  UrlConstants,
} from "../common/imports/CommonImport";
import { unitedStates, doubleArrow, russiaFlag } from "./../common/assets/Images";
import elmsLogo from "./../../assets/images/Powered_by_SPIDER_White.png";
import elmsLogoLarge from "./../../assets/images/siderSpiderlogo@2x.png";
import content from "./../../assets/images/icons/content@2x.png";
import closeWhite from "./../../assets/images/close_white.svg";
import { sidebarHelper } from "./sidebarHelper";
import CourseTimer from "./TimerModule/CourseTimer";
import ChapterAndFinalQuizTimer from "./TimerModule/ChapterAndFinalQuizTimer";

import { socketObserverService } from "../../services/socketObserverService";
import * as SockJS from "sockjs-client";
import Stomp from "stompjs";
import { socketDisconnectServiceByCourseStateId } from "../../services/SocketDisconnetService";
import { socketDisconnectServiceByConfigCourseId } from "../../services/SocketDisconnectServiceByConfigCourseId";
import { socketDisconnectServiceByCourseStatus } from "../../services/SocketDisconnectServiceByCourseStatus";
import { courseCustomAttributeService } from "../../services/courseCustomAttributeService";
import { _const } from "../constant/const-variables/constVariable";
import { useShowNationalFlag } from "../../hooks";
import SavingProgressModal from "../common/savingProgressModal";
var stompClient = null;

const { NATIONAL_LANGUAGE_TEXT, FLAGS_NATIONALS } = _const;

const { UNITEDSTATE, SPAIN, RUSSIA, CHINA } = FLAGS_NATIONALS;
const Sidebar = (props) => {
  const { history } = props;
  const [pathname, setPathname] = useState("/home/course-list");
  const [organizationLogo, setOrganizationLogo] = useState("");
  const [isTableContentOpen, setIsTableContentOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [timer, setTimer] = useState("");
  const [courseTimer, setCourseTimer] = useState("");
  const [isActiveAZFlow] = useState(false);
  const [complianceDetails, setCompilanceDetails] = useState(null);
  const [savingProgress, setSavingProgress] = useState(false);
  const { setFlagNationalCurrent, showNationalFlag } = useShowNationalFlag(
    NATIONAL_LANGUAGE_TEXT.UNITEDSTATE
  );
  const [sourceFlag, setSourceFlag] = useState("");

  useEffect(() => {
    setPathname(history.location.pathname);
  }, [props]);

  useEffect(() => {
    // console.log(" EncryptStorage.setToLocalStorage : ", EncryptStorage.getLocalStorage("courseStatusCheck"))
    // socketConnect();
    sidebarHelper.checkOrganizationLogo(setOrganizationLogo);
    sidebarHelper.setInitialValue(setTimer, setCourseTimer);
    // socketDisconnectServiceByCourseStateId.onMessage().subscribe((res) => {
    //    if (res) {
    //       if (res.text && res.text.toString() == "36") {
    //          console.log('Disconnect')
    //          if (this.stompClient) this.stompClient.disconnect()
    //       }
    //    }
    // });
    // socketDisconnectServiceByConfigCourseId.onMessage().subscribe((res) => {
    //    if (res) {
    //       if (res.text) {
    //          if (res.text.toString() == "838") {
    //             console.log('Disconnect')
    //             if (this.stompClient) this.stompClient.disconnect()
    //          }
    //       }
    //    }
    // });
    courseCustomAttributeService.onMessage().subscribe((res) => {
      if (res) {
        res.forEach((item) => {
          if (item.customAttribute.columnName === "STUDENT_COMPLIANCE_DETAIL") {
            let val = item.customAttributeValue
              ? item.customAttributeValue
              : null;
            setCompilanceDetails(val);
          }
        });
      }
    });
    // socketDisconnectServiceByCourseStatus.onMessage().subscribe((resData) => {
    //    if (resData && resData.text && resData.text !== "CO") {
    //       console.log("socketDisconnectServiceByCourseStatus");
    //       socketDisconnectServiceByCourseStateId.onMessage().subscribe((res) => {
    //          if (res && res.text) {
    //             if (!environment.newChapterContentStateIds.includes(res.text.toString())) {
    //                console.log("socketDisconnectServiceByCourseStateId")
    //                socketConnect();
    //             }
    //          }
    //       });
    //       socketDisconnectServiceByConfigCourseId.onMessage().subscribe((res) => {
    //          if (res) {
    //             if (res.text) {
    //                socketConnect();
    //                if (!environment?.newChapterContentConfigCourseIds?.includes(res.text.toString())) {
    //                   console.log("socketDisconnectServiceByConfigCourseId");
    //                   socketConnect();
    //                }
    //             }
    //          }
    //       });
    //    } else if (resData && resData.text && resData.text === "CO") {
    //       if (this.stompClient) this.stompClient.disconnect()
    //    }
    // });

    socketDisconnectServiceByCourseStatus.onMessage().subscribe((resData) => {
      if (resData && resData.text && resData.text !== "CO") {
        socketDisconnectServiceByCourseStateId
          .onMessage()
          .subscribe((resState) => {
            if (resState && resState.text) {
              if (
                !environment.newChapterContentStateIds.includes(
                  resState.text.toString()
                )
              ) {
                socketDisconnectServiceByConfigCourseId
                  .onMessage()
                  .subscribe((resConfigID) => {
                    if (resConfigID && resConfigID.text) {
                      if (
                        !environment.newChapterContentConfigCourseIds.includes(
                          resConfigID.text.toString()
                        )
                      ) {
                        socketConnect();
                      }
                    }
                  });
              }
            }
          });
      } else if (resData && resData.text && resData.text === "CO") {
        if (this.stompClient) this.stompClient.disconnect();
      }
    });

    setFlagNationalCurrent(EncryptStorage.getLocalStorage("currentBrowserLanguage"));
  }, []);

  useEffect(() => {
    if (showNationalFlag.text === "English") {
      setSourceFlag("https://myimprov.com/wp-content/themes/improv/rafal2/assets/icons/usa.png")
    }
    else if (showNationalFlag.text === "Español (América Latina)") {
      setSourceFlag("https://myimprov.com/wp-content/themes/improv/rafal2/assets/icons/spain.png")
    }
    else if (showNationalFlag.text === "中文(简体)") {
      setSourceFlag("https://myimprov.com/wp-content/themes/improv/rafal2/assets/icons/china.png")
    }
    else if (showNationalFlag.text === "Русский") {
      setSourceFlag(russiaFlag)
    }
  }, [showNationalFlag]);

  const socketConnect = () => {
    {
      /* THIS FEATURE IS DISABLE FOR THE MOMENT AS IS BROKEN IN INFRA*/
    }

    // let courseStateId = EncryptStorage.getLocalStorage("courseStateId")
    //   ? EncryptStorage.getLocalStorage("courseStateId").toString()
    //   : null;
    // let configCourseId = EncryptStorage.getLocalStorage("configCourseId")
    //   ? EncryptStorage.getLocalStorage("configCourseId").toString()
    //   : null;
    // if (
    //   (!courseStateId ||
    //     (courseStateId &&
    //       !environment.newChapterContentStateIds.includes(courseStateId))) &&
    //   (!configCourseId ||
    //     (configCourseId &&
    //       !environment.newChapterContentStateIds.includes(configCourseId)))
    // ) {
    //   let socket = new SockJS(environment.websocketConnectUrl);
    //   stompClient = Stomp.over(socket);
    //   let userObject = EncryptStorage.getLocalStorage("userObject");

    //   if (userObject.emailId) {
    //     stompClient.connect(
    //       { username: userObject.emailId },
    //       (frame) => {

    //         if (frame.command == "CONNECTED") {
    //           socketObserverService.sendMessage("CONNECTED");
    //         }
    //         stompClient.subscribe("/user/queue/messages", (response) => {
    //           if (response.body && JSON.parse(response.body).text) {
    //             if (JSON.parse(response.body).text == "chapter completed") {
    //               socketObserverService.sendMessage("chapter completed");
    //             }
    //           }
    //         });
    //       },
    //       function (error) {
    //         console.log(error);
    //         setTimeout(() => {
    //           socketConnect();
    //         }, 10000);
    //       }
    //     );
    //   } else {
    //     setTimeout(() => {
    //       socketConnect();
    //     }, 5000);
    //   }
    // }
  };

  const chapterLaunchLogout = () => {
    setLoader(true);
    const url = UrlConstants.time_managementUrl;
    const studentCourseChapterId = EncryptStorage.getLocalStorage(
      "studentCourseChapterId"
    );
    const studentCourseId = EncryptStorage.getLocalStorage("studentCourseId");
    const logoutTimeSpent = localStorage.logoutTimeSpent;
    const logoutTimeConsumedAfterIdentityVerification =
      localStorage.logoutTimeConsumedAfterIdentityVerification;
    if (studentCourseChapterId && studentCourseId && logoutTimeSpent) {
      let payload = {
        studentCourseChapterId: studentCourseChapterId,
        studentCourseId: EncryptStorage.getLocalStorage("studentCourseId"),
        timeConsumedAfterIdentityVerification: Number(
          logoutTimeConsumedAfterIdentityVerification
        ),
        timeSpent: Number(logoutTimeSpent),
      };
      GenericAPIService.azFlowUrlConfig(url, payload, "PUT")
        .then((res) => {
          sidebarHelper.dashboardApi(setLoader, history);
        })
        .catch((error) => {
          sidebarHelper.dashboardApi(setLoader, history);
        });
    } else {
      sidebarHelper.dashboardApi(setLoader, history);
    }
  };

  const navOpen = (param) => {
    const mediaQuery = window.matchMedia("(min-width: 1200px)");
    if (!mediaQuery.matches) {
      props.handleToggle();
    }
    let element = document.getElementById("sideBar");
    let element1 = document.getElementById("root");
    param ? element.classList.add("open") : element.classList.remove("open");
    param
      ? element1.classList.add("mob-menu-overlay")
      : element1.classList.remove("mob-menu-overlay");
  };

  const createMarkup = (value) => {
    return { __html: value };
  };

  const showImageAndTextCountry = (image, text) => {
    return (
      <>
        {image} {text};
      </>
    );
  };
  const handleNavigate = (path) => {
    if (pathname === "/home/chapter-launch") {
      setSavingProgress(true);
      // 3 sec wait to let the progress save
      setTimeout(() => {
        setSavingProgress(false);
        sidebarHelper.navigate(
          path,
          pathname,
          history,
          chapterLaunchLogout,
          setLoader
        );
        setIsTableContentOpen(false);
        navOpen();
      }, 2000);
    } else {
      sidebarHelper.navigate(
        path,
        pathname,
        history,
        chapterLaunchLogout,
        setLoader
      );
      setIsTableContentOpen(false);
      navOpen();
    }
  };

  const changeLanguage = (targetLanguage) => {
    EncryptStorage.setToLocalStorage("currentBrowserLanguage", targetLanguage);
    setFlagNationalCurrent(targetLanguage);
  }

  return (
    <>   
      {!isActiveAZFlow ? (
        <>
          <div id="sideBar" className="sidebar-container">
            <div className="sidebar">
              <span className="arrow" onClick={props.handleToggle}>
                <img src={doubleArrow} />
              </span>
              {sidebarHelper.getLoader(loader)}
              <div
                className="close-sidebar d-block d-lg-none"
                onClick={props.handleToggle}
              >
                <img src={closeWhite} />
              </div>
              <div className="logo">
                <img
                  src={environment.bucketUrl + organizationLogo}
                  alt="improv-logo"
                  className="mb-1"
                />
                <img src={elmsLogo} className="elmsLogo mt-2" />
              </div>
              <div className="menu-container">
                <div className="top">
                  <ul className="list-unstyled menu">
                    <li
                      onClick={() => {
                        handleNavigate("/home/dashboard");
                      }}
                      className={
                        history.location.pathname === "/home/dashboard" &&
                        !isTableContentOpen
                          ? "menu-item active"
                          : "menu-item"
                      }
                    >
                      <span className="menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.106"
                          height="13.361"
                          viewBox="0 0 16.106 13.361"
                        >
                          <g
                            id="Layer_2"
                            data-name="Layer 2"
                            transform="translate(-0.001 -0.001)"
                          >
                            <g id="icons" transform="translate(0.001 0.001)">
                              <path
                                id="Path_461"
                                data-name="Path 461"
                                d="M16.18,18.838H5.962a.452.452,0,0,1-.452-.452V10.452a.452.452,0,1,1,.9,0v7.481h9.313V10.452a.452.452,0,1,1,.9,0v7.934A.452.452,0,0,1,16.18,18.838Z"
                                transform="translate(-3.018 -5.477)"
                                fill="#fff"
                              />
                              <path
                                id="Path_462"
                                data-name="Path 462"
                                d="M.453,7.613a.452.452,0,0,1-.3-.792L7.754.114a.453.453,0,0,1,.6.678L.752,7.5A.452.452,0,0,1,.453,7.613Z"
                                transform="translate(-0.001 -0.001)"
                                fill="#fff"
                              />
                              <path
                                id="Path_463"
                                data-name="Path 463"
                                d="M24.86,7.613a.452.452,0,0,1-.3-.113L16.958.792a.453.453,0,0,1,.6-.678l7.6,6.708a.452.452,0,0,1-.3.792Z"
                                transform="translate(-9.205 -0.001)"
                                fill="#fff"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span className="menu-name">Dashboard</span>
                    </li>
                    <li
                      onClick={(e) => {
                        if (pathname === "/home/chapter-launch") {
                          setSavingProgress(true);
                          setTimeout(() => {
                            setSavingProgress(false);
                            sidebarHelper.navigate(
                              "/home/dashboard?open=tableOfContents",
                              pathname,
                              history,
                              chapterLaunchLogout,
                              setLoader
                            );
                            setIsTableContentOpen(true);
                            navOpen();
                          }, 2000); // 2 seconds
                        } else {
                          sidebarHelper.navigate(
                            "/home/dashboard?open=tableOfContents",
                            pathname,
                            history,
                            chapterLaunchLogout,
                            setLoader
                          );
                          setIsTableContentOpen(true);
                          navOpen();
                        }
                      }}
                      className={
                        history.location.pathname === "/home/dashboard" &&
                        isTableContentOpen
                          ? "menu-item active"
                          : "menu-item"
                      }
                    >
                      <span className="menu-icon content">
                        <img src={content} alt="content" />
                      </span>
                      <span className="menu-name">Table of contents</span>
                    </li>
                    <li
                      onClick={(e) => {
                        handleNavigate("/home/course-list");
                      }}
                      className={sidebarHelper.getActiveClassName(
                        history.location.pathname,
                        "/home/course-list"
                      )}
                    >
                      <span className="menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.006"
                          height="16.896"
                          viewBox="0 0 17.006 16.896"
                        >
                          <g
                            id="Layer_2"
                            data-name="Layer 2"
                            transform="translate(-0.25 -0.25)"
                          >
                            <g id="icons" transform="translate(0.75 0.75)">
                              <path
                                id="Path_510"
                                data-name="Path 510"
                                d="M14.252,1.51h2.087a1.326,1.326,0,0,1,1.326,1.326V15.627a1.326,1.326,0,0,1-1.326,1.337H4.24a1.326,1.326,0,0,1-1.32-1.326V2.847A1.326,1.326,0,0,1,4.24,1.51h5.913"
                                transform="translate(-1.658 -1.068)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_511"
                                data-name="Path 511"
                                d="M19.459,8.111,17.412,6.151,15.36,8.111V.75h4.1Z"
                                transform="translate(-6.866 -0.75)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeWidth="1"
                              />
                              <line
                                id="Line_26"
                                data-name="Line 26"
                                x2="1.262"
                                transform="translate(0 12.878)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeWidth="1"
                              />
                              <line
                                id="Line_27"
                                data-name="Line 27"
                                x2="1.262"
                                transform="translate(0 9.739)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeWidth="1"
                              />
                              <line
                                id="Line_28"
                                data-name="Line 28"
                                x2="1.262"
                                transform="translate(0 6.599)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeWidth="1"
                              />
                              <line
                                id="Line_29"
                                data-name="Line 29"
                                x2="1.262"
                                transform="translate(0 3.459)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeWidth="1"
                              />
                              <line
                                id="Line_30"
                                data-name="Line 30"
                                y2="14.902"
                                transform="translate(5.052 0.715)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeWidth="1"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span className="menu-name">Purchased Courses</span>
                    </li>
                    <li
                      onClick={(e) => {
                        handleNavigate("/home/profile");
                      }}
                      className={sidebarHelper.getActiveClassName(
                        history.location.pathname,
                        "/home/profile"
                      )}
                    >
                      <span className="menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.372"
                          height="16.384"
                          viewBox="0 0 16.372 16.384"
                        >
                          <g
                            id="Layer_2"
                            data-name="Layer 2"
                            transform="translate(0.005 0.01)"
                          >
                            <g id="icons" transform="translate(-0.005 -0.01)">
                              <path
                                id="Path_466"
                                data-name="Path 466"
                                d="M10.925,8.768a4.389,4.389,0,1,1,4.383-4.383,4.389,4.389,0,0,1-4.383,4.383Zm0-7.611a3.228,3.228,0,1,0,3.222,3.228,3.228,3.228,0,0,0-3.222-3.228Z"
                                transform="translate(-2.736 0.01)"
                                fill="#ffffff"
                              />
                              <path
                                id="Path_467"
                                data-name="Path 467"
                                d="M15.783,21.719H.578A.581.581,0,0,1,0,21.139a8.186,8.186,0,1,1,16.366,0,.581.581,0,0,1-.581.581ZM1.159,20.558H15.18a7.025,7.025,0,0,0-14.021,0Z"
                                transform="translate(0.005 -5.335)"
                                fill="#ffffff"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span className="menu-name"> Profile</span>
                    </li>
                    <li
                      onClick={(e) => {
                        handleNavigate("/home/settings");
                      }}
                      className={sidebarHelper.getActiveClassName(
                        history.location.pathname,
                        "/home/settings"
                      )}
                    >
                      <span className="menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.963"
                          height="17.963"
                          viewBox="0 0 17.963 17.963"
                        >
                          <g
                            id="Layer_2"
                            data-name="Layer 2"
                            transform="translate(-0.5 -0.5)"
                          >
                            <g id="icons" transform="translate(1 1)">
                              <path
                                id="Path_464"
                                data-name="Path 464"
                                d="M16.824,7.791H15.481a6.269,6.269,0,0,0-.559-1.322l.956-.977a1.139,1.139,0,0,0,0-1.609l-.8-.794a1.134,1.134,0,0,0-1.6,0l-.977.961a6.4,6.4,0,0,0-1.311-.522V2.139A1.144,1.144,0,0,0,10.048,1H8.909A1.139,1.139,0,0,0,7.791,2.139V3.518a6.06,6.06,0,0,0-1.316.522L5.493,3.09a1.139,1.139,0,0,0-1.609,0l-.794.8a1.139,1.139,0,0,0,0,1.609l.961.956a6.1,6.1,0,0,0-.569,1.337H2.134A1.134,1.134,0,0,0,1,8.92v1.134a1.134,1.134,0,0,0,1.139,1.139H3.455a6.546,6.546,0,0,0,.522,1.358l-.888.919a1.144,1.144,0,0,0,0,1.635l.8.8a1.139,1.139,0,0,0,1.609,0L6.412,15a6.305,6.305,0,0,0,1.379.549v1.28a1.139,1.139,0,0,0,1.144,1.139h1.134a1.139,1.139,0,0,0,1.139-1.139v-1.28a6.577,6.577,0,0,0,1.369-.569l.914.9a1.134,1.134,0,0,0,1.6,0l.8-.8a1.139,1.139,0,0,0,0-1.609l-.94-.919a6.269,6.269,0,0,0,.549-1.358h1.316a1.128,1.128,0,0,0,1.139-1.134V8.909A1.144,1.144,0,0,0,16.824,7.791Z"
                                transform="translate(-1 -1)"
                                fill="none"
                                stroke="#ffffff"
                                strokeLinecap="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_465"
                                data-name="Path 465"
                                d="M17.458,14.809A2.659,2.659,0,1,1,14.8,12.15,2.659,2.659,0,0,1,17.458,14.809Z"
                                transform="translate(-6.32 -6.325)"
                                fill="none"
                                stroke="#ffffff"
                                strokeLinecap="round"
                                strokeWidth="1"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span className="menu-name"> Settings</span>
                    </li>
                    <li
                      onClick={(e) => {
                        handleNavigate("/home/support");
                      }}
                      className={sidebarHelper.getActiveClassName(
                        history.location.pathname,
                        "/home/support"
                      )}
                    >
                      <span className="menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.551"
                          height="12.888"
                          viewBox="0 0 16.551 12.888"
                        >
                          <g
                            id="Layer_2"
                            data-name="Layer 2"
                            transform="translate(0.1 0.1)"
                          >
                            <g id="icons" transform="translate(0)">
                              <path
                                id="Path_468"
                                data-name="Path 468"
                                d="M24.741,17.613a.379.379,0,0,1-.278-.121l-5.2-5.524a.379.379,0,1,1,.551-.506l5.2,5.509a.384.384,0,0,1-.273.642Z"
                                transform="translate(-9.448 -5.578)"
                                fill="#fff"
                                stroke="#fff"
                                strokeWidth="0.2"
                              />
                              <path
                                id="Path_469"
                                data-name="Path 469"
                                d="M1.64,17.666a.369.369,0,0,1-.258-.106.379.379,0,0,1,0-.536l5.2-5.519a.384.384,0,0,1,.536,0,.379.379,0,0,1,0,.536l-5.175,5.5A.379.379,0,0,1,1.64,17.666Z"
                                transform="translate(-0.628 -5.63)"
                                fill="#fff"
                                stroke="#fff"
                                strokeWidth="0.2"
                              />
                              <path
                                id="Path_470"
                                data-name="Path 470"
                                d="M8.916,8.657a1.619,1.619,0,0,1-1.133-.506L1.52,2l.506-.541L8.34,7.605a.87.87,0,0,0,.6.293.87.87,0,0,0,.592-.293l6.238-6.147A.379.379,0,0,1,16.277,2L10.039,8.146A1.624,1.624,0,0,1,8.916,8.657Z"
                                transform="translate(-0.751 -0.695)"
                                fill="#fff"
                                stroke="#fff"
                                strokeWidth="0.2"
                              />
                              <path
                                id="Path_471"
                                data-name="Path 471"
                                d="M13.715,12.688H2.61A2.616,2.616,0,0,1,0,10.078V2.61A2.61,2.61,0,0,1,2.61,0H13.74a2.616,2.616,0,0,1,2.61,2.61v7.467a2.616,2.616,0,0,1-2.636,2.61ZM2.61.759A1.852,1.852,0,0,0,.759,2.61v7.467A1.852,1.852,0,0,0,2.61,11.929H13.74a1.852,1.852,0,0,0,1.852-1.852V2.61A1.852,1.852,0,0,0,13.715.759Z"
                                transform="translate(0)"
                                fill="#fff"
                                stroke="#fff"
                                strokeWidth="0.2"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span className="menu-name"> Support</span>
                    </li>
                    <li
                      onClick={(e) => {
                        handleNavigate("/home/customer-support");
                      }}
                      className={sidebarHelper.getActiveClassName(
                        history.location.pathname,
                        "/home/customer-support"
                      )}
                    >
                      <span className="menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.697"
                          height="16.697"
                          viewBox="0 0 16.697 16.697"
                        >
                          <path
                            id="iconmonstr-question-thin"
                            d="M8.348,0A8.348,8.348,0,1,1,0,8.348,8.352,8.352,0,0,1,8.348,0Zm0,.7A7.653,7.653,0,1,1,.7,8.348,7.656,7.656,0,0,1,8.348.7Zm.037,11.827a.588.588,0,1,0-.588-.588A.587.587,0,0,0,8.385,12.522Zm.326-1.963H8.016A2.752,2.752,0,0,1,8.67,8.512,15.846,15.846,0,0,0,10.023,7.08c.5-.855.054-2.21-1.58-2.21A2,2,0,0,0,6.406,6.614L5.7,6.543A2.6,2.6,0,0,1,8.426,4.174a2.318,2.318,0,0,1,2.515,2.24c0,1.256-1.27,1.935-1.835,2.691a2.562,2.562,0,0,0-.4,1.454Z"
                            fill="#fff"
                            fillRule="evenodd"
                          />
                        </svg>
                      </span>
                      <span className="menu-name"> FAQs</span>
                    </li>
                  </ul>
                </div>
                <div className="bottom mb-5">
                  <div className="">
                    <ChapterAndFinalQuizTimer history={history} />
                  </div>
                  <div className="mb-2">
                    <CourseTimer history={history} courseTimer={courseTimer} />
                  </div>
                  {complianceDetails && (
                    <div
                      className="compliance-details"
                      dangerouslySetInnerHTML={createMarkup(complianceDetails)}
                    ></div>
                  )}
                  <ul className="list-unstyled menu mb-0">
                    <li>
                      <img
                        src={elmsLogoLarge}
                        alt="elms Logo Large"
                        className={props.sidebarToggle ? "elmsLogoLarge collapsed" : "elmsLogoLarge"}
                      />
                    </li>
                    <li className="menu-item language-menu">
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="lang-btn"
                          data-translate="no"
                        >
                          <img
                            src={sourceFlag}
                            alt={showNationalFlag.text}
                            data-uw-rm-ima-original={showNationalFlag.text}
                          />
                          {showNationalFlag?.text}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <a
                            data-translate="no"
                            href="javascript:Localize.setLanguage('en');"
                            className="dropdown-item"
                            onClick={() =>
                              changeLanguage(
                                NATIONAL_LANGUAGE_TEXT.UNITEDSTATE
                              )
                            }
                          >
                            {showImageAndTextCountry(
                              UNITEDSTATE.image,
                              UNITEDSTATE.text
                            )}
                          </a>
                          <a
                            data-translate="no"
                            href="javascript:Localize.setLanguage('es-LA');"
                            className="dropdown-item"
                            onClick={() =>
                              changeLanguage(
                                NATIONAL_LANGUAGE_TEXT.SPAIN
                              )
                            }
                          >
                            {showImageAndTextCountry(SPAIN.image, SPAIN.text)}
                          </a>
                          <a
                            data-translate="no"
                            href="javascript:Localize.setLanguage('ru');"
                            className="dropdown-item"
                            onClick={() =>
                              changeLanguage(
                                NATIONAL_LANGUAGE_TEXT.RUSSIA
                              )
                            }
                          >
                            {showImageAndTextCountry(RUSSIA.image, RUSSIA.text)}
                          </a>
                          <a
                            data-translate="no"
                            href="javascript:Localize.setLanguage('zh');"
                            className="dropdown-item"
                            onClick={() =>
                              changeLanguage(
                                NATIONAL_LANGUAGE_TEXT.CHINA
                              )
                            }
                          >
                            {showImageAndTextCountry(CHINA.image, CHINA.text)}
                          </a>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                    <li
                      className="logout menu-item  mb-0"
                      onClick={() => {
                        localStorage.clear();
												window.localStorage.clear();
                        sidebarHelper.navigate(
                          "/",
                          pathname,
                          history,
                          chapterLaunchLogout,
                          setLoader
                        );
                      }}
                    >
                      <span className="menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="13.333"
                          viewBox="0 0 16 13.333"
                        >
                          <path
                            id="iconmonstr-log-out-8"
                            d="M0,2V15.333H9.333V14h-8V3.333h8V2ZM12,6.939l1.975,1.728L12,10.395V9.333H6.667V8H12ZM10.667,4V6.667H5.333v4h5.333v2.667L16,8.667Z"
                            transform="translate(0 -2)"
                            fill="#fff"
                          />
                        </svg>
                      </span>
                      <span className="menu-name"> Logout</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {savingProgress && <SavingProgressModal />}
          </div>
        </>
      ) : null}
    </>
  );
};

export default Sidebar;
