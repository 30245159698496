import { createTheme } from "@material-ui/core/styles";
export const formLabelsTheme = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        "&$error": {
          color: "#ff0000"
        }
      }
    }
  }
});