import React from 'react';
import { Modal } from 'react-bootstrap';
import warning from './../../../assets/images/warning.png';

export default function CoruseExpired({ showmodal }) {
    return (
        <div>
            <Modal show={showmodal} backdrop="static" keyboard={false} className='Register-expired-popup' centered>
                <Modal.Body>
                    <div className='d-flex'>
                        <i>
                            <img src={warning} className='warning-icon'/>
                        </i>
                       <div>
                       <p>The course you are attempting to access is currently undergoing maintenance or has expired.
                        </p>
                        <p>We apologize for the inconvenience. We are actively working on resolving the issue and will have the course available as soon as possible. Please check back at a later time.
                        </p>
                        <p>Thank you for your patience.
                        </p>
                       </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
