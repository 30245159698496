import React, { createContext, useEffect, useMemo, useState } from 'react';
import { EncryptStorage } from '../services/EncryptStorage';

const CourseContext = createContext({});

const initialState = {
    launchChapter: {},
};

const CourseProvider = ({ children }) => {
    const [courseData, setCourseData] = useState(() => {
        try {
            return EncryptStorage.getLocalStorage('courseData') || initialState;
        } catch (error) {
            console.log(error);
        }
    });

    useEffect(() => {
        EncryptStorage.setToLocalStorage('courseData', courseData);
    }, [courseData?.launchChapter?.url]);

    const y = EncryptStorage.getLocalStorage('courseData');

    const memoCourseData = useMemo(() => ({ courseData, setCourseData }), [courseData?.launchChapter?.url]);
    return <CourseContext.Provider value={memoCourseData}>{children}</CourseContext.Provider>;
};

export { CourseProvider, CourseContext };
