import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { EncryptStorage } from "../../common/imports/CommonImport";
import {
  user,
  dashboard,
  timerLite,
  unitedStates,
  componentImg,
  newuser,
} from "../../common/assets/Images";
import { shareTimeService } from "../../../services/shareTimeService";
import PropagateLoader from "react-spinners/PropagateLoader";
import environment from "../../../environments/environment";
import { brandLogoService } from "../../../services/brandLogoService";
import HeaderStepper from "./HeaderStepper";
import { _const } from "../../constant/const-variables/constVariable";
import { useShowNationalFlag } from "../../../hooks";
import ImprovLogo from "../../../assets/images/Improv_logo.png";
const { gapi } = window;

const { NATIONAL_LANGUAGE_TEXT } = _const;

function HeaderPublic(props) {
  const wrapperRef = useRef(null);

  const [timer, setTimer] = useState("");
  const { history, isReachedDashboard } = props;
  const [organizationLogo, setOrganizationLogo] = useState("");
  const isLoggedIn = localStorage.getItem("IsLoggedIn");
  const _isReachedDashboard = isReachedDashboard || false;
  const [parentEmailFlow, setParentEmailFlow] = useState(false);
  const [lanSelect, setLanSelect] = useState(false);
  const [loader] = useState(false);
  const [organisationId, setOrganisationId] = useState(null);
  const { setFlagNationalCurrent, showNationalFlag } = useShowNationalFlag(
    NATIONAL_LANGUAGE_TEXT.UNITEDSTATE
  );
  const [sourceFlag, setSourceFlag] = useState("");

  useEffect(() => {
    let localStore_ParentEmail =
      EncryptStorage.getLocalStorage("parentEmailFlow");
    setParentEmailFlow(localStore_ParentEmail);

    shareTimeService.onMessage().subscribe((res) => {
      const { text = "" } = res ?? {};
      setTimer(text);
    });

    brandLogoService.onMessage().subscribe((res) => {
      if (res && res.LogoPath && res.LogoPath.logoPath) {
        setOrganizationLogo(res.LogoPath.logoPath);
      } else {
        let organisationLogo =
          EncryptStorage.getLocalStorage("organisationLogo");
        if (organisationLogo) {
          setOrganizationLogo(organisationLogo);
        }
      }
      if (res && res.LogoPath && res.LogoPath.organisationId) {
        setOrganisationId(res.LogoPath.organisationId);
      } else {
        let organisation_Id = EncryptStorage.getLocalStorage("organisationId");
        if (organisation_Id) {
          setOrganisationId(organisation_Id);
        }
      }
    });

    setFlagNationalCurrent(EncryptStorage.getLocalStorage("currentBrowserLanguage"));
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    if (showNationalFlag.text === "English") {
      setSourceFlag("https://myimprov.com/wp-content/themes/improv/rafal2/assets/icons/usa.png")
    }
    else if (showNationalFlag.text === "Español (América Latina)") {
      setSourceFlag("https://myimprov.com/wp-content/themes/improv/rafal2/assets/icons/spain.png")
    }
    else if (showNationalFlag.text === "中文(简体)") {
      setSourceFlag("https://myimprov.com/wp-content/themes/improv/rafal2/assets/icons/china.png")
    }
  }, [showNationalFlag]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setLanSelect(false);
    }
  };

  const navigate = (path) => {
    if (path === "/login") {
      if (gapi) {
        let auth = gapi.auth2.getAuthInstance();
        if (auth && auth.isSignedIn.get()) {
          auth.signOut();
        }
      }
      if (window.amazon) window.amazon.Login.logout();
      if (window.FB) fbLogout();
      
      localStorage.clear();
			window.localStorage.clear();
    }
    history.push(path);
  };

  const fbLogout = () =>
    new Promise((resolve) => {
      window.FB.logout(resolve);
    });

  const initPage = () => {
    if (
      history.location.pathname === "/login" ||
      history.location.pathname === "/register"
    ) {
      history.push("/");
    }
  };

  const redirectNotEegistered = () => {
    if (organisationId && organisationId === "1481") {
      //window.location.href = "https://www.improvlearning.com/gsa";
      window.open("https://www.improvlearning.com/gsa", "_blank");
    } else {
      //window.location.href = "https://www.myimprov.com";
      window.open("https://www.myimprov.com", "_blank");
    }
  };

  const newUIHeader = () => {
    return (
      <header className="custom-header">
        {loader && (
          <div className="loader">
            <PropagateLoader size={15} />
          </div>
        )}
        <div className="custom-container">
          <div className="head-contents">
            <div className="head-switch">
              <div className="logo">
                <img
                  src={
                    organizationLogo
                      ? environment.bucketUrl + organizationLogo
                      : ImprovLogo
                  }
                  className="header-logo"
                  alt="Logo"
                  //onClick={initPage}
                  //style={{ cursor: "pointer" }}
                />
              </div>
              {!parentEmailFlow && (
                <>
                  <div className="nav__menu--list language-selector-mobile">
                    <div className="choose_language">
                      <div
                        id="localize-current"
                        onClick={() => {
                          setLanSelect(!lanSelect);
                        }}
                        ref={wrapperRef}
                      >
                        <img
                          src={sourceFlag}
                          alt={showNationalFlag.text}
                          data-uw-rm-ima-original={showNationalFlag.text}
                        />
                      </div>
                      <div
                        id="localize-active-lang"
                        className={
                          lanSelect
                            ? "active localize-active-lang-config"
                            : "localize-active-lang-config"
                        }
                      >
                        <a
                          href="javascript:Localize.setLanguage('en');"
                          data-translate="no"
                          onClick={() =>
                            changeLanguage(
                              NATIONAL_LANGUAGE_TEXT.UNITEDSTATE
                            )
                          }
                        >
                          <img
                            src="https://myimprov.com/wp-content/themes/improv/rafal2/assets/icons/usa.png"
                            alt="american flag"
                            data-uw-rm-ima-original="american flag"
                          />
                        </a>
                        <a
                          href="javascript:Localize.setLanguage('es-LA');"
                          data-translate="no"
                          onClick={() =>
                            changeLanguage(NATIONAL_LANGUAGE_TEXT.SPAIN)
                          }
                        >
                          <img
                            src="https://myimprov.com/wp-content/themes/improv/rafal2/assets/icons/spain.png"
                            alt="spain flag"
                            data-uw-rm-ima-original="spain flag"
                          />
                        </a>
                        {/* <a
                          href="javascript:Localize.setLanguage('ru');"
                          data-translate="no"
                          onClick={() => changeLanguage(NATIONAL_LANGUAGE_TEXT.RUSSIA) }
                        >
                          Русский
                        </a> */}
                        <a
                          href="javascript:Localize.setLanguage('zh');"
                          data-translate="no"
                          onClick={() =>
                            changeLanguage(NATIONAL_LANGUAGE_TEXT.CHINA)
                          }
                        >
                          <img
                            src="https://myimprov.com/wp-content/themes/improv/rafal2/assets/icons/china.png"
                            alt="china flag"
                            data-uw-rm-ima-original="china flag"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="login lg-show language-selector-mobile">
                    <img
                      className="cursor-pointer"
                      src={newuser}
                      alt="user login"
                      width="15"
                      height="15"
                      data-uw-rm-ima-original="user login"
                      onClick={() => {
                        if (
                          EncryptStorage.getLocalStorage("loggedIn") == true
                        ) {
                          localStorage.clear();
													window.localStorage.clear();
                        }
                        history.push("/login");
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            {!parentEmailFlow && <HeaderStepper />}
            <div className="hn-nav flex-lg ">
              {
                // !parentEmailFlow && (
                <>
                  <div className="d-flex">
                    <i className="icon loginUser cursor-pointer">
                      <img
                        src={componentImg}
                        alt="Accessibility"
                        id="accessibilityWidget"
                        tabIndex="0"
                      />
                    </i>
                  </div>
                  <ul className="lg-show">
                    <li>
                      <a
                        href="javascript:Localize.setLanguage('en');"
                        className="language-sel"
                        language="en"
                        aria-hidden="true"
                        data-uw-rm-brl="false"
                      >
                        <img
                          src="https://myimprov.com/wp-content/themes/improv/rafal2/assets/icons/usa.png"
                          alt="american flag"
                          data-uw-rm-ima-original="american flag"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:Localize.setLanguage('zh');"
                        className="language-sel"
                        language="zh-Hans"
                        aria-hidden="true"
                        data-uw-rm-brl="false"
                      >
                        <img
                          src="https://myimprov.com/wp-content/themes/improv/rafal2/assets/icons/china.png"
                          alt="china flag"
                          data-uw-rm-ima-original="china flag"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:Localize.setLanguage('es-LA');"
                        className="language-sel"
                        language="es-LA"
                        aria-hidden="true"
                        data-uw-rm-brl="false"
                      >
                        <img
                          src="https://myimprov.com/wp-content/themes/improv/rafal2/assets/icons/spain.png"
                          alt="spain flag"
                          data-uw-rm-ima-original="spain flag"
                        />
                      </a>
                    </li>
                  </ul>
                  {/* <div className="nav__menu--list">
                        <div className="choose_language">
                          <div id="localize-current" onClick={() => { setLanSelect(!lanSelect) }} ref={wrapperRef}>
                            <img src={unitedStates} />  <a href='javascript:Localize.setLanguage("en")'> English</a>
                          </div>
                          <div id="localize-active-lang" className={lanSelect ? "active" : ""}>
                            <a href="javascript:Localize.setLanguage('en');">English</a>
                            <a href="javascript:Localize.setLanguage('es-LA');">Español (América Latina)</a>
                            <a href="javascript:Localize.setLanguage('ru');">Русский</a>
                            <a href="javascript:Localize.setLanguage('zh');">中文(简体)</a>
                          </div>
                        </div>
                      </div> */}
                </>
                // )
              }
              {!parentEmailFlow && (
                <div className="login lg-show">
                  <img
                    src={newuser}
                    alt="user login"
                    width="15"
                    height="15"
                    data-uw-rm-ima-original="user login"
                  />
                  {EncryptStorage.getLocalStorage("loggedIn") == true ? (
                    <span
                      className="login-link"
                      data-uw-rm-brl="false"
                      onClick={() => {
                        localStorage.clear();
												window.localStorage.clear();
											  history.push("/"); 
                      }}
                    >
                      Logout
                    </span>
                  ) : (
                    <span
                      className="login-link"
                      data-uw-rm-brl="false"
                      onClick={() => {
                        history.push("/login");
                      }}
                    >
                      Login
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    );
  };

  const changeLanguage = (targetLanguage) => {
    EncryptStorage.setToLocalStorage("currentBrowserLanguage", targetLanguage);
    setFlagNationalCurrent(targetLanguage);
  }
  
  return (
    <>
      {history.location.pathname === "/register" ||
      history.location.pathname === "/registerfast" ||
      history.location.pathname === "/home/cart-summary" ||
      history.location.pathname === "/home/fast-lane-cart-summary" ||
      history.location.pathname === "/home/upsell-cart-summary" ||
      history.location.pathname === "/home/parent-cart-summary" ||
      history.location.pathname === "/home/view-billing-details" ||
      history.location.pathname === "/home/review-cart-and-checkout" ? (
        newUIHeader()
      ) : (
        <header className="nav-bar login-header">
          {loader && (
            <div className="loader">
              <PropagateLoader size={15} />
            </div>
          )}
          <div className="container nav">
            <div className="nav__logo">
              <img
                src={
                  organizationLogo
                    ? environment.bucketUrl + organizationLogo
                    : ImprovLogo
                }
                className="logo"
                alt="Logo"
                onClick={initPage}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="nav__menu">
              {timer && (
                <div className="nav__menu--list">
                  <div className="timer">
                    <div className="timer__icon mr-1">
                      <img src={timerLite} />
                    </div>
                    <div className="timer__number">{timer}</div>
                  </div>
                </div>
              )}
              {isLoggedIn && _isReachedDashboard && (
                <div className="nav__menu--list">
                  <div
                    className="dboard"
                    onClick={(e) => navigate("/home/dashboard")}
                  >
                    <div className="dboard__icon mr-2">
                      <img src={dashboard} />
                    </div>
                    <div className="dboard__name"> Dashboard</div>
                  </div>
                </div>
              )}
              {
                // !parentEmailFlow && (
                <>
                  <div className="d-flex">
                    <i className="icon loginUser cursor-pointer">
                      <img
                        src={componentImg}
                        alt="Accessibility"
                        id="accessibilityWidget"
                        tabIndex="0"
                      />
                    </i>
                  </div>

                  <div className="nav__menu--list">
                    <div className="choose_language">
                      <div
                        id="localize-current"
                        onClick={() => {
                          setLanSelect(!lanSelect);
                        }}
                        ref={wrapperRef}
                      >
                        {showNationalFlag?.image}
                        <span className="ignore-translate hide-in-mobile">
                          {showNationalFlag?.text}
                        </span>
                      </div>
                      <div
                        id="localize-active-lang"
                        className={lanSelect ? "active border" : ""}
                      >
                        <a
                          href="javascript:Localize.setLanguage('en');"
                          data-translate="no"
                          onClick={() =>
                            changeLanguage(
                              NATIONAL_LANGUAGE_TEXT.UNITEDSTATE
                            )
                          }
                        >
                          English
                        </a>
                        <a
                          href="javascript:Localize.setLanguage('es-LA');"
                          data-translate="no"
                          onClick={() =>
                            changeLanguage(NATIONAL_LANGUAGE_TEXT.SPAIN)
                          }
                        >
                          Español (América Latina)
                        </a>
                        <a
                          href="javascript:Localize.setLanguage('ru');"
                          data-translate="no"
                          onClick={() =>
                            changeLanguage(
                              NATIONAL_LANGUAGE_TEXT.RUSSIA
                            )
                          }
                        >
                          Русский
                        </a>
                        <a
                          href="javascript:Localize.setLanguage('zh');"
                          data-translate="no"
                          onClick={() =>
                            changeLanguage(NATIONAL_LANGUAGE_TEXT.CHINA)
                          }
                        >
                          中文(简体)
                        </a>
                      </div>
                    </div>
                  </div>
                </>
                // )
              }
              {!parentEmailFlow && (
                <div className="nav__menu--list">
                  <div className="d-flex align-items-center">
                    <div className="d-flex">
                      {history.location.pathname === "/" ||
                      history.location.pathname === "/register" ||
                      history.location.pathname === "/home/cart-summary" ||
                      history.location.pathname ===
                        "/home/fast-lane-cart-summary" ||
                      history.location.pathname === "/cart-summary-schedule" ||
                      history.location.pathname ===
                        "/home/review-cart-and-checkout" ||
                      history.location.pathname === "/forgot-password" ? (
                        <>
                          <i className="icon mr-2">
                            <img src={user} alt="user" />
                          </i>
                          <span className="login-text">
                            <div
                              onClick={() => {
                                if (
                                  EncryptStorage.getLocalStorage("loggedIn")
                                ) {
                                  localStorage.clear();
																	window.localStorage.clear();
                                } else {
                                  history.push("/login");
                                }
                              }}
                            >
                              {EncryptStorage.getLocalStorage("loggedIn") ==
                              true
                                ? "Logout"
                                : "Login"}
                            </div>
                            {/* <Link to="/login">
                              {EncryptStorage.getLocalStorage("loggedIn") ==
                              true
                                ? "Logout"
                                : "Login"}
                            </Link> */}
                          </span>
                        </>
                      ) : null}
                      {/* {history.location.pathname === "/login" ? (
                        <>
                          <i className="icon mr-2">
                            <img src={user} alt="user" />
                          </i>

                          <span className="login-text">
                            <Link
                              onClick={() => {
                                redirectNotEegistered();
                              }}
                            >
                              Register
                            </Link>
                          </span>
                        </>
                      ) : null} */}
                      {history.location.pathname ===
                        "/home/security-quiz-save" ||
                      history.location.pathname === "/home/chapter-quiz" ? (
                        <>
                          <span className="login-text">
                            <Link to="/home/dashboard">Dashboard</Link>
                          </span>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </header>
      )}
    </>
  );
}

export default withRouter(HeaderPublic);
