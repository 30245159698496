import { EncryptStorage } from "../../services/EncryptStorage";

const assignDefaultStorageValues = (res) => {
    if (res.data) {
        localStorage.setItem('IsLoggedIn', 'true');
        EncryptStorage.setToLocalStorage('redirectDashboard', true);
        const token = res.token;
        const student = res.data.student ? res.data.student : res.data;
        const studentId = student.studentId;
        const userObject = JSON.stringify(student);
        const stateId = student.state ? student.state.stateId : '';
        EncryptStorage.setToLocalStorage('userObject', userObject);
        EncryptStorage.setToLocalStorage('firstName', student.firstName ? student.firstName : '');
        // console.log('token');
        // console.log(token);
        // console.log('token');
        EncryptStorage.encryptOnlyKey('token', token);
        EncryptStorage.setToLocalStorage('studentId', studentId);
        EncryptStorage.setToLocalStorage('organisationId', student.organisation.organisationId);
        EncryptStorage.setToLocalStorage('organisationLogo', student.organisation.logoPath);
        EncryptStorage.setToLocalStorage('studentDomain', student.organisation.studentDomain);
        EncryptStorage.setToLocalStorage('studentPaymentTrxId', student.defaultStPaymentTrxId);
        EncryptStorage.setToLocalStorage('stateId', stateId);
        const { isPersonalInfoPending = false } = student;
        EncryptStorage.setToLocalStorage('isPersonalInfoPending', isPersonalInfoPending);
        EncryptStorage.setToLocalStorage('redirectUrl', student.organisation.redirectUrl ? student.organisation.redirectUrl : '');
    }
}

const resetStorageValues = () => {
    localStorage.viewPage = 'login';
    localStorage.setItem('IsLoggedIn', false);
    EncryptStorage.setToLocalStorage('userObject', '');
    EncryptStorage.encryptOnlyKey('token', '');
    EncryptStorage.setToLocalStorage('studentId', '');
    EncryptStorage.setToLocalStorage('organisationId', '');
    EncryptStorage.setToLocalStorage('studentDomain', '');
    EncryptStorage.setToLocalStorage('payLaterPayment', '');
    EncryptStorage.setToLocalStorage('redirectUrl', '');
    EncryptStorage.setToLocalStorage('isAcknowledged', '');
    EncryptStorage.setToLocalStorage('isSecurityQuestionVerifiedToday', '');
}

const checkIsTrue = (value) => {
    let res = false;
    if (value != undefined && value.toString() === "true") {
        res = true;
    }
    return res;
}


export const loginHelper = {
    assignDefaultStorageValues, resetStorageValues, checkIsTrue
}