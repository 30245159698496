import environment from "../../environments/environment";

const profileName = environment.profileName;


export const Paypal_Production_CLIENT_ID = 'Ae6eBHxe0BCJnZSY92X0DtaJzAZJdzTqtMO8DTnlpPC93tRAL7d8WlWDWNJU5hI1bWzk3xLrBPOoE9EE';
export const Paypal_Production_BasicAuth = 'QWU2ZUJIeGUwQkNKblpTWTkyWDBEdGFKekFaSmR6VHF0TU84RFRubHBQQzkzdFJBTDdkOFdsV0RXTkpVNWhJMWJXemszeExyQlBPb0U5RUU6RU5uMlIyaEFXdkRYdi1fN3hMT0ZjX0liUm9wWUVaYVViZFI1dWtMYkZMdW53TnBZTXlxcG1kclJncjFZY3pmc2s5T0lXZVZoWVd2VENJWGE='
export const Paypal_Production_AccessTokenUrl = 'https://api-m.paypal.com/v1/oauth2/token';
export const Paypal_Production_UserInfoUrl = 'https://api-m.paypal.com/v1/identity/oauth2/userinfo?schema=paypalv1.1';


// export const Paypal_Sandbox_CLIENT_ID = 'AU1TeWYmD4klKh2dGRz_PFyvFmfD_Jt3c52NaiWXUjkdbGWlyzEgghjd20xYe6qVhsTCCBQkGpySz0Es'; // thiru paypal developer account
// export const Paypal_Sandbox_BasicAuth = 'QVUxVGVXWW1ENGtsS2gyZEdSel9QRnl2Rm1mRF9KdDNjNTJOYWlXWFVqa2RiR1dseXpFZ2doamQyMHhZZTZxVmhzVENDQlFrR3B5U3owRXM6RUhraWtmUXE2SGd2S2ZLM0lvTW4yTG1zZjRtY2xUWTB2c19yc1BndFZfcnY1ZVhDcFFqS051WVNGOWk0MGlQRWJXR0Z1eV9Hb1R5VUVSWlU=';
// export const Paypal_Sandbox_AccessTokenUrl = 'https://api-m.sandbox.paypal.com/v1/oauth2/token';
// export const Paypal_Sandbox_UserInfoUrl = 'https://api-m.sandbox.paypal.com/v1/identity/oauth2/userinfo?schema=paypalv1.1';

export const Paypal_Sandbox_CLIENT_ID = 'AWeAn-m7Q732Mk1RJWBkXcCYnlYL-j22sGVrIHv2yXzUNZT2zGEkeBXgxXxAjSJP6z4c76lyRnbDWq_L'; // Kannan paypal developer account
export const Paypal_Sandbox_BasicAuth = 'QVdlQW4tbTdRNzMyTWsxUkpXQmtYY0NZbmxZTC1qMjJzR1ZySUh2MnlYelVOWlQyekdFa2VCWGd4WHhBalNKUDZ6NGM3Nmx5Um5iRFdxX0w6RUZfYnR1WjNCMFQ3OTRrbjRtVENDN2k1ZmxBVHlGR0ZUSXphT1g2VnZHS0dnNFhJc2QxVlhkUzNLT2ZPVGhkZm9ubE1nYkxBMFBzdzQ0cTc=';
export const Paypal_Sandbox_AccessTokenUrl = 'https://api-m.sandbox.paypal.com/v1/oauth2/token';
export const Paypal_Sandbox_UserInfoUrl = 'https://api-m.sandbox.paypal.com/v1/identity/oauth2/userinfo?schema=paypalv1.1';