import React, { useEffect } from 'react'
import axios from 'axios';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { EncryptStorage } from '../common/imports/CommonImport';
import {
    Paypal_Sandbox_BasicAuth, Paypal_Sandbox_AccessTokenUrl, Paypal_Sandbox_UserInfoUrl
} from './PaypalClientId';

const getLoader = (<div className="loader">
    <PropagateLoader size={15} />
</div>);

function PaypalLogin(props) {

    const { history } = props;

    useEffect(() => {
        if (props.history.location.search) {
            const code = props.history.location.search.split('?code=')[1].split('&')[0]
            if (code) getAuthTokenByUsigCode(code);
        }
    }, [])

    const getAuthTokenByUsigCode = (_code) => {
        const basic_auth = Paypal_Sandbox_BasicAuth
        let url = Paypal_Sandbox_AccessTokenUrl;
        const params = new URLSearchParams()
        params.append('grant_type', 'client_credentials')
        params.append('code', _code)
        axios(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${basic_auth}`
            },
            data: params
        }).then(res => {
            const data = res ? res.data : null;
            if (data && data.access_token) {
                const access_token = data.access_token;
                getUserInfoByAccessToken(access_token);
            }

        }).catch(error => {
            handleError(error);
        });

    }

    const getUserInfoByAccessToken = (access_token) => {
        let url = Paypal_Sandbox_UserInfoUrl;
        axios(url, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
        }).then(res => {
            const data = res ? res.data : '';
            if (data.emails.length > 0) {
                const payLoad = {
                    firstName: res.data.name.split(' ')[0],
                    lastName: res.data.name.split(' ')[1],
                    emailId: res.data.emails[0].value,
                    password: 'Password@123'
                }
                EncryptStorage.setToLocalStorage('papalUserInfo', payLoad);
                history.goBack();
            }

        }).catch(error => {
            handleError(error);
        });
    }

    const exceptionHandler = () => {
        EncryptStorage.setToLocalStorage('papalUserInfo', 'error');
        history.goBack();
    }

    return (
        <div>
            {getLoader}
        </div>
    )

    function handleError(error) {
        exceptionHandler();
        throw error;
    }
}

export default PaypalLogin
