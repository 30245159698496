export class NewProdUrlConstants {
    /* Registration */
    static registrationUrl = '/improv-student-registration-journey/student/course/registration';  //Done
    static getOrganisationUrl = '/improv-student-registration-journey/student/course/logo'; // Done
    static emailVerificationUrl = "/improv-student-registration-journey/student/email-verification/"; //Done
    static emailResendVerificationUrl = "/improv-student-registration-journey/student/resend-verification-code/"; //Done
    /* Login */
    static initloginUrl = '/improv-bdi-authentication/student/bdi/login';
    static loginUrl = '/improv-student-course-dashboard/student/login';   //Done
    static googleSignUrl = '/improv-student-course-dashboard/student/google/login';   //Done
    /* Logout */
    static logoutUrl = '/improv-student-registration-journey/student/logout';  //Done   
    static getUpSellApi = "/improv-student-course-dashboard/student/configcourseprice/"; //Done
    static saveAZUpsellUrl = "/improv-student-course-dashboard/student/course/upsell/"; //Done
    /* State and Category List */
    static courseMappingUrl = '/improv-course-config/courseconfigurations/'
    static getSteteWithCategoryListUrl = '/improv-course-config/states-reasons/';
    static getSteteListUrl = '/improv-student-course-dashboard/state/states'; //Done
    static getCourseList = "/elms-prod-student-courses/courses";
    static getPaymentClientToken = "/improv-student-registration-journey/student/course/payment/gateway/client-tokens"; //Done
    static checkoutUrl = "/improv-student-registration-journey/student/course/payment/checkout"; //Done
    static parentCheckoutUrl = "/elms-prod-student-parentpaymentcheckout/parentpaymentcheckout";
    static getCourseInfoDetails = '/elms-prod-student-courseinfodetail/courseinformation';
    static getCoursePriceDetails = '/improv-student-registration-journey/student/course/price'; //Done
    static getStudentDetails = '/improv-student-registration-journey/student'; //Done
    static getFlexifieldsList = '/improv-student-registration-journey/student/course/flexifield';  //Done
    static saveflexifield = '/improv-student-course-dashboard/student/course/flexifield'; //Done
    static saveDiversionFeeFlexiField = '/elms-prod-student-save-diversion-flexifields/diversion-flexifields';
    static getCourseInfomation = '/elmscourseinfodetail/courseinformation';
    static getUpSellApi = "/improv-course-config/configcourseprice/";
    static upsellCheckoutUrl = "/improv-student-course-dashboard/student/course/upsell/"; //Done
    /* Dashboard */
    static dashboardListOfChaptersUrl = '/improv-student-course-dashboard/student/course/dashboard'; //Done
    static chapterLaunch = '/improv-student-course-dashboard/student/course/chapter/launch';  //Done
    static multiCourseDashboardUrl = '/improv-student-course-dashboard/student/courses/dashboard'; //Done
    static upsellcoursesUrl = '/elms-prod-student-upsell-courses/upsellcourses';
    static resetCourseUrl = '/improv-student-course-dashboard/student/student/course/reset'; //Done
    /* SecurityQuestion */
    static getSecurityQuestionsUrl = '/improv-student-course-dashboard/student/course/security-question';  //Done
    static saveSecurityQuestionUrl = '/improv-student-course-dashboard/student/course/identity-verification';  //Done
    static getRandomSecurityQuestionUrl = '/improv-student-course-dashboard/student/course/random-security-question';  //Done
    /*Chapter Quiz */
    static getChapterQuizListUrl = '/improv-student-course-dashboard/student/course/chapter/quiz'; //Done
    static updateChapterStatus = '/elms-prod-student-chapter-status-update/chapterstatusupdate';
    static saveFinalQuizResult = '/elms-prod-student-save-final-quiz/studentfinalquiz';
    static chapter_CourseStatusUpdateUrl = '/elms-prod-student-chapter-course-status-update/statusupdate';
    /*My Orders*/
    static getStudentPurchaseDetails = '/improv-student-registration-journey/student/course/purchased';  // Done
    static changePasswordUrl = '/improv-student-registration-journey/student/change-password'; //Done
    static forgotPasswordUrl = '/improv-student-registration-journey/student/forgot-password'; //Done
    static resetPasswordUrl = '/improv-student-registration-journey/student/set-password'; //Done
    /* completedmessageUrl */
    static studentCourseCompletedMessageUrl = '/elms-prod-student-course-completed-message/studentcoursecompletedmessage';
    /* Discount code check*/
    static getStateWiseDiscountDetailsUrl = '/improv-student-registration-journey/student/course/discount'; //Done
    static giftCertificateUrl = '/elms-prod-student-gift-certificate/giftcertificatecode';
    static sacramentoCourtCourseUrl = '/elms-prod-student-county-courses/countycourse';
    static studentPaymentRefundUrl = '/elms-prod-student-payment-refund/studentpaymentrefund';
    /* chapter or Final Quiz Validation */
    static chapterFinalQuizValidationUrl = '/improv-student-course-dashboard/student/course/chapter/validate-quiz'; //Done
    static typingDnaSave_Verify = "/elms-prod-student-typingdnapattern"
    static downloadPaymentReceiptUrl = '/improv-student-registration-journey/student/course/payment/receipt'; //Done
    static emailPaymentReceiptUrl = "/improv-student/student-certificate/send/payment-receipt";
    /* studentfootprint */
    static studentfootprintUrl = '/improv-student-course-dashboard/student/foot-print'; //Done
    /* support ticket */
    static supportTicketUrl = '/improv-student-registration-journey/student/support-ticket';  //Done
    /*Document*/
    static getDocumentCatalogItems = '/improv-student-registration-journey/student/course/'; //Done
    static getDocumentList = '/improv-student-registration-journey/student/course/document/range?'; //Done
    static saveDocument = '/improv-student-registration-journey/student/course/document'; //Done
    static saveWebcamUrl = '/improv-student-registration-journey/student/course/facial-recognition'; //Done
    static updateStudentCourseTime = '/improv-student-course-dashboard/student/chapter/time'
    /*evs time spand*/
    static time_managementUrl = '/improv-student-course-dashboard/student/course/time_management';  //Done
    static universalCheckoutUrl = '/improv-student-course-dashboard/student/course/upsell/';

    // Tilled payment URl
    static createPaymentMethod = '/improv-student-registration-journey/student/course/payment/tilled/paymentMethod/'; //Done

    //parent-verification
    static parentVerificationPostUrl ='/improv-student-course-dashboard/student/course/parent-verification';

    //certificate download
    static certificateDownloadUrl = '/improv-student-course-dashboard/student/student-course-completed-certificate'
    
    //certificate download
    static certificateDownloadUrl = '/improv-student-course-dashboard/student/student-course-completed-certificate'
}
