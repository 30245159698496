import React, { useState, useRef, useEffect, Suspense } from "react";
import { Alert, Form, Modal } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import {
  VisibilityOutlinedIcon,
  VisibilityOffOutlinedIcon,
  unlockKey,
  long_right_arrow,
  sms_modal_img,
  sms_modal_img_mobile,
  CheckCircleIcon,
  LockIcon,
  CancelIcon,
} from "./../common/assets/Images";
import { withRouter } from "react-router-dom";
import { Schema } from "./Schema";
import moment from "moment";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  GenericAPIService,
  EncryptStorage,
  UrlConstants,
  Constants,
  HOF_Methods,
} from "../common/imports/CommonImport";
import NumberFormat from "react-number-format";
import PropagateLoader from "react-spinners/PropagateLoader";
import {
  Google_CLIENT_ID,
  Google_Provider,
  Facebook_CLIENT_ID,
  Facebook_Provider,
  Amazon_CLIENT_ID,
  Amazon_Provider,
} from "../login/social-media-login/AppClient_ID";
import NewFlexiField from "./flexi-field/NewFlexiField";
import { chatCommonService } from "../../services/chatCommonService";
import { toast, ToastContainer } from "react-toastify";
import { registerHelper } from "./registerHelper";
import EmailVerification from "./emailVerification/EmailVerification";
import PrivacyPolicy from "./modal/PrivacyPolicy";
import environment from "../../environments/environment";
import GoDaddySecured from "./layout/GoDaddySecured";
import TextNoticeModal from "./modal/TextNoticeModal";
import ConfrimationModal from "./modal/ConfrimationModal";
import CoruseExpired from "./modal/CoruseExpired";
import GSAAlreadyRegisterMessage from "./modal/GSAAlreadyRegisterMessage";
import RegularLoader from "../loader/Loader";
import { GetCoursePriceDetailsPayload } from "../../components/cartSummary/CardSummaryHelper/CartSummaryImport";
const SocialLoginButton = React.lazy(() =>
  import("../login/social-media-login/SocialLoginButton")
);

const getPaymentLoader = (
  <div className="loader payment-loader">
    <PropagateLoader size={15} style={{ zIndex: 1 }} />
    <div>
      <p>
        Your course allocation in progress,{" "}
        <strong>PLEASE DONT PRESS BACK BUTTON</strong>
      </p>
    </div>
  </div>
);

function RegisterForm(props) {
  const {
    history,
    setErrorMsg,
    agreeFlag,
    rulesFlag,
    configCustomAttributeList,
    loadCourseInfoDetails,
  } = props;
  const storeSubjectValue = EncryptStorage.getLocalStorage("storeSubjectValue");
  const handleShow = () => setShow(true);
  const PWDRef = useRef();
  const inputRef = useRef();
  const formRef = useRef();
  localStorage.viewPage = "register";
  localStorage.RootPage = "";
  const [errorMessage, setErrorMessage] = useState("");
  const [invalidDomainMessage, setInvalidDomainMessage] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [loader, setLoader] = useState(false);
  const [otherInfo, setShowOtherInfo] = useState(false);
  const [flexiFields, setFlexiFields] = useState([]);
  const [acceptValue, setAcceptValue] = useState(true);
  const [smsTextMessageModal, setSmsTextMessageModal] = useState(false);
  const [orgProperty, setOrgProperty] = useState([]);
  const [isRegistrationDisclaimer, setIsRegistrationDisclaimer] =
    useState(false);
  const [registrationDisclaimerData, setRegistrationDisclaimerData] = useState(
    []
  );
  const [show, setShow] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [selectPlaceholder, setSelectPlaceholder] = useState(false);
  const [cloneIsTrueParentEmail, setCloneIsTrueParentEmail] = useState(
    EncryptStorage.getLocalStorage("isTrueParentEmail")
  );
  const [tempStudentId, setTempStudentId] = useState("");
  const [isStateFollowActive, setIsStateFollowActive] = useState(false);
  const [smsChecked, setSmsChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(true);
  const [tempStateID, setTempStateID] = useState(null);
  const [citationDueDateBeforeSevenDate, setCitationDueDateBeforeSevenDate] =
    useState(null);
  const [citationDueDays, setCitationDueDays] = useState(null);
  const [emailVerification, setEmailVerification] = useState(false);
  const [orgCustomAttributeLists, setOrgCustomAttributeLists] = useState([]);
  const [studentRegistrationData, setStudentRegistrationData] = useState("");
  const [emitOtherCourtError, setEmitOtherCourtError] = useState(false);
  let tiktokScriptTag = document.createElement("script");
  const [initOrganisationId, setInitOrganisationId] = useState(null);
  const [isMobileNumberEnable, setIsMobileNumberEnable] = useState(true);
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [bypassPaymentLoader, setBypassPaymentLoader] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const [registeredErrorMessage, setRegisteredErrorMessage] = useState("");
  const [isActiveSkipEmailVerification, setIsActiveSkipEmailVerification] =
    useState(false);
  const [progress, setProgress] = useState(0);
  const [GSAAlreadyRegister, setGSAAlreadyRegister] = useState(false);
  const [loaderType, setLoaderType] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [utmObjectUS, setUtmObjectUS] = useState({});
  let isMailVerified = true;

  useEffect(() => {
    setUtmObjectUS(registerHelper.getIDsFromURL(agreeFlag, rulesFlag));
  }, []);

  useEffect(() => {
    setCloneIsTrueParentEmail(
      EncryptStorage.getLocalStorage("isTrueParentEmail")
    );
  }, [props.isTrueParentEmail]);

  useEffect(() => {
    const extractParamsFromHref = () => {
      const href = window.location.href;
      const hashIndex = href.indexOf('#');
      let queryString = '';

      if (hashIndex !== -1) {
        const hash = href.substring(hashIndex + 1);
        const queryIndex = hash.indexOf('?');
        
        if (queryIndex !== -1) {
          queryString = hash.substring(queryIndex + 1);
        }
      }

      const urlParams = new URLSearchParams(queryString);
      const configCourseId = urlParams.get('configCourseId');     
      EncryptStorage.setToLocalStorage("configCourseIdParam",configCourseId)
     
    };

    extractParamsFromHref();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
    const configCourseId = urlParams.get("configCourseId");
    if (configCourseId && courseName === "") {
      let payload = GetCoursePriceDetailsPayload.getPayload(configCourseId);
      if (payload) {
        const url = UrlConstants.getCoursePriceDetails;
        GenericAPIService.customAPIMethod(url, payload, "POST")
          .then((res) => {
            setCourseName(res.data.name);
          })
          .catch((err) => {
            console.log(Constants.serverErrorMessage);
            console.log(err);
          });
      }
    }
  }, []);

  useEffect(async () => {
    EncryptStorage.setToLocalStorage("domainRestriction", "");
    EncryptStorage.setToLocalStorage("isSecurityQuestionVerifiedToday", "");
    EncryptStorage.setToLocalStorage("isAZflow", false);
    EncryptStorage.setToLocalStorage("trackPlatform", false);
    await getWordpressParam();
    await init();
    tiktokScriptTag.src = "./tiktok.js";
    tiktokScriptTag.async = true;
    document.body.appendChild(tiktokScriptTag);
    return () => {
      document.body.removeChild(tiktokScriptTag);
    };
  }, []);

  useEffect(() => {
    const stateId = EncryptStorage.getLocalStorage("stateId");
    const organisationId = EncryptStorage.getLocalStorage("organisationId");
    setTempStateID(stateId);
    if (stateId == "1" && organisationId == "1001") {
      loadStateList();
      setIsStateFollowActive(true);
      getFlexiFieldInfo(stateId);
    } else {
      getFlexiFieldInfo(stateId);
      setIsStateFollowActive(false);
    }
  }, [isStateFollowActive]);

  useEffect(() => {
    chatCommonService.onMessage().subscribe((res) => {
      if (res) {
        registerHelper.domainRestriction(res);
        let resRemove_Fields = checkingAllAttributes("REMOVE_FIELDS");
        if (resRemove_Fields == "true") {
          setIsMobileNumberEnable(false);
        } else {
          setIsMobileNumberEnable(true);
        }
      }
      if (res && res.reason && res.reason.reasonName) {
        if (res.reason.reasonName == "SPIDER Drivers Education") {
          setIsRegistrationDisclaimer(false);
        } else {
          setIsRegistrationDisclaimer(true);
        }
      }
      if (res) {
        setRegistrationDisclaimerData(res);
        setOrgCustomAttributeLists(res.organisationCustomAttributeMappingList);
        setOrgProperty(res);
      }
    });
  }, [props]);

  const getLoader = (
    <div className="loader text-center">
      {loaderType ? (
        <PropagateLoader size={15} />
      ) : (
        <RegularLoader value={progress} />
      )}
    </div>
  );

  const findCitationDueDateBeforeSevenDate = (citationDueDate) => {
    if (citationDueDate) {
      let _dueDate = citationDueDate;
      let formattedDueDate = moment(_dueDate).format("YYYY-MM-DD");
      let subtrDueDate = moment(formattedDueDate)
        .subtract(7, "d")
        .format("YYYY-MM-DD");

      setCitationDueDateBeforeSevenDate(subtrDueDate);
      const todaydate = new Date();
      const formttedTodaydate = moment(todaydate).format("YYYY-MM-DD");
      let noOfDays = getDaysDiff(subtrDueDate, formttedTodaydate);
      setCitationDueDays(Math.abs(noOfDays) + 1);
    }
  };

  const getDaysDiff = (start_date, end_date, date_format = "YYYY-MM-DD") => {
    const getDateAsArray = (date) => {
      return moment(date.split(/\D+/), date_format);
    };
    return (
      <>
        <ToastContainer />
        {alertShow ? (
          <>
            <Modal
              show={true}
              backdrop="static"
              keyboard={false}
              id="messageModal"
              centered
            >
              <Modal.Body className="messageModal">
                <Alert variant={"danger"}>
                  <span className="success-icon">
                    <CheckCircleIcon />
                  </span>
                  <span className="danger-icon">
                    <CancelIcon />
                  </span>
                  {registeredErrorMessage}
                </Alert>
              </Modal.Body>
            </Modal>
          </>
        ) : null}
        {loader && getLoader}
        {bypassPaymentLoader && getPaymentLoader}
        <div className="registration-border">
          <h1>Let's setup your account</h1>
          <p className="course-name-register-form">{courseName}</p>
          <ConfrimationModal
            {...props}
            showModal={isActiveSkipEmailVerification}
          />
          <GSAAlreadyRegisterMessage
            {...props}
            showModal={GSAAlreadyRegister}
            message={errorMessage}
            handleClose={handleCloseGSAMessageModal}
          />
          {checkingAllAttributes("SOCIAL_LOGIN") === "true" ? (
            <div className="reg-btn">
              <Suspense fallback={getLoader}>
                <SocialLoginButton
                  setLoader={setLoader}
                  setErrorMessage={setErrorMessage}
                  isRegister={true}
                  appId={Google_CLIENT_ID}
                  provider={Google_Provider}
                  studentRegistration={studentRegistration}
                />
              </Suspense>
              <Suspense fallback={getLoader}>
                <SocialLoginButton
                  setLoader={setLoader}
                  setErrorMessage={setErrorMessage}
                  isRegister={true}
                  appId={Facebook_CLIENT_ID}
                  provider={Facebook_Provider}
                  studentRegistration={studentRegistration}
                />
              </Suspense>
            </div>
          ) : (
            <></>
          )}
          <div className="divider"></div>
          {checkingAllAttributes("SOCIAL_LOGIN") === "true" ? (
            <h2>Or register using your email</h2>
          ) : (
            ""
          )}

          <Formik
            innerRef={formRef}
            initialValues={Schema.initialValues}
            validationSchema={
              stateList && stateList.length > 0
                ? Schema.validationSchemaState
                : Schema.validationSchema
            }
            onSubmit={onRegisterFormSubmit}
            // onSubmit={checking}
          >
            {({
              handleSubmit,
              setFieldValue,
              values,
              errors,
              setValues,
              getFieldProps,
            }) => {
              return (
                <Form
                  className="reg-form"
                  onSubmit={handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  {errorMessage && !GSAAlreadyRegister && (
                    <div
                      className="custom-error main-error"
                      title={errorMessage}
                    >
                      {errorMessage}
                    </div>
                  )}
                  <div className="field-row">
                    <div className="field-col">
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        className="custom-form-control"
                        id="firstName"
                        {...getFieldProps("firstName")}
                        autoComplete="none"
                      />
                      <ErrorMessage
                        component="div"
                        className="error"
                        name="firstName"
                      />
                      {getClearIcon(
                        values.firstName,
                        setFieldValue,
                        "firstName"
                      )}
                    </div>
                    <div className="field-col">
                      <label htmlFor="lastName" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        className="custom-form-control"
                        id="lastName"
                        {...getFieldProps("lastName")}
                        autoComplete="none"
                      />
                      <ErrorMessage
                        component="div"
                        className="error"
                        name="lastName"
                      />
                      {getClearIcon(values.lastName, setFieldValue, "lastName")}
                    </div>
                  </div>
                  <div className="field-row">
                    <div className="field-col email">
                      <label htmlFor="emailId" className="form-label">
                        Email address
                      </label>
                      <input
                        type="text"
                        className="custom-form-control"
                        name="emailId"
                        id="emailId"
                        placeholder="your@email.com"
                        {...getFieldProps("emailId")}
                        autoComplete="none"
                      />
                      <ErrorMessage
                        component="div"
                        className="error"
                        name="emailId"
                      />
                      {getClearIcon(values.emailId, setFieldValue, "emailId")}
                    </div>
                    {cloneIsTrueParentEmail ? (
                      <div className="field-col">
                        <label htmlFor="parentEmail" className="form-label">
                          Parent / Guardian Email
                        </label>
                        <input
                          type="text"
                          className="custom-form-control"
                          name="parentEmail"
                          id="parentEmail"
                          placeholder="Parent / Guardian Email"
                          {...getFieldProps("parentEmail")}
                          autoComplete="none"
                        />
                        <ErrorMessage
                          component="div"
                          className="error"
                          name="parentEmail"
                        />
                        {getClearIcon(
                          values.parentEmail,
                          setFieldValue,
                          "parentEmail"
                        )}
                      </div>
                    ) : null}
                  </div>
                  {isMobileNumberEnable ? (
                    <div className="field-row">
                      <div className="field-col">
                        <label htmlFor="mobileNumber" className="form-label">
                          Phone number
                        </label>
                        <NumberFormat
                          className="form-label custom-form-control"
                          getInputRef={inputRef}
                          type="text"
                          label="Phone number"
                          name="mobileNumber"
                          id="registerMobileNumber"
                          placeholder="(000) 000-0000"
                          format="###-###-####"
                          value={values.mobileNumber}
                          onChange={(e) =>
                            handlePhoneChange(e, setFieldValue, "mobileNumber")
                          }
                          //  {...getFieldProps("mobileNumber")}
                        />
                        <span> We use it to send course info only</span>
                        <ErrorMessage
                          component="div"
                          className="error"
                          name="mobileNumber"
                        />
                        {getClearIcon(
                          values.mobileNumber,
                          setFieldValue,
                          "mobileNumber"
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {stateList && stateList.length > 0 ? (
                    <div className="field-row">
                      <div className="field-col">
                        <label htmlFor="state" className="form-label">
                          State
                        </label>
                        <select
                          className={`custom-form-control ${
                            values.state == "" && "gray-placeholder"
                          }`}
                          name="state"
                          id="state"
                          placeholder="State"
                          onChange={(e) => {
                            handleStateSelect(e, "state", setFieldValue);
                          }}
                        >
                          <option
                            disabled
                            value=""
                            selected
                            className="defaultOption"
                          >
                            State Name
                          </option>
                          {stateList.map((item, index) => (
                            <option
                              key={`State${index.toString()}`}
                              className="stateOption"
                              value={item.stateId}
                            >
                              {item.stateName}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage
                          component="div"
                          className="error"
                          name="state"
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="field-row">
                    <div className="field-col password">
                      <label htmlFor="password" className="form-label">
                        Create Password
                      </label>
                      <input
                        ref={PWDRef}
                        type={(visibility && "text") || "password"}
                        name="password"
                        placeholder="Create Password"
                        className="custom-form-control"
                        id="password"
                        {...getFieldProps("password")}
                        autoComplete="none"
                      />
                      <ErrorMessage
                        component="div"
                        className="error"
                        name="password"
                      />
                      <i className="field__icon password-eye">
                        {(visibility && (
                          <VisibilityOutlinedIcon
                            onClick={() => setVisibility(false)}
                          />
                        )) || (
                          <VisibilityOffOutlinedIcon
                            onClick={() => setVisibility(true)}
                          />
                        )}
                      </i>
                      {values.password ? (
                        <i
                          className="field__icon clear password"
                          onClick={() => setFieldValue("password", "")}
                        >
                          <HighlightOffIcon />
                        </i>
                      ) : null}
                    </div>
                  </div>
                  <button
                    className="hide-btn"
                    type="submit"
                    id="registerfrm"
                    name="registerfrm"
                  ></button>
                </Form>
              );
            }}
          </Formik>
          {otherInfo && flexiFields.length > 0 ? (
            <>
              <NewFlexiField
                flexiFields={flexiFields}
                onFlexiFieldSubmit={onFlexiFieldSubmit}
                findCitationDueDateBeforeSevenDate={
                  findCitationDueDateBeforeSevenDate
                }
                emitOtherCourtError={emitOtherCourtError}
                emitOtherCourtErrorEvent={emitOtherCourtErrorEvent}
              />
            </>
          ) : (
            <></>
          )}
          {otherInfo &&
          flexiFields.length > 0 &&
          citationDueDateBeforeSevenDate ? (
            <div className="diversion-message mb-1">
              <p>
                Courts require you to finish the course <span> 7 </span>
                days before the court date. Your course must be completed by
                <span> {citationDueDateBeforeSevenDate}</span>. You have{" "}
                <span> {citationDueDays}</span> days to complete the course.
              </p>
            </div>
          ) : null}
          <div className="mt-2">
            <div className="d-flex align-items-center">
              <button
                type="submit"
                className="submit-btn flex-submit"
                onClick={() => triggerAllForm()}
              >
                {" "}
                Continue <img src={long_right_arrow} />
              </button>
            </div>
          </div>
          <div className="privacy-policy">
            {!registrationDisclaimerData.isRegistrationDisclaimerRequired ? (
              <>
                {isRegistrationDisclaimer ? (
                  <p>
                    By creating an account, I agree to the{" "}
                    <a
                      onClick={() => {
                        setShow(true);
                      }}
                      id="myBtn"
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>
                ) : (
                  <p>
                    By creating an account, I agree to the{" "}
                    <a
                      href="https://driverz.com/terms-and-conditions/"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>
                )}
              </>
            ) : null}

            {orgProperty.isTwilioRequired && isMobileNumberEnable ? (
              <div className="checkbox check__item">
                <label className="label-container">
                  <input
                    type="checkbox"
                    checked={smsChecked}
                    id="isStudentOptSms"
                    name="isStudentOptSms"
                    onClick={() => smsTrigger()}
                    onChange={() => {}}
                  />
                  <span className="checkmark"></span>
                  <span className="text">
                    I agree to be contacted by Interactive Education Concepts
                    Inc DBA IMPROVLearning and DriverZ via SMS text messaging to
                    receive important communications.
                  </span>
                </label>
              </div>
            ) : (
              <></>
            )}
            {show && <PrivacyPolicy show={show} handleClose={handleClose} />}
            <div className="checkbox check__item">
              {registrationDisclaimerData &&
              registrationDisclaimerData.isRegistrationDisclaimerRequired ? (
                <>
                  <label className="label-container">
                    <span className="text">
                      {registrationDisclaimerData &&
                        registrationDisclaimerData.registrationDisclaimerMessage}
                    </span>
                  </label>
                </>
              ) : (
                <>
                  <label className="label-container">
                    <input
                      type="checkbox"
                      checked={emailChecked}
                      id="isStudentOptMail"
                      name="isStudentOptMail"
                      onClick={() => emailTrigger()}
                      onChange={() => {}}
                    />
                    <span className="checkmark"></span>
                    <span className="text">
                      I agree to receive occasional emails from Interactive
                      Education Concepts/DriverZ related to the course
                      completion, important updates on new products and relevant
                      traffic rules and regulations.
                    </span>
                  </label>
                </>
              )}
            </div>
          </div>
          <div className="secure-transaction">
            <p>
              Secure transaction
              <img src={unlockKey} />
            </p>
          </div>
        </div>
        <GoDaddySecured />
        {/* smsTextMessageModal */}
        {!smsChecked && (
          <Modal
            show={smsTextMessageModal}
            centered
            backdrop="static"
            keyboard={false}
            className="modal-white reg-sms-modal"
          >
            <Modal.Body>
              {/* <span className="modal-close" onClick={() => setSmsTextMessageModal(!smsTextMessageModal)}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24.5" height="24.5" viewBox="0 0 24.5 24.5">
                        <g id="iconmonstr-x-mark-circle-thin" transform="translate(0.25 0.25)">
                           <path
                              id="iconmonstr-x-mark-circle-thin-2"
                              data-name="iconmonstr-x-mark-circle-thin"
                              d="M12,0A12,12,0,1,1,0,12,12.006,12.006,0,0,1,12,0Zm0,1A11,11,0,1,1,1,12,11.005,11.005,0,0,1,12,1Zm0,10.293L17.293,6,18,6.707,12.707,12,18,17.293,17.293,18,12,12.707,6.707,18,6,17.293,11.293,12,6,6.707,6.707,6,12,11.293Z"
                              fill="#0048a1"
                              stroke="#0048a1"
                              stroke-width="0.5"
                              fill-rule="evenodd"
                           />
                        </g>
                     </svg>
                  </span> */}
              <div className="d-flex sms-modal-body">
                <div className="reg-sms-modal-left">
                  <img className="show-lg" src={sms_modal_img} />
                  <img className="show-sm" src={sms_modal_img_mobile} />
                </div>
                <div className="reg-sms-modal-right">
                  <div className="reg-sms-modal-right-notification">
                    NOTIFICATION
                  </div>
                  <div className="reg-sms-modal-right-title">SMS Alert!</div>
                  <div className="reg-sms-modal-right-content sms-width">
                    Are you sure you don't want us to send you important course
                    communications? We promise we don't SPAM, only important
                    information relevant to your course will be sent.
                  </div>

                  {isMobileNumberEnable ? (
                    <>
                      <label
                        htmlFor="isStudentOptSms"
                        onClick={() => smsModalTrigger()}
                      >
                        <div className="reg-sms-modal-right-chk">
                          <input
                            type="checkbox"
                            name="isStudentOptSms"
                            id="isStudentOptSms"
                          />{" "}
                          Okay, sign me up
                        </div>
                      </label>

                      <span className="btn-plain" onClick={handleIgnore}>
                        Yes, I am sure
                      </span>
                    </>
                  ) : (
                    <span className="btn-plain" onClick={handleIgnore}>
                      Yes, I am sure
                    </span>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
        {/* {confirmEmail && (
            <Modal show={confirmEmail} centered backdrop="static" keyboard={false} className=" white-rounded-modal smsTextModal">
               <Modal.Body>
                  <span className="modal-close cursor-pointer" onClick={() => setConfirmEmail(false)}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24.5" height="24.5" viewBox="0 0 24.5 24.5">
                        <g id="iconmonstr-x-mark-circle-thin" transform="translate(0.25 0.25)">
                           <path
                              id="iconmonstr-x-mark-circle-thin-2"
                              data-name="iconmonstr-x-mark-circle-thin"
                              d="M12,0A12,12,0,1,1,0,12,12.006,12.006,0,0,1,12,0Zm0,1A11,11,0,1,1,1,12,11.005,11.005,0,0,1,12,1Zm0,10.293L17.293,6,18,6.707,12.707,12,18,17.293,17.293,18,12,12.707,6.707,18,6,17.293,11.293,12,6,6.707,6.707,6,12,11.293Z"
                              fill="#0048a1"
                              stroke="#0048a1"
                              stroke-width="0.5"
                              fill-rule="evenodd"
                           />
                        </g>
                     </svg>
                  </span>
                  <div className="smsTextModal-body">
                     <h5 className="text-center mt-4">Alert</h5>
                     <p>
                        Please confirm your email (<strong>{emailId}</strong>) is valid and correct.
                        All important information about your course progression and completion will be sent to the provided email address.
                     </p>
                     <div className="confirm-email-btn-block">
                        <button type="submit" className="v2-btn standard mobile-full-width" onClick={() => emailConfirmed()}>Confirm</button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         )} */}
        {registerHelper.checkTrue(emailVerification) ||
        emailVerification == "paymentProcess" ? (
          <EmailVerification
            emailVerification={emailVerification}
            handleClose={handleClose}
            paymentProcess={paymentProcess}
            emailId={studentRegistrationData && studentRegistrationData.emailId}
            history={history}
          />
        ) : null}
        {/* <TextNoticeModal /> */}
        {/* <button variant="primary"  onClick={handlgeShowf} >
        Launch demo modal
      </button> */}
        {storeSubjectValue?.isCourseExpired && (
          <CoruseExpired showmodal={storeSubjectValue?.isCourseExpired} />
        )}
      </>
    );
  };

  const handleClose = () => {
    setShow(false);
    setEmailVerification(false);
  };

  const init = async () => {
    const papalUserInfo = EncryptStorage.getLocalStorage("papalUserInfo");
    if (papalUserInfo && papalUserInfo === "error") {
      setErrorMessage(Constants.serverErrorMessage);
    } else if (papalUserInfo) {
      studentRegistration(papalUserInfo);
    }
  };

  const getStateName = (stateId = "") => {
    const payload = {};
    const url = UrlConstants.getSteteListUrl + "?IsActive=true";
    GenericAPIService.customAPIMethodClone(url, payload, "GET")
      .then((res) => {
        if (HOF_Methods.getStatus(res) === "success") {
          const { data = [] } = res;
          EncryptStorage.setToLocalStorage("registerStateName", stateId);
          data &&
            data.length > 0 &&
            data.map((item) => {
              if (item.stateId.toString() == stateId && stateId.toString()) {
                EncryptStorage.setToLocalStorage(
                  "registerStateName",
                  item.stateName
                );
              }
            });
        }
      })
      .catch((err) => {
        setLoader(false);
        setErrorMessage(err?.status?.message);
      });
  };

  const loadStateList = () => {
    setLoader(true);
    const payload = {};
    const url = UrlConstants.getSteteListUrl + "?IsActive=true";
    GenericAPIService.customAPIMethodClone(url, payload, "GET")
      .then((res) => {
        setLoader(false);
        if (HOF_Methods.getStatus(res) === "success") {
          const { data = [] } = res;
          setLoader(false);
          setStateList(data.reverse());
        } else {
          setErrorMessage(res.status.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        setErrorMessage(err.status.message);
      });
  };

  const getWordpressParam = async () => {
    if (window.location.href) {
      let getUrl = window.location.href;
      let splitUrl = window.location.href.includes("?")
        ? window.location.href.split("?")
        : false;
      if (splitUrl) {
        checkSplitUrl(getUrl);
      }
    }
  };

  const checkSplitUrl = (getUrl) => {
    let replaceUrl = getUrl.replace("#/", "");
    replaceUrl = replaceUrl.replace("'", "");
    let url = new URL(replaceUrl);
    const params = new URLSearchParams(new URL(replaceUrl).search);
    const configCourseldValue = params.get("configCourseld")
      ? params.get("configCourseld")
      : "";
    const orgIdValue = params.get("organisationId")
      ? params.get("organisationId")
      : "";
    const stateIdValue = params.get("stateId") ? params.get("stateId") : "";
    const vehicleTagValue = params.get("vehicleTag")
      ? params.get("vehicleTag")
      : "";
    let configCourseId = url.searchParams.get("configCourseId")
      ? url.searchParams.get("configCourseId")
      : "";
    let organisationId = url.searchParams.get("organisationId")
      ? url.searchParams.get("organisationId")
      : "";
    let stateId = url.searchParams.get("stateId")
      ? url.searchParams.get("stateId")
      : "";
    let vehicleTag = url.searchParams.get("vehicleTag")
      ? url.searchParams.get("vehicleTag")
      : "";
    EncryptStorage.setToLocalStorage("parentEmailFlow", false);
    EncryptStorage.setToLocalStorage("registerVia", "nonsacramento");
    EncryptStorage.setToLocalStorage(
      "stateId",
      stateId ? stateId : stateIdValue
    );
    EncryptStorage.setToLocalStorage(
      "configCourseId",
      configCourseId ? configCourseId : configCourseldValue
    );
    EncryptStorage.setToLocalStorage(
      "organisationId",
      organisationId ? organisationId : orgIdValue
    );
    EncryptStorage.setToLocalStorage(
      "vehicleTag",
      vehicleTag ? vehicleTag : vehicleTagValue
    );
    setTempStateID(stateId);
    setInitOrganisationId(organisationId);
    setLoader(true);
    if (stateId == "1" && organisationId == "1001") {
      setIsStateFollowActive(true);
    } else {
      setLoader(true);
      setIsStateFollowActive(false);
    }
    getStateName(stateId);
  };

  const getFlexiFieldInfo = async (stateId = "") => {
    setLoader(true);
    let configCourseId = EncryptStorage.getLocalStorage("configCourseId");
    let _stateId =
      stateId == "" ? EncryptStorage.getLocalStorage("stateId") : stateId;
    if (_stateId != "") {
      if (configCourseId) {
        getFlexiFieldSuccessHandler(configCourseId, _stateId);
      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };

  const getFlexiFieldSuccessHandler = async (configCourseId, _stateId) => {
    setLoader(true);
    const stateIdRegex = /[^0-9]/g;
    let verifiedStateId;
    if (_stateId && typeof _stateId === "string"){
      verifiedStateId = _stateId.replace(stateIdRegex, "");
    }else {
      verifiedStateId = _stateId;
    }
    let payload = {
      configCourseId: configCourseId,
      isDiversionFieldAtRegistration: true,
      stateId: verifiedStateId,
    };
    setTempStateID(verifiedStateId);
    setLoader(true);
    setProgress(0);
    const url =
      UrlConstants.getFlexifieldsList +
      `?configCourseJsonObj=${encodeURIComponent(JSON.stringify(payload))}`;
    await GenericAPIService.customAPIMethod(url, {}, "GET")
      .then((res) => {
        if (HOF_Methods.getStatus(res) === "success") {
          if (res.data && Object.entries(res.data).length != 0) {
            let currentProgress = 0;
            setProgress(100);
            checkStudentFlexiFieldDto(res);
            /* const interval = setInterval(() => {
              currentProgress += 20;
              setProgress(currentProgress);

              if (currentProgress > 100) {
                clearInterval(interval);
                checkStudentFlexiFieldDto(res);
              }
            }, 500); */
          } else {
            setShowOtherInfo(false);
            setLoader(false);
          }
        } else {
          setLoader(false);
          setShowOtherInfo(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        setErrorMessage(err?.status?.message);
      });
  };

  const checkStudentFlexiFieldDto = (res) => {
    if (res.data.studentFlexiFieldDtoList) {
      if (res.data.studentFlexiFieldDtoList.length != 0) {
        setFlexiFields(res.data.studentFlexiFieldDtoList);
        setShowOtherInfo(true);
      }
      setLoader(false);
    }
    if (res.data.studentCourseInformationDtoObj) {
      if (Object.entries(res.data.studentCourseInformationDtoObj).length != 0) {
        loadCourseInfoDetails(res.data.studentCourseInformationDtoObj);
      }
      setLoader(false);
    }
  };

  const onRegisterFormSubmit = (values) => {
    let tempvalues = values;
    tempvalues.firstName = tempvalues.firstName.trim();
    tempvalues.lastName = tempvalues.lastName.trim();
    tempvalues.emailId = tempvalues.emailId.trim();

    if (utmObjectUS.a_aid === ""){
      utmObjectUS.a_aid = utmObjectUS.PAPAffiliateId;
    }

    const tempObject = { ...tempvalues, ...utmObjectUS };

    if (smsChecked) {
      tempObject.isStudentOptSms = true;
    }
    if (agreeFlag && rulesFlag) {
      setErrorMsg(false);
      localStorage.setItem("registerFormValues", JSON.stringify(tempObject));
      localStorage.setItem("registerFormValidated", true);
    } else {
      setLoader(false);
      localStorage.setItem("registerFormValidated", false);
      setErrorMsg(true);
    }

    let registerFormValidated = localStorage.getItem("registerFormValidated")
      ? JSON.parse(localStorage.getItem("registerFormValidated"))
      : false;
    let flexiFieldValidated = localStorage.getItem("flexiFieldValidated")
      ? JSON.parse(localStorage.getItem("flexiFieldValidated"))
      : false;
    let savedValues = localStorage.getItem("registerFormValues")
      ? JSON.parse(localStorage.getItem("registerFormValues"))
      : {};

    if (otherInfo) {
      checkOtherInfo(registerFormValidated, flexiFieldValidated, savedValues);
    } else {
      setLoader(true);

      studentRegistration(savedValues, "formSubmit");
    }
  };

  const checkOtherInfo = (
    registerFormValidated,
    flexiFieldValidated,
    savedValues
  ) => {
    if (registerFormValidated && flexiFieldValidated) {
      localStorage.setItem("flexiFieldValidated", false);
      localStorage.setItem("registerFormValidated", false);
      studentRegistration(savedValues, "formSubmit");
    } else {
      if (
        registerFormValidated.toString() == "true" &&
        flexiFieldValidated.toString() == "false"
      ) {
        if (document.getElementById("OtherCourt")) {
          if (document.getElementById("OtherCourt").value != "") {
            document.getElementById("flexiFieldFormBtn").click();
          }
        }
      }
    }
  };

  const onFlexiFieldSubmit = (values) => {
    let submitError = false;
    let valueKeys = Object.keys(values);
    valueKeys.forEach((item) => {
      if (item.includes("CONFIRM")) {
        let tempName = item.replace("CONFIRM", "");
        if (values[tempName] !== values[item]) {
          submitError = true;
        }
      }
    });
    registerHelper.checkSubmitError(submitError, values);
    let registerFormValidated = localStorage.getItem("registerFormValidated")
      ? JSON.parse(localStorage.getItem("registerFormValidated"))
      : false;
    let flexiFieldValidated = localStorage.getItem("flexiFieldValidated")
      ? JSON.parse(localStorage.getItem("flexiFieldValidated"))
      : false;
    let savedValues = localStorage.getItem("registerFormValues")
      ? JSON.parse(localStorage.getItem("registerFormValues"))
      : {};
    if (registerFormValidated && flexiFieldValidated) {
      localStorage.setItem("flexiFieldValidated", false);
      localStorage.setItem("registerFormValidated", false);
      studentRegistration(savedValues, "formSubmit");
    }
  };

  const getConfigCourseId = (selectBoxStateId = "") => {
    let stateId = selectBoxStateId == "" ? EncryptStorage.getLocalStorage("stateId") : selectBoxStateId;
    let configCourseId = EncryptStorage.getLocalStorage("configCourseId");
    let url = UrlConstants.courseMappingUrl + `course-remapping?configCourseId=${configCourseId}&stateId=${stateId}`;
    setTempStateID(stateId);
    if (stateId && configCourseId) {
      setLoader(true);
      const payload = {};
      GenericAPIService.customAPIMethodCloneNew(url, payload, "GET")
        .then((res) => {
          getConfigCourseIdSuccessHandler(res, stateId);
        })
        .catch((err) => {
          if (err.response.status == 404) {
            setLoader(false);
          } else {
            setLoader(false);
            setErrorMessage(Constants.serverErrorMessage);
          }
        });
    } else {
      setLoader(false);
      setErrorMessage(Constants.serverErrorMessage);
    }
  };

  const getConfigCourseIdSuccessHandler = (res, stateId) => {
    if (HOF_Methods.getStatus(res) === "success") {
      const { data } = res;
      if (data && data.courseConfigId) {
        setLoader(true);
        EncryptStorage.setToLocalStorage("configCourseId", data.courseConfigId);
        getFlexiFieldInfo(stateId);
      }
    } else {
      setLoader(false);
    }
  };

  const studentRegistration = (values, type = "") => {
    /* checking email Verification custom Attribute true or false */
    let isVerifiedValue = registerHelper.emailVerificationEnabled(
      orgCustomAttributeLists
    );
    // setIsVerified(!isVerifiedValue)
    isMailVerified = isVerifiedValue ? false : true;
    let withStateId = registerHelper.getStudentRegisterPayloadForWithStateId(
      values,
      isMailVerified
    );
    let formValue = registerHelper.setStudentRegisterFormValue(
      values,
      isMailVerified
    );
    let url = UrlConstants.registrationUrl;
    setErrorMessage("");
    let newPayloadMap = registerHelper.setStudentRegisterPayloadValue(
      isStateFollowActive,
      withStateId,
      formValue,
      values
    );

    let registerVia = EncryptStorage.getLocalStorage("registerVia");
    if (registerVia == "sacramento") {
      newPayloadMap = Object.assign(newPayloadMap, {
        configCourseId: EncryptStorage.getLocalStorage("configCourseId"),
        countyLedgerId: EncryptStorage.getLocalStorage("countyLedgerId"),
      });
    } else {
      newPayloadMap = Object.assign(newPayloadMap, {
        configCourseId: EncryptStorage.getLocalStorage("configCourseId"),
      });
    }

    if (
      (values.isStudentOptSms === true && acceptValue === true) ||
      (values.isStudentOptSms === false && acceptValue === false) ||
      values.subjectIssuer
    ) {
      // if (environment.profileName == 'Newstage') {
      //    let emailChecked = EncryptStorage.getLocalStorage('emailConfirmed');
      //    if ((emailChecked && emailChecked.toString() === 'true') || type != 'formSubmit') {
      //       if (values && values.subjectIssuer) {
      //          setLoader(true);
      //          url = UrlConstants.googleSignUrl;
      //          let new_Payload = {};
      //          new_Payload.requestType = "registration";
      //          new_Payload.organisationId = EncryptStorage.getLocalStorage("organisationId");
      //          new_Payload.configCourseId = EncryptStorage.getLocalStorage("configCourseId");
      //          new_Payload.subjectIssuer = values.subjectIssuer;
      //          new_Payload.subjectToken = values.subjectToken;
      //          createNewStudentRegisterApiCall(url, new_Payload);
      //       } else {
      //          createNewStudentRegisterApiCall(url, newPayloadMap);
      //       }
      //    }
      //    else {
      //       setLoader(false);
      //       setEmailId(values.emailId);
      //       setConfirmEmail(true);
      //    }
      // }
      // else {
      setLoader(true);

      if (values && values.subjectIssuer) {
        url = UrlConstants.googleSignUrl;
        let new_Payload = {};
        new_Payload.requestType = "registration";
        new_Payload.organisationId =
          EncryptStorage.getLocalStorage("organisationId");
        new_Payload.configCourseId =
          EncryptStorage.getLocalStorage("configCourseId");
        new_Payload.subjectIssuer = values.subjectIssuer;
        new_Payload.subjectToken = values.subjectToken;
        createNewStudentRegisterApiCall(url, new_Payload);
      } else {
        createNewStudentRegisterApiCall(url, newPayloadMap);
      }
      // }
    } else {
      // if (environment.profileName == 'Newstage') {
      //    let emailChecked = EncryptStorage.getLocalStorage('emailConfirmed');
      //    if (emailChecked && emailChecked.toString() === 'true') {
      //       checkIsTwilioRequiredFlag(url, newPayloadMap);
      //    }
      //    else {
      //       setLoader(false);
      //       setEmailId(values.emailId);
      //       setConfirmEmail(true);
      //    }loggedIn
      // }
      // else {
      checkIsTwilioRequiredFlag(url, newPayloadMap);
      // }
    }
    EncryptStorage.setToLocalStorage("loggedIn", true);
  };

  const checkIsTwilioRequiredFlag = (url, newPayloadMap) => {
    /**
     ** checkout isTwilioRequired flag is true
     */
    if (orgProperty.isTwilioRequired) {
      if (!smsChecked) {
        setLoader(false);
        let mobileNumberField = document.getElementById("registerMobileNumber");

        if (mobileNumberField && mobileNumberField.value != "") {
          setSmsTextMessageModal(!smsTextMessageModal);
        } else if (
          newPayloadMap &&
          newPayloadMap.student &&
          newPayloadMap.student.mobileNumber
        ) {
          setLoader(true);
          createNewStudentRegisterApiCall(url, newPayloadMap);
        } else {
          setSmsTextMessageModal(!smsTextMessageModal);
        }
      } else {
        setLoader(true);
        createNewStudentRegisterApiCall(url, newPayloadMap);
      }
    } else {
      setLoader(true);
      createNewStudentRegisterApiCall(url, newPayloadMap);
    }
  };

  const createRegisterApiPayload = (temp_RequestPayload) => {
    if (otherInfo && flexiFields.length != 0) {
      const configCourseId = EncryptStorage.getLocalStorage("configCourseId");
      let tempFlexiFieldObject = [];
      for (const item of flexiFields) {
        if (registerHelper.checkCountyObejct()) {
          if (item.flexiFieldName != "COURT") {
            tempFlexiFieldObject = registerHelper.setNewStudentRegisterPayload(
              tempFlexiFieldObject,
              item,
              configCourseId
            );
          }
        } else {
          tempFlexiFieldObject = registerHelper.setNewStudentRegisterPayload(
            tempFlexiFieldObject,
            item,
            configCourseId
          );
        }
      }
      if (registerHelper.checkCountyObejct()) {
        let othrObj = localStorage.OtherCountyObject;
        let tempOthrObj = JSON.parse(othrObj);
        tempOthrObj.refFieldValue = document.getElementById("OtherCourt").value;
        tempFlexiFieldObject.push(JSON.parse(othrObj));
      }
      temp_RequestPayload.flexifieldList = tempFlexiFieldObject;
    }
    const requestPayload = {
      ...temp_RequestPayload,
      student: {
        ...temp_RequestPayload.student,
        state: {
          stateId: tempStateID,
        },
      },
    };
    return requestPayload;
  };

  const createNewStudentRegisterSuccessHandler = (res) => {
    let studentId = registerHelper.setUserObjectAndStudentId(res);
    const { token = "" } = res;
    setTempStudentId(studentId);
    isMailVerified
      ? EncryptStorage.encryptOnlyKey("token", token)
      : EncryptStorage.encryptOnlyKey("tokenCopy", token);

    if (res && res.data && res.data?.isEmailVerficationSkipped) {
      EncryptStorage.encryptOnlyKey("token", token);
      EncryptStorage.encryptOnlyKey("tokenCopy", token);
    }
    if (configCustomAttributeList && configCustomAttributeList.length > 0) {
      let _configCustomAttributeList = configCustomAttributeList.filter(
        (object) => {
          if (object.customAttribute.columnName == "AZ_FLOW") {
            return 1;
          }
        }
      );
      if (_configCustomAttributeList.length == 0) {
        if (registerHelper.checkTrue(res.data.isEligible)) {
          cardSummaryRedirect(res.data);
        }
        if (registerHelper.checkFalse(res.data.isEligible)) {
          errorLogin(res.status.message);
        }
        if (res.data.isEligible == undefined) {
          cardSummaryRedirect(res.data);
        }
      } else {
        if (registerHelper.checkTrue(res.data.isEligible)) {
          azCardSummaryRedirect(res.data);
        }
        if (registerHelper.checkFalse(res.data.isEligible)) {
          errorLogin(res.status.message);
        }
        if (res.data.isEligible == undefined) {
          azCardSummaryRedirect(res.data);
        }
      }
    } else {
      if (registerHelper.checkTrue(res.data.isEligible)) {
        cardSummaryRedirect(res.data);
      }
      if (registerHelper.checkFalse(res.data.isEligible)) {
        errorLogin(res.status.message);
      }
      if (res.data.isEligible == undefined) {
        cardSummaryRedirect(res.data);
      }
    }
  };

  const redirectToLogin = () => {
    localStorage.clear();
    window.localStorage.clear();
    history.push("/login");
  };

  const createNewStudentRegisterApiCall = (url, newPayloadMap) => {
    setLoader(true);
    let tempRequestPayload = createRegisterApiPayload(newPayloadMap);
    GenericAPIService.customAPIMethod(url, tempRequestPayload, "POST")
      .then((res) => {
        if (res && res.data) {
          setStudentRegistrationData(res.data);
          EncryptStorage.setToLocalStorage("papalUserInfo", "");
          if (HOF_Methods.getStatus(res) === "success") {
            createNewStudentRegisterSuccessHandler(res);
            setErrorMessage(res.status.message);
          } else {
            setLoader(false);
            setErrorMessage(res.status.message);
            if (
              registerHelper.checkTrue(res.data.islicenseplateEligible) ||
              res.status.message ==
                "Please enter a valid license plate to register for the course"
            ) {
              setErrorMessage("Please enter a valid vehicle tag");
            } else {
              if (
                res.status.message.includes(
                  "We could not register you to the course"
                )
              ) {
                errorLogin(res.status.message);
              } else if (
                res.status.message ==
                "Email id already registered with us please contact support team for registering to this course"
              ) {
                setAlertShow(true);
                setErrorMessage("");
                setRegisteredErrorMessage(res.status.message);
                setTimeout(() => {
                  setAlertShow(false);
                  redirectToLogin();
                  setRegisteredErrorMessage("");
                }, 5000);
              } else {
                let result = null;
                if (
                  res.status.message.includes("GSA Driver Safety course") ||
                  !res.status.message.includes("GSA Vehicle Tag Number") ||
                  res.status.message.includes("Email id already registered")
                ) {
                  setGSAAlreadyRegister(true);
                  setRegisteredErrorMessage(res.status.message);

                  setTimeout(() => {
                    setAlertShow(false);
                    redirectToLogin();
                    setRegisteredErrorMessage("");
                  }, 5000);
                } else {
                  result =
                    res.status.message.includes("Duplicate Email Id") ||
                    res.status.message.includes("already registered");
                  result
                    ? setErrorMessage("Mail Id already exists")
                    : setErrorMessage(res.status.message);
                }
              }
            }
          }
        } else if (res && res.status.success === "Fail") {
          setInvalidDomainMessage(res.status.message);
          setLoader(false);
        } else setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setErrorMessage(err?.status?.message);
      });
  };

  const cardSummaryRedirect = async (el) => {
    localStorage.setItem("IsLoggedIn", "true");
    localStorage.setItem("registerFlow", "true");
    localStorage.RootPage = "Register";
    EncryptStorage.setToLocalStorage("isAZflow", false);
    let res = await checkingAllAttributes("PAYMENT_BYPASS");
    if (res == "true") {
      //skip email verification
      if (el?.isEmailVerficationSkipped) {
        EncryptStorage.setToLocalStorage("isEmailVerficationSkipped", true);
        paymentProcess();
      } else {
        if (isMailVerified) {
          paymentProcess();
        } else {
          setEmailVerification("paymentProcess");
        }
        EncryptStorage.setToLocalStorage("isEmailVerficationSkipped", false);
      }
    } else {
      EncryptStorage.setToLocalStorage("redirectUrlName", "/home/cart-summary");
      //skip email verification
      if (el?.isEmailVerficationSkipped) {
        history.push("/home/cart-summary");
        EncryptStorage.setToLocalStorage("isEmailVerficationSkipped", true);
      } else {
        isMailVerified
          ? history.push("/home/cart-summary")
          : setEmailVerification(true);
        EncryptStorage.setToLocalStorage("isEmailVerficationSkipped", false);
      }
    }
  };

  const azCardSummaryRedirect = (el) => {
    localStorage.setItem("IsLoggedIn", "true");
    localStorage.setItem("registerFlow", "true");
    localStorage.RootPage = "Register";
    EncryptStorage.setToLocalStorage("isAZflow", true);
    EncryptStorage.setToLocalStorage(
      "redirectUrlName",
      "/home/az-cart-summary"
    );

    //skip email verification
    if (el?.isEmailVerficationSkipped) {
      history.push("/home/az-cart-summary");
      EncryptStorage.setToLocalStorage("isEmailVerficationSkipped", true);
    } else {
      isMailVerified
        ? history.push("/home/az-cart-summary")
        : setEmailVerification(true);
      EncryptStorage.setToLocalStorage("isEmailVerficationSkipped", false);
    }
  };

  const errorLogin = (message) => {
    setLoader(false);
    EncryptStorage.setToLocalStorage("registrationcompleted", false);
    localStorage.setItem("IsLoggedIn", "false");
    localStorage.setItem("registerFlow", "false");
    EncryptStorage.setToLocalStorage("isAZflow", false);
    setErrorMessage(message);
    setTimeout(() => {
      history.push("/login");
    }, 5000);
  };

  const paymentProcess = () => {
    setBypassPaymentLoader(true);
    let requestPayload = registerHelper.setPaymentProcessPayload();
    let checkoutUrl = UrlConstants.checkoutUrl;
    GenericAPIService.customAPIMethod(checkoutUrl, requestPayload, "POST")
      .then((res) => {
        setBypassPaymentLoader(false);
        if (HOF_Methods.getStatus(res) === "success") {
          localStorage.setItem("IsLoggedIn", "true");
          localStorage.setItem("registerFlow", "true");
          localStorage.RootPage = "Register";
          EncryptStorage.setToLocalStorage("isAZflow", false);
          const { data = "" } = res;
          const { studentCourse = "" } = data;
          const studentCourseId = studentCourse
            ? studentCourse.studentCourseId
            : "";
          EncryptStorage.setToLocalStorage("studentCourseId", studentCourseId);
          const isEmailVerficationSkipped = EncryptStorage.getLocalStorage(
            "isEmailVerficationSkipped"
          );
          if (isEmailVerficationSkipped) {
            setIsActiveSkipEmailVerification(true);
          } else {
            getPersonalInfo();
          }
        } else {
          setErrorMessage(res.status.message);
          toast(res.status.message);
          handleClose();
        }
      })
      .catch((err) => {
        handleClose();
        setBypassPaymentLoader(false);
        setErrorMessage(Constants.serverErrorMessage);
      });
  };

  const getPersonalInfo = () => {
    setLoader(true);
    let studentId = EncryptStorage.getLocalStorage("studentId");
    const payload = {
      studentId: studentId,
    };
    const url = UrlConstants.getStudentDetails;
    GenericAPIService.customAPIMethod(url, payload, "GET")
      .then((res) => {
        if (HOF_Methods.getStatus(res) === "success") {
          const { data = "" } = res;
          if (data) {
            EncryptStorage.setToLocalStorage(
              "isPersonalInfoPending",
              data.isPersonalInfoPending
            );
            const isEmailVerficationSkipped = EncryptStorage.getLocalStorage(
              "isEmailVerficationSkipped"
            );
            if (data.defaultStPaymentTrxId) {
              EncryptStorage.setToLocalStorage(
                "studentPaymentTrxId",
                data.defaultStPaymentTrxId
              );
              history.push("/home/dashboard");
            }
          }
        } else {
          handleClose();
          setErrorMessage(res.status.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        handleClose();
        setLoader(false);
        setErrorMessage(Constants.serverErrorMessage);
      });
  };

  const handleIgnore = () => {
    setAcceptValue(false);
    setTimeout(() => {
      setSmsTextMessageModal(false);
      triggerAllForm();
    }, 1000);
  };

  const checkingAllAttributes = (name) => {
    let result = null;
    if (props.customAttributesList && props.customAttributesList.length > 0) {
      props.customAttributesList.forEach((item) => {
        if (item.customAttribute.columnName === name) {
          if (name === "SOCIAL_LOGIN") {
            result = false;
          } else {
            result = item.customAttributeValue;
          }
        }
      });
      return result;
    } else {
      return result;
    }
  };

  const handleStateSelect = (e, name, setFieldValue) => {
    const { value } = e.target;
    if (value === "State Name") {
      setSelectPlaceholder(false);
    } else {
      setSelectPlaceholder(true);
      setFieldValue(name, value);
      setTempStateID(value);
      getConfigCourseId(value);
    }
  };

  const triggerAllForm = async () => {
    setLoaderType(true);
    if (document.getElementById("flexiFieldFormBtn")) {
      if (document.getElementById("OtherCourt")) {
        if (document.getElementById("OtherCourt").value == "") {
          await document.getElementById("registerfrm").click();
          await document.getElementById("flexiFieldFormBtn").click();
          setEmitOtherCourtError(true);
        } else {
          await document.getElementById("registerfrm").click();
          await document.getElementById("flexiFieldFormBtn").click();
        }
      } else {
        await document.getElementById("registerfrm").click();
        await document.getElementById("flexiFieldFormBtn").click();
      }
    } else {
      document.getElementById("registerfrm").click();
    }
  };

  const emitOtherCourtErrorEvent = (value) => {
    setEmitOtherCourtError(value);
  };

  const smsTrigger = () => {
    document.getElementById("isStudentOptSms").click();
    let mobileNumberField = document.getElementById("registerMobileNumber");
    if (
      mobileNumberField &&
      (mobileNumberField.value == "" || mobileNumberField.value == null)
    ) {
      setSmsChecked(false);
    } else {
      setSmsChecked(!smsChecked);
    }
  };

  const smsModalTrigger = () => {
    let mobileNumberField = document.getElementById("registerMobileNumber");
    if (mobileNumberField && mobileNumberField.value != "") {
      setSmsChecked(true);
    }
    setSmsTextMessageModal(false);
  };

  const emailTrigger = () => {
    document.getElementById("isStudentOptMail").click();
    setEmailChecked(!emailChecked);
  };

  const getClearIcon = (value, setFieldValue, fieldName) => {
    return (
      <>
        {value ? (
          <i
            className="field__icon clear"
            onClick={() => {
              setFieldValue(fieldName, "");
              setSmsChecked(false);
            }}
          >
            <HighlightOffIcon />
          </i>
        ) : null}
      </>
    );
  };

  const emailConfirmed = () => {
    setConfirmEmail(false);
    EncryptStorage.setToLocalStorage("emailConfirmed", true);
    triggerAllForm();
  };
  const handlePhoneChange = (e, setFieldValue, propName) => {
    if (e.nativeEvent.inputType == "deleteContentBackward") {
      setFieldValue("mobileNumber", "");
    } else {
      if (e.target.value != "" && e.target.value != null) {
        if (propName == "mobileNumber") {
          setFieldValue("mobileNumber", e.target.value);
        }
      }
    }

    if (e.target.value == "") {
      setSmsChecked(false);
    }
  };
  const handleCloseGSAMessageModal = () => {
    setGSAAlreadyRegister(false);
  };

  return (
    <>
      <ToastContainer />
      {alertShow ? (
        <>
          <Modal
            show={true}
            backdrop="static"
            keyboard={false}
            id="messageModal"
            centered
          >
            <Modal.Body className="messageModal">
              <Alert variant={"danger"}>
                <span className="success-icon">
                  <CheckCircleIcon />
                </span>
                <span className="danger-icon">
                  <CancelIcon />
                </span>
                {registeredErrorMessage}
              </Alert>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
      {loader && getLoader}
      {bypassPaymentLoader && getPaymentLoader}
      <div className="registration-border">
        <h1>Let's setup your account</h1>
        <p className="course-name-register-form">{courseName}</p>
        <ConfrimationModal
          {...props}
          showModal={isActiveSkipEmailVerification}
        />
        <GSAAlreadyRegisterMessage
          {...props}
          showModal={GSAAlreadyRegister}
          message={errorMessage}
          handleClose={handleCloseGSAMessageModal}
        />
        {checkingAllAttributes("SOCIAL_LOGIN") === "true" ? (
          <div className="reg-btn">
            <Suspense fallback={getLoader}>
              <SocialLoginButton
                setLoader={setLoader}
                setErrorMessage={setErrorMessage}
                isRegister={true}
                appId={Google_CLIENT_ID}
                provider={Google_Provider}
                studentRegistration={studentRegistration}
              />
            </Suspense>
            {/* <Suspense fallback={getLoader}>
              <SocialLoginButton
                setLoader={setLoader}
                setErrorMessage={setErrorMessage}
                isRegister={true}
                appId={Facebook_CLIENT_ID}
                provider={Facebook_Provider}
                studentRegistration={studentRegistration}
              />
            </Suspense> */}
          </div>
        ) : (
          <></>
        )}
        {checkingAllAttributes("SOCIAL_LOGIN") === "true" ? (
          <div className="divider"></div>
        ) : (
          ""
        )}

        {checkingAllAttributes("SOCIAL_LOGIN") === "true" ? (
          <h2>Or register using your email</h2>
        ) : (
          ""
        )}

        <Formik
          innerRef={formRef}
          initialValues={Schema.initialValues}
          validationSchema={
            stateList && stateList.length > 0
              ? Schema.validationSchemaState
              : Schema.validationSchema
          }
          onSubmit={onRegisterFormSubmit}
          // onSubmit={checking}
        >
          {({
            handleSubmit,
            setFieldValue,
            values,
            errors,
            setValues,
            getFieldProps,
          }) => {
            return (
              <Form
                className="reg-form"
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
                {errorMessage && !GSAAlreadyRegister && (
                  <div className="custom-error main-error" title={errorMessage}>
                    {errorMessage}
                  </div>
                )}
                <div className="field-row">
                  <div className="field-col">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      className="custom-form-control"
                      id="firstName"
                      {...getFieldProps("firstName")}
                      autoComplete="none"
                    />
                    <ErrorMessage
                      component="div"
                      className="error"
                      name="firstName"
                    />
                    {getClearIcon(values.firstName, setFieldValue, "firstName")}
                  </div>
                  <div className="field-col">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      className="custom-form-control"
                      id="lastName"
                      {...getFieldProps("lastName")}
                      autoComplete="none"
                    />
                    <ErrorMessage
                      component="div"
                      className="error"
                      name="lastName"
                    />
                    {getClearIcon(values.lastName, setFieldValue, "lastName")}
                  </div>
                </div>
                <div className="field-row">
                  <div className="field-col email">
                    <label htmlFor="emailId" className="form-label">
                      Email address
                    </label>
                    <input
                      type="text"
                      className="custom-form-control"
                      name="emailId"
                      id="emailId"
                      onKeyUp={() => setInvalidDomainMessage("")}
                      placeholder="your@email.com"
                      {...getFieldProps("emailId")}
                      autoComplete="none"
                    />
                    <ErrorMessage
                      component="div"
                      className="error"
                      name="emailId"
                    />
                    {invalidDomainMessage && (
                      <span className="error">{invalidDomainMessage}</span>
                    )}
                    {getClearIcon(values.emailId, setFieldValue, "emailId")}
                  </div>
                  {cloneIsTrueParentEmail ? (
                    <div className="field-col">
                      <label htmlFor="parentEmail" className="form-label">
                        Parent / Guardian Email
                      </label>
                      <input
                        type="text"
                        className="custom-form-control"
                        name="parentEmail"
                        id="parentEmail"
                        placeholder="Parent / Guardian Email"
                        {...getFieldProps("parentEmail")}
                        autoComplete="none"
                      />
                      <ErrorMessage
                        component="div"
                        className="error"
                        name="parentEmail"
                      />
                      {getClearIcon(
                        values.parentEmail,
                        setFieldValue,
                        "parentEmail"
                      )}
                    </div>
                  ) : null}
                </div>
                {isMobileNumberEnable ? (
                  <div className="field-row">
                    <div className="field-col">
                      <label htmlFor="mobileNumber" className="form-label">
                        Phone number
                      </label>
                      <NumberFormat
                        className="form-label custom-form-control"
                        getInputRef={inputRef}
                        type="text"
                        label="Phone number"
                        name="mobileNumber"
                        id="registerMobileNumber"
                        placeholder="(000) 000-0000"
                        format="###-###-####"
                        value={values.mobileNumber}
                        onChange={(e) =>
                          handlePhoneChange(e, setFieldValue, "mobileNumber")
                        }
                        //  {...getFieldProps("mobileNumber")}
                      />
                      <span> We use it to send course info only</span>
                      <ErrorMessage
                        component="div"
                        className="error"
                        name="mobileNumber"
                      />
                      {getClearIcon(
                        values.mobileNumber,
                        setFieldValue,
                        "mobileNumber"
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {stateList && stateList.length > 0 ? (
                  <div className="field-row">
                    <div className="field-col">
                      <label htmlFor="state" className="form-label">
                        State
                      </label>
                      <select
                        className={`custom-form-control ${
                          values.state == "" && "gray-placeholder"
                        }`}
                        name="state"
                        id="state"
                        placeholder="State"
                        onChange={(e) => {
                          handleStateSelect(e, "state", setFieldValue);
                        }}
                      >
                        <option
                          disabled
                          value=""
                          selected
                          className="defaultOption"
                        >
                          State Name
                        </option>
                        {stateList.map((item, index) => (
                          <option
                            key={`State${index.toString()}`}
                            className="stateOption"
                            value={item.stateId}
                          >
                            {item.stateName}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage
                        component="div"
                        className="error"
                        name="state"
                      />
                    </div>
                  </div>
                ) : null}
                <div className="field-row">
                  <div className="field-col password">
                    <label htmlFor="password" className="form-label">
                      Create Password
                    </label>
                    <input
                      ref={PWDRef}
                      type={(visibility && "text") || "password"}
                      name="password"
                      placeholder="Create Password"
                      className="custom-form-control"
                      id="password"
                      {...getFieldProps("password")}
                      autoComplete="none"
                    />
                    <ErrorMessage
                      component="div"
                      className="error"
                      name="password"
                    />
                    <i className="field__icon password-eye">
                      {(visibility && (
                        <VisibilityOutlinedIcon
                          onClick={() => setVisibility(false)}
                        />
                      )) || (
                        <VisibilityOffOutlinedIcon
                          onClick={() => setVisibility(true)}
                        />
                      )}
                    </i>
                    {values.password ? (
                      <i
                        className="field__icon clear password"
                        onClick={() => setFieldValue("password", "")}
                      >
                        <HighlightOffIcon />
                      </i>
                    ) : null}
                  </div>
                </div>
                <button
                  className="hide-btn"
                  type="submit"
                  id="registerfrm"
                  name="registerfrm"
                ></button>
              </Form>
            );
          }}
        </Formik>
        {otherInfo && flexiFields.length > 0 ? (
          <>
            <NewFlexiField
              flexiFields={flexiFields}
              onFlexiFieldSubmit={onFlexiFieldSubmit}
              findCitationDueDateBeforeSevenDate={
                findCitationDueDateBeforeSevenDate
              }
              emitOtherCourtError={emitOtherCourtError}
              emitOtherCourtErrorEvent={emitOtherCourtErrorEvent}
            />
          </>
        ) : (
          <></>
        )}
        {otherInfo &&
        flexiFields.length > 0 &&
        citationDueDateBeforeSevenDate ? (
          <div className="diversion-message mb-1">
            <p>
              Courts require you to finish the course <span> 7 </span>
              days before the court date. Your course must be completed by
              <span> {citationDueDateBeforeSevenDate}</span>. You have{" "}
              <span> {citationDueDays}</span> days to complete the course.
            </p>
          </div>
        ) : null}
        <div className="mt-2">
          <div className="d-flex align-items-center">
            <button
              type="submit"
              className="submit-btn flex-submit"
              onClick={() => triggerAllForm()}
            >
              {" "}
              Continue <img src={long_right_arrow} />
            </button>
          </div>
        </div>
        <div className="privacy-policy">
          {!registrationDisclaimerData.isRegistrationDisclaimerRequired ? (
            <>
              {isRegistrationDisclaimer ? (
                <p>
                  By creating an account, I agree to the{" "}
                  <a
                    onClick={() => {
                      setShow(true);
                    }}
                    id="myBtn"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
              ) : (
                <p>
                  By creating an account, I agree to the{" "}
                  <a
                    href="https://driverz.com/terms-and-conditions/"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
              )}
            </>
          ) : null}

          {orgProperty.isTwilioRequired && isMobileNumberEnable ? (
            <div className="checkbox check__item">
              <label className="label-container">
                <input
                  type="checkbox"
                  checked={smsChecked}
                  id="isStudentOptSms"
                  name="isStudentOptSms"
                  onClick={() => smsTrigger()}
                  onChange={() => {}}
                />
                <span className="checkmark"></span>
                <span className="text">
                  I agree to be contacted by Interactive Education Concepts Inc
                  DBA IMPROVLearning and DriverZ via SMS text messaging to
                  receive important communications.
                </span>
              </label>
            </div>
          ) : (
            <></>
          )}
          {show && <PrivacyPolicy show={show} handleClose={handleClose} />}
          <div className="checkbox check__item">
            {registrationDisclaimerData &&
            registrationDisclaimerData.isRegistrationDisclaimerRequired ? (
              <>
                <label className="label-container">
                  <span className="text">
                    {registrationDisclaimerData &&
                      registrationDisclaimerData.registrationDisclaimerMessage}
                  </span>
                </label>
              </>
            ) : (
              <>
                <label className="label-container">
                  <input
                    type="checkbox"
                    checked={emailChecked}
                    id="isStudentOptMail"
                    name="isStudentOptMail"
                    onClick={() => emailTrigger()}
                    onChange={() => {}}
                  />
                  <span className="checkmark"></span>
                  <span className="text">
                    I agree to receive occasional emails from Interactive
                    Education Concepts/DriverZ related to the course completion,
                    important updates on new products and relevant traffic rules
                    and regulations.
                  </span>
                </label>
              </>
            )}
          </div>
        </div>
        <div className="secure-transaction">
          <p>
            Secure transaction
            <img src={unlockKey} />
          </p>
        </div>
      </div>
      <GoDaddySecured />
      {/* smsTextMessageModal */}
      {!smsChecked && (
        <Modal
          show={smsTextMessageModal}
          centered
          backdrop="static"
          keyboard={false}
          className="modal-white reg-sms-modal"
        >
          <Modal.Body>
            {/* <span className="modal-close" onClick={() => setSmsTextMessageModal(!smsTextMessageModal)}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24.5" height="24.5" viewBox="0 0 24.5 24.5">
                        <g id="iconmonstr-x-mark-circle-thin" transform="translate(0.25 0.25)">
                           <path
                              id="iconmonstr-x-mark-circle-thin-2"
                              data-name="iconmonstr-x-mark-circle-thin"
                              d="M12,0A12,12,0,1,1,0,12,12.006,12.006,0,0,1,12,0Zm0,1A11,11,0,1,1,1,12,11.005,11.005,0,0,1,12,1Zm0,10.293L17.293,6,18,6.707,12.707,12,18,17.293,17.293,18,12,12.707,6.707,18,6,17.293,11.293,12,6,6.707,6.707,6,12,11.293Z"
                              fill="#0048a1"
                              stroke="#0048a1"
                              stroke-width="0.5"
                              fill-rule="evenodd"
                           />
                        </g>
                     </svg>
                  </span> */}
            <div className="d-flex sms-modal-body">
              <div className="reg-sms-modal-left">
                <img className="show-lg" src={sms_modal_img} />
                <img className="show-sm" src={sms_modal_img_mobile} />
              </div>
              <div className="reg-sms-modal-right">
                <div className="reg-sms-modal-right-notification">
                  NOTIFICATION
                </div>
                <div className="reg-sms-modal-right-title">SMS Alert!</div>
                <div className="reg-sms-modal-right-content sms-width">
                  Are you sure you don't want us to send you important course
                  communications? We promise we don't SPAM, only important
                  information relevant to your course will be sent.
                </div>

                {isMobileNumberEnable ? (
                  <>
                    <label
                      htmlFor="isStudentOptSms"
                      onClick={() => smsModalTrigger()}
                    >
                      <div className="reg-sms-modal-right-chk">
                        <input
                          type="checkbox"
                          name="isStudentOptSms"
                          id="isStudentOptSms"
                        />{" "}
                        Okay, sign me up
                      </div>
                    </label>

                    <span className="btn-plain" onClick={handleIgnore}>
                      Yes, I am sure
                    </span>
                  </>
                ) : (
                  <span className="btn-plain" onClick={handleIgnore}>
                    Yes, I am sure
                  </span>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {/* {confirmEmail && (
            <Modal show={confirmEmail} centered backdrop="static" keyboard={false} className=" white-rounded-modal smsTextModal">
               <Modal.Body>
                  <span className="modal-close cursor-pointer" onClick={() => setConfirmEmail(false)}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24.5" height="24.5" viewBox="0 0 24.5 24.5">
                        <g id="iconmonstr-x-mark-circle-thin" transform="translate(0.25 0.25)">
                           <path
                              id="iconmonstr-x-mark-circle-thin-2"
                              data-name="iconmonstr-x-mark-circle-thin"
                              d="M12,0A12,12,0,1,1,0,12,12.006,12.006,0,0,1,12,0Zm0,1A11,11,0,1,1,1,12,11.005,11.005,0,0,1,12,1Zm0,10.293L17.293,6,18,6.707,12.707,12,18,17.293,17.293,18,12,12.707,6.707,18,6,17.293,11.293,12,6,6.707,6.707,6,12,11.293Z"
                              fill="#0048a1"
                              stroke="#0048a1"
                              stroke-width="0.5"
                              fill-rule="evenodd"
                           />
                        </g>
                     </svg>
                  </span>
                  <div className="smsTextModal-body">
                     <h5 className="text-center mt-4">Alert</h5>
                     <p>
                        Please confirm your email (<strong>{emailId}</strong>) is valid and correct.
                        All important information about your course progression and completion will be sent to the provided email address.
                     </p>
                     <div className="confirm-email-btn-block">
                        <button type="submit" className="v2-btn standard mobile-full-width" onClick={() => emailConfirmed()}>Confirm</button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         )} */}
      {registerHelper.checkTrue(emailVerification) ||
      emailVerification == "paymentProcess" ? (
        <EmailVerification
          emailVerification={emailVerification}
          handleClose={handleClose}
          paymentProcess={paymentProcess}
          emailId={studentRegistrationData && studentRegistrationData.emailId}
          history={history}
        />
      ) : null}
      {/* <TextNoticeModal /> */}
      {/* <button variant="primary"  onClick={handlgeShowf} >
        Launch demo modal
      </button> */}
      {storeSubjectValue?.isCourseExpired && (
        <CoruseExpired showmodal={storeSubjectValue?.isCourseExpired} />
      )}
    </>
  );
}

export default withRouter(RegisterForm);
