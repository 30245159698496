import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { EncryptStorage } from '../../../services/EncryptStorage'
import { long_right_arrow, upsell_audio_img, upsell_audio_img_mobile, long_right_black_arrow } from '../../common/assets/Images'
export default function UpsellOfferModal(props) {

    const { showModal, closeModal, upsellCourseList, upsellCoursePriceList, loader, skip } = props;

    const [upsellAssets, setUpsellAssets] = useState(null);

    useEffect(() => {
        upsellCourseList && upsellCourseList.length > 0 && upsellCourseList.map((item) => {
            if (item.isUpGradeCourse) {
                setUpsellAssets(item);
            }
        })

    })

    const handleSkip = () => {
        EncryptStorage.setToLocalStorage("skipPage", true)
        skip()
    }

    return (
        <>
            <Modal show={showModal} centered onHide={closeModal} backdrop="static" className="modal-white reg-sms-modal last-chance-modal">
                <Modal.Body>
                    {/* <span className="modal-close" onClick={() => handleSkip()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.5" height="24.5" viewBox="0 0 24.5 24.5">
                            <g id="iconmonstr-x-mark-circle-thin" transform="translate(0.25 0.25)">
                                <path
                                    id="iconmonstr-x-mark-circle-thin-2"
                                    data-name="iconmonstr-x-mark-circle-thin"
                                    d="M12,0A12,12,0,1,1,0,12,12.006,12.006,0,0,1,12,0Zm0,1A11,11,0,1,1,1,12,11.005,11.005,0,0,1,12,1Zm0,10.293L17.293,6,18,6.707,12.707,12,18,17.293,17.293,18,12,12.707,6.707,18,6,17.293,11.293,12,6,6.707,6.707,6,12,11.293Z"
                                    fill="#0048a1"
                                    stroke="#0048a1"
                                    stroke-width="0.5"
                                    fill-rule="evenodd"
                                />
                            </g>
                        </svg>
                    </span> */}
                    <div className="d-flex sms-modal-body">
                        <div className="reg-sms-modal-left">
                            <img className="show-lg" src={upsell_audio_img} />
                            <img className="show-sm" src={upsell_audio_img_mobile} />
                        </div>
                        <div className="reg-sms-modal-right">
                            <div className="reg-sms-modal-right-notification">
                                IT'S NOW OR NEVER!
                            </div>
                            <div className="reg-sms-modal-right-title">
                                Last Chance
                            </div>
                            <div className="reg-sms-modal-right-content last-chance">
                                Skip the reading and enjoy your course with audio.
                            </div>
                            <div className="reg-sms-modal-right-content last-chance two">
                                Upgrade Now For Only $  {upsellAssets && upsellAssets.basePrice.toFixed(2)}
                            </div>
                            <form className="mt-0">
                                {
                                    upsellCourseList.length > 0 && upsellCourseList.map((item, index) => {
                                        if (item.isUpGradeCourse) {
                                            return (
                                                <button className="btn-purple mt-10" onClick={() => upsellCoursePriceList(item, index)}>
                                                    <span> UPGRADE NOW</span>  <img className='img_icon' src={long_right_arrow} alt="" aria-hidden="true" />
                                                </button>
                                            )
                                        }
                                    })
                                }
                                <button className="btn-white mt-20" onClick={handleSkip}> Skip and Next <img src={long_right_black_arrow} className='img_icon' alt="" aria-hidden="true" /></button>
                            </form>
                            <div className="m-2-notes">
                                <span className='d-warning'>Warning:</span>
                                <p className='mb-0'> Once you start the course you won’t be able to upgrade to audio option.</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

