import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Button } from '../common/assets/UIComponents'
import { ErrorMessage, Formik } from 'formik'
import { Link, withRouter } from 'react-router-dom';
import { HOF_Methods, UrlConstants, GenericAPIService, Constants, EncryptStorage, RegexConst } from '../common/imports/CommonImport'
import PropagateLoader from "react-spinners/PropagateLoader";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TextField from '@material-ui/core/TextField';
import * as Yup from 'yup';

const validationSchema1 = Yup.object({
    caseNumber: Yup.string().required('Case Number is required')
        .max(35, "Case Number should be between 1 to 35")
        .matches(RegexConst.Alpha_Numeri_WithoutCombination_Regex, 'Case Number should only contain alphabets'),
    citationNumber: Yup.string().required('Citation Number is required')
        .max(35, "Citation Number should be between 1 to 35")
        .matches(RegexConst.Alpha_Numeri_WithoutCombination_Regex, 'Citation Number should only contain alphabets')
});

const initialValues1 = { caseNumber: '', citationNumber: '' }

const SacramentoCourse = (props) => {

    const { history } = props;
    const [errorMessage, setErrorMessage] = useState('')
    const [loader, setLoader] = useState(true);
    const [courseList, setCourseList] = useState([]);

    useEffect(() => {
        EncryptStorage.setToLocalStorage('registerVia', '');
        localStorage.clear();
       
				window.localStorage.clear();
        setTimeout(() => {
            setLoader(false);
        }, 1000)
    }, [])


    const onSubmit = (values) => {
        let url = UrlConstants.sacramentoCourtCourseUrl;
        const payload = values;
        setErrorMessage('');
        setLoader(true)
        GenericAPIService.customAPIMethod(url, payload, 'POST').then(res => {
            setLoader(false)
            if (HOF_Methods.getStatus(res) == 'success' && res.data) {
                const _courseList = [res.data] || [];
                setCourseList(_courseList)
            } else {
                setErrorMessage(res.status.message);
                setCourseList([])
            }
        }).catch(err => {
            setLoader(false)
            setErrorMessage(Constants.serverErrorMessage)
        });
    }

    const goToRegistraionPage = (row) => {
        if (row) {
            EncryptStorage.setToLocalStorage('configCourseId', row.configCourseId);
            EncryptStorage.setToLocalStorage('countyLedgerId', row.countyLedgerId);
            EncryptStorage.setToLocalStorage('stateId', row.stateId ? row.stateId : '');
            localStorage.studentSelectCourseStateId = row.stateId ? row.stateId : '';
            EncryptStorage.setToLocalStorage('courseName', row.name);
            EncryptStorage.setToLocalStorage('courseId', row.courseId);
            EncryptStorage.setToLocalStorage('organisationId', row.organisationId);
            EncryptStorage.setToLocalStorage('registerVia', 'sacramento');
            history.push('/register');
        }
    }

    return (
        <>
            <div className="position-relative h-100">
                {loader && <div className="loader">
                    <PropagateLoader size={15} />
                </div>}
                <div className=" container h-100">
                    <Row>
                        <Col md={12}>
                            <p className="small-text text-right font-weight-bold mb-lg-2 mb-md-0 ml-lg-1">
                                <Link to="/">
                                    <span className="font-weight-normal ml-2 sacramento-court font-weight-bold">Return to Home</span>
                                </Link>
                            </p>
                            <Formik
                                initialValues={initialValues1}
                                validationSchema={validationSchema1}
                                onSubmit={onSubmit}>
                                {({ handleSubmit, setFieldValue, getFieldProps, values, errors }) => {
                                    return (
                                        <div className="searchFilter">
                                            {errorMessage && <span className="custom-error" >{errorMessage}</span>}
                                            <Form onSubmit={handleSubmit} noValidate autoComplete="off">
                                                <Row className="justify-content-center">
                                                    <Col md={4}>
                                                        <div className="TextInputField pt-0">
                                                            <TextField name="citationNumber" variant="standard" label="Case Number"
                                                                type="text" autoComplete="none" value={values.caseNumber} {...getFieldProps('caseNumber')} />
                                                            <ErrorMessage component='div' className="error" name="caseNumber" />
                                                            {values.caseNumber ? <i className="TextInputField__icon clear" onClick={() => setFieldValue('caseNumber', '')}>
                                                                <HighlightOffIcon />
                                                            </i> : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="TextInputField pt-0">
                                                            <TextField name="citationNumber" variant="standard" label="Citation Number"
                                                                type="text" autoComplete="none" value={values.citationNumber} {...getFieldProps('citationNumber')} />
                                                            <ErrorMessage component='div' className="error" name="citationNumber" />
                                                            {values.citationNumber ? <i className="TextInputField__icon clear" onClick={() => setFieldValue('citationNumber', '')}>
                                                                <HighlightOffIcon />
                                                            </i> : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={2} className="mt-2"><Button variant="contained" type="submit" color="secondary"> Verify</Button></Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    )
                                }}
                            </Formik>
                        </Col>
                    </Row>
                    {(courseList.length > 0 && courseList.map((row, ind) =>
                        <Row className="w-100 whiteBg align-items-center searchFilterList margin-reset" key={ind}>
                            <Col xs={12} md={8}>
                                <h6 className="text-color">{row.name}</h6>
                            </Col>
                            <Col md={2} >
                                <div className="coursePrice text-right">
                                    <span className="coursePrice__offer mr-3">{row.orginalPrice}</span>
                                    <span className="coursePrice__original">{row.discountPrice}</span>
                                </div>
                            </Col>
                            <Col xs={12} md={2} className="text-md-right text-left "><Button variant="contained" onClick={() => goToRegistraionPage(row)} className="join-btn" color="secondary"> Join </Button></Col>
                        </Row>))
                        || <Row className="whiteBg align-items-center searchFilterList margin-reset">
                            <Col md={12}>
                                <h6 className="text-color text-center no-data">{'No Records Found!'}</h6>
                            </Col>
                        </Row>}
                </div>
            </div>
        </ >
    )
}

export default withRouter(SacramentoCourse)