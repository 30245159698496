import React, { useState, useRef, useEffect } from "react";
import axios from 'axios';
import { EncryptStorage } from "../../../components/common/imports/CommonImport";
import ShopperFooter from "../../common/ShopperFooter";
import { _localStorage } from "../../constant/storage/localStorage";
import { _const } from "../../constant/const-variables/constVariable";
import { GenericAPIService } from "../../../services/GenericAPIService";
import {
  ae_card,
  amazon_pay_wo_background,
  apple_pay_white,
  arrow_swosh,
  emailIcon,
  greenLockIcon,
  master_card,
  paypalWithoutLogo,
  signatureSign,
  simpleTickIcon,
  simpleWarningIcon,
  stars,
  visa_card_white,
} from "../../common/assets/Images";
// import RegisterInputModalFastLane from "./FastLaneTabModals/RegisterInputModalFastLane";
// import MissingInputModalFastLane from "./FastLaneTabModals/MissingInputModalFastLane";
// import MissingCheckboxModalFastLane from "./FastLaneTabModals/MissingCheckboxModalFastLane";
import PhoneModalFastLane from "./FastLaneTabModals/PhoneModalFastLane";
import { Rating } from 'react-simple-star-rating';

export default function FastLaneTab(props) {
  const {
    registerProcess,
    smsChecked, 
    setSmsChecked,
    modalEmail,
    setModalEmail,
    modalPhone,
    setModalPhone,
    showExistingEmailModal,
  } = props;
  const myRef = useRef();
  const [isDataModalVisible, setIsDataModalVisible] = useState(false);
  const [modalOverModalCheckboxSkipped, setModalOverModalCheckboxSkipped] =
    useState(false);
  const [emailChecked, setEmailChecked] = useState(true);
  const [smsEmailMessageModal, setSmsEmailMessageModal] = useState(false);
  const [isActivePaypal, setIsActivePaypal] = useState(false);
  const [isActiveBraintree, setIsActiveBraintree] = useState(false);
  const [isActiveAmazon, setIsActiveAmazon] = useState(false);
  const [isActiveTilled, setIsActiveTilled] = useState(false);
  const [isActiveApple, setIsActiveApple] = useState(false);

  const [emailStyle, setEmailStyle] = useState("email-register-field-input");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const validEmailRegex = /^[\w.%+'-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  const [isFirstTimeEmailUpdate, setIsFirstTimeEmailUpdate] = useState(true);
  const [isPhoneModalVisible, setIsPhoneModalVisible] = useState(false);
  const termsOfUseURL = "https://www.myimprov.com/terms-of-use/";
  const [reviewDetails, setReviewDetails] = useState({
    averageRating: 0,
    totalReviews: 0
  });

  useEffect(() => {
    getShopperReview();
    checkPaymentOptions();
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        setIsPhoneModalVisible(true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const choosePaymenthWithoutLoader = (paymentMethod) => {
    EncryptStorage.setToLocalStorage("paymentMethod", paymentMethod);
    //setIsPhoneModalVisible(!isPhoneModalVisible);
    registerProcess();
  };

  const testValidationUrl = () => {
    let configCourseId;
    let getUrl = window.location.href;
    let replaceUrl = getUrl.replace("#/", "");
    replaceUrl = replaceUrl.replace("'", "");
    let url = new URL(replaceUrl);
    configCourseId = url.searchParams.get("configCourseId")
      ? url.searchParams.get("configCourseId")
      : null;
		
    return configCourseId;
  };

  const checkPaymentOptions = () => {
    // Temporal change to avoid error
    setIsActivePaypal(true);
    setIsActiveBraintree(true);
    setIsActiveAmazon(true);
    setIsActiveTilled(true);
    setIsActiveApple(true);

    // let organisationId = EncryptStorage.getLocalStorage("organisationId");
    // let mapAdminUrl =
    //   "/improv-student-registration-journey/student/course/payment/partner/paymentgateway/configurations/list?isActive=true&organisationId=" +
    //   organisationId;

    // let payload = {};

    // GenericAPIService.customAPIMethod(mapAdminUrl, payload, "GET")
    //   .then((res) => {
    //     if (res.status.success.toLowerCase() === "success") {
    //       if (res.data.length !== 0) {
    //         if (res && res.data) {
    //           res.data.forEach((object) => {
    //             if (object.paymentProvider.providerName == "BRAINTREE") {
    //               setIsActiveBraintree(true);
    //             } else if (object.paymentProvider.providerName == "PAYPAL") {
    //               setIsActivePaypal(true);
    //             } else if (object.paymentProvider.providerName == "AMAZON") {
    //               setIsActiveAmazon(true);
    //             } else if (object.paymentProvider.providerName == "TILLED") {
    //               setIsActiveTilled(true);
    //             } else if (object.paymentProvider.providerName == "APPLE") {
    //               setIsActiveApple(true);
    //             }
    //           });
    //         }
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     setIsActivePaypal(true);
    //     setIsActiveBraintree(true);
    //     setIsActiveAmazon(true);
    //     setIsActiveTilled(true);
    //     setIsActiveApple(true);
    //     console.log(error);
    //     throw error;
    //   });
  };

  const validateEmail = (emailToCheck) => {
    setModalEmail(emailToCheck);
    setIsFirstTimeEmailUpdate(false);

    if (emailToCheck.length < 1){
      setIsValidEmail(false);
    }
    else {
      if (emailToCheck && emailToCheck.match(validEmailRegex) && emailToCheck.length >= 6) {
        setEmailStyle("email-register-field-input");
        setIsValidEmail(true);
      } else {
        setEmailStyle("email-register-field-input invalid");
        setIsValidEmail(false);
      }
    }
  }

    const getShopperReview = () => {
    const siteId = 11612
    const key = 'HsDc9KQF8VMmPxS'
    let url = `https://api.shopperapproved.com/aggregates/reviews/${siteId}?token=${key}&xml=false`;

    axios.get(url).then(function (response) {
        let tempShopperDetail = {
            averageRating: 0,
            totalReviews: 0
        }
        if (response && response.data && response.data.average_rating) {
            tempShopperDetail.averageRating = response.data.average_rating;
        }
        if (response && response.data && response.data.total_reviews) {
            if (response.data.total_reviews < 1000) {
                tempShopperDetail.totalReviews = response.data.total_reviews;
            }
            else {
                tempShopperDetail.totalReviews = `${Math.round(response.data.total_reviews / 1000)}K+`;
            }
        }
        setReviewDetails({ ...tempShopperDetail });
    })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
            // always executed
        });
}

  const fastLaneTab = () => {
    return (
      <>
        <div className="fast-lane-column">
          <img
            src={signatureSign}
            className="fast-lane-tab-signature-arrow shopper-hided-on-mobile"
          />
          <div className="fast-lane-container">
            <div className="fast-lane-inner-container">
              <p className="fast-line-title">Take The Fast Lane</p>
              <div className="arrow-register-label">
                <img src={arrow_swosh} alt="" className="arrow-down" />
                <p className="fast-lane-register-label">Express Registration</p>
              </div>
              <p className="email-register-label">
                Enter your preferred email to create your account and receive receipts and course communication.
              </p>
              <div className="email-register-container">
                <img src={emailIcon} alt="Email Icon" className="email-register-icon mail" />
                <input
                  type="text"
                  className={emailStyle}
                  id="modalEmail"
                  placeholder="Email"
                  onChange={(e) => {
                    validateEmail(e.target.value);
                  }}
                />
                { modalEmail && modalEmail.length > 0 && (
                  <img src={isValidEmail ? simpleTickIcon : simpleWarningIcon} alt="Status Icon" className="email-register-icon tick" />
                )}
              </div>
              {typeof modalEmail !== "undefined" && !isValidEmail && !isFirstTimeEmailUpdate && (
                <span className="register-email-error-label">
                  Please Provide a Valid Email Address
                </span>
              )}
              { isActivePaypal && (
                <button
                  className="fast-lane-payment-button paypal-container"
                  disabled={!isValidEmail}
                  onClick={() => {choosePaymenthWithoutLoader("Paypal");}}
                >
                  <p className="fast-lane-register-payment-button-label blacklabel-config">
                    Continue with
                    <img src={paypalWithoutLogo} alt="Pay with paypal" className="isotype-button-logo big-logos"/>
                  </p>
                </button>
              )}
              {/* { isActiveAmazon && (
                <button
                  className="fast-lane-payment-button amazon-container"
                  disabled={!isValidEmail}
                  onClick={() => {choosePaymenthWithoutLoader("Amazon");}}
                >
                  <p className="fast-lane-register-payment-button-label blacklabel-config">
                    Continue with
                    <img src={amazon_pay_wo_background} alt="Pay with Apple Pay" className="isotype-button-logo big-logos"/>
                  </p>
                </button>
              )} */}
              {testValidationUrl() === "405" && isActiveApple && (
                <button
                  className="fast-lane-payment-button applepay-container"
                  disabled={!isValidEmail}
                  onClick={() => {
                    choosePaymenthWithoutLoader("Apple");
                  }}
                >
                  <p className="fast-lane-register-payment-button-label">
                    Continue with
                    <img
                      src={apple_pay_white}
                      alt="Pay with Apple Pay"
                      className="isotype-button-logo"
                    />
                  </p>
                </button>
              )}
              {isActiveTilled && (
                <button
                  className="fast-lane-payment-button credit-card-container"
                  disabled={!isValidEmail}
                  onClick={() => {choosePaymenthWithoutLoader("Credit Card");}}
                >
                  <div className="fast-lane-register-payment-button-label">
                    Continue with
                    <div className="credit-card-container">
                      <img src={master_card} alt="Pay with Master Card" className="credit-card-button" />
                      <img src={visa_card_white} alt="Pay with Visa" className="credit-card-button" />
                      <img src={ae_card} alt="Pay with American Express" className="credit-card-button" />
                    </div>
                  </div>
                </button>
              )}
              <p className="email-register-label">
                <p>
                  By registering with us, you accept our &nbsp;
                </p>
                <a className='fast-lane-terms-use-link' href={termsOfUseURL} target='blank'>
                  Terms and Conditions
                </a>
              </p>
              <div className="fast-lane-register-godaddy-container">
                <div>
                  <img src={greenLockIcon} alt="Verified and Secured" className="fast-lane-register-godaddy-image" />
                  <p className="fast-lane-register-godaddy-label">
                    SECURE TRANSACTION
                  </p>
                </div>
                <a href="https://www.shopperapproved.com/reviews/myimprov.com/" target="_blank">
                  <Rating className='slide-rating' initialValue={reviewDetails.averageRating-0.1} size={20} readonly={true} allowHover={false} fillColor="#F6BC19" allowFraction={true} />
                </a>
              </div>
            </div>
          </div>
          <div className="show-lg shopper-hided-on-mobile">
            <ShopperFooter />
          </div>
        </div>
        <PhoneModalFastLane
					modalPhone={modalPhone}
          smsChecked={smsChecked}
          setSmsChecked={setSmsChecked}
					setModalPhone={setModalPhone}
          registerProcess={registerProcess}
          isPhoneModalVisible={isPhoneModalVisible}
          setIsPhoneModalVisible={setIsPhoneModalVisible}
        />
      </>
    );
  };

  return (
    <>
      <div className={``} ref={myRef}>
        {fastLaneTab()}
      </div>
    </>
  );
}
