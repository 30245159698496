import React, { useContext } from "react";
import { Nav } from "react-bootstrap";
import timer2 from "../../../assets/images/icons/timer1.svg";
import { CourseTimerContext } from "../../../context";
import { formatTime } from "../../../services/formatTime";

export default function ChapterAndFinalQuizTimer(props) {
  const { history } = props;
  const courseTimerDataContext = useContext(CourseTimerContext);
  const { courseTimer } = courseTimerDataContext;
  const getTimerName = () => {
    return history.location.pathname === "/home/final-quiz"
      ? "Final Quiz Time "
      : "Chapter Time ";
  };

  return courseTimer.chapterTime > 0 ? (
    <Nav.Item className="timecard timecard-2">
      <img src={timer2} alt="timer" className="timerImage" />
      <div className="item">
        <p className="mb-0">{getTimerName()}</p>
        <p className="mb-0 displayTime">
          {formatTime(courseTimer.chapterTime)}
        </p>
      </div>
    </Nav.Item>
  ) : null;
}
