import React, { useEffect, useState } from "react";
import { arrow_down_square, arrow_right_square, courseVideoThumbnail, defensiveDrivingCourseNewYorkVideoThumbnail } from "../../common/assets/Images";
import { Modal } from 'react-bootstrap';

export default function FastLaneFAQ(props) {
    const { courseDetails, courseHeader } = props;
    const [faqElements, setFaqElements] = useState([]);
    const [faqIsCollapsed, setFaqIsCollapsed] = useState(true);
    const [courseInfoIsCollapsed, setCourseInfoIsCollapsed] = useState(false);
    const [isVideoPlayerVisible, setIsVideoPlayerVisible] = useState(false);
    const courseVideoUrl = "https://www.myimprov.com/wp-content/uploads/promo-video-short.mp4";

    useEffect(() => {
        const newElements = [];
        courseDetails && courseDetails.configCourseFaq.map((faqOption) => {
            const faqQuestion = { question: "question", answer: "answer", isCollapsed: true }

            faqQuestion.question = faqOption.faq.questionName;
            faqQuestion.answer = removeHTMLTags(faqOption.faq.description);
            newElements.push(faqQuestion);
        });
        setFaqElements(newElements);
    }, [courseDetails]);

    function removeHTMLTags(text) {
        let updatedLabel = text.replace(/<[^>]*>/g, "");
        return updatedLabel;
    }

    const handleFAQToggleCollapse = (index) => {
        const newElements = [...faqElements];
        newElements[index].isCollapsed = !newElements[index].isCollapsed;
        setFaqElements(newElements);
    };

    const handleFaqToggleCollapse = () => {
        setFaqIsCollapsed(!faqIsCollapsed);
    };

    const createMarkup = (value) => {
        return { __html: value };
    };

    const handleToggleVideoPlayer = () => {
        setIsVideoPlayerVisible(!isVideoPlayerVisible);
    };

    return (
        <div className="fast-lane-faq-container">
            <Modal show={isVideoPlayerVisible} onHide={handleToggleVideoPlayer} id="video-player-modal-register">
                <Modal.Body>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <video className="video-player" src={courseVideoUrl} controls ></video>
                        <button onClick={handleToggleVideoPlayer} className="video-player-close">x</button>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="faq-button-container">
                <button className="faq-button" onClick={handleFaqToggleCollapse}>
                    <p className="faq-title">Frequently Asked Questions</p>
                    <img src={arrow_right_square} className={faqIsCollapsed ? "answer-collapsed-arrow" : "answer-uncollapsed-arrow"} />
                </button>
            </div>
            <div className="faq-detailed-container"  style={{ display: faqIsCollapsed ? "none" : "block" }}>
                <div className="faq-detailed course-info">
                    <button className="faq-question" onClick={() => setCourseInfoIsCollapsed(!courseInfoIsCollapsed)}>
                        Every Minute You Wait Costs You $$$
                        <img
                            src={arrow_right_square}
                            alt="collapse"
                            className={courseInfoIsCollapsed ? "answer-collapsed-arrow" : "answer-uncollapsed-arrow"}
                        />
                    </button>
                    
                    <div
                        className="course-info-html-header course-info-html-header-config fast-lane-course-info"
                        style={{ display: courseInfoIsCollapsed ? "none" : "block" }}
                        dangerouslySetInnerHTML={createMarkup(courseHeader)}>
                    </div>

                    <div className="course-group-image course-info">
                        <button className="watch-demo" onClick={handleToggleVideoPlayer}>
                        <img
                            src={courseVideoThumbnail}
                            alt="Play Video"
                            className="watch-demo-thumbnail course-info"
                        />
                        </button>
                    </div>
                </div>
                {faqElements.map((faq, index) => (
                    <div className="faq-detailed" key={index}>
                        <button className="faq-question" onClick={() => handleFAQToggleCollapse(index)}>
                            {faq.question}
                            <img
                                src={arrow_right_square}
                                alt="collapse"
                                className={faq.isCollapsed ? "answer-collapsed-arrow" : "answer-uncollapsed-arrow"}
                            />
                        </button>
                        
                        <p className="faq-answer" style={{ display: faq.isCollapsed ? "none" : "block" }}>
                            {faq.answer}
                        </p>
                    </div>
                ))}
                <div className="faq-collapse-container">
                    <button className="faq-collapse-button" onClick={handleFaqToggleCollapse}>
                        <p className="faq-collapse-label">Collapse FAQ</p>
                        <img src={arrow_right_square} alt="collapse" className="faq-collapse-arrow"/>
                    </button>
                </div>
            </div>
        </div>
    );
}
