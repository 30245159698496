import React, { useEffect, useState } from "react";
import environment from "../../environments/environment";
import {
  UrlConstants,
  EncryptStorage,
  GenericAPIService,
  HOF_Methods,
} from "../common/imports/CommonImport";
// import PropagateLoader from "react-spinners/PropagateLoader";
import { Modal } from "react-bootstrap";
import moment from "moment";
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import BillingPdf from "./BillingPdf";
// import { client } from "braintree-web";
import {
  arrow_right_square,
  envelop,
  infoIcon,
  long_right_arrow,
  printerIcon,
  questionWithBackground,
  tickleWithBackground,
} from "../common/assets/Images";
import md5 from 'md5';
import LoaderWithMessage from "../register/FastLane/LoaderWithMessage";
import { GTMSend, referralCandyCall } from "./../cart-and-checkout/PaymentDetailsHelper";

export default function GetBillingDetails(props) {
  const { redirectToNextPage, handleCustomerSupport } = props;
  const [loader, setLoader] = useState(false);
  const [orderDetais, setOrderDetails] = useState([]);
  const [selectedPriceItem, setSelectedPriceItem] = useState({
    studentPaymentTrxLines: [],
  });
  const [selectedPriceItems, setSelectedPriceItems] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [greenLightPostAffiliate, setGreenLightPostAffiliate] = useState(false);

  useEffect(() => {
		window.scrollTo(0, 0);
    loadStudentPurchaseDetails();
    EncryptStorage.setToLocalStorage("sessionTime", "true");
  }, []);

  useEffect(() => {
    
		if (greenLightPostAffiliate === true){

      let storageIDs = localStorage.getItem("registerFormValues") ? JSON.parse(localStorage.getItem("registerFormValues")) : {};

      const affiliateID = getAffiliateID(storageIDs);
      if (affiliateID !== "641963accb631" || affiliateID !== "6357f924d3b86"){
        sendDataLayerGTM();
        
        //the template literal is important to have double quotes as the email it self can have a single quote
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `
          PostAffTracker.setAccountId('default1');
          PostAffTracker.setVisitorId('${getVisitorID(storageIDs)}');
          var sale = PostAffTracker.createSale();
          sale.setTotalCost('${getTotalValueWithoutSign()}');
          sale.setOrderID('${selectedPriceItem.targetId}');
          sale.setProductID('${selectedPriceItem.configCourse.course.name ? selectedPriceItem.configCourse.course.name : "Missing Course Name"}');
          sale.setAffiliateID('${getAffiliateID(storageIDs)}');
          sale.setCampaignID('${getCampaignID(storageIDs)}');
          sale.setData1('');
          sale.setData2("${getStudentEmail()}"); 
          PostAffTracker.register();
        `;
        document.body.appendChild(script);
  
        return () => {
          document.body.removeChild(script);
        };
      }
    }
  }, [greenLightPostAffiliate]);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      window.pagesense = window.pagesense || [];
      window.pagesense.push(['trackEvent', 'Payment Completion']);
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const sendDataLayerGTM = () => {
    const mainCourseDetails = { 'course': selectedPriceItem.configCourse.course.name, 'price': getTotalValueWithoutSign(), 'quantity': 1 };
    let discount = "0";
    if (selectedPriceItem.studentPaymentTrxLines.length > 0) {
      selectedPriceItem.studentPaymentTrxLines.reverse().map((item) => {
        if (item.configCoursePrice.coursePriceType.code === "DISCOU"){
          const intPart = item.price ? findprice(item.price) : "0";
          const decimalPart = findpriceDecimal(item.price);
          discount = ""+intPart+decimalPart+"";
        }
      })
    }
    let storageIDs = localStorage.getItem("registerFormValues") ? JSON.parse(localStorage.getItem("registerFormValues")) : {};
    const affiliateID = getAffiliateID(storageIDs);
      
    GTMSend(mainCourseDetails, discount, affiliateID);
  }

  const getAffiliateID = (storageIDs) => {
    if (storageIDs.a_aid){
      return storageIDs.a_aid;
    }
    if (selectedPriceItem.student.aaid !== ""){
      return selectedPriceItem.student.aaid;
    }
    if (storageIDs.affiliateID){
      return storageIDs.affiliateID;
    }
    if (selectedPriceItem.student.affiliateId !== ""){
      return selectedPriceItem.student.affiliateId;
    }
    if (storageIDs.PAPAffiliateId){
      return storageIDs.PAPAffiliateId;
    }

    return "NotAffiliatePayment";
  }

  const getCampaignID = (storageIDs) => {
    if (storageIDs.acid){
      return storageIDs.acid;
    }
    if (selectedPriceItem.student.acid !== ""){
      return selectedPriceItem.student.acid;
    }
    return "MissingCampaignID";
  }

  const getVisitorID = (storageIDs) => {
    if (storageIDs.visitorID){
      return storageIDs.visitorID;
    }
    if (selectedPriceItem.student.visitorId !== ""){
      return selectedPriceItem.student.visitorId;
    }
    return "MissingVisitorID";
  }

  const getStudentEmail = () => {
    if (EncryptStorage.getLocalStorage("studentEmail") && EncryptStorage.getLocalStorage("studentEmail") !== ""){
      return EncryptStorage.getLocalStorage("studentEmail");
    }
    if (selectedPriceItem.student.emailId !== ""){
      return selectedPriceItem.student.emailId;
    }
    return "MissingEmail";
  }

  const sendToReferralCandy = () => {
    let firstName = 'New';
    let lastName = 'Student';
    let email = getStudentEmail();
    let total_Price = getTotalValueWithoutSign();
    let timestamp = moment().unix();
    EncryptStorage.setToLocalStorage('referralTimeStamp', timestamp);
    const apiSecretKey = "8bc8716e30c68649dfdfcaa9cfaa0ef3";
    let signature = md5(`${email},${firstName},${total_Price},${timestamp},${apiSecretKey}`);
    return <div 
              id='refcandy-mint'
              data-app-id='tyjuf2i3dnglo02ebfhmzvigv' 
              data-fname={firstName} 
              data-lname={lastName} 
              data-email={email} 
              data-amount={total_Price} 
              data-currency='USD' 
              data-timestamp={timestamp} 
              data-external-reference-id='93211001' 
              data-signature={signature}></div>;
  };

  const loadStudentPurchaseDetails = () => {
    let studentId = EncryptStorage.getLocalStorage("studentId");
    let upsellPaymentFlag = EncryptStorage.getLocalStorage("upsellPaymentFlag");
    let payload = null;
    let payloadNew;
    payload = { student: { studentId: studentId } };
    if (upsellPaymentFlag && upsellPaymentFlag === 1) {
      payload = EncryptStorage.getLocalStorage("upsellPayload");
      // EncryptStorage.setToLocalStorage('upsellPayload', 0)
      // let zeroPaymentOfferDetails = EncryptStorage.getLocalStorage('zeroPaymentOfferDetails')
      // let _date = new Date()
      // let _yyyymmdd = moment(_date).format('YYYY-MM-DD')
      // payloadNew = {
      //     configCourseId: EncryptStorage.getLocalStorage('config-course-id'),
      //     paymentDate: _yyyymmdd,
      //     studentId: EncryptStorage.getLocalStorage('studentId'),
      //     studentPaymentTrxId: EncryptStorage.getLocalStorage('studentPaymentTrxId'),
      //     totalAmount: EncryptStorage.getLocalStorage('totalPrice'),
      //     studentPaymentTrxLines: [],
      // }
      // if (zeroPaymentOfferDetails) {
      //     if (zeroPaymentOfferDetails.type === 'DISCOUNTCODE') {
      //         console.log('DISCOUNTCODE')
      //         // payloadNew?.studentPaymentTrx[0].discountId = zeroPaymentOfferDetails.discount.discountId
      //     }
      // }
      // let azSeletedCardList = EncryptStorage.getLocalStorage('azSeletedCardList')
      // let _list = azSeletedCardList.map(obj => {
      //     return {
      //         configCoursePrice: {
      //             configCourse: {
      //                 configCourseId: obj.configCourseId,
      //             },
      //             configCoursePriceId: obj.configCoursePriceId,
      //             isActive: true,
      //             isUpsell: obj.isUpsell,
      //             percentage: obj.percentage,
      //             merchantAccountId: obj.merchantAccountId,
      //             coursePriceType: obj.coursePriceType,
      //             price: obj.price,
      //         },
      //         price: obj.price,
      //     }
      // })
      // payloadNew.studentPaymentTrxLines = _list
      // if (localStorage.dcscUpsellItem) {
      //     payloadNew.isDuplicateCertificateRequest = false
      // }
    }
    setLoader(true);
    const url = UrlConstants.getStudentPurchaseDetails;
    GenericAPIService.customAPIMethod(url, payload, "POST")
      .then((res) => {
        setLoader(false);

        if (res.data[0].paymentStatus === "PaymentPending") {
          EncryptStorage.setToLocalStorage("sessionTime", "true");
        }
        if (HOF_Methods.getStatus(res) === "success") {
          const { data = [] } = res;

          let _list = res.data[res.data.length - 1];
          
          setSelectedPriceItems(res.data);
          // setSelectedPriceItem(data[props.billingNumber]);
          // setOrderDetails(data);
				
          setSelectedPriceItem(_list);
          setGreenLightPostAffiliate(true);
          setOrderDetails(_list);
					if(localStorage.getItem("stepFastLane")==="3"){
						 localStorage.setItem("stepFastLane", "4");
					}
					
        }
        if (res.data) {
          // let _responseList = res.data;
          // let _addTocardList = EncryptStorage.getLocalStorage('addTocardList');
          // if(_addTocardList){
          //     let _configCourseIdList = [];
          //     let _configCourseIdListMap = _responseList.map((obj)=>{
          //         _configCourseIdList.push(obj.configCourse.configCourseId);
          //         return obj.configCourse.configCourseId;
          //     });
          //     if(_configCourseIdList){
          //         let filterCardList = _addTocardList.filter((obj)=>{
          //             return _configCourseIdList.indexOf(obj.configCourseId) === -1;
          //         });
          //         EncryptStorage.setToLocalStorage('addTocardList', filterCardList);
          //     }
          // }
          EncryptStorage.setToLocalStorage("addTocardList", []);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  const downloadPaymentReceipt = ({ studentPaymentTrxId = 0 }) => {
    const downloadPaymentReceiptUrl =
      environment.baseUrl + UrlConstants.downloadPaymentReceiptUrl;
    const url = `${downloadPaymentReceiptUrl}?studentPaymentTrxId=${studentPaymentTrxId}`;
    window.location.href = url;
  };

  const emailPaymentReceipt = ({ studentPaymentTrxId = 0 }) => {
    setResponseMessage("");
    const baseUrl =
      environment.baseUrl +
      `${UrlConstants.emailPaymentReceiptUrl}?studentPaymentTrxId=${studentPaymentTrxId}`;
    setLoader(true);
    GenericAPIService.emailReceipt(baseUrl)
      .then((res) => {
        if (HOF_Methods.getStatus(res) === "success") {
          setResponseMessage(res.status.message);
          setLoader(false);
          setModalShow(true);
          setTimeout(() => {
            setModalShow(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const findpriceDecimal = (val) => {
    if (!val) return null;
    let priceValue = val.toString();
    if (priceValue.includes(".")) {
      return `.${parseFloat(priceValue).toFixed(2).split(".")[1]}`;
    }
    return ".00";
  };
  const findprice = (val) => {
    if (!val) return null;
    let priceValue = val.toString();
    if (priceValue.includes(".")) {
      return priceValue.split(".")[0];
    }
    return priceValue;
  };

  const getTotalValue = () => {
    // if (orderDetais[0] && orderDetais[0].totalAmount === 0) {
    //     return (
    //         <span className="value amount">${'0'}.{'00'}</span>
    //     )
    // }
    // else {
    //     return (
    //         <span className="value amount">${orderDetais[0] ? findprice(orderDetais[0].totalAmount) : '00'}{findpriceDecimal(orderDetais[0] ? orderDetais[0].totalAmount : 0)}</span>
    //     )
    // }

    /* if (selectedPriceItem.totalAmount === 0) {
      return (
        <span className="value amount">
          ${"0"}.{"00"}
        </span>
      );
    } else {
      return (
        <span className="value amount">
          ${selectedPriceItem ? findprice(selectedPriceItem.totalAmount) : "00"}
          {findpriceDecimal(
            selectedPriceItem ? selectedPriceItem.totalAmount : 0
          )}
        </span>
      );
    } */
		let _total = 0;
		selectedPriceItems.map((item)=>{
			_total+=item.totalAmount;
		})
		
		if (_total === 0) {
      return (
        <span className="value amount">
          ${"0"}.{"00"}
        </span>
      );
    }else{
			return (
        <span className="value amount">
          ${_total ? findprice(_total) : "00"}
          {findpriceDecimal(
            selectedPriceItem ? _total : 0
          )}
        </span>
      );
		}
  };

  const getTotalValueWithoutSign = () => {
		let _total = 0;
		selectedPriceItems.map((item)=>{
			_total+=item.totalAmount;
		})
		
		if (_total === 0) {
      return "0.00"
    }else{
      const intPart = _total ? findprice(_total) : "00";
      const decimalPart = findpriceDecimal(selectedPriceItem ? _total : 0);
			return ""+intPart+decimalPart+"";
		}
  };

  const getPaymentMethod = (item) => {
    if (item.paymentMode) {
      return item.paymentMode;
    } else if (item.paymentInstrumentType === "credit_card") {
      return "card";
    } else {
      return item.paymentGateway;
    }
  };

  const printPage = () => {
    /* const printContents = document.getElementById('print-section').innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents; */
    /* window.print();
        document.body.innerHTML = originalContents; */
    let mywindow = window.open(
      "",
      "PRINT",
      "height=650,width=900,top=100,left=150"
    );
    const styles = `@font-face {
		font-family: "p22-mackinac-pro";
		src: url("https://use.typekit.net/af/b6ae0d/00000000000000007735b3dc/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
			format("woff2"),
		  url("https://use.typekit.net/af/b6ae0d/00000000000000007735b3dc/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
			format("woff"),
		  url("https://use.typekit.net/af/b6ae0d/00000000000000007735b3dc/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
			format("opentype");
		font-display: swap;
		font-style: normal;
		font-weight: 700;
		font-stretch: normal;
	  }

	  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");
	  
	  @font-face {
		font-family: "Poppins";
		src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
	  }
	  
	  @font-face {
		font-family: "Inter";
		src: url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
	  }
	  
	  .payment-receipt-data-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}

	.payment-receipt-reference-label {
		flex: 1 0 0;
		color: #707070  !important;
		font-family: 'Open Sans', sans-serif;
		font-size: 13px !important;
		font-style: normal;
		font-weight: 400 !important;
		line-height: 18px !important;
		text-align: left !important;
	}

	.payment-receipt-detail-value {
		color: #565F6D !important;
		text-align: right !important;
		font-family: 'Open Sans', sans-serif !important;
		font-size: 13px !important;
		font-style: normal !important;
		font-weight: 500 !important;
		line-height: 24px !important;
    margin-bottom: 0.5rem !important;
	}

	.billing-sec {
		color: $primary-color;
	  
		h5 {
		  margin: 0rem 0 1rem;
		  font-weight: 600;
		  font-family: 'Josefin Sans', sans-serif;
		  text-transform: capitalize;
		}
	  
		.table {
		  color: $primary-color;
		  min-width: 600px;
		  width: auto;
	  
		  thead {
			th {
			  border: 0 none;
			  padding: 0;
			  color: rgba(0, 72, 161, 0.6);
			  font-weight: 400;
			  font-size: 0.875rem;
	  
			  &.chapter-number {
				max-width: 70px;
				min-width: 70px;
				width: 70px;
			  }
	  
			  &.chapter-status {
				max-width: 130px;
				min-width: 130px;
				width: 130px;
			  }
	  
			  &.chapter-read-time {
				max-width: 80px;
				min-width: 80px;
				width: 80px;
			  }
			}
		  }
	  
		  td,
		  th {
			border: 0 none;
			border-bottom: 1px solid #dee2e6;
	  
			&:first-child {
			  padding-left: 0;
			}
	  
			&:last-child {
			  padding-right: 0;
			  text-align: right;
			}
		  }
	  
		  td {
			span {
			  opacity: 0.6;
			  cursor: pointer;
			  text-decoration: underline;
			}
		  }
		}
	  
		.orders-table {
		  @media only screen and (max-width:1000px) {
			min-width: 1000px;
		  }
	  
		}
	  
		.orders {
		  margin-bottom: 2rem;
		}
	  
		.payment-table {
		  margin-bottom: 3rem;
		}
	  }

	  .payment-receipt-continue-progress-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 32px;
	}

	.payment-receipt-container {
		display: flex;
		width: 50%;
		padding: 32px 24px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
		border-radius: 12px;
		background: #FFF;
		box-shadow: 0px 8px 24px 0px rgba(170, 170, 170, 0.12);
		@media screen and (max-width: 768px) {
			width: 80%;
		}
	}
	
	.payment-receipt-header-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		align-self: stretch;
		margin-top: auto;
		margin-bottom: auto;
	}
	
	.confirmation-checkmark {
		width: 56px;
		height: 56px;
		flex-shrink: 0;
	}
	
	.payment-receipt-header-label {
		align-self: stretch;
		color: #121212;
		text-align: center;
		font-family: 'p22-mackinac-pro' !important;
		font-size: 20px !important;
		font-style: normal;
		font-weight: 700;
		line-height: 28px;
    margin-bottom: 1.5rem !important;
	}
	
	.receipt-divider {
		border-style: solid;
		border-width: 1px;
		width: 100%;
		border-color: #E8EAED;
		margin-top: -2%;
		margin-bottom: 2%;
	}
	
	.payment-receipt-part-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		gap: -1%;
		margin-top: 8px;
	}
	
	.payment-receipt-data-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}
	
	.payment-receipt-reference-label {
		flex: 1 0 0;
		color: #707070  !important;
		font-family: 'Open Sans', sans-serif;
		font-size: 13px !important;
		font-style: normal;
		font-weight: 400 !important;
		line-height: 18px !important;
		text-align: left !important;
    margin-bottom: 0.5rem !important;
	}
	
	.payment-receipt-reference-value {
		color: #121212 !important;
		font-family: 'Open Sans', sans-serif; 
		font-size: 13px !important;
		font-style: normal !important;
		font-weight: 700 !important;
		line-height: 18px !important;
		text-align: right !important;
    margin-bottom: 1.5rem !important;
    width: 50%;
		@media screen and (max-width: 768px) {
			font-size: 11px !important;
		}
	}
	
	.payment-receipt-total-label {
		flex: 1 0 0;
		color: #707070 !important; 
		font-family: 'Open Sans', sans-serif !important;
		font-size: 13px !important;
		font-style: normal;
		font-weight: 700 !important;
		line-height: 18px !important;
		text-align: left !important;
	}
	
	.payment-receipt-total-value {
		color: #121212 !important;
		text-align: right;
		font-family: 'Open Sans', sans-serif !important;
		font-size: 16px !important;
		font-style: normal !important;
		font-weight: 500 !important;
		line-height: 24px !important;
	}
	
	.payment-receipt-utility-part-container {
		margin-top: auto;
		margin-bottom: auto;
		width: 100%;
	}
	
	.payment-receipt-utility {
		display: none;
	}
	
	.receipt-print-button {
		display: flex;
		padding-left: 12px;
		padding-right: 12px;
		padding-top: 12px;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 10px;
		flex: 1 0 0;
		border-radius: 6px;
		border: 1px solid #DEDEDE;
		background-color: white;
		height: 48px;
		width: 100%;
	}
	
	.receipt-printer-icon, .email-printer-icon {
		padding-bottom: 10px;
		width: 14px;
	}
	
	.receipt-print-label {
		color: #3D3D3D !important;
		text-align: center !important;
		font-family: 'Open Sans', sans-serif !important;
		font-size: 14px !important;
		font-style: normal !important;
		font-weight: 500 !important;
		line-height: 24px !important;
		margin-top: auto;
		margin-bottom: auto;
	}
	
	.payment-receipt-troubleshoot-container {
		display: flex;
		padding: 20px 18px;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 12px;
		border-radius: 24px;
		background: #FFF;
		width: 70%;
		border: none;
		margin-left: auto;
		margin-right: auto;
	}
	
	.payment-receipt-question-icon {
		width: 48px;
		height: 48px;
		flex-shrink: 0;
	}
	
	.payment-receipt-troubleshoot-title {
		align-self: stretch;
		color: #121212;
		font-family: 'p22-mackinac-pro' !important;
		font-size: 16px !important;
		font-style: normal;
		font-weight: 700;
		line-height: 24px;
		align-self: center;
		text-align: left !important;
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	
	.payment-receipt-troubleshoot-subtitle {
		align-self: stretch;
		color: #3D3D3D;
		font-family: 'Open Sans', sans-serif;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
	}
	
	.payment-receipt-continue-progress-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 16px;
	  }
	  
	  .payment-receipt-continue-container {
		gap: 16px;
	  }
	  
	  .payment-receipt-warning-container {
		width: 294px;
		display: flex;
		flex-direction: row;
		gap: 8px;
	  }
	  
	  .receipt-dashed-divider {
		border-style: dashed;
		border-width: 1px;
		width: 100%;
		border-color: #DCDEE0;
	  }
	  
	  .receipt-point-decoration {
		position: relative;
		width: 100%;
		height: 1px;
	  }
	  
	  .point-decoration {
		position: absolute;
		border: 20px solid #fafafa;
		border-radius: 20px;
	  }
	  
	  .right-decoration {
		right: 0;
		bottom: 0;
		margin-bottom: -35px;
		margin-right: -45px;
	  }
	  
	  .left-decoration {
		left: 0;
		bottom: 0;
		margin-bottom: -35px;
		margin-left: -45px;
	  }

	  .payment-receipt-warning-container {
		width: 80%;
		display: flex;
		flex-direction: row;
		gap: 8px;
		padding: 0;
		margin: auto;
	  }
	  
	  .billing-details-setup {
		gap: 12px;
	  }
	  
	  .billing-continue-button-container-setup {
		display: flex;
		width: 90%;
		margin: auto;
		margin-bottom: 0;
	  }
	  
	  .billing-continue-button-setup {
		width: 100% !important;
		margin-bottom: 0 !important;
	  }
	  
	  .payment-receipt-warning-label {
		color: #2e353f;
		font-family: 'Open Sans', sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 900;
		line-height: 140%;
		text-align: center;
	  }

	  .receipt-note-label {
		color: #2e353f;
		font-family: 'Open Sans', sans-serif;
		font-size: 12px !important;
		font-style: normal;
		font-weight: 600 !important;
		line-height: 140% !important;
		text-align: center;
		padding-top: 10px;
	  }
	  
	  .receipt-note-container {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 8px;
		padding: 0;
		margin: auto;
		align-self: center;
	  }
    
    .extra-claim-info-button-image {
      width: 14px;
      height: 14px;
    }

	  }`;
    mywindow.document.write(
      `<html><head><style>${styles}</style><title>View Billing</title >`
    );
    mywindow.document.write("</head><body >");
    mywindow.document.write(document.getElementById("print-section").innerHTML);
    mywindow.document.write("</body></html>");

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
  };
  const handleClose = () => {
    setModalShow(false);
  };
  const checkIsAudioCourseToGetName = (item) => {
    if (item.configCoursePrice.coursePriceType.code === "BASEPR") {
      if (
        item.configCoursePrice.bhwlName &&
        item.configCoursePrice.bhwlName !== null
      ) {
        return item.configCoursePrice.bhwlName;
      } else {
        return item.configCoursePrice.coursePriceType.name;
      }
    } else {
      return item.configCoursePrice.coursePriceType.name;
    }
  };
  const getNonDiscountCoursePriceItem = () => {
    return (
      <>
        {selectedPriceItems.length > 0 &&
				selectedPriceItems?.map((selectitemPrice)=>{
					return (
            <>
              {selectitemPrice.studentPaymentTrxLines.length > 0 &&
                selectitemPrice.studentPaymentTrxLines.reverse().map((item) => (
                  <>
                    {item.configCoursePrice.coursePriceType.code !==
                      "DISCOU" && (
                      <div
                        key={item.studentPaymentTrxLineId}
                        className="payment-receipt-data-container"
                      >
                        {item.price!=0?<p className="payment-receipt-reference-label">
                          {checkIsAudioCourseToGetName(item)
                            .toString()
                            .split(" ")
                            .join("") === "GiftCertificateCode"
                            ? "GiftCard Redemption Value"
                            : checkIsAudioCourseToGetName(item)}
                        </p>:''}
                        {item.price!=0?<p className="payment-receipt-detail-value">
                          ${item.price && findprice(item.price)}
                          {item.price  && findpriceDecimal(item.price)}
                        </p>:''}
                      </div>
                    )}
                  </>
                ))}
            </>
          );
				})}
         {/*  {selectedPriceItem.studentPaymentTrxLines.length > 0 &&
          selectedPriceItem.studentPaymentTrxLines.reverse().map((item) => (
            <>
              {item.configCoursePrice.coursePriceType.code !== "DISCOU" && (
                <div
                  key={item.studentPaymentTrxLineId}
                  className="payment-receipt-data-container"
                >
                  <p className="payment-receipt-reference-label">
                    {checkIsAudioCourseToGetName(item)
                      .toString()
                      .split(" ")
                      .join("") === "GiftCertificateCode"
                      ? "GiftCard Redemption Value"
                      : checkIsAudioCourseToGetName(item)}
                  </p>
                  <p className="payment-receipt-detail-value">
                    ${item.price ? findprice(item.price) : "00"}
                    {findpriceDecimal(item.price)}
                  </p>
                </div>
              )}
            </>
          ))} */}
      </>
    );
  };
  const getDiscountCoursePriceItem = () => {
    return (
      <>
        {selectedPriceItem.studentPaymentTrxLines.length > 0
          ? selectedPriceItem.studentPaymentTrxLines.reverse().map((item) => (
              <>
                {item.configCoursePrice.coursePriceType.code === "DISCOU" ? (
                  <div
                    key={item.studentPaymentTrxLineId}
                    className="payment-receipt-data-container"
                  >
                    <p className="payment-receipt-reference-label">
                      {item.configCoursePrice.coursePriceType.name
                        .toString()
                        .split(" ")
                        .join("") === "GiftCertificateCode"
                        ? "GiftCard Redemption Value"
                        : item.configCoursePrice.coursePriceType.name}
                    </p>
                    <p className="payment-receipt-detail-value">
                      {"-  $"}
                      {item.price ? findprice(item.price) : "00"}
                      {findpriceDecimal(item.price)}
                    </p>
                  </div>
                ) : null}
              </>
            ))
          : null}
      </>
    );
  };
  const getFormatPaymentDate = (paymentDate) => {
    if (paymentDate) {
      return moment(paymentDate).format("lll");
    } else {
      return "--";
    }
  };

  return (
    <section className="billing-sec p-0" id="print-section">
      {loader && <LoaderWithMessage />}
      <div className="payment-receipt-continue-progress-container">
        <div className="payment-receipt-container">
          <div className="payment-receipt-header-container">
            <img
              src={tickleWithBackground}
              alt=""
              className="confirmation-checkmark"
            />
            <p className="payment-receipt-header-label">Payment Success!</p>
          </div>
          <div className="receipt-point-decoration">
            <div className="point-decoration right-decoration"></div>
            <div className="point-decoration left-decoration"></div>
          </div>
          <div className="receipt-dashed-divider"></div>
          <div className="payment-receipt-part-container">
            <div className="payment-receipt-data-container">
              <p className="payment-receipt-reference-label">
                References Number
              </p>
              <p className="payment-receipt-reference-value">
                {selectedPriceItem.targetId ||
                  selectedPriceItem.nonceFromTheClient}
              </p>
            </div>
            <div className="payment-receipt-data-container">
              <p className="payment-receipt-reference-label">Date</p>
              <p className="payment-receipt-reference-value">
                {selectedPriceItem.paymentDate
                  ? getFormatPaymentDate(selectedPriceItem.paymentDate)
                  : "--"}
              </p>
            </div>
            <div className="payment-receipt-data-container">
              <p className="payment-receipt-reference-label">Payment Method</p>
              <p className="payment-receipt-reference-value">
                {getPaymentMethod(selectedPriceItem)}
              </p>
            </div>
            <div className="payment-receipt-data-container">
              <p className="payment-receipt-reference-label">
                Payment made for
              </p>
              <p className="payment-receipt-reference-value">
                {selectedPriceItem.configCourse
                  ? selectedPriceItem.configCourse.course.name
                  : "--"}
              </p>
            </div>
          </div>
          <div className="receipt-divider"></div>
          <div className="payment-receipt-part-container">
            {getNonDiscountCoursePriceItem()}
          </div>
          {/*<div className="receipt-divider"></div> */}
          <div className="payment-receipt-part-container">
            {getDiscountCoursePriceItem()}
          </div>
          <div className="receipt-divider"></div>
          <div className="payment-receipt-part-container">
            <div className="payment-receipt-data-container">
              <p className="payment-receipt-total-label">Total Amount</p>
              <p className="payment-receipt-total-value">
                {selectedPriceItem ? getTotalValue() : <span>--</span>}
              </p>
            </div>
          </div>
          <div className="receipt-divider"></div>
          <div className="payment-receipt-utility-part-container">
            <div className="payment-receipt-utility">
              <button
                className="receipt-print-button"
                type="button"
                onClick={() => printPage()}
              >
                <img src={printerIcon} className="receipt-printer-icon" />
                <p className="receipt-print-label">PRINT</p>
              </button>
              {/* <button className="receipt-print-button">
					<img src={envelop} alt="" className="email-printer-icon" />
					<p className="receipt-print-label">EMAIL</p>
				</button> */}
            </div>
			<div className="receipt-note-container">
				<p className="receipt-note-label">
					<img src={infoIcon} className='extra-claim-info-button-image' alt="Info"/>   NOTE: This charge will appear as Interactive Education Concepts/ IMPROVLearning on your preferred payment method.
				</p>
			</div>
          </div>
        </div>
        <div className="text-center mb-4 billing-continue-button-container-setup">
            <button className='v2-btn standard billing-continue-button-setup' onClick={redirectToNextPage}><span>Continue</span><img className='img_icon' src={long_right_arrow} alt="" aria-hidden="true" /></button>
        </div>
        <button className="payment-receipt-troubleshoot-container" onClick={handleCustomerSupport}>
            <img src={questionWithBackground} alt="" className="payment-receipt-question-icon"/>
            <p className="payment-receipt-troubleshoot-title">
                Trouble With Your Payment?{"\n"}
                <span className="payment-receipt-troubleshoot-subtitle">
                    Let us know on help center now!
                </span>
            </p>
            <img src={arrow_right_square} alt="" className="payment-receipt-arrow-icon"/>
        </button>
        {greenLightPostAffiliate && sendToReferralCandy()}
        {greenLightPostAffiliate && referralCandyCall()}
        
      </div>
      <Modal
        show={modalShow}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        className="white-rounded-modal"
      >
        <Modal.Body className="mb-0 py-5">
          <p className="mb-0  text-center primary-color">{responseMessage}</p>
        </Modal.Body>
      </Modal>
    </section>
  );
}
