import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { chatCommonService } from "../../../services/chatCommonService";
import { EncryptStorage } from "../../cartSummary/CardSummaryHelper/CartSummaryImport";

const checkGreaterValue = (conditionVar, conditionData, returnDataOne, returnDataTwo) => {
    if (conditionVar > conditionData) {
        return returnDataOne;
    }
    else {
        return returnDataTwo;
    }
}

const checkEqualValue = (conditionVar, conditionData, returnDataOne, returnDataTwo) => {
    if (conditionVar === conditionData) {
        return returnDataOne;
    }
    else {
        return returnDataTwo;
    }
}

export default function HeaderStepper(props) {

    const history = useHistory();

    const [current, setCurrent] = useState(1)
    const [isActiveAZFlow, setIsActiveAZFlow] = useState(false)
    const [nonActiveSidebar, setNonActiveSidebar] = useState(true)
    const stateId = EncryptStorage.getLocalStorage("stateId");

    // this hook form feting custom Attribute current updated array and updating to the state veriable
    useEffect(() => {
        chatCommonService.onMessage().subscribe((res) => {
            if (res) {
                const { organisationCustomAttributeMappingList, configCustomAttributeMappingList } = res
                checkConfigCustomAttributeMappingList(configCustomAttributeMappingList);
                checkOrganisationCustomAttributeMappingList(organisationCustomAttributeMappingList)
            }
        });
    }, [props])

    useEffect(() => {
        let url = history.location.pathname
        switch (url) {
            case "/register":
                return setCurrent(2);
            case "/registerfast":
                return setCurrent(0);
            case "/home/cart-summary":
                return setCurrent(3);
            case "/home/fast-lane-cart-summary":
                return setCurrent(0);
            case "/home/az-cart-summary":
                return setCurrent(3);
            case "/home/review-cart-and-checkout":
                return setCurrent(3);
            case "/home/view-billing-details":
                return setCurrent(0);
            case "/home/fast-lane-personal-information":
                return setCurrent(0);
            case "/home/file-documents":
                return setCurrent(11);
            case "/home/eligibility":
                return setCurrent(12);
            case "/home/az-registration-status":
                return setCurrent(12);
            default:
                return setCurrent(1);
        }
    }, [history.location.pathname])

    const checkConfigCustomAttributeMappingList = (configCustomAttributeMappingList) => {
        configCustomAttributeMappingList && configCustomAttributeMappingList.forEach((item) => {
            if (item.customAttribute.columnName === "AZ_FLOW") {
                setIsActiveAZFlow(true)
            }
        })
    }

    const checkOrganisationCustomAttributeMappingList = (organisationCustomAttributeMappingList) => {
        organisationCustomAttributeMappingList && organisationCustomAttributeMappingList.forEach((item) => {
            if (item.customAttribute.columnName === "REMOVE_FIELDS") {
                setNonActiveSidebar(item.customAttributeValue === 'true' ? false : true)
            }
        })
    }

    return (
        <>
            {nonActiveSidebar && current != 0 && <div className="stepper d-flex justify-content-center" >
                {
                    isActiveAZFlow ? (<>
                        <div className={`stepper-item text-center before-none ${checkGreaterValue(current, 2, ' fill', '')} ${checkEqualValue(current, 2, ' active', '')}`}>
                            <div className="stepper-mark"><span>1</span></div>
                            <div className="stepper-title">Registration</div>
                        </div>
                        <div className={`stepper-item text-center ${checkGreaterValue(current, 3, ' fill', '')} ${checkEqualValue(current, 3, ' active', '')}`}>
                            <div className="stepper-mark"><span>2</span></div>
                            <div className="stepper-title">Payment</div>
                        </div>
                        <div className={`stepper-item text-center  ${checkGreaterValue(current, 11, ' fill', '')} ${checkEqualValue(current, 11, ' active', '')}`}>
                            <div className="stepper-mark "><span>3</span></div>
                            <div className="stepper-title">File Documents</div>
                        </div>
                        <div className={`stepper-item text-center  ${checkGreaterValue(current, 12, ' fill', '')} ${checkEqualValue(current, 12, ' active', '')}`}>
                            <div className="stepper-mark "><span>4</span></div>
                            <div className="stepper-title">Eligibility</div>
                        </div>
                        <div className="stepper-item text-center after-none">
                            <div className="stepper-mark "><span>5</span></div>
                            <div className="stepper-title">Start Course</div>
                        </div>
                    </>) : (<>
                        <div className={`stepper-item text-center before-none ${checkGreaterValue(current, 1, ' fill', '')} ${checkEqualValue(current, 1, ' active', '')}`}>
                            <div className="stepper-mark"><span>1</span></div>
                            <div className="stepper-title">Select course</div>
                        </div>
                        <div className={`stepper-item text-center ${checkGreaterValue(current, 2, ' fill', '')} ${checkEqualValue(current, 2, ' active', '')}`}>
                            <div className="stepper-mark"><span>2</span></div>
                            <div className="stepper-title">Sign up</div>
                        </div>
                        <div className={`stepper-item text-center  ${checkGreaterValue(current, 3, ' fill', '')} ${checkEqualValue(current, 3, ' active', '')}`}>
                            <div className="stepper-mark "><span>3</span></div>
                            <div className="stepper-title">Checkout</div>
                        </div>
                        <div className={`stepper-item text-center  ${checkGreaterValue(current, 4, ' fill', '')} ${checkEqualValue(current, 4, ' active', '')}`}>
                            <div className="stepper-mark "><span>4</span></div>
                            <div className="stepper-title">Start course</div>
                        </div>
                        {
                            (stateId && stateId == 36) ? (
                                <div className="stepper-item text-center after-none">
                                    <div className="stepper-mark "><span>5</span></div>
                                    <div className="stepper-title">Enjoy your 10% discount</div>
                                </div>
                            ) : (
                                <div className="stepper-item text-center after-none">
                                    <div className="stepper-mark "><span>5</span></div>
                                    <div className="stepper-title">E-certificate in 30 min</div>
                                </div>
                            )
                        }
                        
                    </>)
                }
            </div>}
        </>
    );
}
