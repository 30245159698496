import React from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Col, Form } from 'react-bootstrap';
import { renderTextFieldErrors } from '../utils';
import { EncryptStorage } from '../../../../services/EncryptStorage';

export default function NewTextFlexiField(props) {

    const { setFieldValue, confirmFieldErrorMessage, setconfirmFieldErrorMessage, flexiFields, setFlexiFields,
        setOtherInfoErrorMessage, row, ind, errors, touched, handleChange, values } = props;

    const confirmTxtBoxEvent = (field_Name, Event) => {
        if (field_Name.replace("CONFIRM", '') === "PermitNumber") {
            setFieldValue(field_Name, Number(Event.target.value));
        }
        else {
            setFieldValue(field_Name, Event.target.value)
        }
    }

    const txtBoxEvent = (Event) => {
        if (row.id.includes("CONFIRM")) {
            let tempfieldName = row.id.replace("CONFIRM", '');
            let confirmField_ErrorMessage = confirmFieldErrorMessage;
            if (values[tempfieldName] === Event.target.value) {
                confirmField_ErrorMessage[row.id] = '';
                setconfirmFieldErrorMessage(confirmField_ErrorMessage);
            }
            else {
                confirmField_ErrorMessage[row.id] = `${tempfieldName} must match.`;
                setconfirmFieldErrorMessage(confirmField_ErrorMessage);
            }
        }
        let temp = row;
        temp.value = Event.target.value;
        let tempArray = [...flexiFields];
        tempArray[ind] = temp;
        setFlexiFields([]);
        setFlexiFields(tempArray);
    }

    const getClearIcon = () => {
        return (
            <>
                {row.value !== "" ? (
                    <i className="TextInputField__icon clear" onClick={() => { setFieldValue(row.id, ''); txtBoxEvent({ "target": { "value": "" } }); }}>
                        <HighlightOffIcon />
                    </i>
                ) : null}
            </>
        )
    }

    const confirmValueMatch = () => {
        return (
            <>
                {(values[row.id] !== values[`CONFIRM${row.id}`] ?
                    <span className="error">
                        {`${row.id} must match`}
                    </span> : null)
                }
            </>
        )
    }

    const checkVehicleTag = () => {
        return (row.id == 'VEHICLE_TAG' && EncryptStorage.getLocalStorage('vehicleTag') != '') ? true : false;
    }

    return (
        <>
            {
                row.isValidationRequired ? (
                    <>
                        <div className="field-col">
                            {props.isFastLane && (
                                <p className="profile-register-field-label">
                                    {row.flexiFieldDescription}
                                </p>
                            )}
                            <input name={row.id} placeholder={`${row.label} `} value={row.value}
                                className="custom-form-control"
                                readOnly={checkVehicleTag()}
                                onChange={e => {
                                    handleChange(e);
                                    txtBoxEvent(e);
                                    confirmTxtBoxEvent(`${row.id}`, e);
                                    setOtherInfoErrorMessage('');
                                }} autoComplete="none" />
                            {renderTextFieldErrors(errors, row, touched, confirmFieldErrorMessage)}
                            {getClearIcon()}
                        </div>
                        <div className="field-col">
                            {props.isFastLane && (
                                <p className="profile-register-field-label">
                                    {`Confirm ${row.label} `}
                                </p>
                            )}
                            <input name={`CONFIRM${row.id}`} placeholder={`Confirm ${row.label} `}
                                className="custom-form-control"
                                onChange={e => {
                                    handleChange(e); confirmTxtBoxEvent(`CONFIRM${row.id}`, e);
                                    setOtherInfoErrorMessage('');
                                }} autoComplete="none" />
                            {confirmValueMatch()}
                            {getClearIcon()}
                        </div>
                    </>
                ) : (
                    <div className="field-col">
                        {props.isFastLane && (
                            <p className="profile-register-field-label">
                                {row.flexiFieldDescription}
                            </p>
                        )}
                        <input name={row.id}
                            readOnly={checkVehicleTag()}
                            className="custom-form-control"
                            placeholder={`${row.label} `} value={row.value}
                            onChange={e => { handleChange(e); txtBoxEvent(e); setOtherInfoErrorMessage(''); }}
                            autoComplete="none" />
                        {renderTextFieldErrors(errors, row, touched, confirmFieldErrorMessage)}
                        {getClearIcon()}
                    </div>
                )
            }
        </>
    )
}
