import React from 'react'
import { Modal } from 'react-bootstrap';

export default function PrivacyPolicy(props) {
    const { show, handleClose } = props;
    return (
        <>
            <Modal show={show} onHide={handleClose} centered className="custom-modal fade width-700">
                <Modal.Body className="registrationDisclaimer">
                    <span class="close" onClick={() => handleClose()}>&times;</span>
                    <h3>WE DO NOT SELL OUR CUSTOMER LISTS TO ANY THIRD PARTIES FOR MARKETING PURPOSES.</h3>
                    <div className="registrationDisclaimer-scroll">
                        <p><strong>WE DO NOT SELL OUR CUSTOMER LISTS TO ANY THIRD PARTIES FOR MARKETING PURPOSES.</strong></p>
                        <p>Our Company respects the privacy of every individual who visits our website. This Privacy Policy outlines the information we will collect and how we will use that information. This Privacy Policy will also
                            instruct you on what to do if you do not want your personal information collected when you visit our website.</p>
                        <h4>Personal Information</h4>
                        <p>Courts and governmental agencies may require the collection of certain personal information (name, driver's license number, etc.) and contact information (address, phone number, email address, etc.) for
                            administrative purposes. All information which you submit as part of the registration process may be kept in our records for a period of time as required by law. You may review and request changes to
                            this information during normal operating hours by calling our office at 1.800.660.8908 and providing proof of identification.</p>
                        <p>We will not collect your name, address, telephone number, social security number, or e-mail address (Personally Identifiable Information) unless you provide it to us voluntarily. However, we may attempt
                            to collect such Personally Identifiable Information if we believe your use of this website violates any laws. If you do not want this information collected, please do not submit it to us.</p>
                        <p>When you do provide us with Personal Identifiable Information, we may use that information in the following ways, unless stated otherwise: We may store and process that information to better understand your
                            needs and how we can improve our services. We may also use that information to contact you. We will not provide that information to third parties; however if required by a court or government agency
                            or to report your course completion, evaluate our courses effectiveness, certain data may be disclosed to the appropriate parties. We do not sell our customer lists to any third parties for marketing
                            purposes.
                        </p>
                        <h4>Other Information Collected Automatically</h4>
                        <p>In some cases, we may collect information about you that is not personally identifiable. Examples of this type of information include the type of Internet Browser used, the type of computer operating system
                            used, and the URL of the websites that link to or from our website.</p>
                        <h4>Disclosure Regarding Google Display Advertising</h4>
                        <p>We have implemented Google Analytics features based on Display Advertising (Google Display DoubleClick Campaign Manager and Remarketing integration). Visitors can <a href="https://tools.google.com/dlpage/gaoptout/"
                            title="opt-out" target="_blank">opt-out</a> of Google Analytics for Display Advertising and customize Google Display Network ads using the <a href="https://www.google.com/settings/ads" title="Ads Settings"
                                target="_blank">Ads Settings</a>.</p>
                        <p>We, along with third-party vendors, including Google, use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) together to report how ad impressions,
                            other uses of ad services, and interactions with these ad impressions and ad services are related to visits to our site.</p>
                        <h4>Information We May Place Automatically on Your Computer's Hard Drive</h4>
                        <p>When you view our website, we may store some information on your computer. This information will be in the form of a "cookie" or similar file and will help us in many ways. For example, cookies allow us
                            to tailor our website to better match your interests and preferences. With most Internet Browsers, you can erase cookies from your computer hard drive, block all cookies, or receive a warning before
                            a cookie is stored. Please refer to your browser instructions or help screen to learn more about these functions.</p>
                        <h4>Correcting/Updating Personal Information</h4>
                        <p>Prior to final submission of the course registration page we provide a way to correct or update that customer's personal data provided to us. Later updates and corrections can also be made via <a href="mailto:Support@MyImprov.com">email</a>                                                    or calling Customer Support at 1.800.660.8908.</p>
                        <h4>Changes to our Privacy Policy</h4>
                        <p>Periodically we may make changes to our Privacy Policy. You may wish to check our website from time to time to review our Privacy Policy then in effect.</p>
                        <h4>Security</h4>
                        <p>The security of your personal information is always a priority to us. We work hard to help ensure your account information stays secure. Our servers are located in a secure off-site server facility with
                            24x7 onsite security and limited access by only 2 designated managers of the company.</p>
                        <p>We use Secure Socket Layer (SSL) technology (up to 256-bit encryption) to encrypt your personal as well as transaction data over the Internet. Any information you provide is encrypted for decoding by your
                            browser.
                        </p>
                        <h4>Redress</h4>
                        <p>Redress is available for our users who feel their privacy and confidentiality rights are violated. We provide a means to investigate complaints and re-audit policy and procedures in cases of potential violation
                            of system's privacy and confidentiality.</p>
                        <h4>Who to Contact</h4>
                        <p>If you have any questions or concerns about our Privacy Policy, please do not hesitate to contact us at <a href="mailto:support@myimprov.com">Support@MyImprov.com</a></p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
