import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIdleTimer } from "react-idle-timer";
import { handleIframeEvent } from "../../services/shareTimeService";
import {
  GenericAPIService,
  EncryptStorage,
  UrlConstants,
} from "../common/imports/CommonImport";
import { sidebarHelper } from "./sidebarHelper";

export default function IdealSessionLogout(props) {
  const { history } = props;
  let timeout = 300000;
  const [lastEvent, setLastEvent] = useState("Events Emitted on Leader");
  const handleOnActive = () => {
    setLastEvent("active");
  };
  const handleOnIdle = () => setLastEvent("idle");
  const [login, setLogin] = useState(true);
  const [modalShow, setShowModal] = useState(false);

  const { getRemainingTime, isIdle, reset } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    crossTab: {
      emitOnAllTabs: true,
    },
  });

  useEffect(() => {
    let IsLoggedIn = localStorage.getItem("IsLoggedIn");
    let unSubscribe;
    unSubscribe = handleInitialCall(IsLoggedIn, unSubscribe);
    return () => {
      clearInterval(unSubscribe);
    };
  }, []);

  useEffect(() => {
    let inactivityTimeoutFlagEnabled = EncryptStorage.getLocalStorage(
      "inactivityTimeoutFlagEnabled"
    );
    if (
      isIdle() &&
      login &&
      inactivityTimeoutFlagEnabled &&
      inactivityTimeoutFlagEnabled.toString() === "true"
    ) {
      setShowModal(true);
      EncryptStorage.setToLocalStorage("stopInactiveLogout", "true");
      setTimeout(() => {
        let stopInactiveLogout =
          EncryptStorage.getLocalStorage("stopInactiveLogout");
        if (stopInactiveLogout && stopInactiveLogout.toString() === "true") {
          setShowModal(false);
          chapterLaunchLogout();
        }
      }, 90000);
    }
  }, [isIdle()]);

  const chapterLaunchLogout = () => {
    const url = UrlConstants.time_managementUrl;
    const studentCourseChapterId = EncryptStorage.getLocalStorage(
      "studentCourseChapterId"
    );
    const studentCourseId = EncryptStorage.getLocalStorage("studentCourseId");
    const logoutTimeSpent = localStorage.logoutTimeSpent;
    const logoutTimeConsumedAfterIdentityVerification =
      localStorage.logoutTimeConsumedAfterIdentityVerification;
    if (studentCourseChapterId && studentCourseId && logoutTimeSpent) {
      let payload = {
        studentCourseChapterId: studentCourseChapterId,
        studentCourseId: EncryptStorage.getLocalStorage("studentCourseId"),
        timeConsumedAfterIdentityVerification: Number(
          logoutTimeConsumedAfterIdentityVerification
        ),
        timeSpent: Number(logoutTimeSpent),
      };
      GenericAPIService.azFlowUrlConfig(url, payload, "PUT")
        .then((res) => {
          sidebarHelper.mainLogout(null, history);
        })
        .catch((error) => {});
    }
  };
  const logout = () => {
    let studentId = EncryptStorage.getLocalStorage('studentId');
    let configCourseId = EncryptStorage.getLocalStorage('configCourseId');
    let studentCourseId = EncryptStorage.getLocalStorage('studentCourseId');
    const visitChapterLaunch = EncryptStorage.getLocalStorage('visitChapterLaunch')
    let availableTimeForTheDay = EncryptStorage.getLocalStorage('availableTimeForTheDay');
    const payload = {
        "studentId": studentId,
        "isCourseHasAccessTimeLimitPerDay": visitChapterLaunch,
    }
    if (visitChapterLaunch && availableTimeForTheDay) {
        payload.configCourseId = configCourseId;
        payload.studentCourseId = studentCourseId;
        payload.getRemainingTime = getRemainingTime === "00.00" ? "00.00" : getRemainingTime
    }

    callLogoutApi(payload)
}
  const callLogoutApi = (payload) => {
    let url = UrlConstants.logoutUrl;
    GenericAPIService.customAPIMethod(url, payload, "POST")
      .then((res) => {
        resetUserSession();
      })
      .catch((err) => {
        resetUserSession();
      });
  };

  function handleInitialCall(IsLoggedIn, unSubscribe) {
    if (IsLoggedIn) {
      timeout = getRemainingTime();
      unSubscribe = setInterval(() => {
        let inactivityTimeoutFlagEnabled = EncryptStorage.getLocalStorage(
          "inactivityTimeoutFlagEnabled"
        );
        if (
          inactivityTimeoutFlagEnabled &&
          inactivityTimeoutFlagEnabled.toString() === "true"
        ) {
          timeout = getRemainingTime();
        } else {
          timeout = 300000;
        }
      }, 1000);
      setTimeout(() => {
        setLogin(true);
      }, 5000);
    }
    handleIframeEvent.onMessage().subscribe((res) => {
      const { text = "" } = res ?? {};
      if (text === "active") reset();
    });
    return unSubscribe;
  }

  function resetUserSession() {
    let redirectUrl = EncryptStorage.getLocalStorage("redirectUrl");
    localStorage.clear();
    window.localStorage.clear();
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      history.push("/login");
    }
  }

  function handleContinue() {
    setShowModal(false);
    EncryptStorage.setToLocalStorage("stopInactiveLogout", "false");
    reset();
  }

  return (
    <>
      {modalShow && (
        <Modal
          show={modalShow}
          centered
          backdrop="static"
          keyboard={false}
          className=" white-rounded-modal smsTextModal"
        >
          <Modal.Body>
            <div className="smsTextModal-body">
              <h5 className="text-center mt-4">Are you still there?</h5>
              <div className="confirm-email-btn-block">
                <button
                  type="submit"
                  className="v2-btn standard mobile-full-width"
                  onClick={() => handleContinue()}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
