import * as Yup from 'yup';
const validationSChema = Yup.object({
    newPassword: Yup.string().required('New password required')
        .min(6, 'Password must be between 6 to 30 characters')
        .max(30, "Password must be between 6 to 30 characters"),
    confirmPassword: Yup.string().required('Confirm password required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')

});
const initialValues = {
    newPassword: '',
    confirmPassword: '',
}

export const Schema = {
    validationSChema, initialValues
}