import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  HOF_Methods,
  UrlConstants,
  GenericAPIService,
  Constants,
  EncryptStorage,
} from "../common/imports/CommonImport";
import environment from "../../environments/environment";
import { courseEnrollmentHelper } from "./CourseEnrollmentHelper";

const CourseEnrollment = (props) => {
  const { history } = props;
  const [loader, setLoader] = useState(false);

  useEffect(() => {
	
    // const getUrl = window.location.href;
    // let splitUrl = window.location.href.includes('?') ? window.location.href.split('?') : false;
		
    let enableAuthCheck = EncryptStorage.getLocalStorage("enableAuthCheck");
    // if (splitUrl) {
    if (enableAuthCheck && enableAuthCheck.toString() == "true") {
			
      courseEnrollmentHelper.checkAuthToken(history, setLoader);
    } else {
      EncryptStorage.setToLocalStorage("registerVia", "");
      /* localStorage.clear();
            localStorage.clear(); */
    }

    EncryptStorage.setToLocalStorage("organisationProperty", "");
  }, []);

  return (
    <>
      <div className=" position-relative h-100">
        {courseEnrollmentHelper.getLoader(loader)}
        <div className=" container h-100"></div>
      </div>
    </>
  );
};

export default withRouter(CourseEnrollment);
