import React, { useEffect, useState } from 'react'
import { Alert, Modal } from 'react-bootstrap';
import { EncryptStorage } from '../../services/EncryptStorage'
import { CheckCircleIcon, LockIcon, arrow_right_square, infoIcon, long_right_arrow, questionWithBackground } from '../common/assets/Images';
import GetBillingDetails from './GetBillingDetails';
import ShopperSurvey from '../shopper/ShopperSurvey';   


function ViewBilling(props) {

    const [parentEmailFlow, setParentEmailFlow] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [shopperModel, setShopperModel] = useState(false);
    const [emailId, setEmailId] = useState('');
    const [name, setName] = useState('');
    
    let tiktokScriptTag = document.createElement('script');

    useEffect(() => {
        EncryptStorage.setToLocalStorage('isPayLaterTrue', false);
        let _parentEmailFlow = EncryptStorage.getLocalStorage('parentEmailFlow');
        EncryptStorage.setToLocalStorage('isSecurityQuestionVerifiedToday', '');
        if (_parentEmailFlow) {
            setParentEmailFlow(true);
        }
        let getStudentDetails = EncryptStorage.getLocalStorage('userObject')
        setEmailId(getStudentDetails.emailId);
        setName(`${getStudentDetails.firstName} ${getStudentDetails.lastName}`);
        tiktokScriptTag.src = "./tiktok.js";
        tiktokScriptTag.async = true;
        document.body.appendChild(tiktokScriptTag);
        return () => {
            document.body.removeChild(tiktokScriptTag);
        }
        
    }, []);

    const redirectToNextPage = () => {
        const id = EncryptStorage.getLocalStorage('fastLaneStudentID');

        if (id){
            props.history.push("/home/fast-lane-personal-information");
        }
        else{
            if (parentEmailFlow) {
                setShowMessage(true);
                setTimeout(() => {
                    window.location.href = 'https://driverz.com/start-here/';
                }, 2000);
            }
            else {
                let isShowShopperRequired = EncryptStorage.getLocalStorage('isShowShopperRequired');
                if (isShowShopperRequired) {
                    setShopperModel(true);
                } else {
                    directRedirect();
                }             
            }
        }
    }

    const directRedirect = () => {
        let skipPersonalInfo = EncryptStorage.getLocalStorage("skipPersonalInfo")
        if (skipPersonalInfo) {
            props.history.push("/home/dashboard");
        }
        else {
            props.history.push("/home/personal-information");
        }
    }

    const handleCustomerSupport = () => {
        window.open("https://www.myimprov.com/contact/");
    };

    return (
        <>
            {
                showMessage ? (
                    <Modal show={true} backdrop="static" keyboard={false} id="messageModal" centered>
                        <Modal.Body className="messageModal">
                            <Alert variant={"success"}>
                                <span className="success-icon">
                                    <CheckCircleIcon />
                                </span>
                                <span className="danger-icon">
                                    <LockIcon />
                                </span>
                                {"Payment is done successfully."}
                            </Alert>
                        </Modal.Body>
                    </Modal>
                ) : null
            }
            {
                shopperModel ? (
                    <Modal show={true} backdrop="static" keyboard={false} centered className='surveyModal'>
                        <Modal.Body >
                            <ShopperSurvey emailId={emailId} name={name} props={props} />
                        </Modal.Body>
                    </Modal>
                ) : (
                    <div className="resetMainContainer billing-details-setup">
                        <div className="billing-details billing-details-setup">
                            <h6>Notification</h6>
                            <h4>Thank you for your payment</h4>
                            <p className='billing-details-subtitle'>Please print out this receipt and keep it with you.</p>
                            <div className="text-center mb-4 billing-continue-button-container-setup">
                                <button className='v2-btn standard billing-continue-button-setup' onClick={() => redirectToNextPage()}><span>Continue</span><img className='img_icon' src={long_right_arrow} alt="" aria-hidden="true" /></button>
                            </div>
                            <div className="payment-receipt-warning-container">
                                <p className="payment-receipt-warning-label"><img src={infoIcon} className='extra-claim-info-button-image' alt="Info"/>   Please save your reference number, to allow us to support you in case of problems with your information.</p>
                            </div>
                            <GetBillingDetails 
                                billingNumber={0} 
                                redirectToNextPage={redirectToNextPage}
                                handleCustomerSupport={handleCustomerSupport}
                            />
                        </div>
                    </div>
                )
            }
        </>
    )
}
export default ViewBilling;