import React, { useState } from 'react';
import { UrlConstants } from "../../../components/common/imports/CommonImport";
import { _localStorage } from "../../constant/storage/localStorage";
import { arrow_down_square, blackShoppingCart, downwardPurpleArrow } from '../../common/assets/Images';
import nopath from "../../../assets/images/nopath.png";
import ShopperFooter from '../../common/ShopperFooter';
import FastLaneCardDetaiItem from './FastLaneCardDetaiItem';

export default function FastLaneCartDetail(props) 
{
    const { 
        upsellCourseList,
        fixedCoursePriceDetails, 
        fixedCoursePriceData, 
        resetCoursePriceList,
        upsellCoursePriceList,
        isAudioUpgradeAdded,
        setIsAudioUpgradeAdded,
    } = props;
    const [cartIsShown, setCarIsShown] = useState(true);

    const handleCartToggleShown = () => {
        setCarIsShown(!cartIsShown);
    };

    const checkDiscountAvailableOnBasePrice = () => {
        return fixedCoursePriceDetails && fixedCoursePriceDetails.crossOutPrice && fixedCoursePriceDetails.crossOutPrice.toString() != "0";
    }

    const getOriginalBasePrice = () => {
        return fixedCoursePriceDetails && fixedCoursePriceDetails.crossOutPrice ? "$"+fixedCoursePriceDetails.crossOutPrice.toFixed(2) : "";
    }

    const getDiscountedBasePrice = () => {
        return fixedCoursePriceDetails && fixedCoursePriceDetails.basePrice ? fixedCoursePriceDetails.basePrice.toFixed(2) : "0";
    }

    const cleaningDescription = (value) => {
        if (value){
            let newStr = value.replace("<p>", "");
            newStr = newStr.replace("</p>", "");
            return newStr;
        }
        else {
            return value;
        }
    }

    const checkInvalidCourses = () => {
        const invalidCourses = ["777", "776"];
        const configCourseId = localStorage.getItem("configCourseId");
    
        if (configCourseId && invalidCourses.find((element) => element === configCourseId)){
            return false;
        }
        return true;
    }

    return (
    <>
        <div className='fast-lane-cart-detail-container'>
            <div className="course-cart-detail-container">
                <div className="cart-detail-header">
                    <div className="left-cart-detail-header-container">
                        <img src={blackShoppingCart} alt="Cart Icon" className="cart-icon" />
                        <p className="cart-detail-label">Your Courses</p>
                    </div>
                    <button className="right-cart-detail-container" onClick={handleCartToggleShown}>
                        <p className="collapsable-cart-detail-label">View Cart Details</p>
                        <img 
                            src={arrow_down_square}
                            alt=""
                            className={cartIsShown ? "cart-uncollpased-arrow" : "cart-collpased-arrow" }
                             />
                    </button>
                </div>
                {cartIsShown && (
                    <div className="cart-detail-image-description-container">
                        <img 
                            className="course-icon" 
                            src={fixedCoursePriceData.logoPath ? UrlConstants.imageBaseUrl + fixedCoursePriceData.logoPath : nopath} 
                        />
                        <div className="cart-detail-description-container">
                            <p className="cart-detail-course-name-label">{fixedCoursePriceData.name}</p>
                            <div className="cart-detail-course-price-container">
                                {checkDiscountAvailableOnBasePrice && (
                                <p className="cart-detail-course-price-label price-label-lined">{getOriginalBasePrice()}</p>)}
                                <p className="cart-detail-course-price-label">${getDiscountedBasePrice()}</p>
                            </div>
                            <p className="cart-detail-course-description-label">{cleaningDescription(fixedCoursePriceData.description)}</p>
                        </div>
                    </div>
                )}
                {cartIsShown && checkInvalidCourses() && upsellCourseList && upsellCourseList.length > 0 && (
                    <img src={downwardPurpleArrow} className="fast-lane-base-course-arrow-downward" />
                )}
            </div>
            {cartIsShown && checkInvalidCourses() && (
                <FastLaneCardDetaiItem
                    upsellCourseList={upsellCourseList}
                    resetCoursePriceList={resetCoursePriceList}
                    upsellCoursePriceList={upsellCoursePriceList}
                    isAudioUpgradeAdded={isAudioUpgradeAdded}
                    setIsAudioUpgradeAdded={setIsAudioUpgradeAdded}
                    cleaningDescription={cleaningDescription}
                />
            )}
            <div className="review-panel">
                <ShopperFooter />
            </div>
        </div>
    </>
    );
}
