import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import mobileMenu from "./../../../assets/images/icons/menu_mobile.svg";
import elmsLogo from "./../../../assets/images/Powered_by_SPIDER_White.png";
import { EncryptStorage } from "../../../services/EncryptStorage";
import environment from "../../../environments/environment";
import ViewCourseTimer from "../../common/timers/ViewCourseTimer";

export default function MobileNavigation(props) {
  let history = useHistory();
  const [logoPath] = useState(
    EncryptStorage.getLocalStorage("organisationLogo")
  );

  // useEffect(() => {
  //     console.log(history.location.pathname)
  // }, [])

  return (
    <>
      <div className="mobileNavigation">
        <div className="mobileHeader">
          <div className="mobile-menu">
            <img
              src={mobileMenu}
              alt="mobile menu"
              className="cursor-pointer"
              onClick={props.handleToggle}
            />
          </div>
          <div className="mobile-brand">
            <div
              className=""
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "12px",
              }}
            >
              {logoPath ? (
                <div>
                  <img
                    src={environment.bucketUrl + logoPath}
                    alt="improv-logo"
                    className="mb-1 improv-logo"
                  />
                </div>
              ) : (
                <div className="mobile-placeholder">Partner Logo Here</div>
              )}

              <img src={elmsLogo} className="elmsLogo" alt="elms logo" />
            </div>
          </div>
        </div>
        {history.location.pathname === "/home/dashboard" ||
        history.location.pathname === "/home/chapter-launch" ? (
          <div className="d-flex justify-content-center mt-2">
            <ViewCourseTimer passingProps={history} />
          </div>
        ) : null}
      </div>
    </>
  );
}
