import React from 'react'

export default function FlexiFieldsTitle({ action, content }) {
    return (
        <div className='flex-title'>
            {
                content === "Other Information" ? <h6 className="mb-0">{action ? content : null}</h6> : <p className="mb-0">{action ? content : null}</p>
            }

        </div>
    )
}
