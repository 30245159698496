import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Rating } from 'react-simple-star-rating';
import RegisterShopperSlider from '../register/RegisterShopperSlider'
import { newGoDaddy, sa_logo, shopperApprovedIsotype } from './assets/Images'

export default function ShopperFooter() {

    const [reviewDetails, setReviewDetails] = useState({
        averageRating: 0,
        totalReviews: 0
    });

    useEffect(() => {
        getShopperReview();
    }, [])

    const getShopperReview = () => {
        const siteId = 11612
        const key = 'HsDc9KQF8VMmPxS'
        let url = `https://api.shopperapproved.com/aggregates/reviews/${siteId}?token=${key}&xml=false`;

        axios.get(url).then(function (response) {
            let tempShopperDetail = {
                averageRating: 0,
                totalReviews: 0
            }
            if (response && response.data && response.data.average_rating) {
                tempShopperDetail.averageRating = response.data.average_rating;
            }
            if (response && response.data && response.data.total_reviews) {
                if (response.data.total_reviews < 1000) {
                    tempShopperDetail.totalReviews = response.data.total_reviews;
                }
                else {
                    tempShopperDetail.totalReviews = `${Math.round(response.data.total_reviews / 1000)}K+`;
                }
            }
            setReviewDetails({ ...tempShopperDetail });
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    return (
        <>
            <div className="ratings">
                <div className="col-rating shopper-footer-review-container">
                    <a href="https://www.shopperapproved.com/reviews/myimprov.com/" target="_blank">
                        <img className='shopper-approved-logo-desktop' src={sa_logo} alt="Shopper Approved"/>
                        <img className='shopper-approved-logo-mobile' src={sa_logo} alt="Shopper Approved"/>
                    </a>
                    <a className="customer-reviews customer-reviews-setup" href="https://www.shopperapproved.com/reviews/myimprov.com/" target="_blank">
                        <Rating className='slide-rating' initialValue={reviewDetails.averageRating-0.1} size={22} readonly={true} allowHover={false} fillColor="#F6BC19" allowFraction={true} />
                        <p className=''>
                            <b>{reviewDetails.totalReviews}</b> &nbsp; Customer Reviews
                        </p>
                    </a>
                </div>
            </div>
            <div className='shopper-footer-review-label-container'>
                <p className='shopper-footer-review-label'>Real-Time Reviews</p>
            </div>
            <div className="testimony">
                <RegisterShopperSlider />
            </div>
        </>
    )
}
