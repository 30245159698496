import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { shareTimeService } from "../../../services/shareTimeService";
import { courseTimeService } from "../../../services/courseTimeService";
import { EncryptStorage } from "../../common/imports/CommonImport";
import { logo, logoNew } from "../../common/assets/Images";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import WarningModal from "../../dashboard/modals/WarningModal";

export default function Header(props) {
   const { history } = props;
   const [timer, setTimer] = useState("");
   const [courseTimer, setCourseTimer] = useState("");
   const [userName, setUserName] = useState("Gust");
   const [showWarningModal, setShowWarningModal] = useState(false);

   useEffect(() => {
      setInitialValue(setUserName, setTimer, setCourseTimer);
      //console.log(timer, courseTimer, userName);
   }, []);

   const navOpen = (param) => {
      let element = document.getElementById("sideBar");
      let element1 = document.getElementById("root");
      param ? element.classList.add("open") : element.classList.remove("open");
      param ? element1.classList.add("mob-menu-overlay") : element1.classList.remove("mob-menu-overlay");
   };

   const navigateToDashboard = () => {
      setShowWarningModal(false);
      history.push("/home/dashboard");
   };

   return (
      <>
         <header className="header">
            <div className="hamburger" onClick={() => navOpen(true)}>
               <span className="bar top"></span>
               <span className="bar center"></span>
               <span className="bar btm"></span>
            </div>
            <div className="logo">
               <img src={logo} alt="improv logo" />
            </div>
            <div className="logo new">
               <img src={logoNew} alt="improv logo" />
            </div>
         </header>
         <WarningModal modalShow={showWarningModal} handleYesEvent={navigateToDashboard} handleClose={(value) => setShowWarningModal(value)} />
      </>
   );
}

export function ChapterAndFinalQuizTimer({ getTimerName, timer }) {
   if (!timer) return null;

   return (
      <Nav.Item className="available-time d-flex align-items-center">
         <div className="timer course-timer">
            <div className="timer__icon mr-1">
               <AccessTimeIcon /> <span className="timer-heading">{getTimerName()}</span>
            </div>
         </div>
         <h5 className="liveTimer">
            {timerSplitter(timer)[0]}m {timerSplitter(timer)[1]}s
         </h5>
         <div className="time-active">
            <h6 className="text-center">You still have</h6>
            <h3 className="text-center py-2">
               {timerSplitter(timer)[0]}m {timerSplitter(timer)[1]}s
            </h3>
            <h6 className="text-center mb-0 pb-0">left on this Chapter</h6>
         </div>
      </Nav.Item>
   );
}

export function CourseTimer({ checkDashboardPath, courseTimer }) {
   if (!courseTimer) return null;

   return (
      <Nav.Item className={"available-time d-flex align-items-center " + checkDashboardPath("className")}>
         <div className="timer course-timer">
            <div className="timer__icon mr-1">
               <AccessTimeIcon /> <span className="timer-heading">Time Left Today</span>
            </div>
         </div>
         <div>{checkDashboardPath("path")}</div>
         <div className="time-active">
            <h6 className="text-center">You still have</h6>
            <h3 className="text-center py-2">
               {timerSplitter(courseTimer)[0]}h {timerSplitter(courseTimer)[1]}m
            </h3>
            <h6 className="text-center mb-0 pb-0">left for this Course today</h6>
         </div>
      </Nav.Item>
   );
}

function setInitialValue(setUserName, setTimer, setCourseTimer) {
   let { firstName = "" } = EncryptStorage.getLocalStorage("userObject") ?? {};
   EncryptStorage.setToLocalStorage("isCourseHasAccessTime", "true");
   setUserName(firstName);
   shareTimeService.onMessage().subscribe((res) => {
      const { text = "" } = res ?? {};
      setTimer(text);
   });
   courseTimeService.onMessage().subscribe((res) => {
      const { text = "" } = res ?? {};
      setCourseTimer(text);
   });
}

const timerSplitter = (time) => {
   if (time) {
      let timeSplit = time.replace(":", ".").split(".");
      let getHours = timeSplit[0];
      let getMin = timeSplit[1];
      return [getHours, getMin];
   }
};
