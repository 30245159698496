import { EncryptStorage } from '../../common/imports/CommonImport';

function getPayload(item) {
    if (item) {
        const stateId = EncryptStorage.getLocalStorage('stateId');
        const countyId = EncryptStorage.getLocalStorage('countyId');
        const courtId = EncryptStorage.getLocalStorage('courtId');
        const citationDate = EncryptStorage.getLocalStorage('citationDate');
        const studentId =  "46254";
        return {
            configCourseId: item.upsellConfigCourse.configCourseId,
            studentId: studentId,
            stateId: stateId ? stateId : 0,
            countyId: countyId ? countyId : 0,
            courtId: courtId ? courtId : 0,
            citationDate: citationDate ? citationDate : null,
        };
    }
}

export const GetSelectUpsellDetailsHelper = {
    getPayload
}