import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { Rating } from 'react-simple-star-rating'

function ReviewSlider({ sliderCount, variant, rating }) {
    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: sliderCount,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "40px",
        autoplay: true,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: sliderCount,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const [reviewList, setReviewList] = useState([])
    useEffect(() => {
        getShopperReview()
    }, [])
    const getShopperReview = () => {
        const siteId = 11612
        const key = 'HsDc9KQF8VMmPxS'
        let url = `https://api.shopperapproved.com/reviews/${siteId}?token=${key
            }&rating=4%2C5&limit=50&test=false&xml=false`

        axios.get(url).then(function (response) {
            let arr = Object.entries(response.data);
            let result = arr.map(item => {
                if (item[1].initial_comments) return item[1]
            })
            let comments = result.filter(item => { return item })
            setReviewList(comments);
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }
    return (
        <>

            <Slider {...settings}>
                {
                    reviewList && reviewList.map((review, index) =>
                        <div className="sliderCardContainer">
                            <div className={`sliderCard ` + variant}>
                                {rating ? <> <Rating initialValue={review.overall} readonly={true} allowHover={false} fillColor="#ED8D0E" /></> : null}
                                {rating ? <><p className='name-top'> {review.display_name}</p></> : null}
                                <div className='top'>
                                    {rating ? null : <>  <p>{review.comments}</p></>}
                                    <h6>{review.initial_comments}</h6>
                                </div>
                                {rating ? null : <><p className='name'>@ {review.display_name}</p></>}

                            </div>
                        </div>
                    )
                }
            </Slider>
        </>
    )
}

export default ReviewSlider