import React, { useState } from 'react'
import { Row, Col, Form, Alert, Modal, } from 'react-bootstrap';
import TextInputField from '../common/forms/TextInputField';
import LockIcon from '@material-ui/icons/Lock';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Formik } from 'formik'
import { Schema } from './Schema';
import { Constants, GenericAPIService, HOF_Methods, UrlConstants } from '../common/imports/CommonImport';
import PropagateLoader from 'react-spinners/PropagateLoader';

const ForgetPassword = (props) => {

    const { history } = props;
    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [message, setMessage] = useState('');

    const onSubmit = (values) => {
        setErrorMessage('');
        const payload = values;
        setLoader(true);
        const url = UrlConstants.forgotPasswordUrl
        GenericAPIService.customAPIMethod(url, payload, 'POST').then(res => {
            setLoader(false);
            if (HOF_Methods.getStatus(res) == 'success') {
                setMessage(res.status.message);
                setTimeout(() => {
                    history.goBack();
                    setMessage('');
                }, 5000);
            } else setErrorMessage(res.status.message);

        }).catch(error => {
            setLoader(false)
            setErrorMessage(Constants.serverErrorMessage);
        });
    }

    return (
        <div className="forgetPassword__container ">
            <div className="changePassword">
                <h3 className="blue-color font-weight-bolder text-center mb-2 mb-md-4  pb-2">Forgot Password</h3>
                <div className="container">
                    {loader === true && <div className="loader">
                        <PropagateLoader size={15} />
                    </div>}
                    {message && <Modal show={true} backdrop="static" keyboard={false}>
                        <Modal.Body className="messageModal">
                            <Alert variant={'success'}>
                                <span className="success-icon"><CheckCircleIcon /></span>
                                <span className="danger-icon"><LockIcon /></span>
                                <span> {message}</span>
                            </Alert>
                        </Modal.Body>
                    </Modal>}
                    {!message ? <div className="changePassword__card">
                        <Formik
                            initialValues={Schema.initialValues}
                            validationSchema={Schema.validationSChema}
                            onSubmit={onSubmit}>
                            {({ handleSubmit }) => (
                                <Form onSubmit={handleSubmit} noValidate autoComplete="off">
                                    {errorMessage && <div className="custom-error">{errorMessage}</div>}
                                    <Row className="align-items-center RegisterForm">
                                        <Col md={12} xl={12}>
                                            <div className="TextInputField">
                                                <TextInputField name="emailId" inputProps={{ maxLength: 50 }} label=" Registered Email ID *" type="text" />
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="register__button  mt-4">
                                                <button type="submit" className="v2-btn standard w-100  mx-4 justify-content-center text-uppercase font-weight-bold">Submit</button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </div> : null}
                </div>
            </div>

        </div>
    )
}
export default ForgetPassword