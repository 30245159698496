import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { EncryptStorage } from '../../services/EncryptStorage';
import nopath from './../../assets/images/nopath.png';

function CourseLanding(props) {

    const [selectedCourse, setSelectedCourse] = useState(1)
    const [staticPrice] = useState(['20', '50', '30', '40',]);

    const handleClickCourses = () => {
        EncryptStorage.setToLocalStorage('organisationId', 1001);
        EncryptStorage.setToLocalStorage('configCourseId', 383);
        EncryptStorage.setToLocalStorage('courseLanding', true);
        EncryptStorage.setToLocalStorage('selectedPackage', selectedCourse);
        props.history.push("/register")
    }

    const handleCheckbox = (el) => {
        setSelectedCourse(el)
    }

    const checkboxValue = (el) => {
        EncryptStorage.setToLocalStorage('selectedPackage', el);
        return selectedCourse === el ? true : false;
    }

    return (
        <div className="container">
            <div className="landing course-landing">
                <div className="flashDealCard">
                    <div className="flashDealCardMain">

                        <div className="dealsImage">
                            <img src={nopath} alt="improve-cart" />
                        </div>
                        <div className="DealDetails">
                            <p className="DealHead blue">Test Driving</p>
                            <p className="details blue">Driver License Direct by IMPROV driver’s ed course is a 30-hour online driver’s education program.</p>
                        </div>
                        <div className="cost">
                            <p className="offer blue">$80</p>
                        </div>
                    </div>
                    <div className="cardBtnMain justify-content-end">
                        <Button className="btn card-btn bg-blue" onClick={handleClickCourses}>Continue</Button>
                    </div>
                </div>
                {
                    staticPrice.map((item, index) =>
                        <div className="flashDealCard cursor-pointer" onClick={() => { handleCheckbox(index + 1) }}>
                            <div className="flashDealCardMain">
                                <div className="Check">
                                    <input type="checkbox" name="deal" id="deal1" checked={checkboxValue(index + 1)}></input>
                                    <label htmlFor="deal1"></label>
                                </div>
                                <div className="dealsImage">

                                    <img src={nopath} alt="improve-cart" />
                                </div>
                                <div className="DealDetails">
                                    <p className="DealHead blue">Package {index + 1}</p>

                                    <p className="details blue">Driver License Direct by IMPROV driver’s ed course is a 30-hour online driver’s education program.</p>
                                </div>
                                <div className="cost">
                                    <p className="offer blue">${item}.00</p>
                                </div>
                            </div>

                        </div>
                    )
                }

            </div>
        </div>
    )
}

export default CourseLanding