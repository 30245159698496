import React from 'react';

export default function SelectedUpsellCourseList(props) {
    const { selectedUpsellCourse } = props;
    return (
        <>
            {
                selectedUpsellCourse.length > 0 && selectedUpsellCourse.map((upselObj, inx) => (
                    <div className='subtotal flex justify-content-spacebetween' key={inx}>
                        {/* <p className='gray-title'>Advanced Purchase</p> */}
                        <p>{upselObj.upsellConfigCourse.course.name}</p>
                        <p>${upselObj.basePrice.toFixed(2)}</p>
                    </div>
                ))
            }
        </>
    )
}
