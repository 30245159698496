import React, { useEffect, useState } from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "../assets/UIComponents";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useField, ErrorMessage } from "formik";
import { formLabelsTheme } from "./utils";

export default function InputSelect(props) {
  const { label, onChange, data, itemId, itemName, value, ...rest } = props;
  const [field] = useField(props);
  const [itemList, setItemList] = useState([]);
  useEffect(() => {
    setItemList(data || []);
  }, [props]);
  return (
    <>
      <FormControl variant="outlined">
        <InputLabel {...rest} id={field.name} theme={formLabelsTheme}>
          {label}
        </InputLabel>
        <Select
          IconComponent={ExpandMoreIcon}
          label={label}
          autoComplete="none"
          inputProps={{
            id: "deviceSource-native-select",
            name: "deviceSource",
          }}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            disableScrollLock: true,
          }}
          labelId={field.name}
          value={value}
          id={field.name}
          onChange={onChange}
          name={field.name}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          {...rest}
        >
          {itemList.map((row) => (
            <MenuItem key={row[itemId]} value={row[itemId]}>
              {row[itemName]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ErrorMessage component="div" className="error" name={field.name} />
    </>
  );
}
