import React, { useEffect, useState } from 'react';
import { _localStorage } from "../../constant/storage/localStorage";
import { defensiveDrivingCourseNewYorkVideoThumbnail } from '../../common/assets/Images';
import { Modal } from 'react-bootstrap';
import FastLaneFAQ from './FastLaneFAQ';
import { GenericAPIService, EncryptStorage, UrlConstants, Constants } from '../../common/imports/CommonImport';
import { GetCoursePriceDetailsPayload, } from '../../cartSummary/CardSummaryHelper/CartSummaryImport';
import LoaderWithMessage from './LoaderWithMessage';

export default function FastLaneCourseInfo(props) 
{
    const { courseDetails, internalPaymentMethodCallback, courseName, setCourseName } = props;
    const [courseHeader, setCourseHeader] = useState("");
    const [loader, setLoader] = useState(false);

    const [courseInfoIsCollapsed, setCourseInfoIsCollapsed] = useState(true);
    const [isVideoPlayerVisible, setIsVideoPlayerVisible] = useState(false);
    const courseVideoUrl = "https://www.myimprov.com/wp-content/uploads/promo-video-short.mp4";

    useEffect(() => {
      getCoursePriceDetails();
      if (courseDetails.infoHeader){
        const newHeader = removeHTMLTags(courseDetails.infoHeader);
        setCourseHeader(newHeader);
        internalPaymentMethodCallback(newHeader);
      }
    }, [courseDetails]);
    
    const handleCourseInfoToggleCollapse = () => {
      setCourseInfoIsCollapsed(!courseInfoIsCollapsed);
    };

    const getConfigCourseId = () => {
      let configCourseId = EncryptStorage.getLocalStorage("configCourseId");
      let getUrl = window.location.href;
      let splitUrl = window.location.href.includes("?")
        ? window.location.href.split("?")
        : false;
      if (configCourseId) {
        //console.log(configCourseId);
      } else {
        if (splitUrl) {
          let replaceUrl = getUrl.replace("#/", "");
          replaceUrl = replaceUrl.replace("'", "");
          let url = new URL(replaceUrl);
          configCourseId = url.searchParams.get("configCourseId")
            ? url.searchParams.get("configCourseId")
            : null;
        }
      }
      return configCourseId;
    };

    const getStateId = () => {
      let stateId = EncryptStorage.getLocalStorage("stateId");
      let getUrl = window.location.href;
      let splitUrl = window.location.href.includes("?")
        ? window.location.href.split("?")
        : false;
      if (!stateId) {
        if (splitUrl) {
          let replaceUrl = getUrl.replace("#/", "");
          replaceUrl = replaceUrl.replace("'", "");
          let url = new URL(replaceUrl);
          if (url.searchParams.get("stateId")){
            const regex = /[^0-9]/g;
            stateId = url.searchParams.get("stateId").replace(regex, "");
          } else {
            stateId = null;
          }
        }
      }
      return stateId;
    };

    const getCoursePriceDetails = async () => {
      let configCourseId = getConfigCourseId();
      if (configCourseId && courseName === "") 
      {
        setLoader(true);
        let payload = await GetCoursePriceDetailsPayload.getPayload(configCourseId);
        if (payload) {
          const url = UrlConstants.getCoursePriceDetails;
          GenericAPIService.customAPIMethod(url, payload, 'POST')
            .then(res => {
              setLoader(false);
              setCourseName(res.data.name);
            })
            .catch(err => {
              console.log(Constants.serverErrorMessage);
              console.log(err);
            })
        }
      }
    }
    const handleToggleVideoPlayer = () => {
        setIsVideoPlayerVisible(!isVideoPlayerVisible);
    };

    const createMarkup = (value) => {
      return { __html: value };
    };

    function removeHTMLTags(text) {
      const regularExp = /<p(.*?)<\/p>/;
      let updatedLabel = text.replace(regularExp, "");
      return updatedLabel;
    }

    return (
      <>
        <Modal
          show={isVideoPlayerVisible}
          onHide={handleToggleVideoPlayer}
          centered
          className='video-player-modal-register'
        >
          <Modal.Body className='video-player-modal-register-body'>
            <video
              className="video-player"
              src={courseVideoUrl}
              controls
            ></video>
            <button
              onClick={handleToggleVideoPlayer}
              className="video-player-close"
            >
              ✖
            </button>
          </Modal.Body>
        </Modal>
        {/* {loader && <LoaderWithMessage/>} */}
        <div className="course-info-container">
          <div className="course-info-desc">
          {courseDetails && (
            <div className="course-info-html-container">
              <p className='course-info-title'>{courseName}</p>
              {/* <button className='course-info-hide-button' onClick={handleCourseInfoToggleCollapse} >
                <p className='course-info-hide-button-header'>Course details</p>
                <img src={arrow_right_square} alt="" className='course-info-hide-image'/>
              </button> */}
              {
                // !courseInfoIsCollapsed && (
                //   <>
                //     <div
                //       className="course-info-html-list course-info-hide-desktop"
                //       dangerouslySetInnerHTML={createMarkup(courseDetails.info)}>
                //     </div>
                //   </>
                // )
              }
              {/* <div
                className="course-info-html-list course-info-hide-mobile"
                dangerouslySetInnerHTML={createMarkup(courseDetails.info)}>
              </div> */}
            </div>
          )}
          </div>
          <div className="course-group-image">
            <button className="watch-demo" onClick={handleToggleVideoPlayer}>
              <img
                src={defensiveDrivingCourseNewYorkVideoThumbnail}
                alt="Play Video"
                className="watch-demo-thumbnail"
              />
            </button>
          </div>
          <div className="course-info-faq-holder">
            <FastLaneFAQ
              courseDetails={courseDetails}
            />
          </div>
          <p className="faq-question hide-in-mobile">
            Every Minute You Wait Costs You $$$
          </p>
          <div
            className="course-info-html-header fast-lane-course-info-config"
            dangerouslySetInnerHTML={createMarkup(courseHeader)}>
          </div>
          {getStateId === 36 && (
            <div
              className="course-info-html-header fast-lane-course-info-config"
              dangerouslySetInnerHTML={createMarkup(courseDetails.info)}>
            </div>
          )}
        </div>
      </>
    );
}
