import React, { useEffect, useRef, useState } from "react";
import ShopperFooter from "../common/ShopperFooter";
import { _localStorage } from "../constant/storage/localStorage";
import { _const } from "../constant/const-variables/constVariable";
import { arrow_twisted_right_orange } from "../common/assets/Images";
import { FastlanePrice } from "./FastLane/FastlanePricePast";

export default function CourseInfoTabs(props) {
  const { courseInfo, courseDetailsShow } = props;
  const [courseDetails, setCourseDetails] = useState(props.courseInfo);
  const [tabs, setTabs] = useState(_const.tabOption.tab_2);
  const [accordionIndex, setAccordionIndex] = useState('accordion-0');
  const [newInfo, setNewInfo] = useState("");
  const [courseInfoTitle, setCourseInfoTitle] = useState("");
  const [courseInfoDescription, setCourseInfoDescription] = useState("");
  const myRef = useRef();
  const [hasInfoUpdated, setHasInfoUpdated] = useState(false);

  useEffect(() => {
    if (hasInfoUpdated) 
    {
      setCourseDetails(courseInfo);
    }
    setHasInfoUpdated(true);
  }, [props.courseInfo]);

  useEffect(() => {
    getShopperReview()
  }, [])

  useEffect(() => {
    getShopperReview();
  }, [props.courseDetailsShow]);

  const getShopperReview = () => {
    function saLoadScript(src) {
      let js = window.document.createElement("script");
    /*   debugger */
      js.src = src; js.type = "text/javascript";
      document.getElementsByTagName("head")[0].appendChild(js);
    }
    saLoadScript("https://www.shopperapproved.com/merchant/11612.js");
  }

  const createMarkup = (value) => {
    return { __html: value };
  };

  const mobileHandleClick = (e) => {
    setTabs(e);
  };

  const accordionChange = (accordionIndex) => {
    setAccordionIndex(`accordion-${accordionIndex}`)
  }

  const getCourseInfo = (info) => {
    setCourseInfoTitle(info.name);
    setCourseInfoDescription(info.description);
  }

  const getCorseInfoTabs = () => {
    return (
      <>
        <nav className="info-tabs">
          <div className="tab-container" id="nav-tab" role="tablist">
          {
            // <div className="course-info-price-container">
                // <div className="course-info-description-container">
                //   <p className="course-info-title">{courseInfoTitle}</p>
                //   <p className="course-info-description">
                //     {courseInfoDescription}
                //   </p>
                // </div>
                // <FastlanePrice
                //   getCourseInfo={getCourseInfo}
                // />
            // </div>
          }

            <div className="tabs">
              <span
                className={
                  tabs === _const.tabOption.tab_2 ? "tab active" : "tab"
                }
                onClick={() => mobileHandleClick(_const.tabOption.tab_2)}
              >
                Course Details
              </span>
              <span
                className={
                  tabs === _const.tabOption.tab_3 ? "tab active" : "tab"
                }
                onClick={() => mobileHandleClick(_const.tabOption.tab_3)}
              >
                Why Choose Us?
              </span>
              <span
                className={
                  tabs === _const.tabOption.tab_1 ? "tab active" : "tab"
                }
                onClick={() => mobileHandleClick(_const.tabOption.tab_1)}
              >
                FAQs
              </span>
            </div>
          </div>
        </nav>
        <div className="content">
          <div
            id="tab-1-content"
            className={
              tabs === _const.tabOption.tab_1
                ? "content-dynamic fade show active"
                : "content-dynamic fade hide-tab"
            }
          >
            <div className="accordion scroll">
              {courseDetails &&
                courseDetails.configCourseFaq.map((item, index) => (
                  <>
                    <button
                      className={`accordion-header ${
                        accordionIndex == `accordion-${index}` ? "active" : ""
                      }`}
                      id={`accordion-${index}`}
                      onClick={() => accordionChange(index)}
                    >
                      {item.faq.questionName}
                    </button>
                    <div
                      className={`accordion-body ${
                        accordionIndex == `accordion-${index}`
                          ? "accordion-body-show"
                          : ""
                      }`}
                    >
                      {item.faq.description && (
                        <div
                          className="faq-cont"
                          dangerouslySetInnerHTML={createMarkup(
                            item.faq.description
                          )}
                        ></div>
                      )}
                    </div>
                    <div className="divider-ac"></div>
                  </>
                ))}
            </div>
          </div>
          <div
            className={
              tabs === _const.tabOption.tab_2
                ? "content-dynamic fade show active"
                : "content-dynamic fade hide-tab"
            }
            id="tab-2-content"
          >
            <div className="course-details">
              {courseDetails && (
                <>
                  {courseDetails &&
                    (courseDetails.info || courseDetails.infoHeader) && (
                      <div className="show-lg">
                        <div
                          className="course-info-header"
                          dangerouslySetInnerHTML={createMarkup(
                            courseDetails.infoHeader
                          )}
                        ></div>
                        <div
                          className="course-info-list"
                          dangerouslySetInnerHTML={createMarkup(courseDetails.info)}
                        ></div>
                      </div>
                    )}
                  <div className="show-sm">
                    <div
                      className="course-info-list"
                      dangerouslySetInnerHTML={createMarkup(courseDetails.info)}
                    ></div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className={
              tabs == _const.tabOption.tab_3
                ? "content-dynamic fade show active"
                : "content-dynamic fade hide-tab"
            }
            id="tab-3-content"
          >
            <div className="course-details">
              <div className="choose-scroll scroll">
                <div id="shopper_review_page">
                  <div id="review_header"></div>
                  <div id="merchant_page"></div>
                  <div id="review_image">
                    <a
                      href="https://www.shopperapproved.com/reviews/IMPROVlearning/"
                      target="_blank"
                      rel="nofollow"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="show-lg">
            {_localStorage.isShowShopperRequired && <ShopperFooter />}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={`accordion-body-v2 ${courseDetailsShow ? 'show-sm' : 'show-lg'}`} ref={myRef}>
        {getCorseInfoTabs()}
      </div>
    </>

  );
}
