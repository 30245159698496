import React from 'react'
import { Col } from 'react-bootstrap';
import { renderFieldErrors, renderSSNFieldErrors, renderTextFieldErrors } from '../utils';
import TextField from '@material-ui/core/TextField';

export default function NewNumberFlexField(props) {

    const { row, ind, handleChange, setOtherInfoErrorMessage, errors, touched, values, setFieldValue, confirmFieldErrorMessage,
        setconfirmFieldErrorMessage, flexiFields, setFlexiFields } = props;

    const checkInputFunction = (e) => {
        return (
            row.flexiFieldName === 'SSNLASTFOUR' ?
                () => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4);
                } : null
        )
    }

    const checkMobileNumberInputFunction = (e) => {
        if (e.target.value.match(/^\d*$/)) {
            return e.target.value
        }
        else {
            e.target.value = e.target.value.slice(0, e.target.value.length - 1)
        }
    }

    const confirmValueMatch = () => {
        return (
            <>
                {(values[row.id] !== values[`CONFIRM${row.id}`] ?
                    <span className="error">
                        {`${row.id} must match`}
                    </span> : null)
                }
            </>
        )
    }

    const confirmTxtBoxEvent = (field_Name, Event) => {
        if (field_Name.replace("CONFIRM", '') === "PermitNumber") {
            setFieldValue(field_Name, Number(Event.target.value));
        }
        else {
            setFieldValue(field_Name, Event.target.value)
        }
    }

    const txtBoxEvent = (Event) => {
        if (row.id.includes("CONFIRM")) {
            let tempfieldName = row.id.replace("CONFIRM", '');
            let confirmField_ErrorMessage = confirmFieldErrorMessage;
            if (values[tempfieldName] === Event.target.value) {
                confirmField_ErrorMessage[row.id] = '';
                setconfirmFieldErrorMessage(confirmField_ErrorMessage);
            }
            else {
                confirmField_ErrorMessage[row.id] = `${tempfieldName} must match.`;
                setconfirmFieldErrorMessage(confirmField_ErrorMessage);
            }
        }
        let temp = row;
        temp.value = Event.target.value;
        let tempArray = [...flexiFields];
        tempArray[ind] = temp;
        setFlexiFields([]);
        setFlexiFields(tempArray);
    }

    const ssnTxtBoxEvent = (Event) => {
        if (Event.length <= 11) {
            let tempEvent = Event;
            if (row.value.length > Event.length) {
                tempEvent = Event;
            }
            else if (tempEvent.length === 3) {
                tempEvent = tempEvent + '-'
            }
            else if (tempEvent.length === 6 && tempEvent.includes('-')) {
                tempEvent = tempEvent + '-';
            }
            let temp = row;
            temp.value = tempEvent;
            let tempArray = [...flexiFields];
            tempArray[ind] = temp;
            setFlexiFields([]);
            setFlexiFields(tempArray);
        }
    }

    const renderNonRequiredNumberField = () => {
        if (row.flexiFieldName.trim() === 'SSN') {
            return renderSsnField();
        }
        else {
            if (row.flexiFieldName && row.flexiFieldName.toLocaleLowerCase().includes('phone' || 'mobile')) {
                return (
                    // <Col md={12} lg={6}>
                    <div className="field-col">
                        {/* <div className="custom-form-control"> */}
                        {props.isFastLane && (
                            <p className="profile-register-field-label">
                                {row.flexiFieldDescription}
                            </p>
                        )}
                        <input type="text" required={row.isMandatoryField} name={row.id} className="custom-form-control" placeholder={`${row.label.toLocaleLowerCase()} `} value={row.value} defaultValue={row.value}
                            onInput={(e) => checkMobileNumberInputFunction(e)} autoComplete="none" onChange={e => { handleChange(e); txtBoxEvent(e); setOtherInfoErrorMessage(''); }} />
                        {/* <TextField required={row.isMandatoryField} type="string" id="outlined-multiline-static" placeholder={`${row.label.toLocaleLowerCase()} `}
                            name={row.id} variant="outlined" autoComplete="none" value={row.value} defaultValue={row.value}
                            onInput={(e) => checkMobileNumberInputFunction(e)}
                            onChange={e => { handleChange(e); txtBoxEvent(e); setOtherInfoErrorMessage(''); }} /> */}
                        {renderTextFieldErrors(errors, row, touched)}
                        {/* </div> */}
                    </div>
                    // </Col>
                )
            }
            else {
                return (
                    // <Col md={12} lg={6}>
                    <div className="field-col">
                        {/* <div className="custom-form-control"> */}
                        {props.isFastLane && (
                            <p className="profile-register-field-label">
                                {row.flexiFieldDescription}
                            </p>
                        )}
                        <input required={row.isMandatoryField} type="number" name={row.id} className="custom-form-control" placeholder={`${row.label.toLocaleLowerCase()} `} value={row.value} defaultValue={row.value}
                            onInput={(e) => checkInputFunction(e)} autoComplete="none" onChange={e => { handleChange(e); txtBoxEvent(e); setOtherInfoErrorMessage(''); }} />
                        {/* <TextField required={row.isMandatoryField} type="number" id="outlined-multiline-static" placeholder={`${row.label.toLocaleLowerCase()} `}
                            name={row.id} variant="outlined" autoComplete="none" value={row.value} defaultValue={row.value}
                            onInput={(e) => checkInputFunction(e)}
                            onChange={e => { handleChange(e); txtBoxEvent(e); setOtherInfoErrorMessage(''); }} /> */}
                        {renderFieldErrors(errors, row, touched)}
                        {/* </div> */}
                    </div>
                    // </Col>
                )
            }
        }
    }

    const renderRequiredNumberfield = () => {
        return (
            <>
                {/* <Col md={12} lg={6}> */}
                <div className="field-col">
                    {/* <div className="custom-form-control"> */}
                    {props.isFastLane && (
                        <p className="profile-register-field-label">
                            {row.flexiFieldDescription}
                        </p>
                    )}
                    <input required={row.isMandatoryField} type="number" name={row.id} className="custom-form-control" placeholder={`${row.label.toLocaleLowerCase()} `} value={row.value} defaultValue={row.value}
                        onInput={(e) => checkInputFunction(e)} autoComplete="none" onChange={e => {
                            handleChange(e);
                            txtBoxEvent(e);
                            confirmTxtBoxEvent(`CONFIRM${row.id}`, e);
                            setOtherInfoErrorMessage('');
                        }} />
                    {/* <TextField required={row.isMandatoryField} type="number" id="outlined-multiline-static" placeholder={`${row.label.toLocaleLowerCase()} `}
                        name={row.id} variant="outlined" autoComplete="none" value={row.value} defaultValue={row.value}
                        onInput={(e) => checkInputFunction(e)}

                        onChange={e => {
                            handleChange(e);
                            txtBoxEvent(e);
                            confirmTxtBoxEvent(`CONFIRM${row.id}`, e);
                            setOtherInfoErrorMessage('');
                        }}
                    /> */}
                    {renderFieldErrors(errors, row, touched)}
                    {/* </div> */}
                </div>
                {/* </Col> */}
                {/* <Col md={12} lg={6}> */}
                <div className="field-col">
                    {props.isFastLane && (
                        <p className="profile-register-field-label">
                            {row.flexiFieldDescription}
                        </p>
                    )}
                    <input required={row.isMandatoryField} type="number" name={`CONFIRM${row.id}`} className="custom-form-control" placeholder={`confirm ${row.label.toLocaleLowerCase()} `} value={row.value} defaultValue={row.value}
                        onInput={(e) => checkInputFunction(e)} autoComplete="none" onChange={e => { handleChange(e); confirmTxtBoxEvent(`CONFIRM${row.id}`, e); setOtherInfoErrorMessage(''); }} />
                    {/* <div className="custom-form-control"> */}
                    {/* <TextField required={row.isMandatoryField} type="number" id="outlined-multiline-static" label={`Confirm ${row.label} `}
                        name={`CONFIRM${row.id}`} variant="outlined" autoComplete="none" defaultValue={row.value}
                        onInput={(e) => checkInputFunction(e)}
                        onChange={e => { handleChange(e); confirmTxtBoxEvent(`CONFIRM${row.id}`, e); setOtherInfoErrorMessage(''); }} /> */}
                    {confirmValueMatch()}
                    {/* </div> */}
                </div>
                {/* </Col> */}
            </>
        )
    }

    const renderSsnField = () => {
        return (
            // <div className="field-col">
            //                 <input name={row.id} placeholder={`${row.label} `} value={row.value}
            //                     className="custom-form-control"
            //                     readOnly={checkVehicleTag()}
            //                     onChange={e => {
            //                         handleChange(e);
            //                         txtBoxEvent(e);
            //                         confirmTxtBoxEvent(`CONFIRM${row.id}`, e);
            //                         setOtherInfoErrorMessage('');
            //                     }} autoComplete="none" />
            //                 {renderTextFieldErrors(errors, row, touched, confirmFieldErrorMessage)}
            //                 {getClearIcon()}
            //             </div>
            <div className="field-col">
                {/* <div className="custom-form-control"> */}
                {props.isFastLane && (
                    <p className="profile-register-field-label">
                        {row.flexiFieldDescription}
                    </p>
                )}
                <input required={row.isMandatoryField} type="text" name={row.id} className="custom-form-control" placeholder={`${row.label.toLocaleLowerCase()} `} value={row.value} defaultValue={row.value}
                    onChange={e => { handleChange(e); ssnTxtBoxEvent(e.target.value); setOtherInfoErrorMessage(''); }} autoComplete="none" />
                {/* <TextField required={row.isMandatoryField} id="outlined-multiline-static" placeholder={`${row.label.toLocaleLowerCase()} `}
                    name={row.id} variant="outlined" autoComplete="none" value={row.value} defaultValue={row.value}

                    onChange={e => { handleChange(e); ssnTxtBoxEvent(e.target.value); setOtherInfoErrorMessage(''); }} /> */}
                {renderSSNFieldErrors(errors, row, touched)}
                {/* </div> */}
            </div>
        )
    }

    return (
        <>
            {
                row.isValidationRequired ? (
                    renderRequiredNumberfield()
                ) : (
                    renderNonRequiredNumberField()
                )
            }
        </>
    )
}
