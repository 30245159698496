import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { EncryptStorage } from '../../../services/EncryptStorage';
import { GenericAPIService } from '../../../services/GenericAPIService';
import { Constants, PropagateLoader, UrlConstants } from '../../common/imports/CommonImport';
import { ToastContainer, toast } from 'react-toastify';
import password from './../../../assets/images/password.png'
import 'react-toastify/dist/ReactToastify.css';
import { loginHelper } from '../../login/loginHelper';

export default function EmailVerification(props) {

    const numInputs = 6
    const { emailVerification, handleClose, history, studentId, loginResponse, paymentProcess } = props;
    const [loader, setLoader] = useState(false);
    const [verificationCode, setVerificationCode] = useState('')
    const [isDisabled, setIsDisabled] = useState(true)
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const re = /^[0-9\b]+$/;
        let { value } = e.target;
        if (value === '' || re.test(value)) {
            setVerificationCode(value)
        }
        let codeLength = value.length !== numInputs ? verificationCode.length + 1 : value.length
        if (numInputs === codeLength) {
            setErrorMessage("")
            setIsDisabled(false)
        }
        else {
            setIsDisabled(true)
        }
    }

    const onSubmitVerificationCode = () => {
        setLoader(true)
        let redirectUrl = EncryptStorage.getLocalStorage('redirectUrlName')
        const payload = {};
        let pathVariable = studentId ? studentId : EncryptStorage.getLocalStorage("studentId")
        let paramsValue = `?code=${verificationCode}`
        const url = UrlConstants.emailVerificationUrl + pathVariable + paramsValue;
        GenericAPIService.customAPIMethodClone(url, payload, 'PUT').then(res => {
            if (res.status.success.toLowerCase() === 'success') {
                if (loginResponse) {
                    loginHelper.assignDefaultStorageValues(loginResponse)
                }
                let tokenCopy = EncryptStorage.decryptOnlyKey("tokenCopy")
                EncryptStorage.encryptOnlyKey("token", tokenCopy);
                if (emailVerification == 'paymentProcess') {
                    paymentProcess();
                } else {
                    history.push(redirectUrl);
                }
            } else {
                setErrorMessage(res.status.message);
                setLoader(false);
            }
        }).catch(err => {
            setLoader(false)
            setErrorMessage(Constants.serverErrorMessage)
        });
    }

    const resendVerificationEmail = () => {
        setLoader(true)
        const payload = {};
        let _studentId = EncryptStorage.getLocalStorage("studentId");
        let loginStudentId = loginResponse && loginResponse.data.student.studentId;
        let pathVariable = _studentId ? _studentId : loginStudentId;
        const url = UrlConstants.emailResendVerificationUrl + pathVariable;
        GenericAPIService.customAPIMethodClone(url, payload, 'PUT').then(res => {
            setLoader(false)
            if (res.status.success.toLowerCase() === 'success') {
                setErrorMessage("")
                setVerificationCode("")
                setIsDisabled(false)
                toast(res.status.message);
            } else {
                toast(res.status.message);
            }
        }).catch(err => {
            setLoader(false)
            setErrorMessage(Constants.serverErrorMessage)
        });
    }

    return (
        <div>
            <ToastContainer />
            <Modal show={emailVerification === true || emailVerification == 'paymentProcess' ? true : false}
                onHide={handleClose} centered backdrop="static"
                keyboard={false} className="emailVerify-modal white-rounded-modal">
                <Modal.Body className="modal-inside-loader">
                    {loader && <div className="loader">
                        <PropagateLoader size={15} />
                    </div>}
                    <div className='content d-flex justify-content-between'>
                        <div className="left">
                            <img src={password} />
                        </div>
                        <div className="right">
                            <h3>Two Factor Authentication</h3>
                            <p>A verification code has been sent to your email. This will be valid for 15 minutes.</p>
                            <div className="">
                                <div className="validationEmailInput">
                                    <input maxLength={6} type="text" pattern="[0-9]*" name="verificationEmail" className="form-verificationEmail" placeholder="Enter Code"
                                        value={verificationCode} onChange={handleChange} />
                                    <button type="submit" className="submitVerificationButton" disabled={isDisabled} onClick={onSubmitVerificationCode}>VERIFY</button>
                                </div>
                            </div>
                            <p className="underLine cursor-pointer" onClick={resendVerificationEmail}>Resend verification email</p>
                            <div className="validationErrorMessage">
                                <p className="mt-1 mb-0 error">{errorMessage}</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
} 
