module.exports = {
    "baseUrl": "https://studentback.spiderlms.com/core/api/v1",
    "countyAgencyUrl": "https://studentback.spiderlms.com.cloudnowtech.com/core/api/v1",
    "profileName": "Newstage",
    "bucketUrl": "https://elmscontentfrontdoor-g6aedycecufbbedq.z01.azurefd.net/",
    "baseUIUrl": "https://student.spiderlms.com",
    "ocp_Apim_Subscription_Key": "d5056a1f5fd94bada2f1bc00ecd4359b",
    "ocp_Apim_Subscription_Key_admin": "15ccb1057b39459dbf994f8b34820d46",
    "imageBaseUrl": "https://elmscontentfrontdoor-g6aedycecufbbedq.z01.azurefd.net/",
    "downloadCertificateUrl": "https://studentback.spiderlms.com/core/api/v1/improv-student-course-dashboard/student/student-course-completed-certificate",
    "downloadPaymentReceiptUrl": "https://student.westus.cloudapp.azure.com/core/api/v1/improv-student/student-certificate/receipt?",
    "comm100ButtonID": "comm100-button-474b4c00-0000-0000-0000-008e000189f9",
    "elmsAdminUrl": "https://adminback.spiderlms.com/core/api/v1",
    "freshdeskDomain": "improvlearning",
    "freshdeskDomainApiKey": "rTIsZ2SdCBDjFi3O9cTx",
    "websocketConnectUrl": "https://student.westus.cloudapp.azure.com/websocket/connect",
    "shopperSurveySite": 11612,
    "shopperSurveyToken": "840aa",
    "newAdminUrl": "https://student.westus.cloudapp.azure.com/core/api/v1",
    "courseConfigUrl": "https://apim-improv-elms-student-prod-eastus.azure-api.net/core/api/v1",
    "courseBucketUrl": "https://saimprovstduieastusprod.azureedge.net/",
    "domainUrl": "https://student.spiderlms.com",
    "nonHashedUrls": [
        "http://testdevgsa.spiderlms.com/"
    ],
    "newChapterContentStateIds": [
        "36"
    ],
    "newChapterContentConfigCourseIds": [
        "838",
        "844",
        "845",
        "465",
        "598",
        "620",
        "641",
        "642",
        "784",
        "840",
        "834",
        "565",
        "572",
        "601",
        "699",
        "889",
        "862",
        "977",
        "980",
        "816",
        "717",
        "385",
        "1022",
        "713",
        "968",
        "425",
        "422",
        "435",
        "883",
        "693",
        "692",
        "611",
        "454",
        "823",
        "714",
        "710",
        "1050",
        "377",
        "399",
        "410",
        "418",
        "428",
        "429",
        "430",
        "431",
        "432",
        "438",
        "459",
        "463",
        "606",
        "626",
        "634",
        "639",
        "643",
        "644",
        "645",
        "646",
        "670",
        "671",
        "683",
        "774",
        "802",
        "803",
        "851",
        "861",
        "903",
        "904",
        "911",
        "951",
        "992",
        "1023",
        "1024",
        "386",
        "460",
        "679",
        "514",
        "599",
        "630",
        "610",
        "621",
        "628",
        "629",
        "650",
        "651",
        "652",
        "804",
        "860",
        "863",
        "944",
        "956",
        "974",
        "709",
        "755",
        "789",
        "1032",
        "1057",
        "1058",
        "1059",
        "1060",
        "685",
        "763",
        "767",
        "770",
        "773",
        "780",
        "787",
        "791",
        "794",
        "797",
        "829",
        "841",
        "847",
        "853",
        "856",
        "867",
        "875",
        "878",
        "902",
        "913",
        "918",
        "924",
        "932",
        "935",
        "941",
        "950",
        "954",
        "959",
        "962",
        "965",
        "1005",
        "1042",
        "1048",
        "776",
        "777",
        "790",
        "996",
        "1033",
        "1034",
        "1035",
        "1036",
        "1037",
        "1060",
        "1076",
        "824",
        "1363",
        "1466",
        "825",
        "1410",
        "1434",
        "1449"
    ],
    "newBucketUrl": "https://saimprovstduieastusprod.blob.core.windows.net/improv-prod/",
    "sandboxTilled": false,
    "sandBoxPayments": false,
    "sandboxApplePay": false,
    "loginUrl": "https://adminback.spiderlms.com/core/api/v1",
    "tilledApiUrl": "https://api.tilled.com/v1/payment-methods/"
}