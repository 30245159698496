import React from 'react';

export default function Footer() {
    
    return (
        <footer>
            <div className="container">
                <p className="text-center"> &#169; 2022 Interactive Education Concepts Inc. All rights reserved.</p>
            </div>
        </footer>
    )
}
