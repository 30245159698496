import React from 'react'
import PropagateLoader from "react-spinners/PropagateLoader";

export default function CustomLoader() {
    
    return (
        <div className="loader"> 
            <PropagateLoader size={15} />
        </div>         
    )
}