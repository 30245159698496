import React, { useEffect, useState } from 'react'
import { TextField } from '../assets/UIComponents'
import { useField, ErrorMessage } from 'formik';
import { formLabelsTheme } from './utils';

export default function TextInputField({ label, ...props }) {

  const [field] = useField(props);
  const [type, setType] = useState('text');

  useEffect(() => {
    setType(props.type || 'text')
  }, [props]);

  return (
    <>
      <TextField theme={formLabelsTheme} id={field.name} label={label} type={type} variant="standard"
        rows={field.rows} {...props} {...field} placeholder={label} inputProps={{
          autoComplete: 'off',
        }} />
      <ErrorMessage component='div' className="error" name={field.name} />
    </>
  )
}
