import React, { useEffect, useState } from "react";
import { EncryptStorage } from "../common/imports/CommonImport";
import Header from "./page-layouts/Header";
import Sidebar from "./Sidebar";
import HomeRouting from "./HomeRouting";
import Steppers from "../common/Steppers";
import IdealSessionLogout from "./IdealSessionLogout";
import MobileNavigation from "./mobile-header/MobileNavigation";
import HeaderPublic from "./page-layouts/HeaderPublic";
import { CourseTimerProvider } from "../../context";
// import environment from "../../environments/environment";

const HomeComponent = (props) => {
   const { history } = props;
   const [sessionLogin, setSessionLogin] = useState(EncryptStorage.getLocalStorage("sessionTime"));
   const [isReachedDashboard, setIsReachedDashboard] = useState(false);
   const [shadowClass, setShadowClass] = useState("");
   const [parentEmailFlow, setParentEmailFlow] = useState(false);
   const [headerOptions, setHeaderOptions] = useState("1");
   const [sidebarToggle, setSidebarToggle] = useState(false);
   const [resetPadding, setResetPadding] = useState("");
   const [_addingHeaderPadding, _setAddingHeaderPadding] = useState("");

   useEffect(() => {
      let localStore_ParentEmail = EncryptStorage.getLocalStorage('parentEmailFlow');
      setParentEmailFlow(localStore_ParentEmail);
   }, [])

   useEffect(() => {
      let _sessionTime = EncryptStorage.getLocalStorage("sessionTime");
      setSessionLogin(_sessionTime);
      if (history.location.pathname === "/home/dashboard") {
         EncryptStorage.setToLocalStorage("isReachedDashboard", "true");
      }
      const _isReachedDashboard = EncryptStorage.getLocalStorage("isReachedDashboard");
      setIsReachedDashboard(_isReachedDashboard);
      setClassName(history.location.pathname);
      setHeaderOption(history.location.pathname);
      resetPaddingValue(history.location.pathname);
      addingHeaderPadding(history.location.pathname);
   }, [props.match]);

   const setClassName = (path) => {
      switch (path) {
         case pageBackgroundWhite.find((item) => item === path):
            setShadowClass("white");
            break;
         case pageBackgroundBlue.find((item) => item === path):
            setShadowClass("lighten-blue");
            break;
         case pageBackgroundGreen.find((item) => item === path):
            setShadowClass("lighten-green");
            break;
         case pageBackgroundWave.find((item) => item === path):
            setShadowClass("lighten-wave");
            break;
         default:
            setShadowClass("lighten-blue");
            break;
      }
   };

   const setHeaderOption = (path) => {
      switch (path) {
         case routerNames.find((item) => item === path):
            setHeaderOptions("public");
            break;
         case otherRouterName.find((item) => item === path):
            setHeaderOptions("no-header");
            break;
         default:
            setHeaderOptions("no-header");
            break;
      }
   };

   const resetPaddingValue = (path) => {
      switch (path) {
         case reset.find((item) => item === path):
            setResetPadding("reset-padding");
            break;
         case reset.find((item) => item !== path):
            setResetPadding("");
            break;
         default:
            setResetPadding("");
            break;
      }
   };

   const addingHeaderPadding = (path) => {
      switch (path) {
         case addingHeader.find((item) => item === path):
            _setAddingHeaderPadding("addingHeaderPadding");
            break;
         case addingHeader.find((item) => item !== path):
            _setAddingHeaderPadding("");
            break;
         default:
            _setAddingHeaderPadding("");
            break;
      }
   };

   const handleToggle = () => {
      setSidebarToggle(!sidebarToggle);
   };

   const activeElement = (e) => {
      let url = history.location.pathname;
      if (
         url === "/home/az-cart-summary" ||
         url === "/home/file-documents" ||
         url === "/home/eligibility" ||
         url === "/home/az-upsell" ||
         url === "/home/az-registration-status"
      )
         return e;
   };

   const resetLeftPadding = () => RESET_LEFT_PADDING.includes(history.location.pathname) ? 'reset-left-padding' : '';
   const resetOnlyLeftPadding = () => RESET_ONLY_LEFT_PADDING.includes(history.location.pathname) ? 'reset-only-left-padding' : '';

   const resetPosition = () => {
      if (history.location.pathname === '/home/cart-summary' || history.location.pathname === "/home/upsell-cart-summary" || history.location.pathname === "/home/fast-lane-cart-summary" || history.location.pathname === "/home/parent-cart-summary" || history.location.pathname === "/home/review-cart-and-checkout") {
         return 'reset-position';
      }
      else {
         return ''
      }
   }

   return (
      <CourseTimerProvider>
      <div className={`pageBackground ${shadowClass}  ${sidebarToggle ? "sidebar-toggle" : ""} `}>
         <div className={`header-container ${headerOptions} ${resetPosition()}`}>{headerOptions === "no-header" ? <Header {...props} isReachedDashboard={isReachedDashboard} /> : <HeaderPublic />}</div>
         {history.location.pathname === "/home/dashboard" ||
            history.location.pathname === "/home/billing" ||
            history.location.pathname === "/home/profile" ||
            history.location.pathname === "/home/settings" ||
            history.location.pathname === "/home/support" ||
            history.location.pathname === "/home/customer-support" ||
            history.location.pathname === "/home/take-survey" ||
            history.location.pathname === "/home/security-quiz-verification" ||
            history.location.pathname === "/home/breakTime" ||
            history.location.pathname === "/home/webcam" ||
            history.location.pathname === "/home/initialWebcamModel" ||
            history.location.pathname === "/home/typing-verification-message" ||
            history.location.pathname === "/home/typing-pattern-verification" ||
            history.location.pathname === "/home/chapter-launch" ||
            history.location.pathname === "/home/course-list" ||
            history.location.pathname === "/home/otp-configuration" ||
            history.location.pathname === "/home/typing-pattern-save" ? (
            <>
               <Sidebar {...props} handleToggle={handleToggle} sidebarToggle={sidebarToggle} />
            </>
         ) : null}
         <div className={`main-container ${resetPadding} ${_addingHeaderPadding} ${resetLeftPadding()} ${resetOnlyLeftPadding()} ${parentEmailFlow && 'parent-container'}`}>
            {activeElement(<Steppers />)}
            {history.location.pathname === "/home/dashboard" ||
               history.location.pathname === "/home/billing" ||
               history.location.pathname === "/home/profile" ||
               history.location.pathname === "/home/settings" ||
               history.location.pathname === "/home/support" ||
               history.location.pathname === "/home/customer-support" ||
               history.location.pathname === "/home/take-survey" ||
               history.location.pathname === "/home/security-quiz-verification" ||
               history.location.pathname === "/home/breakTime" ||
               history.location.pathname === "/home/webcam" ||
               history.location.pathname === "/home/initialWebcamModel" ||
               history.location.pathname === "/home/typing-verification-message" ||
               history.location.pathname === "/home/typing-pattern-verification" ||
               history.location.pathname === "/home/chapter-launch" ||
               history.location.pathname === "/home/course-list" ||
               history.location.pathname === "/home/otp-configuration" ||
               history.location.pathname === "/home/typing-pattern-save" ? (
               <MobileNavigation handleToggle={handleToggle} />
            ) : null}

            <HomeRouting {...props} />
            {sessionLogin ? <IdealSessionLogout {...props} /> : null}
         </div>
      </div>
      </CourseTimerProvider>
   );
};
export default HomeComponent;

export const routerNames = [
   "/home/documents",
   "/home/syllabus",
   "/home/upsell",
   "/home/chapter-quiz",
   "/home/final-quiz",
   "/home/forgot-password",
   "/home/reset-password",
   "/home/chapter",
   "/home/myorders",
   "/home/cart-summary",
   "/home/az-cart-summary",
   "/home/fast-lane-cart-summary",
   "/home/upsell-cart-summary",
   "/home/personal-information",
   "/home/fast-lane-personal-information",
   "/home/parent-verification",
   "/home/cart-summary-schedule",
   "/home/security-quiz-save",
   "/home/parent-cart-summary",
   "/home/special-offers",
   "/home/refund",
   "/home/refund-summary",
   "/home/review-cart-and-checkout",
   "/home/change-password",
   "/home/view-billing-details",
   "/home/file-documents",
   "/home/eligibility",
   "/home/az-upsell",
   "/home/az-registration-status",
];

export const pageBackgroundBlue = [
   "/home/documents",
   "/home/syllabus",
   "/home/upsell",
   "/home/chapter-quiz",
   "/home/final-quiz",
   "/home/forgot-password",
   "/home/reset-password",
   "/home/chapter",
   "/home/myorders",
   "/home/personal-information",
   "/home/fast-lane-personal-information",
   "/home/parent-verification",
   "/home/cart-summary-schedule",
   "/home/security-quiz-save",
   "/home/special-offers",
   "/home/refund",
   "/home/refund-summary",
   "/home/change-password",
   "/home/dashboard",
   "/home/profile",
   "/home/course-list",
   "/home/customer-support",
   "/home/support",
   "/home/settings",
   "/home/typing-pattern-save",
   "/home/typing-verification-message",
   "/home/typing-pattern-verification",
   "/home/chapter-launch",
   "/home/security-quiz-verification",
   "/home/billing",
];
export const pageBackgroundGreen = [
   "/home/cart-summary",
   "/home/az-cart-summary",
   "/home/fast-lane-cart-summary",
   "/home/upsell-cart-summary",
   "/home/review-cart-and-checkout",
   "/home/view-billing-details",
   "/home/parent-cart-summary",
];

export const pageBackgroundWave = ["/home/file-documents", "/home/eligibility", "/home/az-upsell", "/home/az-registration-status"];

export const pageBackgroundWhite = ["security-quiz-save", "/home/take-survey"];

export const addingHeader = [
   "/home/cart-summary",
   "/home/az-cart-summary",
   "/home/fast-lane-cart-summary",
   "/home/upsell-cart-summary",
   "/home/parent-cart-summary",
   "/home/review-cart-and-checkout",
   "/home/view-billing-details",
   "/home/personal-information",
   "/home/fast-lane-personal-information",
   "/home/parent-verification",
   "/home/change-password",
   "/home/chapter-quiz",
   "/home/final-quiz",
   "/home/file-documents",
   "/home/eligibility",
   "/home/az-upsell",
   "/home/az-registration-status",
];

export const otherRouterName = [
   "/home/dashboard",
   "security-quiz-save",
   "/home/take-survey",
   "/home/billing",
   "/home/profile",
   "/home/customer-support",
   "/home/support",
   "/home/settings",
   "/home/course-list",
   "/home/typing-pattern-save",
   "/home/typing-verification-message",
   "/home/typing-pattern-verification",
   "/home/chapter-launch",
   "/home/security-quiz-verification",
   "/home/breakTime",
   "/home/webcam",
   "/home/initialWebcamModel",
];

export const headerRouterNames = [
   "/home/review-cart-and-checkout",
   "/home/view-billing-details",
   "/home/file-documents",
   "/home/eligibility",
   "/home/az-upsell",
   "/home/upsell-cart-summary",
   "/home/az-registration-status",
];

export const reset = ["/home/security-quiz-save", "/home/chapter-quiz", "/home/final-quiz", "/home/change-password", "/home/refund"];

const RESET_LEFT_PADDING = [
   "/home/cart-summary",
   "/home/upsell-cart-summary",
   "/home/fast-lane-cart-summary",
   "/home/parent-cart-summary",
   "/home/review-cart-and-checkout",
]

const RESET_ONLY_LEFT_PADDING = [
   "/home/fast-lane-personal-information",
]