import React from 'react'

export default function FinalPriceCard(props) {
	
    return (
        <>
            <div className="total-cart-summary-separator"></div>
            <div className="total-cart-summary-container">
                <p className="total-cart-summary-tag">TOTAL</p>
                <p className="total-cart-summary-price">${props.finalPrice.toFixed(2)}</p>
            </div>
        </>
        
    )
}
