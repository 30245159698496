import axios from "axios";
import environment from "../environments/environment";
import { EncryptStorage } from "./EncryptStorage";
let CancelToken = axios.CancelToken;
let cancel;

const baseUrl = environment.baseUrl;
const loginUrl = environment.loginUrl;
const elmsAdminUrl = environment.elmsAdminUrl;
const ocp_Apim_Subscription_Key = environment["ocp_Apim_Subscription_Key"];
const ocp_Apim_Subscription_Key_admin =
  environment["ocp_Apim_Subscription_Key_admin"];
const freshdesk_api_key = environment.freshdeskDomainApiKey;
//api_key

axios.interceptors.request.use(
  (config) => {
    const _token = EncryptStorage.decryptOnlyKey("token");
    const studentId = EncryptStorage.getLocalStorage("studentId");
    config.headers["Ocp-Apim-Subscription-Key"] =
      config.headers["Ocp-Apim-Subscription-Key"] || ocp_Apim_Subscription_Key;

    if (_token) {
      config.headers.AUTHORIZATION = `Bearer ${_token}`;
      if (EncryptStorage.getLocalStorage("parentEmailFlow")) {
        config.headers.AUTHORIZATION = `${_token}`;
      }
    }
    if (studentId) {
      config.headers.studentId = studentId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (
        response.data.status &&
        response.data.status.message === "Access Denied"
      ) {
        localStorage.clear();
        window.localStorage.clear();
        localStorage.setItem("IsAccessDenied", true);
        if (window.location.hash !== "#/") {
          window.location = "#/login";
        }
      }
    }
    return response;
  },
  (error) => {
    // THIS IS SPECIFIC FOR THE LOGIN CALL
    const loginRequestUrl =
      loginUrl +
      "/improv-node-auth/auth/improv-student-course-dashboard/student/login";

    if (
      error.response.request.responseURL === loginRequestUrl &&
      error.response.status === 401
    ) {
      return Promise.reject(error);
    }
    if (
      error.response &&
      error.response.data &&
      error.response.data.error &&
      (error.response.data.session === false ||
        error.response.data.session === "false")
    ) {
      localStorage.clear();
      window.localStorage.clear();
      if (window.location.hash !== "#/") {
        window.location = "#/login";
      }
    }
    // https://stackoverflow.com/questions/59484512/logout-if-token-is-expired
    else if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.localStorage.clear();
      if (window.location.hash != "#/") {
        window.location = "#/login";
      }
    } else return Promise.reject(error);
  }
);

const post = (apiUrl, payload) => {
  let url = baseUrl + apiUrl;
  return axios(url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
const postAddess = (apiUrl, payload) => {
  let url = apiUrl;
  return axios(url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
const put = (apiUrl, payload) => {
  let url = baseUrl + apiUrl;
  return axios(url, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

const emailReceipt = (apiUrl) => {
  let url = apiUrl;
  return axios(url, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Ocp-Apim-Subscription-Key": ocp_Apim_Subscription_Key_admin,
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

const imageUpload = (apiUrl, payload, method) => {
  let url = baseUrl + apiUrl;
  return axios(url, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data;",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

const get = (apiUrl) => {
  let url = baseUrl + apiUrl;
  return axios(url, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
const getWithoutBaseUrl = (apiUrl) => {
  let url = apiUrl;
  return axios(url, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

const getListBySearch = (apiUrl) => {
  let url = baseUrl + apiUrl;
  if (cancel !== undefined) cancel();
  return axios(url, {
    method: "GET",
    cancelToken: new CancelToken((c) => (cancel = c)),
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

const deleteApi = (apiUrl, payload) => {
  let url = baseUrl + apiUrl;
  return axios(url, {
    method: "DELETE",
    data: payload,
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

const customAPIMethod = (apiUrl, payload, method) => {
  // let url = (apiUrl.includes('http') && apiUrl) || baseUrl + apiUrl;
  let url = baseUrl + apiUrl;
  return axios(url, {
    method: method,
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
const loginMethod = (apiUrl, payload, method) => {
  //THIS URL SHOULD CHANGE FOR PROD THIS IS ONLY FOR THE BRANCH newstage
  let url = loginUrl + apiUrl;
  return axios(url, {
    method: method,
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
const imageUploadClone = (apiUrl, payload, method) => {
  let url = baseUrl + apiUrl;
  return axios(url, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      token: "123123",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type",
      "Ocp-Apim-Subscription-Key": ocp_Apim_Subscription_Key_admin,
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
const prodImageUploadClone = (apiUrl, payload, method) => {
  let url = baseUrl + apiUrl;
  return axios(url, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      token: "123123",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type",
      "Ocp-Apim-Subscription-Key": ocp_Apim_Subscription_Key_admin,
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
const customAPIMethodClone = (apiUrl, payload, method) => {
  let url = baseUrl + apiUrl;
  return axios(url, {
    method: method,
    headers: {
      "content-type": "application/json",
      token: "123123",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type",
      "Ocp-Apim-Subscription-Key": ocp_Apim_Subscription_Key_admin,
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
const customAPIMethodPaymentNew = (apiUrl, payload, method) => {
  let url = baseUrl + apiUrl;
  return axios(url, {
    method: method,
    headers: {
      "content-type": "application/json",
      token: "123123",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type",
      studentId: EncryptStorage.getLocalStorage("studentId"),
      "Ocp-Apim-Subscription-Key": ocp_Apim_Subscription_Key_admin,
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
const customAPIMethodPaymentGet = (apiUrl) => {
  let url = baseUrl + apiUrl;
  return axios(url, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: "123123",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET",
      "Access-Control-Allow-Headers": "Content-Type",
      studentId: EncryptStorage.getLocalStorage("studentId"),
      "Ocp-Apim-Subscription-Key": ocp_Apim_Subscription_Key_admin,
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
const customAPIMethodCloneNew = (apiUrl, payload, method) => {
  let url = elmsAdminUrl + apiUrl;
  return axios(url, {
    method: method,
    headers: {
      "content-type": "application/json",
      token: "123123",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type",
      "Ocp-Apim-Subscription-Key": ocp_Apim_Subscription_Key_admin,
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
const fresdeshPostData = (apiUrl, payload) => {
  let url = apiUrl;
  return axios(url, {
    method: "POST",
    headers: {
      Authorization: "Basic " + btoa(freshdesk_api_key + ":x"),
      contentType: "application/json; charset=utf-8",
      dataType: "json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
const fresdeshPostDataWithImg = (apiUrl, payload) => {
  let url = apiUrl;
  return axios(url, {
    method: "POST",
    contentType: false,
    processData: false,
    headers: {
      Authorization: "Basic " + btoa(freshdesk_api_key + ":x"),
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
const fresdeshGet = (apiUrl) => {
  let url = apiUrl;
  return axios(url, {
    method: "GET",
    contentType: false,
    processData: false,
    headers: {
      Authorization: "Basic " + btoa(freshdesk_api_key + ":x"),
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

const azFlowUrlConfig = (apiUrl, payload, method) => {
  let url = baseUrl + apiUrl;
  return axios(url, {
    method: method,
    headers: {
      "content-type": "application/json",
      token: "123123",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type",
      "Ocp-Apim-Subscription-Key": ocp_Apim_Subscription_Key_admin,
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

const adminUrlConfig = (apiUrl, payload, method) => {
  let _url = baseUrl + apiUrl;
  return axios(_url, {
    method: method,
    headers: {
      "content-type": "application/json",
      token: "123123",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type",
      "Ocp-Apim-Subscription-Key": ocp_Apim_Subscription_Key_admin,
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const GenericAPIService = {
  post,
  get,
  deleteApi,
  imageUpload,
  put,
  customAPIMethod,
  imageUploadClone,
  getListBySearch,
  getWithoutBaseUrl,
  customAPIMethodClone,
  customAPIMethodCloneNew,
  fresdeshPostData,
  fresdeshPostDataWithImg,
  fresdeshGet,
  emailReceipt,
  customAPIMethodPaymentNew,
  postAddess,
  azFlowUrlConfig,
  customAPIMethodPaymentGet,
  prodImageUploadClone,
  adminUrlConfig,
  loginMethod,
};
