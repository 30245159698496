import React, { useState, useEffect, useMemo } from 'react';
import { renderFieldErrors } from '../utils';
import { Constants, GenericAPIService, UrlConstants, EncryptStorage } from '../../../common/imports/CommonImport';
import environment from '../../../../../src/environments/environment';
import PropagateLoader from 'react-spinners/PropagateLoader';

export default function Test(props) {
    const [loader, setLoader] = useState(false);
    const { row, ind, handleChange, flexiFields, setFieldValue, setFlexiFields, setOtherInfoErrorMessage, setIsOtherCourt, errors, touched, callingSchemaFunction, losAngeles, setLosAngeles } = props;
    let indexOfCountyAgency = flexiFields?.findIndex(e => e?.flexiFieldId == 161);
    const fetchData = () => {
        const mapAdminUrl = environment.countyAgencyUrl + '/improv-student-registration-journey/county-agency/';
        GenericAPIService.getWithoutBaseUrl(mapAdminUrl).then(res => {
            if (res.status.success.toLowerCase() === 'success') {
                setLoader(true);
                const apiResponse = res?.data;
                if (apiResponse?.length > 0) {
                    const countyDropdownList = apiResponse.map(list => ({
                        value: list.countyCode,
                        label: list?.label,
                        name: list?.label,
                        //     name: list?.countyCode + ' ' + list?.countyName,
                        id: list?.countyCode,
                        countyName: list?.countyName,
                    }));
                    const flexiFieldCopy = [...flexiFields];
                    flexiFieldCopy[indexOfCountyAgency].dropdown = countyDropdownList;
                    flexiFieldCopy[indexOfCountyAgency].value = countyDropdownList.value;
                    setFlexiFields(flexiFieldCopy);
                }
                setLoader(false);
            }
        });
    };
    const dropdownEvent = Event => {
        let tempArray = [...flexiFields];
        let dropdownName = '';
        try {
            if (row.type.toLocaleLowerCase() === 'dropdown' || row.flexiFieldName.toLocaleLowerCase() === 'dropdown') {
                let inx = row.dropdown.findIndex(e => e.id == Event.target.value);
                if (inx != -1) dropdownName = row.dropdown[inx].name;
                if (row.flexiFieldId == 161) {
                    const index = row.dropdown.findIndex(e => e.countyCode == Event.target.value);
                    if (index != -1) dropdownName = row.dropdown[inx].countyName;
                    const flexiFieldCopy = [...flexiFields];
                    flexiFieldCopy[indexOfCountyAgency].value = Event.target.value;
                    flexiFieldCopy[indexOfCountyAgency].dropdownName = dropdownName;
                    setFlexiFields(flexiFieldCopy);
                }
                setCountyCourtNestedDropdown(tempArray, inx);
            }
            let temp = row;
            temp.value = Event.target.value;
            temp.dropdownName = dropdownName;
            if (temp.value == '2815' && temp.dropdownName == 'Los Angeles' && flexiFields.length >= 2 && indexOfCountyAgency != -1) {
                // setLoader(true);
                fetchData();
                setLosAngeles(true);
            } else {
                if (temp?.flexiFieldId == 89 && flexiFields.length >= 2 && indexOfCountyAgency != -1) {
                    const shallowCopy = [...flexiFields];
                    shallowCopy[indexOfCountyAgency].dropdown = [];
                    setLosAngeles(false);
                }
            }
            tempArray[ind] = temp;
            setFlexiFields([]);
            setFlexiFields(tempArray);
            setFieldValue(row.id, Event.target.value);
            if (row.flexiFieldName == 'COURT' && Event.target.value == 'Other') {
                setIsOtherCourt(true);
            } else {
                setIsOtherCourt(false);
            }
        } catch (error) {
            setOtherInfoErrorMessage(Constants.serverErrorMessage);
        }
    };
    const setCountyCourtNestedDropdown = (tempArray, inx) => {
        if (row.catalogItem.code.toLocaleLowerCase() === 'county' && row.flexiFieldName.toLocaleLowerCase() !== 'court') {
            setFieldValue('Court', '');
            tempArray.filter(e => {
                if ((e.catalogItem && e.catalogItem.code.toLocaleLowerCase() === 'court') || e.flexiFieldName.toLocaleLowerCase() === 'court') {
                    e.dropdown = row.dropdown[inx].nestedDropDown;
                    e.dropdown.push({ id: null, name: 'Other' });
                    return e;
                }
            });
        }
    };
    const getOptionList = () => {
        return (
            <>
                {row && row.dropdown && row.dropdown.length > 0 ? (
                    row &&
                    row.dropdown &&
                    row.dropdown.map((innerRow, index) => (
                        <option key={index + 1} value={innerRow.id}>
                            {innerRow.name}
                        </option>
                    ))
                ) : (
                    <></>
                )}
            </>
        );
    };
    const getOptionListLea = () => {
        return (
            <>
                {row && row.dropdown && row.dropdown.length > 0 ? (
                    row &&
                    row.dropdown &&
                    row.dropdown.map((innerRow, index) => (
                        <option key={index + 1} value={innerRow.value}>
                            {innerRow.name}
                        </option>
                    ))
                ) : (
                    <></>
                )}
            </>
        );
    };
    const renderLosAnglesDropdown = () => {
        return (
            <div className='field-col'>
                {losAngeles ? (
                    <select
                        className={`custom-form-control ${row.value == '' && 'gray-placeholder'}`}
                        name={row.name}
                        id={row.id}
                        placeholder={row.label}
                        onChange={e => {
                            handleChange(e);
                            dropdownEvent(e);
                            setOtherInfoErrorMessage('');
                        }}>
                        <option disabled value='' selected className='defaultOption'>
                            {row.label}
                        </option>
                        {getOptionListLea()}
                    </select>
                ) : null}
                {renderFieldErrors(errors, row, touched)}
            </div>
        );
    };

    return (
        <>
            {loader ? (
                <div className='loader'>
                    <PropagateLoader size={12} />
                </div>
            ) : null}
            {row.flexiFieldId != 161 ? (
                <>
                    <div className='field-col'>
                        {props.isFastLane && (
                            <p className="profile-register-field-label">
                                {row.flexiFieldDescription}
                            </p>
                        )}
                        <select
                            className={`custom-form-control ${row.value == '' && 'gray-placeholder'} ${props.isFastLane === true && 'custom-icon'}`}
                            name={row.id}
                            id={row.id}
                            placeholder={row.label}
                            onChange={e => {
                                handleChange(e);
                                dropdownEvent(e);
                                setOtherInfoErrorMessage('');
                            }}>
                            <option disabled value='' selected className='defaultOption'>
                                {row.label}
                            </option>
                            {getOptionList()}
                        </select>
                        {renderFieldErrors(errors, row, touched)}
                    </div>
                </>
            ) : (
                row.flexiFieldId == 161 &&losAngeles && renderLosAnglesDropdown()
            )}
        </>
    );
}
