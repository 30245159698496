import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import BaseRouting from './components/routing/BaseRouting';
import React, { useEffect, useState } from 'react';
import { HashRouter } from 'react-router-dom';
import environment from './environments/environment';
import { RouteConstants } from './utils/constants/Constants';
import { EncryptStorage } from './services/EncryptStorage';

const App = () => {

  const [showHashRouter, setShowHashRouter] = useState(false);

  useEffect(() => {
    (function (d) { let s = d.createElement("script"); s.setAttribute("data-account", "Z5dVNRZGuj"); s.setAttribute("src", "https://cdn.userway.org/widget.js"); (d.body || d.head).appendChild(s); })(document)
    let origin = window.location.origin;
    let pathname = window.location.pathname;
    let showHashRouter = true;
    if (EncryptStorage.getLocalStorage('showHashRouter') && EncryptStorage.getLocalStorage('showHashRouter').toString() === 'true') {
      showHashRouter = true;
    }
    else {
      showHashRouter = false;
    }
    if (window.location.href.includes('/home')) {
      setShowHashRouter(showHashRouter);
    }
    else {
      if (window.location.href.includes('authToken')) {
        if (window.location.href.includes('studentSource')) {
          if ((environment.domainUrl === origin && pathname === '/') || (environment.nonHashedUrls.includes(origin)) || (environment.domainUrl === origin && RouteConstants.routes.includes(pathname))) {
            EncryptStorage.setToLocalStorage('showHashRouter', false)
            setShowHashRouter(false);
            if (window.location.href.includes('#')) {
              let url = window.location.href.replace('#', '');
              let finalUrl = url.replace('//', '/');
              window.location.href = finalUrl;
            }
          }
          else {
            EncryptStorage.setToLocalStorage('showHashRouter', true)
            setShowHashRouter(true);
            let url = window.location.href;
            window.location.href = url;
          }
        }
        else {
				
          checkAuthToken(origin, pathname);
        }
      }
      else {
        checkHashRouter(origin, pathname);
      }
    }
  }, []);

  const checkAuthToken = (origin, pathname) => {
		localStorage.clear();
		window.localStorage.clear();
	 	/* debugger;  */
    EncryptStorage.setToLocalStorage('enableAuthCheck', true)
    let replaceUrl = window.location.href.replace("#/", "");
    let url = new URL(replaceUrl);
    let authToken = url.searchParams.get("authToken")
    let studentId = url.searchParams.get("studentId")
    let studentPaymentTrxId = url.searchParams.get("studentPaymentTrxId")

    EncryptStorage.encryptOnlyKey('token', authToken);
    EncryptStorage.setToLocalStorage('studentId', studentId);

    EncryptStorage.setToLocalStorage('studentPaymentTrxId', studentPaymentTrxId);
    if ((environment.domainUrl === origin && pathname === '/') || (environment.nonHashedUrls.includes(origin)) || (environment.domainUrl === origin && RouteConstants.routes.includes(pathname))) {
      EncryptStorage.setToLocalStorage('showHashRouter', false)
      setShowHashRouter(false);
      window.location.href = `${window.location.origin}/`;
    }
    else {
      EncryptStorage.setToLocalStorage('showHashRouter', true)
      setShowHashRouter(true);
      window.location.href = `${window.location.origin}${pathname}#/`;
    }
  }

  const checkHashRouter = (origin, pathname) => {
    if ((environment.domainUrl === origin && pathname === '/') || (environment.nonHashedUrls.includes(origin)) || (environment.domainUrl === origin && RouteConstants.routes.includes(pathname))) {
      EncryptStorage.setToLocalStorage('showHashRouter', false)
      setShowHashRouter(false);
      if (window.location.href.includes('#')) {
        removeHashFormUrl();
      }
    }
    else {
      EncryptStorage.setToLocalStorage('showHashRouter', true)
      setShowHashRouter(true);
    }
  }

  const removeHashFormUrl = () => {
    let url = window.location.href;
    if (url.includes('/#/')) {
      window.location.href = url.split('/#/').join('/');
    }
    else if (url.includes('#/')) {
      window.location.href = url.split('#/').join('/');
    }
    else if (url.includes('/#')) {
      window.location.href = url.split('/#').join('/');
    }
  }

  return (
    <>
      
      {EncryptStorage.getLocalStorage("showHashRouter") &&
      EncryptStorage.getLocalStorage("showHashRouter").toString() === "true" ? (
        <div className="app">
          <HashRouter>
            <BaseRouting />
          </HashRouter>
        </div>
      ) : (
        <div className="app">
          <BaseRouting />
        </div>
      )}
    </>
  );
}
export default App;