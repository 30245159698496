import { EncryptStorage } from '../../common/imports/CommonImport';

export const initStorageSetup = () => {
    EncryptStorage.setToLocalStorage('scheduleDate', '');
    EncryptStorage.setToLocalStorage('scheduleTime', '');
    EncryptStorage.setToLocalStorage('isUpGradeCourseFlag', '');
    EncryptStorage.setToLocalStorage('isUpGradeCourseActive', false);
    EncryptStorage.setToLocalStorage("skipPage", false);
}
export const initEmptyStorageSetup = () => {
    EncryptStorage.setToLocalStorage('appliedDiscountObject', '');
    localStorage.tempDiscode = '';
    EncryptStorage.setToLocalStorage('discountResponseObject', '');
    localStorage.testCoursePriceDetails = '';
    localStorage.testSeletedUpsellCourseDetails = '';
}

export const coursePriceDetailInitPayload = (configCourseId) => {
    let payload = {};
    if (configCourseId) {
        const stateId = EncryptStorage.getLocalStorage('stateId');
        const countyId = EncryptStorage.getLocalStorage('countyId');
        const courtId = EncryptStorage.getLocalStorage('courtId');
        const citationDate = EncryptStorage.getLocalStorage('citationDate');
        const studentId = EncryptStorage.getLocalStorage('studentId');
        payload = {
            configCourseId: configCourseId,
            studentId: studentId,
            stateId: stateId ? stateId : 0,
            countyId: countyId ? countyId : 0,
            courtId: courtId ? courtId : 0,
            citationDate: citationDate ? citationDate : '2022-09-11'
        };
    }
    return payload;
}
export const initSetupConfigValue = () => {
    const courseName = EncryptStorage.getLocalStorage('courseName');
    const configCourseId = EncryptStorage.getLocalStorage('configCourseId');
    const studentId = EncryptStorage.getLocalStorage('studentId');
    const isPayLaterPaymentCheck = EncryptStorage.getLocalStorage('payLaterPayment');
    return {
        courseName: courseName,
        configCourseId: configCourseId,
        studentId: studentId,
        isPayLaterPaymentCheck: isPayLaterPaymentCheck
    }
}
export const getPayloadForSelecteUpsell = (item) => {
    const stateId = EncryptStorage.getLocalStorage('stateId');
    const countyId = EncryptStorage.getLocalStorage('countyId');
    const courtId = EncryptStorage.getLocalStorage('courtId');
    const citationDate = EncryptStorage.getLocalStorage('citationDate');
    const studentId = EncryptStorage.getLocalStorage('studentId');
    return {
        configCourseId: item.upsellConfigCourse.configCourseId,
        studentId: studentId,
        stateId: stateId ? stateId : 0,
        countyId: countyId ? countyId : 0,
        courtId: courtId ? courtId : 0,
        citationDate: citationDate ? citationDate : null,
    };
}
export const getIsUpGradeCourse = (configUpsellCourseDto, coursePriceDetails) => {
    return configUpsellCourseDto.map((obj, inx) => {
        if (obj.isUpGradeCourse) {
            let rootPrice = coursePriceDetails.basePrice.toFixed(2);
            obj.basePrice = (obj.basePrice - rootPrice);
        }
        return {
            ...obj,
            ...{
                currentActive: false
            }
        }
    });
}
export const findTotalPrice = (values) => {
    let totalPrice = 0;
    let i = values.groupPriceTransactions.length;
    while (i--) {
        if (values.groupPriceTransactions[i]) {
            values.groupPriceTransactions[i].initialPrice = values.groupPriceTransactions[i].price ? values.groupPriceTransactions[i].price : 0;
            totalPrice += values.groupPriceTransactions[i].price ? values.groupPriceTransactions[i].price : 0;
            values.groupPriceTransactions[i].selected = true;
        } else {
            values.splice(i, 1);
        }
    }
    totalPrice += values.basePrice;
    if (values.diversionPriceDto) {
        totalPrice += values.diversionPriceDto.price;
    }
    return totalPrice;
}
export const getSelectedPriceItemValue = (groupPriceTransactions, i, totalPrice) => {
    if (groupPriceTransactions[i].mailPrice) {
        for (let item of groupPriceTransactions[i].mailPrice.mailItems) {
            if (item.selected) {
                totalPrice += groupPriceTransactions[i].initialPrice;
            }
        }
    }
    if (groupPriceTransactions[i].bhwlPrice) {
        for (let item of groupPriceTransactions[i].bhwlPrice.bhwlItems) {
            if (item.selected) {
                totalPrice += groupPriceTransactions[i].initialPrice;
            }
        }
    }
    return totalPrice;
}

export const priceGroupsetupInstore = (isDiscountApplicable, isPayLater, isRequestPaymentLinkForParent) => {
    EncryptStorage.setToLocalStorage('isDiscountApplicable', isDiscountApplicable);
    EncryptStorage.setToLocalStorage('isPayLater', isPayLater);
    EncryptStorage.setToLocalStorage('isRequestPaymentLinkForParent', isRequestPaymentLinkForParent);
}
export const beforePaymentStoreValues = (_finalPrice, _totalPrice, coursePriceDetails,
    upsellCourseList, seletedUpsellCourseDetails, discountPrice,
    isUpGradeCourseActive) => {
		
    EncryptStorage.setToLocalStorage('totalPrice', _finalPrice == 0 ? _totalPrice : _finalPrice);
    EncryptStorage.setToLocalStorage('totalPrice_', _totalPrice);
    EncryptStorage.setToLocalStorage('coursePriceDetails', coursePriceDetails);
    EncryptStorage.setToLocalStorage('upsellCourseList', upsellCourseList);
    EncryptStorage.setToLocalStorage('seletedUpsellCourseDetails', seletedUpsellCourseDetails);
    EncryptStorage.setToLocalStorage('discountPrice', discountPrice)
    localStorage.testCoursePriceDetails = JSON.stringify(coursePriceDetails);
    localStorage.testSeletedUpsellCourseDetails = JSON.stringify(seletedUpsellCourseDetails);
    EncryptStorage.setToLocalStorage('isUpGradeCourseActive', isUpGradeCourseActive);
    let mainCoursePrice = coursePriceDetails ? coursePriceDetails.basePrice : 0;
    coursePriceDetails && coursePriceDetails.groupPriceTransactions.map((item) => {
        if (item && item.coursePriceType && item.coursePriceType.name === 'Mail') {
            item.mailPrice && item.mailPrice.mailItems.length > 0 && item.mailPrice.mailItems.map((data) => {
                if (data.selected) {
                    mainCoursePrice = Number(mainCoursePrice) + Number(data.price);
                }
            })
        }
        else if (item && item.coursePriceType && item.coursePriceType.name === "BHWL") {
            item.bhwlPrice && item.bhwlPrice.bhwlItems.length > 0 && item.bhwlPrice.bhwlItems.map((data) => {
                if (data.selected) {
                    mainCoursePrice = Number(mainCoursePrice) + Number(data.price);
                }
            })
        }
        else if (item.selected) {
            mainCoursePrice = Number(mainCoursePrice) + Number(item.price);
        }
    })
    if (coursePriceDetails.diversionPriceDto) mainCoursePrice = Number(mainCoursePrice) + Number(coursePriceDetails.diversionPriceDto.price);
    let mainCourseName = EncryptStorage.getLocalStorage('registerCourseName');
    EncryptStorage.setToLocalStorage('primaryCoursePriceDetails', { 'course': mainCourseName, 'price': mainCoursePrice, 'quantity': 1 });
    let upsellPriceDetails = [];
    seletedUpsellCourseDetails && seletedUpsellCourseDetails.length > 0 && seletedUpsellCourseDetails.map((item) => {
        let tempObj = {
            'course': '',
            'price': 0,
            'quantity': 1
        };
        if (item.configCourseId) {
            tempObj.course = item.name;
            for (let configCourseItem of item.configCoursePriceDto) {
                if (configCourseItem.coursePriceType.code === 'BASEPR') {
                    tempObj.price = configCourseItem.price;
                }
            }
        }
        upsellPriceDetails.push(tempObj)
    })
    EncryptStorage.setToLocalStorage('upsellCoursePriceDetails', upsellPriceDetails);
    return true;
}
export const payLaterInitPayloadSetup = (method) => {
    return {
        "paymentGateway": "paylater",
        "isParentPaymentRequest": method === 'requestEmail' ? true : false,
        "studentPaymentTrx": []
    }
}

export const setupPaylaterPayload = async (method, coursePriceDetails, upsellCourseList) => {
    const configCourseId = EncryptStorage.getLocalStorage('configCourseId');
    debugger;
    const studentId = EncryptStorage.getLocalStorage('studentId');
    let payload = await payLaterInitPayloadSetup(method);
    if (payload) {
        let primaryCoursePayload = {
            "paymentGateway": "paylater",
            "totalAmount": coursePriceDetails.basePrice,
            "isPayLater": true,
            "student": {
                "studentId": studentId
            },
            "configCourse": {
                "configCourseId": configCourseId
            },
            "isPrimary": true,
            "studentPaymentTrxLines": []
        };
        upsellCourseList && upsellCourseList.forEach((element) => {
            if (element.currentActive !== undefined && element.currentActive.toString() === "true" && element.isUpGradeCourse) {
                primaryCoursePayload.totalAmount = coursePriceDetails.basePrice;
                primaryCoursePayload.configCourse.configCourseId = element.upsellConfigCourse ? element.upsellConfigCourse.configCourseId : null;
                primaryCoursePayload.isPrimary = true;
            }
        });
        debugger
        payload.studentPaymentTrx.push(primaryCoursePayload);
        upsellCourseList && upsellCourseList.forEach((element) => {
            if (element.currentActive !== undefined && element.currentActive.toString() === "true" && !element.isUpGradeCourse) {
                let upsellPayload = {
                    "paymentGateway": "paylater",
                    "totalAmount": element.basePrice,
                    "isPayLater": true,
                    "student": {
                        "studentId": studentId
                    },
                    "configCourse": {
                        "configCourseId": element.upsellConfigCourse ? element.upsellConfigCourse.configCourseId : null
                    },
                    "isPrimary": false,
                    "studentPaymentTrxLines": []
                };
                payload.studentPaymentTrx.push(upsellPayload);
            }
        });
    }
    debugger;
    return payload;
}
export const setScheduleDateTime = () => {
    if (document.getElementsByName('scheduleDate')[0]) {
        let scheduleDate = document.getElementsByName('scheduleDate')[0].value;
        EncryptStorage.setToLocalStorage('scheduleDate', scheduleDate);
    }
    if (document.getElementsByName('scheduleTime')[0]) {
        let scheduleTime = document.getElementsByName('scheduleTime')[0].value;
        EncryptStorage.setToLocalStorage('scheduleTime', scheduleTime);
    }
}