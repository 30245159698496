export function formatTime(seconds) {
    // Calcula las horas, minutos y segundos
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    // Formatea los valores para asegurarse de que tengan dos dígitos
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    // Retorna el formato deseado
    return `${formattedHours}h ${formattedMinutes}m`;
}