import React from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { chatCommonService } from "../../services/chatCommonService";
import { EncryptStorage } from "../../services/EncryptStorage";
import {
  GenericAPIService,
  HOF_Methods,
  UrlConstants,
} from "../common/imports/CommonImport";

const checkAuthToken = (history, setLoader) => {
  // if (getUrl) {
  // let replaceUrl = getUrl.replace("#/", "");
  // let url = new URL(replaceUrl);
  // let authToken = url.searchParams.get("authToken")
  // let studentId = url.searchParams.get("studentId")
  // let studentPaymentTrxId = url.searchParams.get("studentPaymentTrxId")
  let authToken = EncryptStorage.decryptOnlyKey("token");

  let studentId = EncryptStorage.getLocalStorage("studentId");
  let studentPaymentTrxId = EncryptStorage.getLocalStorage(
    "studentPaymentTrxId"
  );
  if (authToken) {
    // localStorage.setItem('IsLoggedIn', 'true');
    // EncryptStorage.encryptOnlyKey('token', authToken);
    // EncryptStorage.setToLocalStorage('studentId', studentId);
    // EncryptStorage.setToLocalStorage('redirectDashboard', true);
    // EncryptStorage.setToLocalStorage('studentPaymentTrxId', studentPaymentTrxId);
    let payload = {
      student: studentId,
    };
    setLoader(true);
    const url = UrlConstants.getStudentDetails;
    GenericAPIService.customAPIMethod(url, payload, "GET")
      .then((res) => {
        if (HOF_Methods.getStatus(res) === "success") {
          const currentDate = new Date();
          EncryptStorage.setToLocalStorage("expiration_time", currentDate);
          const { isPersonalInfoPending = false } = res.data;
          EncryptStorage.setToLocalStorage(
            "isPersonalInfoPending",
            isPersonalInfoPending
          );
          EncryptStorage.setToLocalStorage("userObject", res.data);
          GetOrganizationLogo(setLoader, history);
          setLoader(true);
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        setLoader(true);
      });
  }
  // }
};

const GetOrganizationLogo = (setLoader, history) => {
  let url = getDomainUrl();
  const payload = {};
  let baseUrl = UrlConstants.getOrganisationLogoUrl + `?domain=${url}`;
  setLoader(true);
  GenericAPIService.customAPIMethod(baseUrl, payload, "GET")
    .then((res) => {
      if (HOF_Methods.getStatus(res) === "success") {
        EncryptStorage.setToLocalStorage("organisationLogo", res.data.logoPath);
        chatCommonService.sendMessage(res.data);
      }
      localStorage.setItem("IsLoggedIn", "true");
      EncryptStorage.setToLocalStorage("redirectDashboard", true);
      history.push("/home/dashboard");
    })
    .catch((err) => {
      localStorage.setItem("IsLoggedIn", "true");
      EncryptStorage.setToLocalStorage("redirectDashboard", true);
      history.push("/home/dashboard");
      setLoader(false);
    });
};

const getDomainUrl = () => {
  let domainName = "";
  if (window.location.href.includes("#")) {
    domainName = window.location.href.includes("/?authToken")
      ? window.location.href.split("/?authToken")[0]
      : window.location.href.split("#")[0];
  } else {
    domainName = window.location.href.includes("/?authToken")
      ? window.location.href.split("/?authToken")[0]
      : window.location.origin;
  }
  return domainName;
};

const getLoader = (loader) => {
  return (
    <>
      {loader && (
        <div className="loader">
          <PropagateLoader size={15} />
        </div>
      )}
    </>
  );
};

const getCustomErrorMessage = (errorMessage) => {
  return (
    <>{errorMessage && <span className="custom-error">{errorMessage}</span>}</>
  );
};

export const courseEnrollmentHelper = {
  getLoader,
  getCustomErrorMessage,
  checkAuthToken,
};
