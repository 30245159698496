import React, { useEffect, useState, useContext } from "react";
import { Nav } from "react-bootstrap";
import timer2 from "./../../../assets/images/icons/timer1.svg";
import { shareTimeService } from "../../../services/shareTimeService";
import { EncryptStorage } from "../imports/CommonImport";
import { courseTimeService } from "../../../services/courseTimeService";
import { CourseTimerContext } from "../../../context";
import { formatTime } from "../../../services/formatTime";

const ViewCourseTimer = (props) => {
  const { location = "" } = props.passingProps;

  const courseTimerDataContext = useContext(CourseTimerContext);
  const { courseTimer } = courseTimerDataContext;

  const getTimerName = () => {
    return location.pathname === "/home/final-quiz"
      ? "Final Quiz Time "
      : "Chapter Time ";
  };

  const checkDashboardPath = (value) => {
    let path = location ? location.pathname : "";
    if (path !== "/home/dashboard" && value === "path")
      return (
        <h5 className="liveTimer">{formatTime(courseTimer.courseTime)}</h5>
      );
    if (path === "/home/dashboard" && value === "className")
      return "dashboardVisit";
  };

  return (
    <div className="d-flex">
      <div className="">
        <ChapterAndFinalQuizTimer getTimerName={getTimerName} />
      </div>
      <div className="mb-2">
        <CourseTimer
          checkDashboardPath={checkDashboardPath}
          courseTime={courseTimer.courseTime}
        />
      </div>
    </div>
  );
};

export default ViewCourseTimer;

export function ChapterAndFinalQuizTimer({ getTimerName }) {
  const courseTimerDataContext = useContext(CourseTimerContext);
  const { courseTimer } = courseTimerDataContext;
  return courseTimer.chapterTime > 0 ? (
    <Nav.Item className="timecard timecard-2">
      <img src={timer2} alt="timer" className="timerImage" />
      <div className="item">
        <p className="mb-0">{getTimerName()}</p>
        <p className="mb-0 displayTime">
          {formatTime(courseTimer.chapterTime)}
        </p>
      </div>
    </Nav.Item>
  ) : null;
}

export function CourseTimer({ checkDashboardPath, courseTime }) {
  if (!courseTime) return null;
  if (courseTime) EncryptStorage.setToLocalStorage("alertBox", true);

  return (
    <Nav.Item className={"timecard green " + checkDashboardPath("className")}>
      <img src={timer2} alt="timer" className="timerImage" />
      <div className="item">
        <p className="mb-0"> Max Time Allowed for Today</p>
        <p className="mb-0 displayTime">{formatTime(courseTime)}</p>
      </div>
    </Nav.Item>
  );
}
