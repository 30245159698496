import { BehaviorSubject } from 'rxjs';

const chapterTimeSubject = new BehaviorSubject();
const iframeSubject = new BehaviorSubject();

export const shareTimeService = {
    sendMessage: timer => chapterTimeSubject.next({ text: timer }),
    clearMessages: () => chapterTimeSubject.next(),
    onMessage: () => chapterTimeSubject.asObservable()
};

export const handleIframeEvent = {
    sendMessage: timer => iframeSubject.next({ text: timer }),
    clearMessages: () => iframeSubject.next(),
    onMessage: () => iframeSubject.asObservable()
};