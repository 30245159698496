import { BehaviorSubject } from 'rxjs';
import { EncryptStorage } from './EncryptStorage';

const subject = new BehaviorSubject(EncryptStorage.getLocalStorage('storeCourseSubjectValue'));

const storeSubject = (universalList) => {
    subject.next(universalList)
}

export const courseCustomAttributeService = {
    sendMessage: storeSubject,
    clearMessages: () => subject.next(),
    onMessage: () => subject.asObservable()
};