import React from "react";
import { brandLogoService } from "../../services/brandLogoService";
import { courseTimeService } from "../../services/courseTimeService";
import { shareTimeService } from "../../services/shareTimeService";
import { EncryptStorage, GenericAPIService, PropagateLoader, UrlConstants } from "../common/imports/CommonImport";

const { gapi } = window;

const getLoader = (loader) => {
    return (
        <>
            {loader && <div className="loader">
                <PropagateLoader size={15} />
            </div>}
        </>
    )
}

const getActiveClassName = (pathname, mapPathName) => {
    return pathname == mapPathName ? 'menu-item active' : 'menu-item';
}

const setTimerObject = (payload, time, hasTimer) => {
    let courseTimeFlag = EncryptStorage.getLocalStorage('zeroCourseTime');
    if (courseTimeFlag) {
        payload = {
            ...payload,
            'availableTimeForTheDay': time,
            'isCourseHasAccessTimeLimitPerDay': true
        };
    } else {
        payload = {
            ...payload,
            'availableTimeForTheDay': time,
            'isCourseHasAccessTimeLimitPerDay': hasTimer
        };
    }

    return payload;
}

const checkAvailableTimeForTheDay = (availableTimeForTheDay, _payload) => {
    let payload = _payload;
    if (availableTimeForTheDay) {
        availableTimeForTheDay = availableTimeForTheDay.toString().replace(':', '.');
        if (availableTimeForTheDay != '00.00' && availableTimeForTheDay != '0.0' && availableTimeForTheDay != '0') {
            payload = setTimerObject(payload, availableTimeForTheDay, true);
        } else {
            payload = setTimerObject(payload, 0, false);
        }
    } else {
        payload = setTimerObject(payload, 0, false);
    }
    return payload;
}

const dashboardApi = (setLoader, history) => {
    let studentPaymentTrxId = EncryptStorage.getLocalStorage('studentPaymentTrxId');
    let _payload = { studentPaymentTrxId: studentPaymentTrxId };
    let availableTimeForTheDay = EncryptStorage.getLocalStorage('availableTimeForTheDay');
    let payload = checkAvailableTimeForTheDay(availableTimeForTheDay, _payload);
    const checkPayload = EncryptStorage.getLocalStorage('parentVerificationStatus') ? { ...payload, isCourseEligable: true } : payload;
    const url = UrlConstants.dashboardListOfChaptersUrl;
    GenericAPIService.customAPIMethod(url, checkPayload, 'POST').then(res => {
        mainLogout(setLoader, history);
    }, () => {
        mainLogout(setLoader, history);
    })
}

const mainLogout = (setLoader, history) => {
    EncryptStorage.setToLocalStorage("orgCustomAttributes", '');
    const studentId = EncryptStorage.getLocalStorage('studentId');
    let chapterLaunchPage = EncryptStorage.getLocalStorage('chapterLaunchPage');
    let Clone_AvailableTimeForTheDay = EncryptStorage.getLocalStorage('Clone_AvailableTimeForTheDay');
    Clone_AvailableTimeForTheDay = Clone_AvailableTimeForTheDay.toString().replace(':', '.');
    let configCourseId = EncryptStorage.getLocalStorage('configCourseId');
    let studentCourseId = EncryptStorage.getLocalStorage('studentCourseId');
    let redirectUrl = EncryptStorage.getLocalStorage('redirectUrl');
    if (studentId) {
        let url = UrlConstants.logoutUrl;
        let payload =
        {
            "studentId": studentId,
            "isCourseHasAccessTimeLimitPerDay": chapterLaunchPage ? true : false,
            ...(chapterLaunchPage && { 'availableTimeForTheDay': Clone_AvailableTimeForTheDay }),
            ...(configCourseId && { "configCourseId": configCourseId }),
            ...(studentCourseId && { "studentCourseId": studentCourseId })
        };
        if(setLoader) setLoader(true);        
        GenericAPIService.customAPIMethod(url, payload, 'POST').then(res => {            
            if(setLoader) setLoader(false);
            localStorage.clear();
            window.localStorage.clear();
            if (redirectUrl) {
                window.location.href = redirectUrl;
            } else {
             if(history)  history.push('/login');
            }
        }).catch(err => {
            if(setLoader) setLoader(false);
            localStorage.clear();
            window.localStorage.clear();
            if(history) history.push('/login');
        });
    }
}

const setInitialValue = (setTimer, setCourseTimer) => {
    EncryptStorage.setToLocalStorage('isCourseHasAccessTime', 'true');
    shareTimeService.onMessage().subscribe((res) => {
        const { text = "" } = res ?? {};
        setTimer(text);
    });
    courseTimeService.onMessage().subscribe((res) => {
        const { text = "" } = res ?? {};
        setCourseTimer(text);
    });
}

const checkOrganizationLogo = (setOrganizationLogo) => {
    brandLogoService.onMessage().subscribe((res) => {
        if (res && res.LogoPath && res.LogoPath.logoPath) {
            setOrganizationLogo(res.LogoPath.logoPath);
        } else {
            let _organizationLogo = EncryptStorage.getLocalStorage('organisationLogo');
            if (_organizationLogo) {
                setOrganizationLogo(_organizationLogo);
            }
        }
    });
}

const logout = (chapterLaunchLogout, setLoader, history) => {
    if (history.location.pathname == '/home/chapter-launch') {
        chapterLaunchLogout();
    } else {
        mainLogout(setLoader, history);
    }
}

const navigate = (path, pathname, history, chapterLaunchLogout, setLoader) => {
    if (path === '/login') {
        googleSignOut(chapterLaunchLogout, setLoader, history);
    } else if (pathname === '/home/breakTime') {
        return true;
    } else {
        history.push(path);
    }
}

const googleSignOut = (chapterLaunchLogout, setLoader, history) => {
    logout(chapterLaunchLogout, setLoader, history);
    if (gapi) {
        let auth = gapi.auth2.getAuthInstance();
        if (auth && auth.isSignedIn.get()) {
            auth.signOut();
        }
    }
    else if (window.amazon) {
        window.amazon.Login.logout();
    }
    else if (window.FB) { fbLogout(); }
}


const fbLogout = () => new Promise((resolve) => {
    window.FB.logout(resolve)
})

export const sidebarHelper = {
    getLoader, setTimerObject, getActiveClassName, navigate, mainLogout, checkAvailableTimeForTheDay,
    setInitialValue, checkOrganizationLogo, dashboardApi
}