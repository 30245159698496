import React, { Component } from 'react'
import { Switch, Route, Redirect } from "react-router-dom";
import HomeComponent from './HomeComponent';
import PageNotFound from './page-layouts/PageNotFound';
import PublicRouting from './PublicRouting';
import { componentImg, unitedStates } from '../common/assets/Images';
import { Dropdown } from 'react-bootstrap';
import ChatUI from '../common/ChatUI';

const PrivateRouteHome = ({ component: Home, ...rest }) => (
    <Route {...rest} render={props =>
        localStorage.IsLoggedIn === 'true' ? (<Home {...props} />
        ) : (<Redirect from='/' to={{
            pathname: '/login',
        }} />)
    }
    />
)

const PublicRoute = ({ auth, ...props }) => {
    const isAllowed = localStorage.IsLoggedIn;
		const token = localStorage.token;
    const registerFlow = localStorage.registerFlow;
	

    if (isAllowed === 'true' && token!=="") {
        if (registerFlow) {
            return (
                <Route {...props} />
            )
        }
        else {
            return (
                <Redirect to="/home/course-list" />
            )
        }
    }
    else {
        return (
            <Route {...props} />
        )
    }
};

export default class BaseRouting extends Component {

    constructor(props) {
        super(props)
        this.state = {
            lanSelect: false,
            isActiveMobileToggle: false,
        }
    }

    componentWillUnmount() {
        clearInterval();
    }

    render() {
        return (
            <>

                <Switch>
                    <PrivateRouteHome path="/home" component={HomeComponent} />
                    <PublicRoute path='/' component={PublicRouting} />
                    <Route component={PageNotFound} />
                </Switch>
                <div className={`mobile-assistive-container ${this.state.isActiveMobileToggle ? "active" : ""}`}>
                    <div className="d-flex">
                        <i className="icon loginUser cursor-pointer">
                            <img src={componentImg} alt="Accessibility" id="accessibilityWidget" tabIndex="0" />
                        </i>
                    </div>
                    <div className="nav__menu--list mt-2">
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className="lang-btn">
                                <img src={unitedStates} /> English
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <a className='dropdown-item' href="javascript:Localize.setLanguage('en');">  <img src={unitedStates} /> English</a>
                                <a className='dropdown-item' href="javascript:Localize.setLanguage('es-LA');">Español (América Latina)</a>
                                <a className='dropdown-item' href="javascript:Localize.setLanguage('ru');">Русский</a>
                                <a className='dropdown-item' href="javascript:Localize.setLanguage('zh');">中文(简体)</a>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <ChatUI />
            </>
        )
    }
}

