import React  from 'react';
import { _localStorage } from '../../constant/storage/localStorage';
import { UrlConstants } from "../../../components/common/imports/CommonImport";
import { Button } from 'react-bootstrap';
import { add_cart, audioGirl, check_purple, speakerAudioIcon, trash } from '../../common/assets/Images';
import { EncryptStorage } from '../../cartSummary/CardSummaryHelper/CartSummaryImport';

const FastLaneCardDetaiItem = (props) => {
    const { 
        upsellCourseList, 
        resetCoursePriceList, 
        upsellCoursePriceList, 
        isAudioUpgradeAdded,
        setIsAudioUpgradeAdded, 
        cleaningDescription
    } = props;

    const checkValidAudioCourseForNewYork = (courseName) => {
        const isAnAudioUpgrade = courseName.includes("Audio");
        const isInNewYork = EncryptStorage.getLocalStorage("stateId") == 36;
        return (isInNewYork && isAnAudioUpgrade);
    }

    return (
    <>
    {
        upsellCourseList.length > 0 && upsellCourseList.map((item, index) =>
            checkValidAudioCourseForNewYork(item.upsellConfigCourse.course.name) ? (
                <div className="course-cart-upgrade-container" key={index}>
                    <div className="course-audio-upgrade-image-container">
                        <img src={audioGirl} alt="Surprised Girl" className="course-cart-girl" />
                        <img src={speakerAudioIcon} alt="Audio Icon" className="course-cart-speaker-icon" />
                    </div>
                    <div className="course-cart-description-container">
                        <div className="course-top">
                            <div className="course-text">
                                <p className="course-cart-description-title-label">Upgrade Your Learning Journey with Our <span>AUDIO COURSE</span></p>
                                <p className="course-cart-description-label"><b>Course made easy</b> - just listen, no need to read.</p>
                            </div>
                        </div>
                        <div className="course-bottom course-bottom-aditional-config">
                            <div className="price-num price-num-aditional-config">
                                <p className='course-audio-upgrade-only-label'>ONLY</p> 
                                <div className="discounted discounted-aditional-config" data-uw-rm-sr="base-price">
                                    ${`${item && item.basePrice ? item.basePrice.toFixed(2) : '00'}`} <span></span>
                                </div>
                            </div>
                            <div className="cart-btn-block cart-btn-block-aditional-config">
                                {
                                    item.currentActive && isAudioUpgradeAdded ? (
                                        <>
                                            <div className="delete" >
                                                <Button onClick={() => { resetCoursePriceList(item, index); setIsAudioUpgradeAdded(false); }}>
                                                    <img src={trash} alt="trash" />
                                                </Button>
                                            </div>
                                            <div className="added">
                                                <Button>
                                                    <img src={check_purple} alt="add to cart" /> Added
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="add-to-cart" onClick={() => { upsellCoursePriceList(item, index); setIsAudioUpgradeAdded(true); }}>
                                            <Button onClick={() => { upsellCoursePriceList(item, index); setIsAudioUpgradeAdded(true); }}>
                                                <img src={add_cart} alt="add to cart" /> Add to cart
                                            </Button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="course-item" key={index}>
                    <div className="cart-course-img">
                        <img className='show-lg' src={UrlConstants.imageBaseUrl + item.upsellConfigCourse.course.logoPath} alt="upsell-course-img" />
                        <img className='show-sm' src={UrlConstants.imageBaseUrl + item.upsellConfigCourse.course.logoPath} alt="upsell-course-img" />
                    </div>
                    <div className="course-desc">
                        <div className="course-top">
                            <div className="course-text">
                                <h2>{item.upsellConfigCourse.course.name}</h2>
                                <p>{cleaningDescription(item.upsellConfigCourse.course.description)}</p>
                            </div>
                        </div>
                        <div className="course-bottom">
                            <div className="price-num">
                                {
                                    item && item.crossPrice && item.crossPrice.toString() != '0' ? (
                                        <div className="crossed" data-uw-rm-sr="crossout-price">
                                            ${item.crossPrice.toFixed(2)}
                                        </div>
                                    ) : null
                                }
                                <div className="discounted" data-uw-rm-sr="base-price">
                                    ${`${item && item.basePrice ? item.basePrice.toFixed(2) : '00'}`} <span>*</span>
                                </div>
                            </div>
                            <div className="cart-btn-block">
                                {
                                    item.currentActive ? (
                                        <>
                                            <div className="delete" >
                                                <Button onClick={() => { resetCoursePriceList(item, index) }}>
                                                    <img src={trash} alt="trash" />
                                                </Button>
                                            </div>
                                            <div className="added">
                                                <Button>
                                                    <img src={check_purple}
                                                        alt="add to cart" /> Added
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="add-to-cart" onClick={() => { upsellCoursePriceList(item, index) }}>
                                            <Button onClick={() => { upsellCoursePriceList(item, index) }}>
                                                <img src={add_cart}
                                                    alt="add to cart" /> Add to cart
                                            </Button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    }
    </>
    );
};
export default FastLaneCardDetaiItem;
