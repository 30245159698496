import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import environment from "../../environments/environment";
import { EncryptStorage } from "../../services/EncryptStorage";
import { GenericAPIService } from "../../services/GenericAPIService";
import {
  Constants,
  HOF_Methods,
  UrlConstants,
} from "../common/imports/CommonImport";
import LoginForm from "./LoginForm";
import { brandLogoService } from "../../services/brandLogoService";
import { chatCommonService } from "../../services/chatCommonService";
import { warningSupport } from "../common/assets/Images";

export default function Login() {
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [organisationId, setOrganisationId] = useState("");

  useEffect(() => {
	
    EncryptStorage.setToLocalStorage("orgCustomAttributes", "");
    EncryptStorage.setToLocalStorage("trackPlatform", false);

    GetOrganizationLogo();
  }, []);

  const stripTrailingSlash = (str) => {
    return str.endsWith("/") ? str.slice(0, -1) : str;
  };
  const GetOrganizationLogo = (reasonId = "") => {
    const url = window.location.href;
    let domain;

    if (url.includes("#")) {
      domain = url.includes("/#") ? url.split("/#")[0] : url.split("#")[0];
    } else {
      domain = window.location.origin;
    }
    const payload = {};
    let baseUrl =
      UrlConstants.getOrganisationLogoUrl +
      `?domain=${domain}${reasonId ? `/&reasonId=${reasonId}` : ""}`;

    setLoader(true);

    GenericAPIService.customAPIMethod(baseUrl, payload, "GET")
      .then((res) => {
        setLoader(false);
        if (HOF_Methods.getStatus(res) === "success") {
          if (!reasonId) {
            EncryptStorage.setToLocalStorage(
              "organisationLogo",
              res.data.logoPath
            );
          }
          EncryptStorage.setToLocalStorage("favIcon", res.data.logoPath);
          if (res.data.logoPath) {
            const favicon = document.getElementsByClassName("favicon");
            const organisationLogo =
              UrlConstants.imageBaseUrl + res.data.logoPath;
            favicon[0].href = organisationLogo;
            favicon[1].href = organisationLogo;
          }
          let isPartner = {
            logoPath: !res.data.isPartnerOrganisation
              ? res.data.logoPath
              : EncryptStorage.getLocalStorage("organisationLogo"),
            organisationId: res.data.organisationId
              ? res.data.organisationId
              : EncryptStorage.getLocalStorage("organisationId"),
          };
          EncryptStorage.setToLocalStorage("storeSubjectValue", res.data);
          brandLogoService.sendMessage(isPartner);
          chatCommonService.sendMessage(res.data);
          if (res.data) {
            EncryptStorage.setToLocalStorage(
              "isRegistrationDisclaimerRequired",
              res.data.isRegistrationDisclaimerRequired
            );
            EncryptStorage.setToLocalStorage(
              "orgCustomAttributes",
              res.data.organisationCustomAttributeMappingList
            );
						debugger;
            EncryptStorage.setToLocalStorage(
              "organisationId",
              res.data.organisationId
            );
            setOrganisationId(res.data.organisationId);
          }
        } else {
          setErrorMessage(res.status.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        setErrorMessage(Constants.serverErrorMessage);
      });
  };

  return (
    <>
      <div className="container">
        <Row className="justify-content-center align-items-lg-center  layout-login">
          <Col
            xs={{ span: 12, order: 1 }}
            lg={{ span: 6, order: 1 }}
            className="px-md-0 pt-4 py-lg-5"
          >
            {/* <h1 className="login-welcome-1 text-center">Welcome back!</h1>
                  <h5 className="login-welcome-2 text-center">Let's get you back to your course.</h5> */}
            <div
              className="pt-1 px-sm-3"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <LoginForm organisationId={organisationId} />

              {/* Maintence */}

              {/*  <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={warningSupport} style={{ width: "200px" }} />
              </div>
              <h4 className="primary-color mt-2">Maintenance in progress </h4> */}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
