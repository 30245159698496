import React from 'react';
import { EncryptStorage } from '../../common/imports/CommonImport';

const CalculateDiscountActive = (el, basePrice, tempFinalPrice, youSavedPrice, tempDiscountPrice, coursePriceData) => {
   
    if (el.type === "DISCOUNTCODE") {
        if (coursePriceData && coursePriceData.configCoursePriceDto) {
            let discountCodeObject = coursePriceData.configCoursePriceDto.filter(item => {
                if (item.coursePriceType.code === 'DISCOU') {
                    return item;
                }
            });
            if (discountCodeObject) {
                EncryptStorage.setToLocalStorage('appliedDiscountObject', discountCodeObject.length === 0 ? '' : JSON.stringify(discountCodeObject));
                localStorage.tempDiscode = JSON.stringify(discountCodeObject);
                EncryptStorage.setToLocalStorage('discountResponseObject', JSON.stringify(el));
            }
        }
    }
    if (el.type === "GIFTCERTIFICATE") {
        if (coursePriceData && coursePriceData.configCoursePriceDto) {
            let discountCodeObject = coursePriceData.configCoursePriceDto.filter(item => {
                if (item.coursePriceType.code === 'GIFTCC') {
                    return item;
                }
            });
            if (discountCodeObject) {
                EncryptStorage.setToLocalStorage('appliedDiscountObject', discountCodeObject.length === 0 ? '' : JSON.stringify(discountCodeObject));
                localStorage.tempDiscode = JSON.stringify(discountCodeObject);
                EncryptStorage.setToLocalStorage('discountResponseObject', JSON.stringify(el));
            }
        }
    }
    return true;
}

export default CalculateDiscountActive;