import React from 'react'

export default function DiscountPriceCard(props) {
    const { showDiscountPrice, coursePriceDetails, selectedUpsellCourse, discountPrice } = props;

    const caluculateYouSavedPrice = () => {
        let tempDifference = 0;
        let totalCrossOutPrice = 0;
        let totalBasePrice = 0;
        if (coursePriceDetails && coursePriceDetails.crossOutPrice) {
            totalCrossOutPrice = totalCrossOutPrice + coursePriceDetails.crossOutPrice;
            totalBasePrice = coursePriceDetails.basePrice;
        }
        selectedUpsellCourse && selectedUpsellCourse.length > 0 && selectedUpsellCourse.map((item) => {
            if (item && item.crossPrice && item.crossPrice.toString() != '0') {
                totalCrossOutPrice = totalCrossOutPrice + item.crossPrice;
                totalBasePrice = totalBasePrice + item.basePrice;
            }
        })
        if (totalCrossOutPrice != 0) {
            tempDifference = Number(totalCrossOutPrice) - Number(totalBasePrice);
        }
        if (discountPrice) {
            tempDifference = Number(tempDifference) + Number(discountPrice);
        }
        return (tempDifference == 0 ? `$${tempDifference}` : `$${tempDifference.toFixed(2)}`)
    }
    
    return (
        <>
            {showDiscountPrice ? (
                <div className="saved-cart-summary-container">
                    <p className='saved-cart-summary-tag'>YOU SAVED</p>
                    <p className='saved-cart-summary-price'>{`${coursePriceDetails && caluculateYouSavedPrice()}`}</p>
                </div>
            ) : null}
        </>
    )
}
