import * as Yup from 'yup';
import { EncryptStorage } from '../../services/EncryptStorage';

const extractDomainName = (email) => {
    let temp = email.replace(/.*@/, '').split('.');
    return temp[temp.length - 1];
}

const checkDomainAllownName = (email) => {
    let temp = email.split('@');
    return temp[1];
}

const mail = (_mail) => {
    let num = _mail.search("@");
    return ((Math.sign(num) === -1) ? false : true);
}
// 'Space not allowed'
// 'First name should be between 1 to 20'
// 'First Name should only contain alphabets, hyphen, apostrophe or number'
const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required')
        .nullable()
        .strict()
        // .trim('Whitespaces are not allowed')
        .min(1, 'Please enter at least 1 character')
        .max(35, "Input exceeds maximum character limit of 35 characters")
        .matches(/^[^\s'][\w\s']*[\w\s']$/, 'Please enter a valid firstname'),
        // .matches(/^[A-Za-z0-9_'']+([ ][A-Za-z0-9_''])[ ]?$/, 'Please enter a valid firstname'),

    lastName: Yup.string().required('Last Name is required').nullable()
        .strict()
        // .trim('Whitespaces are not allowed')
        .min(1, 'Please enter at least 1 character')
        .max(35, "Input exceeds maximum character limit of 35 characters")
        .matches(/^[^\s'][\w\s']*[\w\s']$/, 'Please enter a valid lastname'),
        // .matches(/^[^\s][\w\s]*[^\s]$/, 'Please enter a valid lastname'),
        // .matches(/^[A-Za-z0-9_']+$/, "Please enter a valid lastname"),

    emailId: Yup.string().required('Email Address is required').matches(/^[\w.%+'-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,'Please enter a valid email address').test((value, { createError }) => {
        let listedDomain = EncryptStorage.getLocalStorage("domainRestriction")
        if (listedDomain && listedDomain.length > 0 && value && value.length > 0) {
            /*  Checking Email Address Valid or not  checking with "@" */
            return checkEmailValid(listedDomain, value, createError)
        }
        else {
            return true
        }
    },
    ),
    parentEmail: Yup.string().email(`Please enter a valid email address`).test('email-match', 'Parent ID and student ID cannot be the same', function (value) {
        return this.parent.emailId !== value
    }),
    isStudentOptSms: Yup.boolean(),
    mobileNumber: Yup.string().when('isStudentOptSms', {
        is: true,
        then: Yup.string().required('Mobile number is required')
        .matches(/^\d{3}-\d{3}-\d{4}$/,'Please enter valid phone number'),
    }),
    password: Yup.string().required('Password is required')
        .test('len', 'Password should be between 6 to 30 characters', val => val && (val.length >= 6 && val.length <= 30))
});

const checkEmailValid = (listedDomain, value, createError) => {
    let isEmailValid = mail(value);
    if (isEmailValid) {
        if (listedDomain.length > 0) {
            let listedDomainArray = listedDomain.split(",")
            let emailDomainExtension = extractDomainName(value);
            let result1 = listedDomainArray.includes(emailDomainExtension);
            let domainCheck = checkDomainAllownName(value);
            let result2 = listedDomainArray.includes(domainCheck);
            let resp = ((result1 && typeof result1 === 'boolean') || (result2 && typeof result2 === 'boolean')) ? true : false;
            return resp ? resp : createError({
                message: ` Enter a valid email`,
            });
        }
    }
}

const validationSchemaState = Yup.object({
    firstName: Yup.string().required('First Name is required')
        .nullable()
        .strict()
        // .trim('Whitespaces are not allowed') ^[A-Za-z0-9_'']+([ ][A-Za-z0-9_''])[ ]?$
        .min(1, 'Please enter at least 1 character')
        .max(35, "Input exceeds maximum character limit of 35 characters")
        .matches(/^[^\s'][\w\s']*[\w\s']$/, 'Please enter a valid firstname'),
        // .matches(/^[^\s][\w\s]*[^\s]$/, 'Please enter a valid firstname'),
        // .matches(/^[A-Za-z0-9_'']+([ ][A-Za-z0-9_''])[ ]?$/, 'Please enter a valid firstname'),

    lastName: Yup.string().required('Last Name is required').nullable()
        .strict()
        // .trim('Whitespaces are not allowed')
        .min(1, 'Please enter at least 1 character')
        .max(35, "Input exceeds maximum character limit of 35 characters")
        .matches(/^[^\s'][\w\s']*[\w\s']$/, 'Please enter a valid lastname'),
        // .matches(/^[^\s][\w\s]*[^\s]$/, 'Please enter a valid lastname'),
        // .matches(/^[A-Za-z0-9_']+$/, "Please enter a valid lastname"),

    emailId: Yup.string().required('Email Address is required').email('Please enter a valid email address'),
    parentEmail: Yup.string().email(`Please enter a valid email address`).test('email-match', 'Parent ID and student ID cannot be the same', function (value) {
        return this.parent.emailId !== value
    }),
    isStudentOptSms: Yup.boolean(),
    mobileNumber: Yup.string().when('isStudentOptSms', {
        is: true,
        then: Yup.string().required('Mobile number is required')
        .matches(/^\d{3}-\d{3}-\d{4}$/,'Please enter valid phone number'),
    }),
    state: Yup.string().required('State is required'),
    password: Yup.string().required('Password is required')
        .test('len', 'Password should be between 6 to 30 characters', val => val && (val.length >= 6 && val.length <= 30))
})

const initialValues = {
    firstName: '',
    lastName: '',
    emailId: '',
    parentEmail: '',
    mobileNumber: '',
    password: '',
    isStudentOptSms: false,
    isStudentOptMail: true,
    state: '',
}

export const Schema = {
    validationSchema, initialValues, validationSchemaState
}