import pageNotFound from '../../../assets/images/page-not-found.png'
import React from 'react';

const PageNotFound = () => {

    return (
        <div id="wrapper" className="text-center pageNotFound">
            <img src={pageNotFound} className="pageNotFound__img" alt="page not found" />
            <div id="info">
                <h3>This page could not be found</h3>
            </div>
        </div>
    )
}

export default PageNotFound