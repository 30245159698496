import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import CourseInfoTabs from "./CourseInfoTabs";
import RegisterForm from "./RegisterForm";
import { brandLogoService } from "../../services/brandLogoService";
import { chatCommonService } from "../../services/chatCommonService";
import {
  HOF_Methods,
  GenericAPIService,
  EncryptStorage,
  //PropagateLoader,
  Constants,
  UrlConstants,
} from "../common/imports/CommonImport";
import environment from "../../environments/environment";
import { registerHelper } from "./registerHelper";
import { arrow_down_pruple, arrow_right_pruple } from "../common/assets/Images";
import ShopperFooter from "../common/ShopperFooter";
//import { WisePopsCall } from "./WisePopupCall";
import { _localStorage } from "../constant/storage/localStorage";

const Register = (props) => {
  const [loader, setLoader] = useState(false);
  const [courseDetails, setCourseDetails] = useState("");
  const [agreeFlag, setAgreeFlag] = useState(true);
  const [rulesFlag, setRulesFlag] = useState(true);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isVisible, setIsVisible] = useState("faq-mobile-fixed");
  const [isNotVisible, setIsNotVisible] = useState("");
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isTrueParentEmail, setIsTrueParentEmail] = useState("Close");
  const [masterData, setMasterData] = useState([]);
  const [organisationProperty, setOrganisationProperty] = useState([]);
  const [customAttributesList, setCustomAttributesList] = useState([]);
  const [configCustomAttributeList, setConfigCustomAttributeList] = useState(
    []
  );
  const [courseDetailsShow, setCourseDetailsShow] = useState(false);

  const handleClose = () => setShow(false);
  let courseInfoTab = 0;

  useEffect(() => {
    registerHelper.redirection();
  }, []);

  // this hook form feting custom Attribute current updated array and updating to the state veriable
  useEffect(() => {
    chatCommonService.onMessage().subscribe((res) => {
      if (res) {
        const {
          organisationCustomAttributeMappingList,
          configCustomAttributeMappingList,
        } = res;
        setCustomAttributesList(organisationCustomAttributeMappingList);
        setConfigCustomAttributeList(configCustomAttributeMappingList);
        EncryptStorage.setToLocalStorage("TEXT_NOTICE", "");

        configCustomAttributeMappingList &&
          configCustomAttributeMappingList.length > 0 &&
          configCustomAttributeMappingList.map((item) => {
            if (
              item.customAttribute &&
              item.customAttribute.columnName === "IMPROV_TICKETS" &&
              item.customAttributeValue === "true"
            ) {
              EncryptStorage.setToLocalStorage("IMPROV_TICKETS", true);
            }
            if (
              item.customAttribute &&
              item.customAttribute.columnName === "FLASH_DEAL" &&
              item.customAttributeValue === "true"
            ) {
              EncryptStorage.setToLocalStorage("FLASH_DEAL", true);
            }
            if (
              item.customAttribute &&
              item.customAttribute.columnName === "TEXT_NOTICE" &&
              item.customAttributeValue
            ) {
              EncryptStorage.setToLocalStorage(
                "TEXT_NOTICE",
                item.customAttributeValue
              );
            }
          });
      }
    });
  }, [props]);

  useEffect(() => {
    let windowUrl = window.location.pathname;
    let splitWindowLocationPath = windowUrl.split("/");
    if (splitWindowLocationPath[1].length === 0) {
      let tab_Data = document.querySelector(".couserInfoTab");
      if (tab_Data) {
        const WHight =
          window.scrollY + tab_Data && tab_Data.getBoundingClientRect().top;
        courseInfoTab = WHight - window.scrollY;
        window.addEventListener("scroll", toggleVisibility);
      }
      EncryptStorage.setToLocalStorage("partnerDomain", "");
    } else {
      EncryptStorage.setToLocalStorage("partnerDomain", "isActivePartner");
    }
    setAgreeFlag(true);
    setRulesFlag(true);
    setTimeout(() => {
      registerHelper.trackAffliate();
    }, 3000);
    GetOrganizationLogo();
    let wisepopupScript = null;
    /* if (window.location.href && window.location.href.includes("1001")) {
      wisepopupScript = document.createElement("script");
      wisepopupScript.src = WisePopsCall();
      document.body.appendChild(wisepopupScript);
    } */
    EncryptStorage.setToLocalStorage("payLaterPayment", "");
    /* return () => {
      if (wisepopupScript) {
        document.body.removeChild(wisepopupScript);
        let wisepopModal = document.getElementById("wisepops-root");
        wisepopModal && wisepopModal.remove();
        let wisepopupscript = document.getElementById("inner-wise-pops");
        wisepopupscript.remove();
        let scriptList = document.querySelectorAll("script");
        let wisepopCdn = null;
        scriptList &&
          scriptList.length > 0 &&
          scriptList.forEach((item) => {
            if (item.src.includes("//cdn.wisepops.com")) {
              wisepopCdn = item;
            }
          });
        if (wisepopCdn) {
          wisepopCdn.remove();
        }
      }
    }; */
  }, []);

  const getDomainUrl = () => {
    let url = "";
    if (window.location.href.includes("#")) {
      let windowUrl = window.location;
      const domainName = window.location.pathname;
      if (windowUrl.hostname === "localhost") {
        url = environment.baseUIUrl;
      } else {
        debugger;
        if (domainName === "/") {
          url = window.origin;
        } else {
          url = window.origin + stripTrailingSlash(domainName);
        }
      }
    } else {
      url = window.location.origin;
    }

    return url;
  };

  const getConfigCourseId = () => {
    let configCourseId = EncryptStorage.getLocalStorage("configCourseId");
    let getUrl = window.location.href;
    let splitUrl = window.location.href.includes("?")
      ? window.location.href.split("?")
      : false;
    if (configCourseId) {
      //console.log(configCourseId);
    } else {
      if (splitUrl) {
        let replaceUrl = getUrl.replace("#/", "");
        replaceUrl = replaceUrl.replace("'", "");
        let url = new URL(replaceUrl);
        configCourseId = url.searchParams.get("configCourseId")
          ? url.searchParams.get("configCourseId")
          : null;
      }
    }
    localStorage.setItem("configCourseId", configCourseId);
    return configCourseId;
  };

  const getOrganisationLogoSuccessHandler = (res, reasonId) => {
    setMasterData(res.data);
    EncryptStorage.setToLocalStorage(
      "isShowShopperRequired",
      res.data.isShowShopperRequired
    );
    setLoader(false);
    if (res.data) {
      EncryptStorage.setToLocalStorage(
        "isRegistrationDisclaimerRequired",
        res.data.isRegistrationDisclaimerRequired
      );
      setLoader(false);
    }
    if (!reasonId) {
      EncryptStorage.setToLocalStorage("organisationLogo", res.data.logoPath);
      setLoader(false);
    }
    if (res.data && res.data.reason && res.data.reason.reasonName) {
      if (res.data.reason.reasonName === "SPIDER Drivers Education") {
        EncryptStorage.setToLocalStorage("isTrueParentEmail", true);
        setIsTrueParentEmail("Open");
        setLoader(false);
      }
    }
    let isPartner = {
      logoPath: !res.data.isPartnerOrganisation
        ? res.data.logoPath
        : EncryptStorage.getLocalStorage("organisationLogo"),
    };
    EncryptStorage.setToLocalStorage("storeSubjectValue", res.data);
    brandLogoService.sendMessage(isPartner);
    chatCommonService.sendMessage(res.data);
    setOrganisationProperty(res.data);
    setLoader(false);
  };

  const GetOrganizationLogo = (reasonId = "") => {
    let url = getDomainUrl();
    const payload = {};
    let configCourseId = getConfigCourseId();
    let baseUrl = UrlConstants.getOrganisationLogoUrl + `?domain=${url}`;
    if (configCourseId) {
      baseUrl = baseUrl + `&configCourseId=${configCourseId}`;
    }
    setLoader(true);
    GenericAPIService.customAPIMethod(baseUrl, payload, "GET")
      .then((res) => {
        if (HOF_Methods.getStatus(res) === "success") {
          getOrganisationLogoSuccessHandler(res, reasonId);
        } else {
          setErrorMessage(res.status.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        setErrorMessage(Constants.serverErrorMessage);
      });
  };

  const toggleVisibility = () => {
    if (
      courseInfoTab > 0 &&
      window.pageYOffset + window.innerHeight >= Math.ceil(courseInfoTab)
    ) {
      setIsVisible("");
    } else {
      setIsVisible("faq-mobile-fixed");
    }
    if (window.pageYOffset < Math.ceil(373)) {
      setIsNotVisible("");
    }
  };

  const scrollToTop = (value) => {
    window.scrollTo({ top: value, behavior: "smooth" });
    value > 0 ? setIsNotVisible("visible-btn") : setIsNotVisible("");
  };

  const stripTrailingSlash = (str) => {
    return str.endsWith("/") ? str.slice(0, -1) : str;
  };

  const loadCourseInfoDetails = (courseInfoDetails) => {
    if (courseInfoDetails) {
      setCourseDetails(courseInfoDetails);
      EncryptStorage.setToLocalStorage("courseInfoDetails", courseInfoDetails);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const handleSetMethod = (value) => {
    setIsNotVisible(value);
  };

  const checkingAllAttributes = (name) => {
    EncryptStorage.setToLocalStorage("partnerDomain", "isActivePartner");
    let result = null;
    if (customAttributesList && customAttributesList.length > 0) {
      customAttributesList.forEach((item) => {
        if (item.customAttribute.columnName === name) {
          result = item.customAttributeValue;
          item.customAttributeValue === "true"
            ? EncryptStorage.setToLocalStorage("partnerDomain", "")
            : EncryptStorage.setToLocalStorage(
                "partnerDomain",
                "isActivePartner"
              );
        }
      });
      return result;
    } else {
      EncryptStorage.setToLocalStorage("partnerDomain", "isActivePartner");
      return result;
    }
  };

  const createMarkup = (value) => {
    return { __html: value };
  };

  return (
    <>
      <section className="content">
        {loader && (
          <div className="loader">{/* <PropagateLoader size={15} /> */}</div>
        )}
        <div className="custom-container">
          <div
            className={`content-body ${
              checkingAllAttributes("COURSE_INFORMATION") !== "true"
                ? "justify-content-center"
                : ""
            }`}
          >
            <div className="custom-col registration">
              <RegisterForm
                agreeFlag={agreeFlag}
                rulesFlag={rulesFlag}
                setErrorMsg={setErrorMsg}
                masterData={masterData}
                courseDetails={courseDetails}
                isTrueParentEmail={isTrueParentEmail}
                customAttributesList={customAttributesList}
                organisationProperty={organisationProperty}
                configCustomAttributeList={configCustomAttributeList}
                loadCourseInfoDetails={loadCourseInfoDetails}
              />

              {/*  {_localStorage.isShowShopperRequired && ( */}
              <div className="show-sm">
                <ShopperFooter />
              </div>
              {/*  )} */}
            </div>
            {checkingAllAttributes("COURSE_INFORMATION") === "true" ? (
              <div className="custom-col widget ">
                {courseDetails && courseDetails.infoHeader && (
                  <div className="course-details p-0">
                    <div
                      className="course-info-header show-sm"
                      dangerouslySetInnerHTML={createMarkup(
                        courseDetails.infoHeader
                      )}
                    ></div>
                  </div>
                )}
                <button
                  className="accordion-header-v2 course-details-btn show-sm"
                  id="accordion-4"
                  onClick={() => setCourseDetailsShow(!courseDetailsShow)}
                >
                  Course details{" "}
                  <img
                    src={
                      courseDetailsShow ? arrow_down_pruple : arrow_right_pruple
                    }
                  />
                </button>
                <CourseInfoTabs
                  courseInfo={courseDetails}
                  setIsNotVisible={handleSetMethod}
                  myScrollToTop={scrollToTop}
                  masterData={masterData}
                  courseDetailsShow={courseDetailsShow}
                />
              </div>
            ) : null}
          </div>
        </div>
      </section>
      <Modal className="smsModal" centered show={show} onHide={handleClose}>
        <Modal.Body>
          Examples of messages I might receive could include important
          information about your registration, course completion process, and
          completion certificate processing. Messages will never include
          sensitive information such as my driverÃ¢â‚¬â„¢s license and my
          information will never be shared with other vendors. I understand that
          standard text messaging and data rates from my service provider may
          apply under my cell phone service agreement.
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Register;
