import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { UrlConstants, GenericAPIService, HOF_Methods, Constants } from '../common/imports/CommonImport';

function StudentRedirectPayment() {
    const { search } = useLocation();
    const [setLoader] = useState(false);
    const [setErrorMessage] = useState('');
    useEffect(() => {
        const token = new URLSearchParams(search).get('token');
        loadCategoryList(token)
        localStorage.viewPage = 'register';
    }, [])

    const loadCategoryList = (token) => {
        const url = UrlConstants.getSteteWithCategoryListUrl;
        const payload = { token: token };
        setLoader(true)
        GenericAPIService.customAPIMethodClone(url, payload, 'GET').then(res => {
            if (HOF_Methods.getStatus(res) === 'success') {
                setLoader(false)
            } else {
                setErrorMessage(res.status.message);
            }
        }).catch(err => {
            setLoader(false)
            setErrorMessage(Constants.serverErrorMessage);
        });
    }

    return (
        <div>

        </div>
    )
}

export default StudentRedirectPayment
