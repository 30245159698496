import * as Yup from 'yup';
const validationSChema = Yup.object({
    emailId: Yup.string().required('Email Address is required').email('Invalid email'),

});
const initialValues = {
    emailId: '',

}

export const Schema = {
    validationSChema, initialValues
}