import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function ConfrimationModal(props) {
    const { history , showModal} = props;
    const redirectToLogin  = () => {
        localStorage.clear();
        localStorage.clear();
        history.push('/login');
    }

    return (
        <div>
            <Modal show={showModal} centered backdrop='static' keyboard={false} className='modal-white ack-modal'>
                <Modal.Body>
                    <div className='account-lock-cont p-4 mt-4'>
                        <p className='mb-4'> your course is allocated, please login to access</p>
                        <div className='w-100 d-flex justify-content-center'>
                            <Button className='blue-btn' onClick={redirectToLogin}>Login</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
