import SimpleCrypto from "simple-crypto-js";
const secretKey = "F%&qw%&gjasdsHFY&^Rui";

function setToLocalStorage(key, value) {
  let encryptedKey = window.btoa(key);

  if (value === undefined) {
    value = "";
  }

  if (value === null) {
    value = "";
  }

  let encryptedValue = encryptText(value);

  localStorage.setItem(encryptedKey, encryptedValue);
  /* localStorage.setItem(key, value);  */
}

function getLocalStorage(key) {
  let encryptedKey = window.btoa(key);
  let localStorageEncryptedValue = localStorage.getItem(encryptedKey);
  let plainText = "";
  if (localStorageEncryptedValue)
    plainText = decryptText(localStorageEncryptedValue);

  /*   plainText = localStorage.getItem(key);  */
  return plainText;
}

function encryptOnlyKey(key, value) {
  let encryptedKey = window.btoa(key);
  let encryptedValue = value;
  localStorage.setItem(encryptedKey, encryptedValue);
  /*    localStorage.setItem(key, value);  */
}

function decryptOnlyKey(key) {
  let encryptedKey = window.btoa(key);
  let localStorageEncryptedValue = localStorage.getItem(encryptedKey);
  let plainText = "";
  if (localStorageEncryptedValue) plainText = localStorageEncryptedValue;
  /*   plainText = localStorage.getItem(key);  */
  return plainText;
}

function encryptText(plainText) {
  let simpleCrypto = new SimpleCrypto(secretKey);
  return simpleCrypto.encrypt(plainText);
}

function decryptText(text) {
  let simpleCrypto = new SimpleCrypto(secretKey);
  return simpleCrypto.decrypt(text);
}

export const EncryptStorage = {
  setToLocalStorage,
  getLocalStorage,
  encryptOnlyKey,
  decryptOnlyKey,
};
