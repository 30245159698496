import React from "react";
import { EncryptStorage } from "../../services/EncryptStorage";
import moment from "moment";
import md5 from "md5";


export const getTrxLine = (priceItem, price) => {
    return {
        configCoursePrice: priceItem,
        price: price ? price : 0,
    };
}
export const initStorageValue = () => {
    const giftCertificateCodeId = EncryptStorage.getLocalStorage(
        "giftCertificateCodeId"
    );
    const countyLedgerId = EncryptStorage.getLocalStorage("countyLedgerId");
    const discountAmount = EncryptStorage.getLocalStorage("discountAmount");
    const discountType = EncryptStorage.getLocalStorage("discountType");
    const coursePrice = EncryptStorage.getLocalStorage("totalPrice");
    const studentPaymentTrxLines = EncryptStorage.getLocalStorage(
        "studentPaymentTrxLines"
    );
    return {
        giftCertificateCodeId: giftCertificateCodeId,
        countyLedgerId: countyLedgerId,
        discountAmount: discountAmount,
        discountType: discountType,
        coursePrice: coursePrice,
        studentPaymentTrxLines: studentPaymentTrxLines
    }
}
export const getScheduleDate = () => {
    let scheduleDate = EncryptStorage.getLocalStorage("scheduleDate");
    if (!scheduleDate) return "";
    return (
        scheduleDate.split("/")[2] +
        "-" +
        scheduleDate.split("/")[0] +
        "-" +
        scheduleDate.split("/")[1]
    );
}
export const getScheduleTime = () => {
    let scheduleTime = EncryptStorage.getLocalStorage("scheduleTime");
    if (!scheduleTime) return "";
    return moment(scheduleTime, ["h:mm A"]).format("HH:mm:ss");
}

export const payloadMap = (amazon_order_reference_id, finalAmount,
    studentPaymentTrxLines, scheduleDate, countyLedgerId, scheduleTime) => {

    return {
        paymentGateway: "amazon",
        authorizedTransactionId: amazon_order_reference_id,
        totalAmount: finalAmount,
        student: { studentId: EncryptStorage.getLocalStorage("studentId") },
        configCourse: {
            configCourseId: EncryptStorage.getLocalStorage("configCourseId"),
        },
        studentPaymentTrxLines: studentPaymentTrxLines,
        ...(EncryptStorage.getLocalStorage('isAZflow') && { 'isAzPayment': true }),
        ...(scheduleDate && { 'drivingSchedule': scheduleDate + "T" + scheduleTime + ".000+00:00" }),
        ...(countyLedgerId && { 'countyLedgerId': countyLedgerId })
    };
}
export const upsellInitPayload = (finalAmount, braintreePaymentMode, nonce,
    paymentReference, giftCertificateCodeId, studentPaymentTrxLines) => {
    return [{
        totalAmount: finalAmount,
        paymentGateway: braintreePaymentMode,
        nonceFromTheClient: nonce,
        paymentMode: paymentReference,
        paymentReference: paymentReference,
        student: { studentId: EncryptStorage.getLocalStorage("studentId") },
        configCourse: {
            configCourseId: EncryptStorage.getLocalStorage("configCourseId"),
        },
        ...(giftCertificateCodeId && { 'giftCertificateCodeId': giftCertificateCodeId }),
        studentPaymentTrxLines: [...studentPaymentTrxLines]
    }];
}
export const setUpsellWithTotalPrice = (braintreePaymentMode, nonce,
    paymentReference, giftCertificateCodeId, studentPaymentTrxLines) => {
    return {
        totalAmount: EncryptStorage.getLocalStorage('totalPrice_'),
        paymentGateway: braintreePaymentMode,
        nonceFromTheClient: nonce,
        paymentMode: paymentReference,
        paymentReference: paymentReference,
        student: { studentId: EncryptStorage.getLocalStorage("studentId") },
        configCourse: {
            configCourseId: EncryptStorage.getLocalStorage("configCourseId"),
        },
        ...(giftCertificateCodeId && { 'giftCertificateCodeId': giftCertificateCodeId }),
        studentPaymentTrxLines: [...studentPaymentTrxLines]
    };
}
export const UpSellPaymentObjects = (configCourseItem, braintreePaymentMode, nonce, paymentReference, item) => {
    return {
        totalAmount: configCourseItem.price,
        paymentGateway: braintreePaymentMode,
        nonceFromTheClient: nonce,
        paymentMode: paymentReference,
        paymentReference: paymentReference,
        student: { studentId: EncryptStorage.getLocalStorage("studentId") },
        configCourse: {
            configCourseId: item.configCourseId,
        },
        studentPaymentTrxLines: [{
            configCoursePrice: configCourseItem,
            price: configCourseItem.price
        }]
    }
}
export const payloadInitObjectMap = (finalAmount, nonce, _dataCollector,
    paymentDetailsReturn, scheduleDate, scheduleTime, countyLedgerId) => {
    return {
        totalAmount: finalAmount,
        paymentGateway: "braintree",
        nonceFromTheClient: nonce,
        organisationId: EncryptStorage.getLocalStorage('organisationId'),
        currency: 'USD',
        deviceData: _dataCollector.hasOwnProperty("_instance")
            ? _dataCollector._instance.deviceData
            : "",
        studentPaymentTrx: paymentDetailsReturn,
        ...(EncryptStorage.getLocalStorage('isAZflow') && { 'isAzPayment': true }),
        ...(scheduleDate && { 'drivingSchedule': scheduleDate + "T" + scheduleTime + ".000+00:00" }),
        ...(countyLedgerId && { 'countyLedgerId': countyLedgerId })
    };
}
export const studentPaymentTrxMap = (ClonePaymentObj) => {
    let _paymentTrxList = ClonePaymentObj.studentPaymentTrx;
    return _paymentTrxList.map((objs) => {
        objs.studentPaymentTrxId = EncryptStorage.getLocalStorage('studentPaymentTrxId');
        return objs;
    });
}
export const giftCertificateCodeIdValue = () => {
    let giftCertificateCodeId = null;
    let discountResponseObject = EncryptStorage.getLocalStorage("discountResponseObject");
    if (discountResponseObject) {
        if (discountResponseObject.discount && discountResponseObject.discount.giftCertificateCodeId) {
            giftCertificateCodeId = discountResponseObject.discount.giftCertificateCodeId;
        }
    }
    return giftCertificateCodeId;
}
export const initPayloadSetup = () => {
    let _date = new Date();
    let _yyyymmdd = moment(_date).format('YYYY-MM-DD');
    return {
        "configCourseId": EncryptStorage.getLocalStorage("configCourseId"),
        "paymentDate": _yyyymmdd,
        "studentId": EncryptStorage.getLocalStorage("studentId"),
        "studentPaymentTrxId": EncryptStorage.getLocalStorage('studentPaymentTrxId'),
        "totalAmount": EncryptStorage.getLocalStorage('totalPrice'),
        "studentPaymentTrxLines": []
    };
}
export const setAZSeletedCardList = (azSeletedCardList) => {
    return azSeletedCardList.map((obj) => {
        return {
            "configCoursePrice": {
                "configCourse": {
                    "configCourseId": obj.configCourseId
                },
                "configCoursePriceId": obj.configCoursePriceId,
                "isActive": true,
                "isUpsell": obj.isUpsell,
                "percentage": obj.percentage,
                "price": obj.price
            },
            "price": obj.price
        }
    });
}
export const setConfigCourseAndStudentCourseId = (res) => {
    const { data = "" } = res;
    const { studentCourse = "", configCourse = "" } = data;
    const configCourseId = configCourse ? configCourse.configCourseId : "";
    const studentCourseId = studentCourse ? studentCourse.studentCourseId : "";
    EncryptStorage.setToLocalStorage("configCourseId", configCourseId);
    EncryptStorage.setToLocalStorage("studentCourseId", studentCourseId);
    EncryptStorage.setToLocalStorage('addTocardList', []);
}
export const azFlowResponse = (res) => {
    const { data = "" } = res;
    const { studentCourse = "", configCourse = "" } = data;
    const configCourseId = configCourse ? configCourse.configCourseId : "";
    const studentCourseId = studentCourse ? studentCourse.studentCourseId : "";
    EncryptStorage.setToLocalStorage("configCourseId", configCourseId);
    EncryptStorage.setToLocalStorage("studentCourseId", studentCourseId);
}
export const nonAZflowPayloadSetup = (payload) => {
    let tempStudentPaymentTrxId = EncryptStorage.getLocalStorage('studentPaymentTrxId');
    let isUpGradeCourseActive = EncryptStorage.getLocalStorage('isUpGradeCourseActive');
    if (isUpGradeCourseActive) {
        let coursePriceDetails = EncryptStorage.getLocalStorage('coursePriceDetails');
        if (coursePriceDetails &&
            coursePriceDetails.priceItem &&
            coursePriceDetails.priceItem.configCourse &&
            coursePriceDetails.priceItem.configCourse.configCourseId) {
            let tempMainconfigCourseId = coursePriceDetails.priceItem.configCourse.configCourseId;
            let _payload = payload;
            let filterStudentPaymentTrx = [];
            filterStudentPaymentTrx = payload.studentPaymentTrx.filter((obj) => {
                if (tempMainconfigCourseId != obj.configCourse.configCourseId) {
                    return obj;
                }
            });
            _payload.studentPaymentTrx = filterStudentPaymentTrx;
            payload = _payload;
        }
    }
    if (tempStudentPaymentTrxId) {
        if (payload.studentPaymentTrx.length != 0) {
            let tempData = payload.studentPaymentTrx;
            tempData.map((obj) => {
                obj.studentPaymentTrxId = EncryptStorage.getLocalStorage('studentPaymentTrxId');
                return obj;
            });
            payload.studentPaymentTrx = tempData;
        }
    }
    return payload;
}
export const affiliateAndGoogleAnalysisCall = () => {
    // let totalPrice = EncryptStorage.getLocalStorage('totalPrice_') ? EncryptStorage.getLocalStorage('totalPrice_') : null;
    // const userDummy = JSON.parse(localStorage.getItem("registerFormValues"));
    // const studentId = EncryptStorage.getLocalStorage("studentId");
    // if (totalPrice && window.PostAffTracker) {
    //     let sale = window.PostAffTracker.createSale();
    //     sale.setTotalCost(totalPrice);
    //     sale.setOrderID(EncryptStorage.getLocalStorage('studentPaymentTrxId') ? EncryptStorage.getLocalStorage('studentPaymentTrxId') : '');
    //     sale.setProductID(EncryptStorage.getLocalStorage("registerCourseName") ? EncryptStorage.getLocalStorage("registerCourseName") : '');
    //     sale.setData1(EncryptStorage.getLocalStorage("registerFirstName") ? EncryptStorage.getLocalStorage("registerFirstName") : '');
    //     sale.setData2(EncryptStorage.getLocalStorage("registerEmail") ? EncryptStorage.getLocalStorage("registerEmail") : '');
    //     window.PostAffTracker.register();
    // }
    // if (totalPrice && window.dataLayer) {
    //     let stateName = EncryptStorage.getLocalStorage('registerStateName');
    //     let discount = EncryptStorage.getLocalStorage('discountPrice') ? EncryptStorage.getLocalStorage('discountPrice') : 0;
    //     let mainCourseDetails = EncryptStorage.getLocalStorage('primaryCoursePriceDetails');
    //     let upsellCourseDetails = EncryptStorage.getLocalStorage('upsellCoursePriceDetails');
    //     let products = [];
    //     let allPrice = mainCourseDetails ? mainCourseDetails.price : 0;
    //     products.push(mainCourseDetails);
    //     upsellCourseDetails && upsellCourseDetails.length > 0 && upsellCourseDetails.map((item) => {
    //         products.push(item);
    //         allPrice = Number(allPrice) + (item.price ? Number(item.price) : 0);
    //     })
    //     let payload = {
    //         'event': 'transaction',
    //         'state': stateName,
    //         'products': products,
    //         'discount': discount,
    //         'fees': allPrice - discount,
    //         'total_price': allPrice
    //     };
    //     let checkout_payload = {
    //         'event': 'spider_lms_checkout_complete_v2_gtm',
    //         'state': stateName,
    //         'products': JSON.stringify(products),
    //         'discount': discount,
    //         'fees': allPrice - discount,
    //         'total_price': allPrice,
    //         'dummy_user_related_to_the_purchase': userDummy?.emailId,
    //         'studentId': studentId
    //     };
    //     window.dataLayer.push(checkout_payload);
    //     window.dataLayer.push(payload);
    // }
    // referralCandyCall();
}
export const referralCandyCall = () => {
    (function (e) {
        var t, n, r, i, s, o, u, a, f, l, c, h, p, d, v, z;
        z = "script";
        l = "refcandy-purchase-js";
        c = "refcandy-mint";
        p = "go.referralcandy.com/purchase/";
        t = "data-app-id";
        r = { email: "a", fname: "b", lname: "c", amount: "d", currency: "e", "accepts-marketing": "f", timestamp: "g", "referral-code": "h", locale: "i", "external-reference-id": "k", signature: "ab" };
        i = e.getElementsByTagName(z)[0];
        s = function (e, t) {
            if (t) {
                return "" + e + "=" + encodeURIComponent(t)
            }
            else {
                return ""
            }
        };
        d = function (e) {
            return "" + p + h.getAttribute(t) + ".js?aa=75&"
        };
        if (!e.getElementById(l)) {
            h = e.getElementById(c);
            if (h) {
                o = e.createElement(z);
                o.id = l;
                a = function () {
                    var e;
                    e = [];
                    for (n in r) {
                        u = r[n]; v = h.getAttribute("data-" + n);
                        e.push(s(u, v))
                    }
                    return e
                }();
                o.src = "//" + d(h.getAttribute(t)) + a.join("&");
                return i.parentNode.insertBefore(o, i)
            }
        }
    })(document);
}
export const setCourseIdStuId = (res) => {
    const { data = "" } = res;
    const { studentCourse = "", configCourse = "" } = data;
    const configCourseId = configCourse ? configCourse.configCourseId : "";
    const studentCourseId = studentCourse ? studentCourse.studentCourseId : "";
    EncryptStorage.setToLocalStorage("configCourseId", configCourseId);
    EncryptStorage.setToLocalStorage("studentCourseId", studentCourseId);
}

export const GTMSend = (mainCourseDetails, discount, affiliateID) => {
    const user = JSON.parse(localStorage.getItem("registerFormValues"));
    const studentId = EncryptStorage.getLocalStorage("studentId");
    let stateName = EncryptStorage.getLocalStorage('registerStateName');
    let upsellCourseDetails = EncryptStorage.getLocalStorage('upsellCoursePriceDetails');
    let products = [];
    let allPrice = mainCourseDetails ? mainCourseDetails.price : 0;
    products.push(mainCourseDetails);
    upsellCourseDetails && upsellCourseDetails.length > 0 && upsellCourseDetails.map((item) => {
        products.push(item);
        allPrice = Number(allPrice) + (item.price ? Number(item.price) : 0);
    })

    const script = document.createElement('script');
    script.type = 'text/javascript';

    // dummy_user_related_to_the_purchase the template literal is important to have double quotes as the email it self can have a single quote
    script.innerHTML = `
        window.dataLayer.push({
            'event': 'spider_lms_checkout_complete_v2_gtm',
            'state': '${stateName}',
            'products': '${JSON.stringify(products)}',
            'discount': '${discount}',
            'fees': '',
            'total_price': '${allPrice}',
            'dummy_user_related_to_the_purchase': "${user?.emailId}", 
            'studentId': '${studentId}',
            'affiliateID': '${affiliateID}',
        });
    `;

    document.body.appendChild(script);
    referralCandyCall();
    return () => {
    document.body.removeChild(script);
    };
}