import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export default function PaymentRequestedModel(props) {
    const { paymentRequested, paymentRequestedModal, goToPayLater } = props
    return (
        <>
            <Modal show={paymentRequested} centered backdrop="static" keyboard={false} className="modal-white ack-modal">
                <Modal.Body>
                    <Button variant="secondary" className="close-modal" onClick={paymentRequestedModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.5" height="24.5" viewBox="0 0 24.5 24.5">
                            <g id="iconmonstr-x-mark-circle-thin" transform="translate(0.25 0.25)">
                                <path id="iconmonstr-x-mark-circle-thin-2" data-name="iconmonstr-x-mark-circle-thin" d="M12,0A12,12,0,1,1,0,12,12.006,12.006,0,0,1,12,0Zm0,1A11,11,0,1,1,1,12,11.005,11.005,0,0,1,12,1Zm0,10.293L17.293,6,18,6.707,12.707,12,18,17.293,17.293,18,12,12.707,6.707,18,6,17.293,11.293,12,6,6.707,6.707,6,12,11.293Z" fill="#0048a1" stroke="#0048a1" stroke-width="0.5" fill-rule="evenodd" />
                            </g>
                        </svg>
                    </Button>
                    <div className="account-lock-cont p-4 mt-4">
                        <h6>Payment Requested</h6>
                        <p className="mb-4">You requested a Parent / Guardian to pay for you.</p>
                        <div className='w-100 d-flex justify-content-center'>
                            <Button className="blue-btn" onClick={() => goToPayLater("requestEmail")}>Done</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
