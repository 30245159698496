import React, { useEffect, useContext } from "react";
import { Nav } from "react-bootstrap";
import timer2 from "../../../assets/images/icons/timer1.svg";
import { EncryptStorage } from "../../../services/EncryptStorage";
import { CourseTimerContext } from "../../../context";
import { formatTime } from "../../../services/formatTime";

export default function CourseTimer(props) {
  const { history } = props;

  const courseTimerDataContext = useContext(CourseTimerContext);
  const { courseTimer } = courseTimerDataContext;
  useEffect(() => {
    setAlertBoxFunction();
  }, []);
 
  const setAlertBoxFunction = () => {
    const alertBoxValue = !!courseTimer;
    EncryptStorage.setToLocalStorage("alertBox", alertBoxValue);
  };
 

  const checkDashboardPath = (value) => {
    let path = history.location.pathname;
    if (path !== "/home/dashboard" && value === "path")
      return (
        <h5 className="liveTimer">
          {formatTime(courseTimer.courseTime)}
        </h5>
      );
    if (path == "/home/dashboard" && value === "className")
      return "dashboardVisit";
  };

  return (
    <>   
      {courseTimer.courseTime >0 ? (
        <Nav.Item
          className={"timecard green " + checkDashboardPath("className")}
        >
          <img src={timer2} alt="timer" className="timerImage" />
          <div className="item">
            <p className="mb-0"> Max Time Allowed for Today</p>
            <p className="mb-0 displayTime" data-translate="no">
              {formatTime(courseTimer.courseTime)}
            </p>
          </div>
        </Nav.Item>
      ) : (
        <></>
      )}
    </>
  );
}
