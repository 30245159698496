import React, { createContext, useMemo, useState } from "react";


const CourseTimerContext = createContext({});

const initialState = {
  chapterTime: null,
  courseTime: null,
}

const CourseTimerProvider = ({ children }) => {
  const [courseTimer, setCourseTimer] = useState(initialState);

  const memoCourseTimerData = useMemo(
    () => ({ courseTimer, setCourseTimer }),
    [courseTimer]
  );
  return (
    <CourseTimerContext.Provider value={memoCourseTimerData}>
      {children}
    </CourseTimerContext.Provider>
  );
};

export { CourseTimerProvider, CourseTimerContext };
