import React from 'react';

export const renderBHWLPriceGroupName = (row, showItemTransaction, groupIndex, getPirceValue) => {
    if (row.bhwlPrice.bhwlItems.length != 0) return (
        <div className='item'>
            <div className='header'>
                <p>
                    {row.coursePriceType.name}
                    <span className='ml-3 cursor-pointer edit' onClick={() => showItemTransaction(groupIndex)}>
                        {showActionText()}
                    </span>
                </p>
                <p>
                    <span>${getPirceValue(row)}</span>
                </p>
            </div>
        </div>
    );
    return null

    function showActionText() {
        if (!row.showItem) return (row.bhwlPrice.bhwlItems.length > 1 ? 'Edit' : 'View');
        return 'Done';
    }
}

export const renderMailPriceGroupName = (row, showItemTransaction, groupIndex, setActionText, getPirceValue) => {
    if (row.mailPrice.mailItems.length != 0) return (
        <>
            {
                row.coursePriceType.name ? <div className='item'>
                    <p className='gray-title'>Certificate Delivery</p>
                    <div className='header'>
                        <p>
                            {row.coursePriceType.name}
                            <span className='ml-3 cursor-pointer edit' onClick={() => showItemTransaction(groupIndex)}>
                                {setActionText(row)}
                            </span>
                        </p>
                        <p>
                            <span>${getPirceValue(row)}</span>
                        </p>
                    </div> </div> : null
            }
        </>
    )
    return null;
}
export const findtempDiscountPrice = (el, tempFinalPrice, basePrice) => {
    
    let tempDiscountPrice = 0;
    if (el.discount.discountId) {
        if (el.discount.isPercentageBased) {
            if (el.discount.percentage == 100) {
                tempDiscountPrice = (el.discount.percentage / 100) * tempFinalPrice;
            }
            else {
                tempDiscountPrice =(el.discount.percentage / 100) * tempFinalPrice;
						
								
            }
        }
        else {
            tempDiscountPrice = el.discount.price;
        }
    }
    else {
        if (el.discount.isValueByPercentage) {
				
            if (el.discount.percentage) {
                if (el.discount.percentage == 100) {
                    tempDiscountPrice = (el.discount.percentage / 100) * tempFinalPrice;
                }
                else {
                    tempDiscountPrice = (el.discount.percentage / 100) * basePrice;
                }
            } else {
                if (el.discount.value == 100) {
                    tempDiscountPrice = (el.discount.value / 100) * tempFinalPrice;
                } else {
                    tempDiscountPrice = (el.discount.value / 100) * basePrice;
                }
            }
        }
        else {
            tempDiscountPrice = el.discount.value;
        }
    }
    return tempDiscountPrice;
}