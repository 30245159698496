import React, { useState } from 'react'
import { _const } from '../components/constant/const-variables/constVariable';

const { FLAGS_NATIONALS, NATIONAL_LANGUAGE_TEXT } = _const;

const useShowNationalFlag = (country = NATIONAL_LANGUAGE_TEXT.UNITEDSTATE) => {
  const [flagNationalCurrent, setFlagNationalCurrent] = useState(country);

  const showNationalFlag = () => {
    return FLAGS_NATIONALS[flagNationalCurrent] || "";
  };
  return {
    setFlagNationalCurrent,
    flagNationalCurrent,
    showNationalFlag: showNationalFlag(),
  };
};

export default useShowNationalFlag;