import axios from 'axios';
const baseUrl = 'https://elmsstudent-apim-stage.azure-api.net/improv-elms-student-evs/'
const instance = axios.create({
});
const post = (apiUrl, payload) => {

    let url = baseUrl + apiUrl;
    return instance(url, {

        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "POST",
            "Access-Control-Allow-Headers": "Content-Type",
            //  "Ocp-Apim-Subscription-Key": "d5056a1f5fd94bada2f1bc00ecd4359b",
        },
        data: payload,
    })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

const get = (apiUrl) => {
    let url = baseUrl + apiUrl;
    return axios(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
        }
    })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}


export const GenericAPIServiceWithToken = {
    post, get,
}
