import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Alert, Modal } from 'react-bootstrap';
import TextInputField from '../common/forms/TextInputField';
import LockIcon from '@material-ui/icons/Lock';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { VisibilityOutlinedIcon, VisibilityOffOutlinedIcon } from './../common/assets/Images';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { Formik } from 'formik'
import { Schema } from './Schema';
import { UrlConstants, GenericAPIService, Constants, HOF_Methods } from '../common/imports/CommonImport';

const ResetPassword = (props) => {
    const { history } = props;
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        const token = getUrlParam();
        if (!token) history.push('/login');
    }, []);

    const getUrlParam = () => {
        let resp = null;
        let url = window.location.href;
        if (url.includes('?')) {  
            let params = url.split("?")[1]
            let paramValue = params.split("=")[1]
            resp = paramValue.split("&")[0];         
        }
        return resp;
    }

    const onSubmit = (values) => {
        const token = getUrlParam();
        setErrorMessage('');
        if (token) {
            const payload = {
                "resetToken": token,
                "password": values.confirmPassword
            }
            setLoader(true);
            const url = UrlConstants.resetPasswordUrl
            GenericAPIService.customAPIMethod(url, payload, 'POST').then(res => {
                setLoader(false);
                if (HOF_Methods.getStatus(res) == 'success') {
                    setMessage(res.status.message);
                    setTimeout(() => {
                        history.push('/login');
                        setMessage('');
                    }, 4000);
                } else setErrorMessage(res.status.message);

            }).catch(error => {
                setLoader(false)
                setErrorMessage(Constants.serverErrorMessage);
            });
        } else {
            setErrorMessage(Constants.serverErrorMessage);
        }
    }

    return (
        <div className="changePassword__container">
            <div className="changePassword">
                <h3 className="blue-color font-weight-bolder text-center  mb-2 mb-md-4 pb-2">Reset Password</h3>
                <div className="container">
                    {loader && <div className="loader">
                        <PropagateLoader size={15} />
                    </div>}
                    {message ? <Modal show={true} backdrop="static" keyboard={false}>
                        <Modal.Body className="messageModal">
                            <Alert variant={'success'}>
                                <span className="success-icon"><CheckCircleIcon /></span>
                                <span className="danger-icon"><LockIcon /></span>
                                <span> {message}</span>
                            </Alert>
                        </Modal.Body>
                    </Modal> : null}
                    {!message ? <div className="changePassword__card">
                        <Formik
                            initialValues={Schema.initialValues}
                            validationSchema={Schema.validationSChema}
                            onSubmit={onSubmit}>
                            {({ handleSubmit }) => (
                                <Form onSubmit={handleSubmit} noValidate>
                                    {errorMessage && <div className="custom-error">{errorMessage}</div>}
                                    <Row className="align-items-center RegisterForm">
                                        <Col md={12} xl={12}>
                                            <div className="TextInputField">
                                                <TextInputField name="newPassword" inputProps={{ maxLength: 30 }} label=" New Password *" type={showNewPassword ? 'text' : "password"} />
                                                <i className="TextInputField__icon" onClick={() => setShowNewPassword(!showNewPassword)}>
                                                    {showNewPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}</i>
                                            </div>
                                        </Col>
                                        <Col md={12} xl={12}>
                                            <div className="TextInputField">
                                                <TextInputField name="confirmPassword" inputProps={{ maxLength: 30 }} label="Confirm Password*" type={showConfirmPassword ? 'text' : "password"} />
                                                <i className="TextInputField__icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                    {showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}</i>
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="register__button  mt-4">
                                                <button type="submit" className="btn green w-100  mx-4 justify-content-center text-uppercase font-weight-bold">Submit</button>
                                            </div>
                                        </Col>

                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </div> : null}
                </div>
            </div>
        </div>
    )
}
export default ResetPassword