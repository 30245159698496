import React from "react";
import environment from "../../environments/environment";
import { EncryptStorage } from "../../services/EncryptStorage";


export const getMinits = (hours, minutes) => {
   return parseInt(hours) * 60 + parseInt(minutes);
};
export const makeSec = (min) => {
   return parseInt(min) * 60;
};
export const getSecWiseTime = (value) => {
   const sec = parseInt(value, 10);
   let hours = Math.floor(sec / 3600); // get hours
   let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
   if (hours < 10) {
      hours = "0" + hours;
   }
   if (minutes < 10) {
      minutes = "0" + minutes;
   }
   return hours + ":" + minutes; // Return is HH : MM : SS
};
export const IframePlayer = ({ launchUrl = "", chapterStatusForConBtn = "" }) => {

   let courseStateId = EncryptStorage.getLocalStorage('courseStateId') ? EncryptStorage.getLocalStorage('courseStateId').toString() : '0';
   let configCourseId = EncryptStorage.getLocalStorage('configCourseId') ? EncryptStorage.getLocalStorage('configCourseId').toString() : '0';

   console.log(launchUrl);
   if (!launchUrl) return null;
   return (
      <iframe
         width="100%"
         allowFullScreen={true}
         id="iframeDOM"
         className="iframe-style"
         src={environment.newChapterContentStateIds.includes(courseStateId) || environment.newChapterContentConfigCourseIds.includes(configCourseId) ? environment.courseBucketUrl + launchUrl : environment.bucketUrl + launchUrl}
         title="chapter content"
      ></iframe>
   );
};
export const checkIsTrue = (value) => {
   let res = false;
   if (value != undefined && value.toString() === "true") {
      res = true;
   }
   return res;
};
