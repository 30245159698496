import React from 'react'
import { UrlConstants } from '../../common/imports/CommonImport'
import { Button } from 'react-bootstrap';
import { add_cart, audioGirl, check_purple, girlPointing, new_close, plusIcon, speakerAudioIcon, surprisedGirl, trash } from '../../common/assets/Images';

export default function UpsellCourse(props) {

    const { upsellCourseList, resetCoursePriceList, upsellCoursePriceList, setIsAudioUpgradeAdded, checkValidAudioCourseForNewYork } = props;

    const getBasePrice = (itemToCheck) => {
        const newPrice = itemToCheck.basePrice - 0;
        return (itemToCheck.basePrice) ? newPrice.toFixed(2) : 0.00;
    }

    return (
      <>
        {upsellCourseList.length > 0 &&
          upsellCourseList.map((item, index) => (
            <>
              {checkValidAudioCourseForNewYork(
                item.upsellConfigCourse.course.name
              ) ? (
                <div className="course-cart-upgrade-container" key={index}>
                  <div class="course-audio-upgrade-image-container">
                    <img
                      src={audioGirl}
                      alt="Surprised Girl"
                      className="course-cart-girl"
                    />
                    <img
                      src={speakerAudioIcon}
                      alt="Audio Icon"
                      className="course-cart-speaker-icon"
                    />
                  </div>
                  <div className="course-cart-description-container">
                    <div class="course-top">
                      <div class="course-text">
                        <p className="course-cart-description-title-label">
                          Upgrade Your Learning Journey with Our{" "}
                          <span>AUDIO COURSE</span>
                        </p>
                        <p className="course-cart-description-label">
                          <b>Course made easy</b> - just listen, no need to
                          read.
                        </p>
                      </div>
                    </div>
                    <div class="course-bottom course-bottom-aditional-config">
                      <div class="price-num price-num-aditional-config">
                        <p className="course-audio-upgrade-only-label">ONLY</p>
                        <div
                          class="discounted discounted-aditional-config"
                          data-uw-rm-sr="base-price"
                        >
                          ${getBasePrice(item)} <span></span>
                        </div>
                      </div>
                      <div class="cart-btn-block cart-btn-block-aditional-config">
                        {item.currentActive ? (
                          <>
                            <div class="delete">
                              <Button
                                onClick={() => {
                                  resetCoursePriceList(item, index);
                                  setIsAudioUpgradeAdded(false);
                                }}
                              >
                                <img src={trash} alt="trash" />
                              </Button>
                            </div>
                            <div class="added">
                              <Button>
                                <img src={check_purple} alt="add to cart" />{" "}
                                Added
                              </Button>
                            </div>
                          </>
                        ) : (
                          <div
                            class="add-to-cart"
                            onClick={() => {
                              upsellCoursePriceList(item, index);
                              setIsAudioUpgradeAdded(true);
                            }}
                          >
                            <Button
                              onClick={() => {
                                upsellCoursePriceList(item, index);
                                setIsAudioUpgradeAdded(true);
                              }}
                            >
                              <img src={add_cart} alt="add to cart" /> Add to
                              cart
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div class="course-item" key={index}>
                  <div class="cart-course-img">
                    <img
                      className="show-lg"
                      src={
                        UrlConstants.imageBaseUrl +
                        item.upsellConfigCourse.course.logoPath
                      }
                      alt="upsell-course-img"
                    />
                    <img
                      className="show-sm"
                      src={
                        UrlConstants.imageBaseUrl +
                        item.upsellConfigCourse.course.logoPath
                      }
                      alt="upsell-course-img"
                    />
                  </div>
                  <div class="course-desc">
                    <div class="course-top">
                      <div class="course-text">
                        <h2>{item.upsellConfigCourse.course.name}</h2>
                        <p>{item.upsellConfigCourse.course.description}</p>
                        
                      </div>
                    </div>
                    <div class="course-bottom">
                      <div class="price-num">
                        {item &&
                        item.crossPrice &&
                        item.crossPrice.toString() != "0" ? (
                          <div class="crossed" data-uw-rm-sr="crossout-price">
                            ${item.crossPrice.toFixed(2)}
                          </div>
                        ) : null}
                        <div class="discounted" data-uw-rm-sr="base-price">
                          ${getBasePrice(item)} <span>*</span>
                        </div>
                      </div>
                      <div class="cart-btn-block">
                        {item.currentActive ? (
                          <>
                            <div class="delete">
                              <Button
                                onClick={() => {
                                  resetCoursePriceList(item, index);
                                }}
                              >
                                <img src={trash} alt="trash" />
                              </Button>
                            </div>
                            <div class="added">
                              <Button>
                                <img src={check_purple} alt="add to cart" />{" "}
                                Added
                              </Button>
                            </div>
                          </>
                        ) : (
                          <div
                            class="add-to-cart"
                            onClick={() => {
                              upsellCoursePriceList(item, index);
                            }}
                          >
                            <Button
                              onClick={() => {
                                upsellCoursePriceList(item, index);
                              }}
                            >
                              <img src={add_cart} alt="add to cart" /> Add to
                              cart
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
      </>
    );
}


