import React from 'react';
import { EncryptStorage } from '../../common/imports/CommonImport';

function initStore(){    
    EncryptStorage.setToLocalStorage('scheduleDate', '');
    EncryptStorage.setToLocalStorage('scheduleTime', '');
    EncryptStorage.setToLocalStorage('isUpGradeCourseFlag', '');
    EncryptStorage.setToLocalStorage('isUpGradeCourseActive', false);
    EncryptStorage.setToLocalStorage("skipPage", false);
    EncryptStorage.setToLocalStorage('appliedDiscountObject', '');
    localStorage.tempDiscode = '';
    EncryptStorage.setToLocalStorage('discountResponseObject', '');
    localStorage.testCoursePriceDetails = '';
    localStorage.testSeletedUpsellCourseDetails = '';
}

export const InitStorageSetup = {
    initStore
}