import environment from '../../environments/environment';
import { QAUrlConstants } from './QAUrlConstant';
import { DevUrlConstants } from './DevUrlConstant';
import { ProdUrlConstants } from './ProdUrlConstant';
import { StageUrlConstant } from './StageUrlConstant';
import { NewStageUrlConstants } from './NewStageUrlConstant';
import { NewProdUrlConstants } from './NewProdUrlConstants';

const env_name = environment.profileName.toString();

const qa = QAUrlConstants;
const dev = DevUrlConstants;
const prod = ProdUrlConstants;
const stage = StageUrlConstant;
const newstage = NewStageUrlConstants;
const newprod = NewProdUrlConstants;

const getEndpoint = (name) => {
    switch (name.toLocaleLowerCase()) {
        case 'dev':
          return newstage;
        case 'qa':
            return qa;
        case 'prod':
            return prod;
        case 'stage':
            return stage;
        case 'newstage':
            return newstage;
        case 'newprod':
            return newprod;
        default:
            return null
    }
}

var _apiEndpoint = getEndpoint(env_name);


export class UrlConstants {

    /*baseImgae */
    static imageBaseUrl = environment.imageBaseUrl;
    /* Registration */
    static registrationUrl = _apiEndpoint.registrationUrl;
    static emailVerificationUrl = _apiEndpoint.emailVerificationUrl;
    static emailResendVerificationUrl = _apiEndpoint.emailResendVerificationUrl;
    static getOrganisationLogoUrl = _apiEndpoint.getOrganisationUrl;
    /* Login */
    static initloginUrl = _apiEndpoint.initloginUrl;
    static loginUrl = _apiEndpoint.loginUrl;
    static googleSignUrl = _apiEndpoint.googleSignUrl;
    static changePasswordUrl = _apiEndpoint.changePasswordUrl;
    static forgotPasswordUrl = _apiEndpoint.forgotPasswordUrl;
    static resetPasswordUrl = _apiEndpoint.resetPasswordUrl;
    /* Logout */
    static logoutUrl = _apiEndpoint.logoutUrl
    /* State and Category List */
    static getSteteWithCategoryListUrl = _apiEndpoint.getSteteWithCategoryListUrl
    static getSteteListUrl = _apiEndpoint.getSteteListUrl
    static courseMappingUrl = _apiEndpoint.courseMappingUrl
    static getCourseList = _apiEndpoint.getCourseList
    static getPaymentClientToken = _apiEndpoint.getPaymentClientToken;
    static checkoutUrl = _apiEndpoint.checkoutUrl;
    static upsellCheckoutUrl = _apiEndpoint.upsellCheckoutUrl;
    static parentCheckoutUrl = _apiEndpoint.parentCheckoutUrl
    static getCourseInfoDetails = _apiEndpoint.getCourseInfoDetails
    static getCoursePriceDetails = _apiEndpoint.getCoursePriceDetails
    static getStudentDetails = _apiEndpoint.getStudentDetails
    static getFlexifieldsList = _apiEndpoint.getFlexifieldsList
    static saveflexifield = _apiEndpoint.saveflexifield;
    static saveDiversionFeeFlexiField = _apiEndpoint.saveDiversionFeeFlexiField;

    /* Dashboard */
    static getUpSellApi = _apiEndpoint.getUpSellApi;
    static saveAZUpsellUrl = _apiEndpoint.saveAZUpsellUrl;
    static dashboardListOfChaptersUrl = _apiEndpoint.dashboardListOfChaptersUrl
    static chapterLaunch = _apiEndpoint.chapterLaunch;
    static resetCourseUrl = _apiEndpoint.resetCourseUrl
    /* SecurityQuestion */
    static typingDnaSave_verify = _apiEndpoint.typingDnaSave_Verify
    static saveSecurityQuestionUrl = _apiEndpoint.saveSecurityQuestionUrl
    /* SecurityQuestion */
    static getSecurityQuestionsUrl = _apiEndpoint.getSecurityQuestionsUrl
    static getRandomSecurityQuestionUrl = _apiEndpoint.getRandomSecurityQuestionUrl
    /*Chapter Quiz */
    static getChapterQuizListUrl = _apiEndpoint.getChapterQuizListUrl
    static updateChapterStatus = _apiEndpoint.updateChapterStatus;
    static saveFinalQuizResult = _apiEndpoint.saveFinalQuizResult;
    static getStudentPurchaseDetails = _apiEndpoint.getStudentPurchaseDetails;
    static chapter_CourseStatusUpdateUrl = _apiEndpoint.chapter_CourseStatusUpdateUrl;
    /* completedmessageUrl */
    static studentCourseCompletedMessageUrl = _apiEndpoint.studentCourseCompletedMessageUrl;
    /* Discount code check*/
    static getStateWiseDiscountDetailsUrl = _apiEndpoint.getStateWiseDiscountDetailsUrl;
    static giftCertificateUrl = _apiEndpoint.giftCertificateUrl;
    static sacramentoCourtCourseUrl = _apiEndpoint.sacramentoCourtCourseUrl;
    static studentPaymentRefundUrl = _apiEndpoint.studentPaymentRefundUrl;

    /* Courses Dashboard*/

    static multiCourseDashboardUrl = _apiEndpoint.multiCourseDashboardUrl;
    static upsellcoursesUrl = _apiEndpoint.upsellcoursesUrl;

    /* chapter or Final Quiz Validation */
    static chapterFinalQuizValidationUrl = _apiEndpoint.chapterFinalQuizValidationUrl;
    static downloadPaymentReceiptUrl = _apiEndpoint.downloadPaymentReceiptUrl;
    static emailPaymentReceiptUrl = _apiEndpoint.emailPaymentReceiptUrl;

    /* studentfootprint */
    static studentfootprintUrl = _apiEndpoint.studentfootprintUrl;

    /* support-ticket */
    static supportTicketUrl = _apiEndpoint.supportTicketUrl;

    static zipperUrl = '/zipperapi/';

    /*Document*/
    static getCatalogItemsUrl = _apiEndpoint.getDocumentCatalogItems;
    static getDocumentListUrl = _apiEndpoint.getDocumentList;
    static saveDocumentUrl = _apiEndpoint.saveDocument;
    static saveWebcamUrl = _apiEndpoint.saveWebcamUrl;
    static updateStudentCourseTime = _apiEndpoint.updateStudentCourseTime

    /*evs */
    static time_managementUrl = _apiEndpoint.time_managementUrl;
    static universalCheckoutUrl = _apiEndpoint.universalCheckoutUrl;

     /*Tilled Payment */
     static tilledPaymentMethodUrl = _apiEndpoint.createPaymentMethod;

    /*parent-verification*/
    static parentVerificationPostUrl=_apiEndpoint.parentVerificationPostUrl;

    //certificate download
    static certificateDownloadUrl = _apiEndpoint.certificateDownloadUrl
}