import {
    DateFnsUtils, MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '../../../common/assets/UIComponents';
import React from 'react'
import { Col } from 'react-bootstrap';
import { nullChecker, renderFieldErrors } from '../utils';

export default function NewDatepickerField(props) {

    const { setFlexiFields, flexiFields, row, ind, minDate, setFieldValue, setOtherInfoErrorMessage,
        errors, touched, findCitationDueDateBeforeSevenDate } = props;

    const datePickerEvent = (Event) => {
        let temp = row;
        temp.value = Event ? Event : null;
        let tempArray = [...flexiFields];
        tempArray[ind] = temp;
        setFlexiFields([]);
        setFlexiFields(tempArray);
        if (row.flexiFieldName == 'CITATIONDUEDATE' && Event) {
            findCitationDueDateBeforeSevenDate(Event);
        }
    }

    return (
        <div className="field-col" key={ind}>
            {/* <div className="custom-form-control"> */}
            {props.isFastLane && (
                <p className="profile-register-field-label">
                    {row.flexiFieldDescription}
                </p>
            )}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker required={row.isMandatoryField} placeholder={row.label + '(mm-dd-yyyy)'} margin="normal" id={row.id}
                    name={row.id} inputVariant="outlined" format="MM-dd-yyyy"
                    minDate={row.id !== "CITATIONDATE" && minDate} minDateMessage={true} value={nullChecker(row.value)} defaultValue={null}
                    onChange={e => { datePickerEvent(e); setFieldValue(row.id, e); setOtherInfoErrorMessage(''); }} />
                {renderFieldErrors(errors, row, touched)}
            </MuiPickersUtilsProvider>
            {/* </div> */}
        </div>

    )
}
