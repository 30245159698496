import React, { useEffect, useState } from 'react'
import { PropagateLoader } from "../../cartSummary/CardSummaryHelper/CartSummaryImport";

export default function LoaderWithMessage() {
    const [currentPhrase, setCurrentPhrase] = useState("");
    const phrases = [
        "Your course is loading... Did you know? The first speeding ticket was issued in 1902. The speed? A thrilling 45 mph!",
        "Hold tight, we're getting your course ready! Fun fact: In 1895, the first-ever car race was won at a breakneck speed of 15 mph.", 
        "Just a moment more... Did you know the first cars didn't have steering wheels? Drivers had to steer with a lever!",
        "Your driving adventure is about to start! Trivia: The world's first traffic signal was installed in 1914 in Cleveland, Ohio.",
        "Loading your course... In the meantime, did you know electric cars were popular in the 1900s before gasoline cars took over?",
        "Course loading... Guess what? The inventor of cruise control was blind. Talk about visionary!",
        "Revving up your course! Fun fact: The world's first driver's education course began in 1934 at a high school in State College, Pennsylvania.",
        "Almost there... Did you know? In the early 1900s, drivers were required to have a person walk in front of their car, waving a red flag!",
        "Preparing your driving journey... Trivia: The first car radio was introduced in 1929. Imagine driving in silence before that!",
        "Your course is on its way! Did you know the term 'dashboard' comes from horse-drawn carriages, where it prevented mud from 'dashing' onto drivers?",
        "Hang tight, we're nearly there! Fun fact: In Japan, some street lights are blue because historically, there wasn't a word for 'green.'",
        "Course loading... Here's a fun one: The world's longest traffic jam lasted for 12 days in 2010 in Beijing, stretching over 62 miles!",
        "Almost ready... Did you know? The New York City taxi was originally red and green before it was painted the iconic yellow.",
        "Just a bit longer... Trivia: The first seat belt wasn't introduced until the 1950s. Safety first!",
        "Your driving course is coming up! Fun fact: Volkswagen owns twelve well-known car brands from seven European countries.",
        "Preparing your course... Did you know that in Sweden, people drive on the right-hand side of the road, but until 1967, it was the left?",
        "Loading... Here's a quirky one: In 2013, Nevada was the first state to authorize self-driving cars. The future is now!",
        "Your course is just around the corner... Trivia: The first car accident occurred in 1891 in Ohio.",
        "Hold on, we're almost there! Did you know? Karl Benz's wife funded the development of the first gasoline-powered car.",
        "Just finalizing... Fun fact: The 'new car smell' is actually a mix of over 50 volatile organic compounds. Unique, isn't it?",
        "Tuning up your course... Did you know? The first gas gauge in cars wasn't introduced until the 1920s. Before that, drivers had to guess!",
        "Getting your course road-ready... Fun fact: The first electric traffic light was installed in 1914. Imagine the roads without them!",
        "Just a moment more... Trivia: The highest recorded mileage on a car is over 3 million miles. Talk about a long journey!",
        "Your course is almost at the starting line... Did you know? In some countries, driver's licenses can be written in up to 12 languages.",
    ];

    useEffect(() => {
        setCurrentPhrase(phrases[getRandomIndex()]);
    }, []);

    const getRandomIndex = () => {
        const min = 0;
        const max = phrases.length;
        return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
      }

    return (
        <div className="loader priority loader-message-container">
            <p className='loader-message-label'>{currentPhrase}</p>
            <PropagateLoader size={15} />
        </div>
    )
}
