import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react'
import Slider from "react-slick";
import { Rating } from 'react-simple-star-rating'
import { leftArr, rightArr } from '../common/assets/Images';
import moment from 'moment';

function RegisterShopperSlider({ sliderCount, variant, rating }) {

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 8000,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
    };

    const sliderRef = useRef();

    const [reviewList, setReviewList] = useState([]);

    useEffect(() => {
        getShopperReview();
    }, [])

    const getShopperReview = () => {
        const siteId = 11612
        const key = 'HsDc9KQF8VMmPxS'
        let url = `https://api.shopperapproved.com/reviews/${siteId}?token=${key
            }&rating=4%2C5&limit=50&test=false&xml=false`

        axios.get(url).then(function (response) {
            let arr = Object.entries(response.data);
            let result = arr.map(item => {
                if (item[1].initial_comments) return item[1]
            })
            let comments = result.filter(item => { return item })
            setReviewList(comments);
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    const goToNext = () => {
        sliderRef.current.slickNext();
    }

    const goToPrev = () => {
        sliderRef.current.slickPrev();
    }

    const getFormattedDate = (dateToEdit) => {
        const date = new Date(dateToEdit);
        const dateToShow = moment(date).format("ddd, DD MMM YYYY");
        return dateToShow;
    }

    return (
        <div className="carouseldiv">
            <Slider {...settings} ref={sliderRef}>
                {
                    reviewList && reviewList.map((review, index) =>
                        <div className="slide" key={`slide-${index}`}>
                            <div className="slideContent">
                                <Rating className='slide-rating' initialValue={review.overall} size={22} readonly={true} allowHover={false} fillColor="#F6BC19" />
                                <p> {review.comments}</p>
                                <div className="author">
                                    <p>{review.display_name}</p>
                                    <p className="date">{getFormattedDate(review.review_date)}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
            </Slider>
            <div className="navigation">
                <div className="prev-arrow" onClick={() => goToPrev()}><img src={leftArr} /></div>
                <div className="next-arrow" onClick={() => goToNext()}><img src={rightArr} /></div>
            </div>
        </div>
    )
}

export default RegisterShopperSlider 