import React from 'react'
import { EncryptStorage } from '../../common/imports/CommonImport';
import nopath from '../../../assets/images/nopath.png';
import { add_cart, check_purple, info_circle, trash } from '../../common/assets/Images';
import { Button } from 'react-bootstrap';

export default function BHWLItemList(props) {

    const { pItem, ind, handleItemCheckPackage, row, groupIndex } = props;

    return (
        <>
            <div class="course-item" key={pItem.coursePriceTypeId}>
                {/* <BHWLItemList ind={ind} pItem={pItem} /> */}
                <div class="cart-course-img">
                    <img className='show-lg' src={nopath} />
                    <img className='show-sm' src={nopath} />
                </div>
                <div class="course-desc">
                    <div class="course-top">
                        <div class="course-text">
                            <h2>{pItem.bhwlName ? pItem.bhwlName : `Package ${ind + 1}`}</h2>
                            <p>{pItem.bhwlDescription ? pItem.bhwlDescription : "This online defensive driving course will reduce your point total by up to 4 points upon course completion."}</p>
                        </div>
                        {/* <div class="question">
                            <img src={info_circle} />
                        </div> */}
                    </div>
                    <div class="course-bottom">
                        <div class="price-num">
                            <div class="discounted" data-uw-rm-sr="">
                                ${pItem.price.toFixed(2)} <span>*</span>
                            </div>
                        </div>
                        <div class="cart-btn-block">
                            {
                                pItem.selected === true ? (
                                    <>
                                        <div class="delete" >
                                            <Button onClick={() => handleItemCheckPackage(row, groupIndex, pItem, ind, 'Bhwl')}>
                                                <img src={trash} alt="trash" />
                                            </Button>
                                        </div>
                                        <div class="added">
                                            <Button>
                                                <img src={check_purple}
                                                    alt="added" /> Added
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <div class="add-to-cart">
                                        <Button onClick={() => handleItemCheckPackage(row, groupIndex, pItem, ind, 'Bhwl')}>
                                            <img src={add_cart}
                                                alt="add to cart" /> Add to cart
                                        </Button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
