import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import RegisterForm from "./RegisterFormcopy";
import { brandLogoService } from "../../services/brandLogoService";
import { chatCommonService } from "../../services/chatCommonService";
import {
  HOF_Methods,
  GenericAPIService,
  EncryptStorage,
  Constants,
  UrlConstants,
} from "../common/imports/CommonImport";
import { registerHelper } from "./registerHelper";
import { _localStorage } from "../constant/storage/localStorage";
import FastLaneTab from "./FastLane/FastLaneTab";
import FastLaneFAQ from "./FastLane/FastLaneFAQ";
import ShopperFooter from "../common/ShopperFooter";
import environment from "../../environments/environment";
import { Modal } from "react-bootstrap";
import LoaderWithMessage from "./FastLane/LoaderWithMessage";
import EmptyEmailModalFastLane from "./FastLane/FastLaneTabModals/EmptyEmailModalFastLane";

const RegisterNew = (props) => {
  const [loader, setLoader] = useState(false);
  const [courseDetails, setCourseDetails] = useState("");
  const [agreeFlag, setAgreeFlag] = useState(true);
  const [rulesFlag, setRulesFlag] = useState(true);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isVisible, setIsVisible] = useState("faq-mobile-fixed");
  const [isNotVisible, setIsNotVisible] = useState("");
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isTrueParentEmail, setIsTrueParentEmail] = useState("Close");
  const [masterData, setMasterData] = useState([]);
  const [organisationProperty, setOrganisationProperty] = useState([]);
  const [customAttributesList, setCustomAttributesList] = useState([]);
  const [configCustomAttributeList, setConfigCustomAttributeList] = useState(
    []
  );
  const [courseDetailsShow, setCourseDetailsShow] = useState(false);
  const handleClose = () => setShow(false);
  const history = useHistory();
  const [otherInfo, setShowOtherInfo] = useState(false);
  const [orgCustomAttributeLists, setOrgCustomAttributeLists] = useState([]);
  const [isStateFollowActive, setIsStateFollowActive] = useState(false);
  const [acceptValue, setAcceptValue] = useState(true);
  const [flexiFields, setFlexiFields] = useState([]);
  const [studentRegistrationData, setStudentRegistrationData] = useState("");
  const [tempStudentId, setTempStudentId] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const [registeredErrorMessage, setRegisteredErrorMessage] = useState("");
  const [GSAAlreadyRegister, setGSAAlreadyRegister] = useState(false);
  const [orgProperty, setOrgProperty] = useState([]);
  const [smsChecked, setSmsChecked] = useState(true);
  const [emailChecked, setEmailChecked] = useState(true);
  const [smsTextMessageModal, setSmsTextMessageModal] = useState(false);
  const [emailVerification, setEmailVerification] = useState(false);
  const [bypassPaymentLoader, setBypassPaymentLoader] = useState(false);
  const [isActiveSkipEmailVerification, setIsActiveSkipEmailVerification] = useState(false);
  const [courseHeader, setCourseHeader] = useState("");
  const [showExistingEmailModal, setShowExistingEmailModal] = useState(false);
  const [showEmptyEmailModal, setShowEmptyEmailModal] = useState(false);
  const [phoneForRegistration, setPhoneForRegistration] = useState("");
  const [courseName, setCourseName] = useState("");
  const [modalEmail, setModalEmail] = useState("");
  const [modalPhone, setModalPhone] = useState("");
  const [utmObjectUS, setUtmObjectUS] = useState({});
  let isMailVerified = true;

  let courseInfoTab = 0;

  useEffect(() => {
    registerHelper.redirection();
  }, []);

  useEffect(() => {
    setUtmObjectUS(registerHelper.getIDsFromURL(agreeFlag, rulesFlag));
  }, []);

  // this hook form feting custom Attribute current updated array and updating to the state veriable
  useEffect(() => {
    chatCommonService.onMessage().subscribe((res) => {
      if (res) {
        const {
          organisationCustomAttributeMappingList,
          configCustomAttributeMappingList,
        } = res;

        setCustomAttributesList(organisationCustomAttributeMappingList);
        setConfigCustomAttributeList(configCustomAttributeMappingList);
        EncryptStorage.setToLocalStorage("TEXT_NOTICE", "");

        configCustomAttributeMappingList &&
          configCustomAttributeMappingList.length > 0 &&
          configCustomAttributeMappingList.map((item) => {
            if (
              item.customAttribute &&
              item.customAttribute.columnName == "IMPROV_TICKETS" &&
              item.customAttributeValue == "true"
            ) {
              EncryptStorage.setToLocalStorage("IMPROV_TICKETS", true);
            }

            if (
              item.customAttribute &&
              item.customAttribute.columnName == "FLASH_DEAL" &&
              item.customAttributeValue == "true"
            ) {
              EncryptStorage.setToLocalStorage("FLASH_DEAL", true);
            }
            if (
              item.customAttribute &&
              item.customAttribute.columnName == "TEXT_NOTICE" &&
              item.customAttributeValue
            ) {
              EncryptStorage.setToLocalStorage(
                "TEXT_NOTICE",
                item.customAttributeValue
              );
            }
          });
      }
    });
  }, [props]);

  useEffect(() => {
    let windowUrl = window.location.pathname;
    let splitWindowLocationPath = windowUrl.split("/");
    if (splitWindowLocationPath[1].length === 0) {
      let tab_Data = document.querySelector(".couserInfoTab");
      if (tab_Data) {
        const WHight =
          window.scrollY + tab_Data && tab_Data.getBoundingClientRect().top;
        courseInfoTab = WHight - window.scrollY;
        window.addEventListener("scroll", toggleVisibility);
      }
      EncryptStorage.setToLocalStorage("partnerDomain", "");
    } else {
      EncryptStorage.setToLocalStorage("partnerDomain", "isActivePartner");
    }
    setAgreeFlag(true);
    setRulesFlag(true);
    setTimeout(() => {
      registerHelper.trackAffliate();
    }, 3000);
    GetOrganizationLogo();
    EncryptStorage.setToLocalStorage("payLaterPayment", "");

    const isRegisterFastLane = localStorage.getItem("isRegisterFastLane");
    const stepFastLane = localStorage.getItem("stepFastLane");

    if (isRegisterFastLane === "true" && stepFastLane === "2") {
      history.push("/home/fast-lane-cart-summary");
    } else if (isRegisterFastLane === "true" && stepFastLane === "3") {
      history.push("/home/view-billing-details");
    } else if (isRegisterFastLane === "true" && stepFastLane === "4") {
      history.push("/home/fast-lane-personal-information");
    } else if (isRegisterFastLane === "true" && stepFastLane === "5") {
      history.push("/home/dashboard");
    }
  }, []);

  // useEffect(() => {
  //   setModalEmail("");
  //   setModalPhone("");
  // }, [showExistingEmailModal]);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      window.pagesense = window.pagesense || [];
      window.pagesense.push(['trackEvent', 'Initial Fast Lane Page']);
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [courseName]);

  // useEffect(() => {
  //   if (phoneForRegistration !== ""){
  //     registerStudentPhone();
  //   }
  // }, [phoneForRegistration]);

  const getDomainUrl = () => {
    let url = "";
    if (window.location.href.includes("#")) {
      let windowUrl = window.location;
      const domainName = window.location.pathname;
      if (windowUrl.hostname === "localhost") {
        url = environment.baseUIUrl;
      } else {
        if (domainName == "/") {
          url = window.origin;
        } else {
          url = window.origin + stripTrailingSlash(domainName);
        }
      }
    } else {
      url = window.location.origin;
    }
    if (window.origin == "https://8a7a-38-41-40-10.ngrok-free.app") {
      url = environment.baseUIUrl;
    }

    return url;
  };
  const stripTrailingSlash = (str) => {
    return str.endsWith("/") ? str.slice(0, -1) : str;
  };
  const getConfigCourseId = () => {
    let configCourseId = EncryptStorage.getLocalStorage("configCourseId");
    let getUrl = window.location.href;
    let splitUrl = window.location.href.includes("?")
      ? window.location.href.split("?")
      : false;
    if (configCourseId) {
      //console.log(configCourseId);
    } else {
      if (splitUrl) {
        let replaceUrl = getUrl.replace("#/", "");
        replaceUrl = replaceUrl.replace("'", "");
        let url = new URL(replaceUrl);
        configCourseId = url.searchParams.get("configCourseId")
          ? url.searchParams.get("configCourseId")
          : null;
      }
    }
    localStorage.setItem("configCourseId", configCourseId);
    return configCourseId;
  };

  const getStateId = () => {
    let stateId;
    let getUrl = window.location.href;
    let replaceUrl = getUrl.replace("#/", "");
    replaceUrl = replaceUrl.replace("'", "");
    let url = new URL(replaceUrl);
    stateId = url.searchParams.get("stateId") ? url.searchParams.get("stateId") : "99";
    return stateId;
  };

  const getOrganisationLogoSuccessHandler = (res, reasonId) => {
    setMasterData(res.data);
    EncryptStorage.setToLocalStorage(
      "isShowShopperRequired",
      res.data.isShowShopperRequired
    );
    setLoader(false);
    if (res.data) {
      EncryptStorage.setToLocalStorage(
        "isRegistrationDisclaimerRequired",
        res.data.isRegistrationDisclaimerRequired
      );
      setLoader(false);
    }
    if (!reasonId) {
      EncryptStorage.setToLocalStorage("organisationLogo", res.data.logoPath);
      setLoader(false);
    }
    if (res.data && res.data.reason && res.data.reason.reasonName) {
      if (res.data.reason.reasonName == "SPIDER Drivers Education") {
        EncryptStorage.setToLocalStorage("isTrueParentEmail", true);
        setIsTrueParentEmail("Open");
        setLoader(false);
      }
    }
    let isPartner = {
      logoPath: !res.data.isPartnerOrganisation
        ? res.data.logoPath
        : EncryptStorage.getLocalStorage("organisationLogo"),
    };
    EncryptStorage.setToLocalStorage("storeSubjectValue", res.data);
    brandLogoService.sendMessage(isPartner);
    chatCommonService.sendMessage(res.data);
    setOrganisationProperty(res.data);
    setLoader(false);
  };

  const GetOrganizationLogo = (reasonId = "") => {
    let url = getDomainUrl();
    const payload = {};
    let configCourseId = getConfigCourseId();
    let baseUrl = UrlConstants.getOrganisationLogoUrl + `?domain=${url}`;
    if (configCourseId) {
      baseUrl = baseUrl + `&configCourseId=${configCourseId}`;
    }
    //setLoader(true);

    GenericAPIService.customAPIMethod(baseUrl, payload, "GET")
      .then((res) => {
        if (HOF_Methods.getStatus(res) === "success") {
          getOrganisationLogoSuccessHandler(res, reasonId);
        } else {
          setErrorMessage(res.status.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        setErrorMessage(Constants.serverErrorMessage);
      });
  };

  const toggleVisibility = () => {
    if (
      courseInfoTab > 0 &&
      window.pageYOffset + window.innerHeight >= Math.ceil(courseInfoTab)
    ) {
      setIsVisible("");
    } else {
      setIsVisible("faq-mobile-fixed");
    }
    if (window.pageYOffset < Math.ceil(373)) {
      setIsNotVisible("");
    }
  };

  const scrollToTop = (value) => {
    window.scrollTo({ top: value, behavior: "smooth" });
    value > 0 ? setIsNotVisible("visible-btn") : setIsNotVisible("");
  };

  const loadCourseInfoDetails = (courseInfoDetails) => {
    if (courseInfoDetails) {
      setCourseDetails(courseInfoDetails);
      EncryptStorage.setToLocalStorage("courseInfoDetails", courseInfoDetails);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const handleSetMethod = (value) => {
    setIsNotVisible(value);
  };

  const checkingAllAttributes = (name) => {
    EncryptStorage.setToLocalStorage("partnerDomain", "isActivePartner");
    let result = null;
    if (customAttributesList && customAttributesList.length > 0) {
      customAttributesList.forEach((item) => {
        if (item.customAttribute.columnName === name) {
          result = item.customAttributeValue;
          item.customAttributeValue === "true"
            ? EncryptStorage.setToLocalStorage("partnerDomain", "")
            : EncryptStorage.setToLocalStorage(
                "partnerDomain",
                "isActivePartner"
              );
        }
      });
      return result;
    } else {
      EncryptStorage.setToLocalStorage("partnerDomain", "isActivePartner");
      return result;
    }
  };

  const registerProcess = () => {
    setLoader(true);
    let tempvalues = [];

    tempvalues.firstName = "";
    tempvalues.lastName = "";
    if (modalEmail !== "") {
      EncryptStorage.setToLocalStorage("studentEmail", modalEmail);
      tempvalues.emailId = modalEmail;
    } else {
      setLoader(false);
      setShowEmptyEmailModal(true);
      return;
      //tempvalues.emailId = "fastLaneEmail" + Date.now() + "@testmail.com";
    }
    tempvalues.isStudentOptMail = emailChecked;
    tempvalues.isStudentOptSms = smsChecked;
    if (modalPhone !== "") {
      tempvalues.mobileNumber = modalPhone;
      setPhoneForRegistration(modalPhone);
    } else {
      tempvalues.mobileNumber = "";
    }

    tempvalues.parentEmail = "";
    tempvalues.password = "Password@123";
    tempvalues.state = { stateId: getStateId() };

    if (utmObjectUS.a_aid === ""){
      utmObjectUS.a_aid = utmObjectUS.PAPAffiliateId;
    }

    const tempObject = { ...tempvalues, ...utmObjectUS };

    if (agreeFlag && rulesFlag) {
      setErrorMsg(false);
      localStorage.setItem("registerFormValues", JSON.stringify(tempObject));
      localStorage.setItem("registerFormValidated", true);
    } else {
      setLoader(false);
      localStorage.setItem("registerFormValidated", false);
      setErrorMsg(true);
    }

    let registerFormValidated = localStorage.getItem("registerFormValidated")
      ? JSON.parse(localStorage.getItem("registerFormValidated"))
      : false;
    let flexiFieldValidated = localStorage.getItem("flexiFieldValidated")
      ? JSON.parse(localStorage.getItem("flexiFieldValidated"))
      : false;
    let savedValues = localStorage.getItem("registerFormValues")
      ? JSON.parse(localStorage.getItem("registerFormValues"))
      : {};

    if (otherInfo) {
      checkOtherInfo(registerFormValidated, flexiFieldValidated, savedValues);
    } else {
      setLoader(true);
      studentRegistration(savedValues, "formSubmit");
    }
  };

  const checkOtherInfo = (
    registerFormValidated,
    flexiFieldValidated,
    savedValues
  ) => {
    if (registerFormValidated && flexiFieldValidated) {
      localStorage.setItem("flexiFieldValidated", false);
      localStorage.setItem("registerFormValidated", false);
      studentRegistration(savedValues, "formSubmit");
    } else {
      if (
        registerFormValidated.toString() == "true" &&
        flexiFieldValidated.toString() == "false"
      ) {
        if (document.getElementById("OtherCourt")) {
          if (document.getElementById("OtherCourt").value != "") {
            document.getElementById("flexiFieldFormBtn").click();
          }
        }
      }
    }
  };

  const studentRegistration = (values, type = "") => {
    let isVerifiedValue = registerHelper.emailVerificationEnabled(
      orgCustomAttributeLists
    );
    isMailVerified = isVerifiedValue ? false : true;
    let withStateId = registerHelper.getStudentRegisterPayloadForWithStateId(
      values,
      isMailVerified
    );
    let formValue = registerHelper.setStudentRegisterFormValue(
      values,
      isMailVerified
    );
    let url = UrlConstants.registrationUrl;
    setErrorMessage("");
    let newPayloadMap = registerHelper.setStudentRegisterPayloadValue(
      isStateFollowActive,
      withStateId,
      formValue,
      values
    );
    let registerVia = EncryptStorage.getLocalStorage("registerVia");
    if (registerVia == "sacramento") {
      newPayloadMap = Object.assign(newPayloadMap, {
        configCourseId: EncryptStorage.getLocalStorage("configCourseId"),
        countyLedgerId: EncryptStorage.getLocalStorage("countyLedgerId"),
      });
    } else {
      newPayloadMap = Object.assign(newPayloadMap, {
        configCourseId: EncryptStorage.getLocalStorage("configCourseId"),
      });
    }

    if (
      (values.isStudentOptSms === true && acceptValue === true) ||
      (values.isStudentOptSms === false && acceptValue === false) ||
      values.subjectIssuer
    ) {
      setLoader(true);
      if (values && values.subjectIssuer) {
        url = UrlConstants.googleSignUrl;
        let new_Payload = {};
        new_Payload.requestType = "registration";
        new_Payload.organisationId =
          EncryptStorage.getLocalStorage("organisationId");
        new_Payload.configCourseId =
          EncryptStorage.getLocalStorage("configCourseId");
        new_Payload.subjectIssuer = values.subjectIssuer;
        new_Payload.subjectToken = values.subjectToken;
        createNewStudentRegisterApiCall(url, new_Payload);
      } else {
        createNewStudentRegisterApiCall(url, newPayloadMap);
      }
    } else {
      checkIsTwilioRequiredFlag(url, newPayloadMap);
    }

    if (EncryptStorage.getLocalStorage("studentPaymentTrxLines")) {
    }

    EncryptStorage.setToLocalStorage("loggedIn", true);
  };

  const createRegisterApiPayload = (temp_RequestPayload) => {
    if (otherInfo && flexiFields.length != 0) {
      const configCourseId = EncryptStorage.getLocalStorage("configCourseId");
      let tempFlexiFieldObject = [];
      for (const item of flexiFields) {
        if (registerHelper.checkCountyObejct()) {
          if (item.flexiFieldName != "COURT") {
            tempFlexiFieldObject = registerHelper.setNewStudentRegisterPayload(
              tempFlexiFieldObject,
              item,
              configCourseId
            );
          }
        } else {
          tempFlexiFieldObject = registerHelper.setNewStudentRegisterPayload(
            tempFlexiFieldObject,
            item,
            configCourseId
          );
        }
      }
      if (registerHelper.checkCountyObejct()) {
        let othrObj = localStorage.OtherCountyObject;
        let tempOthrObj = JSON.parse(othrObj);
        tempOthrObj.refFieldValue = document.getElementById("OtherCourt").value;
        tempFlexiFieldObject.push(JSON.parse(othrObj));
      }
      temp_RequestPayload.flexifieldList = tempFlexiFieldObject;
    }
    return temp_RequestPayload;
  };

  const createNewStudentRegisterSuccessHandler = (res) => {
    let studentId = registerHelper.setUserObjectAndStudentId(res);
    const { token = "" } = res;
    setTempStudentId(studentId);
    isMailVerified
      ? EncryptStorage.encryptOnlyKey("token", token)
      : EncryptStorage.encryptOnlyKey("tokenCopy", token);

    if (res && res.data && res.data?.isEmailVerficationSkipped) {
      EncryptStorage.encryptOnlyKey("token", token);
      EncryptStorage.encryptOnlyKey("tokenCopy", token);
    }

    if (configCustomAttributeList && configCustomAttributeList.length > 0) {
      let _configCustomAttributeList = configCustomAttributeList.filter(
        (object) => {
          if (object.customAttribute.columnName == "AZ_FLOW") {
            return 1;
          }
        }
      );
      if (_configCustomAttributeList.length == 0) {
        if (registerHelper.checkTrue(res.data.isEligible)) {
          cardSummaryRedirect(res.data);
        }
        if (registerHelper.checkFalse(res.data.isEligible)) {
          //errorLogin(res.status.message);
          setLoader(false);
          setShowExistingEmailModal(true);
        }
        if (res.data.isEligible == undefined) {
          cardSummaryRedirect(res.data);
        }
      } else {
        if (registerHelper.checkTrue(res.data.isEligible)) {
          azCardSummaryRedirect(res.data);
        }
        if (registerHelper.checkFalse(res.data.isEligible)) {
          errorLogin(res.status.message);
        }
        if (res.data.isEligible == undefined) {
          azCardSummaryRedirect(res.data);
        }
      }
    } else {
      if (registerHelper.checkTrue(res.data.isEligible)) {
        cardSummaryRedirect(res.data);
      }
      if (registerHelper.checkFalse(res.data.isEligible)) {
        //errorLogin(res.status.message);
        setLoader(false);
        setShowExistingEmailModal(true);
      }
      if (res.data.isEligible == undefined) {
        cardSummaryRedirect(res.data);
      }
    }
  };

  const createNewStudentRegisterApiCall = (url, newPayloadMap) => {
    setLoader(true);
    let tempRequestPayload = createRegisterApiPayload(newPayloadMap);
    GenericAPIService.customAPIMethod(url, tempRequestPayload, "POST")
      .then((res) => {
        if (res && res.data) {
          EncryptStorage.setToLocalStorage(
            "fastLaneStudentID",
            res.data.studentId
          );
          EncryptStorage.setToLocalStorage(
            "fastLaneKeyCloakUserID",
            res.data.keycloakUserID
          );
          setStudentRegistrationData(res.data);
          EncryptStorage.setToLocalStorage("papalUserInfo", "");
          if (HOF_Methods.getStatus(res) === "success") {
            createNewStudentRegisterSuccessHandler(res);
            setErrorMessage(res.status.message);
          } else {
            setLoader(false);
            setErrorMessage(res.status.message);
            if (
              registerHelper.checkTrue(res.data.islicenseplateEligible) ||
              res.status.message ==
                "Please enter a valid license plate to register for the course"
            ) {
              setErrorMessage("Please enter a valid vehicle tag");
            } else {
              if (
                res.status.message.includes(
                  "We could not register you to the course"
                )
              ) {
                errorLogin(res.status.message);
              } else if (
                res.status.message ==
                "Email id already registered with us please contact support team for registering to this course"
              ) {
                setAlertShow(true);
                setErrorMessage("");
                setRegisteredErrorMessage(res.status.message);
                setTimeout(() => {
                  setAlertShow(false);
                  setLoader(false);
                  setShowExistingEmailModal(true);
                  setRegisteredErrorMessage("");
                }, 5000);
              } else {
                let result = null;
                if (
                  res.status.message.includes("GSA Driver Safety course") ||
                  !res.status.message.includes("GSA Vehicle Tag Number") ||
                  res.status.message.includes("Email id already registered")
                ) {
                  setGSAAlreadyRegister(true);
                  setRegisteredErrorMessage(res.status.message);
                  //setTimeout(() => {
                  setAlertShow(false);
                  //if (res.status.message.includes("Email id already registered")){
                  setLoader(false);
                  setShowExistingEmailModal(true);
                  //}
                  //else{
                  //  redirectToLogin();
                  //}
                  setRegisteredErrorMessage("");
                  //}, 3000);
                } else {
                  result =
                    res.status.message.includes("Duplicate Email Id") ||
                    res.status.message.includes("already registered");
                  result
                    ? setErrorMessage("Mail Id already exists")
                    : setErrorMessage(res.status.message);
                }
              }
            }
          }
        } else setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        setShowExistingEmailModal(true);
        setErrorMessage(err?.status?.message);
      });
  };

  const registerStudentPhone = () => {
    let phonePayload = {
      mobileNumber: phoneForRegistration,
      message: `Thanks for signing up for the IMPROV Driver Course! Just noticed your $5 promo discount wasn't applied. Please complete your registration at www.MyIMPROV.com using PROMO Code 2912ARP. Hurry, this discount expires at midnight today! 🕛🚗`,
    };
    let registerPhoneURL =
      "/improv-student-registration-journey/student/twilio/send-message";

    GenericAPIService.customAPIMethod(registerPhoneURL, phonePayload, "POST")
      .then((res) => {
        if (HOF_Methods.getStatus(res) !== "success") {
          console.log(res.status.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(Constants.serverErrorMessage);
      });
  };

  const checkIsTwilioRequiredFlag = (url, newPayloadMap) => {
    if (orgProperty.isTwilioRequired) {
      if (!smsChecked) {
        setLoader(false);
        if (
          newPayloadMap &&
          newPayloadMap.student &&
          newPayloadMap.student.mobileNumber
        ) {
          setLoader(true);
          createNewStudentRegisterApiCall(url, newPayloadMap);
        } else {
          setSmsTextMessageModal(!smsTextMessageModal);
        }
      } else {
        setLoader(true);
        createNewStudentRegisterApiCall(url, newPayloadMap);
      }
    } else {
      setLoader(true);
      createNewStudentRegisterApiCall(url, newPayloadMap);
    }
  };

  const cardSummaryRedirect = async (el) => {
    localStorage.setItem("IsLoggedIn", "true");
    localStorage.setItem("registerFlow", "true");
    localStorage.RootPage = "Register";
    EncryptStorage.setToLocalStorage("isAZflow", false);
    let res = await checkingAllAttributes("PAYMENT_BYPASS");
    if (res == "true") {
      //skip email verification
      if (el?.isEmailVerficationSkipped) {
        EncryptStorage.setToLocalStorage("isEmailVerficationSkipped", true);
        paymentProcess();
      } else {
        if (isMailVerified) {
          paymentProcess();
        } else {
          setEmailVerification("paymentProcess");
        }
        EncryptStorage.setToLocalStorage("isEmailVerficationSkipped", false);
      }
    } else {
      EncryptStorage.setToLocalStorage(
        "redirectUrlName",
        "/home/fast-lane-cart-summary"
      );
      localStorage.setItem("isRegisterFastLane", true);
      localStorage.setItem("stepFastLane", "2");
      //skip email verification
      if (el?.isEmailVerficationSkipped) {
        history.push("/home/fast-lane-cart-summary");
        EncryptStorage.setToLocalStorage("isEmailVerficationSkipped", true);
      } else {
        isMailVerified
          ? history.push("/home/fast-lane-cart-summary")
          : setEmailVerification(true);
        EncryptStorage.setToLocalStorage("isEmailVerficationSkipped", false);
      }
    }
  };

  const errorLogin = (message) => {
    setLoader(false);
    EncryptStorage.setToLocalStorage("registrationcompleted", false);
    localStorage.setItem("IsLoggedIn", "false");
    localStorage.setItem("registerFlow", "false");
    EncryptStorage.setToLocalStorage("isAZflow", false);
    setErrorMessage(message);
    //setTimeout(() => {

    history.push("/login");
    //}, 1000);
  };

  const azCardSummaryRedirect = (el) => {
    localStorage.setItem("IsLoggedIn", "true");
    localStorage.setItem("registerFlow", "true");
    localStorage.RootPage = "Register";
    EncryptStorage.setToLocalStorage("isAZflow", true);
    EncryptStorage.setToLocalStorage(
      "redirectUrlName",
      "/home/az-cart-summary"
    );

    //skip email verification
    if (el?.isEmailVerficationSkipped) {
      history.push("/home/az-cart-summary");
      EncryptStorage.setToLocalStorage("isEmailVerficationSkipped", true);
    } else {
      isMailVerified
        ? history.push("/home/az-cart-summary")
        : setEmailVerification(true);
      EncryptStorage.setToLocalStorage("isEmailVerficationSkipped", false);
    }
  };

  const redirectToLogin = () => {
    localStorage.clear();
    window.localStorage.clear();

    history.push("/login");
  };

  const paymentProcess = () => {
    setBypassPaymentLoader(true);
    let requestPayload = registerHelper.setPaymentProcessPayload();
    let checkoutUrl = UrlConstants.checkoutUrl;
    GenericAPIService.customAPIMethod(checkoutUrl, requestPayload, "POST")
      .then((res) => {
        setBypassPaymentLoader(false);
        if (HOF_Methods.getStatus(res) === "success") {
          localStorage.setItem("IsLoggedIn", "true");
          localStorage.setItem("registerFlow", "true");
          localStorage.RootPage = "Register";
          EncryptStorage.setToLocalStorage("isAZflow", false);
          const { data = "" } = res;
          const { studentCourse = "" } = data;
          const studentCourseId = studentCourse
            ? studentCourse.studentCourseId
            : "";
          EncryptStorage.setToLocalStorage("studentCourseId", studentCourseId);
          const isEmailVerficationSkipped = EncryptStorage.getLocalStorage(
            "isEmailVerficationSkipped"
          );
          if (isEmailVerficationSkipped) {
            setIsActiveSkipEmailVerification(true);
          } else {
            getPersonalInfo();
          }
        } else {
          setErrorMessage(res.status.message);
          toast(res.status.message);
          handleClose();
        }
      })
      .catch((err) => {
        handleClose();
        setBypassPaymentLoader(false);
        setErrorMessage(Constants.serverErrorMessage);
      });
  };

  const getPersonalInfo = () => {
    setLoader(true);
    let studentId = EncryptStorage.getLocalStorage("studentId");
    const payload = {
      studentId: studentId,
    };
    const url = UrlConstants.getStudentDetails;
    GenericAPIService.customAPIMethod(url, payload, "GET")
      .then((res) => {
        if (HOF_Methods.getStatus(res) === "success") {
          const { data = "" } = res;
          if (data) {
            EncryptStorage.setToLocalStorage(
              "isPersonalInfoPending",
              data.isPersonalInfoPending
            );
            const isEmailVerficationSkipped = EncryptStorage.getLocalStorage(
              "isEmailVerficationSkipped"
            );
            if (data.defaultStPaymentTrxId) {
              EncryptStorage.setToLocalStorage(
                "studentPaymentTrxId",
                data.defaultStPaymentTrxId
              );
              history.push("/home/dashboard");
            }
          }
        } else {
          handleClose();
          setErrorMessage(res.status.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        handleClose();
        setLoader(false);
        setErrorMessage(Constants.serverErrorMessage);
      });
  };

  const internalPaymentMethodCallback = (value) => {
    setCourseHeader(value);
  };

  const confirmLoginModal = () => {
    setShowExistingEmailModal(false);
    redirectToLogin();
  };

  const confirmCreateModal = () => {
    //setModalEmail("");
    //setModalPhone("");
    setShowExistingEmailModal(false);
  };

  return (
    <section className="register-new-content-config">
      {loader && <LoaderWithMessage />}

      <div className="custom-container custom-container-config">
        <div
          className={`content-body ${
            checkingAllAttributes("COURSE_INFORMATION") !== "true"
              ? "justify-content-center"
              : ""
          }`}
        >
          <div className="custom-col registration flex-1">
            <RegisterForm
              agreeFlag={agreeFlag}
              rulesFlag={rulesFlag}
              setErrorMsg={setErrorMsg}
              masterData={masterData}
              courseDetails={courseDetails}
              isTrueParentEmail={isTrueParentEmail}
              customAttributesList={customAttributesList}
              organisationProperty={organisationProperty}
              configCustomAttributeList={configCustomAttributeList}
              loadCourseInfoDetails={loadCourseInfoDetails}
              internalPaymentMethodCallback={internalPaymentMethodCallback}
              courseName={courseName}
              setCourseName={setCourseName}
            />
          </div>
          <div style={{ order: 2 }} className="flex-1 register-new-column">
            <div className="register-new-fast-lane-options">
              <FastLaneTab
                smsChecked={smsChecked}
                setSmsChecked={setSmsChecked}
                courseInfo={courseDetails}
                setIsNotVisible={handleSetMethod}
                myScrollToTop={scrollToTop}
                masterData={masterData}
                courseDetailsShow={courseDetailsShow}
                registerProcess={registerProcess}
                modalEmail={modalEmail}
                setModalEmail={setModalEmail}
                modalPhone={modalPhone}
                setModalPhone={setModalPhone}
              />
              <div className="register-new-faq-container">
                <FastLaneFAQ
                  courseDetails={courseDetails}
                  courseHeader={courseHeader}
                />
              </div>
              <div className="shopper-hided-on-desktop">
                <ShopperFooter />
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <EmptyEmailModalFastLane
        showEmptyEmailModal={showEmptyEmailModal}
        setShowEmptyEmailModal={setShowEmptyEmailModal}
      />
      <Modal
        show={showExistingEmailModal}
        centered
        backdrop="static"
        keyboard={false}
        className="existing-email-login-modal"
      >
        <Modal.Body className="existing-email-login-modal-body">
          <div className="existing-email-login-modal-title-container">
            <div className="existing-email-login-modal-title">
              Looks like you are already registered
            </div>
            <div className="existing-email-login-modal-subtitle">
              Would you like to log in to your existing account or create a new
              account with a different email?
            </div>
          </div>
          <div className="existing-email-login-modal-button-container">
            <button
              className="existing-email-login-modal-login-button"
              onClick={confirmLoginModal}
            >
              Log in with existing account
            </button>
            <div className="existing-email-login-modal-subtitle">
              To create a New Account, please use a different Email Address
            </div>
            <button
              className="existing-email-login-modal-create-button"
              onClick={confirmCreateModal}
            >
              Continue creating a new account
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};
export default RegisterNew;
