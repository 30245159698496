import React, { useState, useEffect } from "react";
import { Alert, Modal } from "react-bootstrap";
import { CheckCircleIcon, CancelIcon } from "../common/assets/Images";
import { withRouter } from "react-router-dom";
import {
  GenericAPIService,
  EncryptStorage,
  UrlConstants,
  Constants,
  HOF_Methods,
} from "../common/imports/CommonImport";
import PropagateLoader from "react-spinners/PropagateLoader";
import { chatCommonService } from "../../services/chatCommonService";
import { toast, ToastContainer } from "react-toastify";
import { registerHelper } from "./registerHelper";
import EmailVerification from "./emailVerification/EmailVerification";
import CoruseExpired from "./modal/CoruseExpired";
import RegularLoader from "../loader/Loader";
import FastLaneCourseInfo from "./FastLane/FastLaneCourseInfo";
import FastLaneCartDetail from "./FastLane/FastLaneCartDetail";
import LoaderWithMessage from "./FastLane/LoaderWithMessage";

const getPaymentLoader = (
  <div className="loader payment-loader">
    <PropagateLoader size={15} style={{ zIndex: 1 }} />
    <div>
      <p>
        Your course allocation in progress,{" "}
        <strong>PLEASE DONT PRESS BACK BUTTON</strong>
      </p>
    </div>
  </div>
);

function RegisterForm(props) {
  const { history, loadCourseInfoDetails, courseDetails, internalPaymentMethodCallback, courseName, setCourseName } = props;
  const storeSubjectValue = EncryptStorage.getLocalStorage("storeSubjectValue");
  localStorage.viewPage = "register";
  localStorage.RootPage = "";
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [otherInfo, setShowOtherInfo] = useState(false);
  const [flexiFields, setFlexiFields] = useState([]);
  const [orgProperty, setOrgProperty] = useState([]);
  const [isRegistrationDisclaimer, setIsRegistrationDisclaimer] =
    useState(false);
  const [registrationDisclaimerData, setRegistrationDisclaimerData] = useState(
    []
  );
  const [show, setShow] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cloneIsTrueParentEmail, setCloneIsTrueParentEmail] = useState(
    EncryptStorage.getLocalStorage("isTrueParentEmail")
  );
  const [isStateFollowActive, setIsStateFollowActive] = useState(false);
  const [tempStateID, setTempStateID] = useState(null);
  const [orgCustomAttributeLists, setOrgCustomAttributeLists] = useState([]);
  const [initOrganisationId, setInitOrganisationId] = useState(null);
  const [isMobileNumberEnable, setIsMobileNumberEnable] = useState(true);
  const [alertShow, setAlertShow] = useState(false);
  const [registeredErrorMessage, setRegisteredErrorMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [loaderType, setLoaderType] = useState(false);

  useEffect(() => {
    setCloneIsTrueParentEmail(
      EncryptStorage.getLocalStorage("isTrueParentEmail")
    );
  }, [props.isTrueParentEmail]);

  useEffect(async () => {
    EncryptStorage.setToLocalStorage("domainRestriction", "");
    EncryptStorage.setToLocalStorage("isSecurityQuestionVerifiedToday", "");
    EncryptStorage.setToLocalStorage("isAZflow", false);
    EncryptStorage.setToLocalStorage("trackPlatform", false);
    await getWordpressParam();
  }, []);

  useEffect(() => {
    const stateId = EncryptStorage.getLocalStorage("stateId");
    const organisationId = EncryptStorage.getLocalStorage("organisationId");
    setTempStateID(stateId);
    if (stateId == "1" && organisationId == "1001") {
      loadStateList();
      setIsStateFollowActive(true);
      getFlexiFieldInfo(stateId);
    } else {
      getFlexiFieldInfo(stateId);
      setIsStateFollowActive(false);
    }
  }, [isStateFollowActive]);

  useEffect(() => {
    chatCommonService.onMessage().subscribe((res) => {
      if (res) {
        registerHelper.domainRestriction(res);
        let resRemove_Fields = checkingAllAttributes("REMOVE_FIELDS");
        if (resRemove_Fields == "true") {
          setIsMobileNumberEnable(false);
        } else {
          setIsMobileNumberEnable(true);
        }
      }
      if (res && res.reason && res.reason.reasonName) {
        if (res.reason.reasonName == "SPIDER Drivers Education") {
          setIsRegistrationDisclaimer(false);
        } else {
          setIsRegistrationDisclaimer(true);
        }
      }
      if (res) {
        setRegistrationDisclaimerData(res);
        setOrgCustomAttributeLists(res.organisationCustomAttributeMappingList);
        setOrgProperty(res);
      }
    });
  }, [props]);

  const getLoader = (
    <div className="loader text-center">
      {loaderType ? (
        <LoaderWithMessage />
      ) : (
        <RegularLoader value={progress} />
      )}
    </div>
  );

  const getStateName = (stateId = "") => {
    const payload = {};
    const url = UrlConstants.getSteteListUrl + "?IsActive=true";
    GenericAPIService.customAPIMethodClone(url, payload, "GET")
      .then((res) => {
        if (HOF_Methods.getStatus(res) === "success") {
          const { data = [] } = res;
          EncryptStorage.setToLocalStorage("registerStateName", stateId);
          data &&
            data.length > 0 &&
            data.map((item) => {
              if (item.stateId.toString() == stateId && stateId.toString()) {
                EncryptStorage.setToLocalStorage(
                  "registerStateName",
                  item.stateName
                );
              }
            });
        }
      })
      .catch((err) => {
        setLoader(false);
        setErrorMessage(err?.status?.message);
      });
  };

  const loadStateList = () => {
    setLoader(true);
    const payload = {};
    const url = UrlConstants.getSteteListUrl + "?IsActive=true";
    GenericAPIService.customAPIMethodClone(url, payload, "GET")
      .then((res) => {
        setLoader(false);
        if (HOF_Methods.getStatus(res) === "success") {
          const { data = [] } = res;
          setLoader(false);
          setStateList(data.reverse());
        } else {
          setErrorMessage(res.status.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        setErrorMessage(err.status.message);
      });
  };

  const getWordpressParam = async () => {
    if (window.location.href) {
      let getUrl = window.location.href;
      let splitUrl = window.location.href.includes("?")
        ? window.location.href.split("?")
        : false;
      if (splitUrl) {
        checkSplitUrl(getUrl);
      }
    }
  };

  const checkSplitUrl = (getUrl) => {
    let replaceUrl = getUrl.replace("#/", "");
    replaceUrl = replaceUrl.replace("'", "");
    let url = new URL(replaceUrl);
    const params = new URLSearchParams(new URL(replaceUrl).search);
    const configCourseldValue = params.get("configCourseld")
      ? params.get("configCourseld")
      : "";
    const orgIdValue = params.get("organisationId")
      ? params.get("organisationId")
      : "";
    const stateIdValue = params.get("stateId") ? params.get("stateId") : "";
    const vehicleTagValue = params.get("vehicleTag")
      ? params.get("vehicleTag")
      : "";
    let configCourseId = url.searchParams.get("configCourseId")
      ? url.searchParams.get("configCourseId")
      : "";
    let organisationId = url.searchParams.get("organisationId")
      ? url.searchParams.get("organisationId")
      : "";
    let stateId = url.searchParams.get("stateId")
      ? url.searchParams.get("stateId")
      : "";
    let vehicleTag = url.searchParams.get("vehicleTag")
      ? url.searchParams.get("vehicleTag")
      : "";
    EncryptStorage.setToLocalStorage("parentEmailFlow", false);
    EncryptStorage.setToLocalStorage("registerVia", "nonsacramento");
    EncryptStorage.setToLocalStorage(
      "stateId",
      stateId ? stateId : stateIdValue
    );
    EncryptStorage.setToLocalStorage(
      "configCourseId",
      configCourseId ? configCourseId : configCourseldValue
    );
    EncryptStorage.setToLocalStorage(
      "organisationId",
      organisationId ? organisationId : orgIdValue
    );
    EncryptStorage.setToLocalStorage(
      "vehicleTag",
      vehicleTag ? vehicleTag : vehicleTagValue
    );
    setTempStateID(stateId);
    setInitOrganisationId(organisationId);
    setLoader(true);
    if (stateId == "1" && organisationId == "1001") {
      setIsStateFollowActive(true);
    } else {
      setLoader(true);
      setIsStateFollowActive(false);
    }
    getStateName(stateId);
  };

  const getFlexiFieldInfo = async (stateId = "") => {
    setLoader(true);
    let configCourseId = EncryptStorage.getLocalStorage("configCourseId");
    let _stateId =
      stateId == "" ? EncryptStorage.getLocalStorage("stateId") : stateId;
    if (_stateId != "") {
      if (configCourseId) {
        getFlexiFieldSuccessHandler(configCourseId, _stateId);
      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };

  const getFlexiFieldSuccessHandler = async (configCourseId, _stateId) => {
    setLoader(true);
    const stateIdRegex = /[^0-9]/g;
    let verifiedStateId;
    if (_stateId && typeof _stateId === "string"){
      verifiedStateId = _stateId.replace(stateIdRegex, "");
    }else {
      verifiedStateId = _stateId;
    }

    let payload = {
      configCourseId: configCourseId,
      isDiversionFieldAtRegistration: true,
      stateId: verifiedStateId,
    };
    setTempStateID(verifiedStateId);
    setLoader(true);
    setProgress(0);
    const url =
      UrlConstants.getFlexifieldsList +
      `?configCourseJsonObj=${encodeURIComponent(JSON.stringify(payload))}`;
    await GenericAPIService.customAPIMethod(url, {}, "GET")
      .then((res) => {
        if (HOF_Methods.getStatus(res) === "success") {
          if (res.data && Object.entries(res.data).length != 0) {
            let currentProgress = 0;
            const interval = setInterval(() => {
                currentProgress += 10;
                setProgress(currentProgress);

                if (currentProgress > 100) {
                    clearInterval(interval);
                    checkStudentFlexiFieldDto(res);
                }
            }, 200);
          } else {
            setShowOtherInfo(false);
            setLoader(false);
          }
        } else {
          setLoader(false);
          setShowOtherInfo(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        setErrorMessage(err?.status?.message);
      });
  };

  const checkStudentFlexiFieldDto = (res) => {
    if (res.data.studentFlexiFieldDtoList) {
      if (res.data.studentFlexiFieldDtoList.length != 0) {
        setFlexiFields(res.data.studentFlexiFieldDtoList);
        setShowOtherInfo(true);
      }
      setLoader(false);
    }
    if (res.data.studentCourseInformationDtoObj) {
      if (Object.entries(res.data.studentCourseInformationDtoObj).length != 0) {
        loadCourseInfoDetails(res.data.studentCourseInformationDtoObj);
      }
      setLoader(false);
    }
  };

  const checkingAllAttributes = (name) => {
    let result = null;
    if (props.customAttributesList && props.customAttributesList.length > 0) {
      props.customAttributesList.forEach((item) => {
        if (item.customAttribute.columnName === name) {
          result = item.customAttributeValue;
        }
      });
      return result;
    } else {
      return result;
    }
  };

  return (
    <>
      <ToastContainer />
      {alertShow ? (
        <>
          <Modal show={true} backdrop="static" keyboard={false} id="messageModal" centered >
            <Modal.Body className="messageModal">
              <Alert variant={"danger"}>
                <span className="success-icon">
                  <CheckCircleIcon />
                </span>
                <span className="danger-icon">
                  <CancelIcon />
                </span>
                {registeredErrorMessage}
              </Alert>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
      {loader && getLoader}
      <FastLaneCourseInfo
        courseDetails={courseDetails}
        internalPaymentMethodCallback={internalPaymentMethodCallback}
        courseName={courseName}
        setCourseName={setCourseName}
      />
      {storeSubjectValue?.isCourseExpired && (
        <CoruseExpired showmodal={storeSubjectValue?.isCourseExpired} />
      )}
    </>
  );
}
export default withRouter(RegisterForm);
