import React from 'react';
import loaderCircle from '../../assets/images/loader/loader.gif';
import ProgressBar from 'react-bootstrap/ProgressBar';
export default function RegularLoader(props) {
    const { value } = props;
    const now = value;
    return (
        <>
            <div className='loader--container'>
                <img className='img' src={loaderCircle} alt='loader' />
                <h5 className='percentage text-center font-weight-bold'>{`${now}%`}</h5>
                <ProgressBar now={now} />
                <h6 className='fontStyle text-center'>Buckle Up! You’re being sent to the IMPROV SPIDER Learning management Platform to complete your payment and allocate your course.</h6>
            </div>
        </>
    );
}
