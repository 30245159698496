import React, { useState } from 'react'
import { arrow_twisted_right_orange, new_close } from '../../common/assets/Images';

export default function SpecialOffer(props) {

    const { coursePriceData, fixedCoursePriceDetails } = props;
    const [viewDetails, setViewDetails] = useState(true);
    const [showSpecialOffer, setShowSpecialOffer] = useState(true);

    const calculateCrossOutPricePercentage = () => {
        let totalCrossOutPrice = 0;
        let totalBasePrice = 0;
        if (fixedCoursePriceDetails && fixedCoursePriceDetails.crossOutPrice) {
            totalCrossOutPrice = totalCrossOutPrice + fixedCoursePriceDetails.crossOutPrice;
            totalBasePrice = fixedCoursePriceDetails.basePrice;
        }

        if (totalCrossOutPrice != 0) {
            let tempDifference = Number(totalCrossOutPrice) - Number(totalBasePrice);
            let tempDiscount = (Number(tempDifference) / Number(totalCrossOutPrice)) * 100;
            return (`${Math.round(tempDiscount)}%`)
        }
        else {
            return "0%"
        }
    }

    const displayFullContents = () => {
        setViewDetails(!viewDetails)
    }

    const createMarkup = (value) => {
        return { __html: value };
    };

    return (
        <>
            {
                showSpecialOffer && coursePriceData.checkoutHeader && calculateCrossOutPricePercentage() !== "0%" ? <>
                    <div className="course-flashdeal-container">
                        <div className="course-flashdeal-top-section">
                            <p className="course-flashdeal-top-info">Flash Deal</p>
                            <img src={arrow_twisted_right_orange} alt="" className="course-flashdeal-arrow-image" />
                            <p className="course-flashdeal-discount">{calculateCrossOutPricePercentage()} Off</p>
                        </div>
                        <div className="course-flashdeal-bottom-section">
                            <p className='course-flashdeal-bottom-info'>Save more with our <strong>special offer</strong></p>
                        </div>
                        <img src={new_close} className="flashdeal-button-close" onClick={() => setShowSpecialOffer(false)} />
                    </div>
                </> : null
            }
        </>
    )
}
