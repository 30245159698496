import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function GSAAlreadyRegisterMessage(props) {

    const {history, message , showModal, handleClose} = props;
    const redirectToLogin  = () => {
        localStorage.clear();
        window.localStorage.clear();
        history.push('/login');
    }
    return (
        <div>
            <Modal show={showModal} onHide={handleClose}  centered  keyboard={false} className='modal-white GSA-modal'>
                <Modal.Body>
                    <div className='account-lock-cont p-4 mt-4'>
                        <p className='mb-4'>{message}</p>
                        <div className='w-100 d-flex justify-content-center'>
                            <Button className='blue-btn' onClick={redirectToLogin}>Login</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
