import { EncryptStorage } from "../../common/imports/CommonImport";

function getPayload(configCourseId) {
  let stateId = EncryptStorage.getLocalStorage("stateId");
  const countyId = EncryptStorage.getLocalStorage("countyId");
  const courtId = EncryptStorage.getLocalStorage("courtId");
  const citationDate = EncryptStorage.getLocalStorage("citationDate");
  const studentId = EncryptStorage.getLocalStorage("studentId");
  const stateIdRegex = /[^0-9]/g;

  if (stateId && typeof stateId === "string"){
    stateId = stateId.replace(stateIdRegex, "");
  }

  return {
    configCourseId: configCourseId,
    /* studentId: 166884, */
    studentId: studentId ? studentId : 0, 
    stateId: stateId ? stateId : 0,
    countyId: countyId ? countyId : 0,
    courtId: courtId ? courtId : 0,
    citationDate: citationDate ? citationDate : "2022-09-11",
  };
}

export const GetCoursePriceDetailsPayload = {
  getPayload,
};
