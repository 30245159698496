import * as Yup from 'yup';
import moment from 'moment';

const minDate = moment('1950-01-01T01:00:00').format('MM-DD-yyyy');
const maxDate = moment('2005-01-01T01:00:00').format('MM-DD-yyyy');

const personalInfoValidationSchema = Yup.object({
    firstName: Yup.string().nullable().required('First name is required')
        .strict()
        .trim('Space not allowed')
        .min(1, 'First name should be between 1 to 35')
        .max(35, "First name should be between 1 to 35")
        .matches(/^[A-Za-z0-9_']+$/, "First name should only contain alphabets, hyphen, apostrophe or number"),
    middleName: Yup.string()
        .strict()
        .trim('Space not allowed')
        .min(1, 'Middle name should be between 1 to 35')
        .max(35, "Middle name should be between 1 to 35")
        .matches(/^[A-Za-z0-9_']+$/, "Middle name should only contain alphabets, hyphen, apostrophe or number"),
    lastName: Yup.string().required('Last Name is required')
        .strict()
        .trim('Space not allowed')
        .min(1, 'Last name should be between 1 to 35')
        .max(35, "Last name should be between 1 to 35")
        .matches(/^[A-Za-z0-9_']+$/, "Last name should only contain alphabets, hyphen, apostrophe or number"),
    // dateOfBirth: Yup.date().required('Date of Birth is required').nullable(),
    dateOfBirth: Yup.date().nullable()
        .typeError('Date Format Invalid Use the following format MM-DD-YYYY'),
    // .min(new Date(minDate), 'f Date must be later than today.')
    // .max(new Date(maxDate), 's Date must be later than today.'),
    state: Yup.object({ stateId: Yup.string().nullable().required('State is required') }),
    parentEmail: Yup.string()
        .email("Invalid characters in email address")
        .strict()
        .trim()
        .min(10, 'Email address should be between 10 to 100')
        .max(100, "Email address should be between 10 to 100"),

    mobileNumber: Yup.string(),
    street: Yup.string().required('Address Line is required'),
    // apartment: Yup.string().required('Apartment/ Suite/ Unit is required'),
    city: Yup.string().required('City code is required').nullable()
        .strict()
        .trim('Space not allowed')
        .min(2, 'city should be between 2 to 40')
        .max(40, "city should be between 2 to 40")
        .matches(/^[A-Za-z ]*$/, "City name should only contain alphabets"),

    // zip: Yup.string().required('Required Field')
    zip: Yup.string().required('Zip code is required')
        .matches(/^[0-9]+$/, "ZIP should only contain numeric")
        .min(5, 'ZIP should have only 5 digits')
        .max(5, 'ZIP should have only 5 digits')
});



const personalInfoInitialValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    parentEmail: '',
    mobileNumber: '',
    street: '',
    apartment: '',
    city: '',
    state: { stateId: '' },
    zip: ''
}


export const Schema = {
    personalInfoValidationSchema, personalInfoInitialValues,
}
