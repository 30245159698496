export class ProdUrlConstants {
   /* Registration */
   static registrationUrl = '/elms-prod-studentregistration-1/studentregistration';
   static getOrganisationUrl = '/improv-master/organisation/logo';
   static emailVerificationUrl = "/improv-student/student/email-verification/"
   static emailResendVerificationUrl = "/improv-student/student/resend-verification-code/"
   /* Login */
   static initloginUrl = '/improv-bdi-authentication/student/bdi/login';
   static loginUrl = '/elms-prod-studentlogin/studentlogin';
   static googleSignUrl = '/elms-prod-student-googlelogin/studentgooglelogin';
   /* Logout */
   static logoutUrl = '/elms-prod-student-logout/studentlogout';
   static getUpSellApi = "/improv-course-config/configcourseprice/";
   static saveAZUpsellUrl = "/improv-student/student/upsell/";
   /* State and Category List */
   static courseMappingUrl = '/improv-course-config/courseconfigurations/'
   static getSteteWithCategoryListUrl = '/improv-course-config/states-reasons/';
   static getSteteListUrl = '/improv-course-config/state/states';
   static getCourseList = "/elms-prod-student-courses/courses";
   static getPaymentClientToken = "/elms-prod-student-paymentclienttoken/paymentclienttoken";
   static checkoutUrl = "/elms-prod-student-paymentcheckout/studentpaymentcheckout";
   static parentCheckoutUrl = "/elms-prod-student-parentpaymentcheckout/parentpaymentcheckout";
   static getCourseInfoDetails = '/elms-prod-student-courseinfodetail/courseinformation';
   static getCoursePriceDetails = '/elms-prod-student-coursepricedetail/courseprice';
   static getStudentDetails = '/elms-prod-student-detail/studentdetails';
   static getFlexifieldsList = '/elms-prod-student-courseflexifields/flexifields';
   static saveflexifield = '/elms-prod-student-savecourseflexifields/flexifields';
   static saveDiversionFeeFlexiField = '/elms-prod-student-save-diversion-flexifields/diversion-flexifields';
   static getCourseInfomation = '/elmscourseinfodetail/courseinformation';
   static getUpSellApi = "/improv-course-config/configcourseprice/";
   static saveAZUpsellUrl = "/improv-student/student/upsell/";
   /* Dashboard */
   static dashboardListOfChaptersUrl = '/elms-prod-student-course-dashboard/coursedashboard';
   static chapterLaunch = '/elms-prod-student-chapter-launch/chapterLaunch';
   static multiCourseDashboardUrl = '/elms-prod-student-courses-dashboard/coursesdashboard';
   static upsellcoursesUrl = '/elms-prod-student-upsell-courses/upsellcourses';
   static resetCourseUrl = '/elms-prod-student-reset-course/resetcourse';
   /* SecurityQuestion */
   static getSecurityQuestionsUrl = '/elms-prod-student-securityquestions/studentsecurityquestions';
   static saveSecurityQuestionUrl = '/elms-prod-student-savesecurityquestion/savestudentsecurityquestions';
   static getRandomSecurityQuestionUrl = '/elms-prod-student-randomsecurityquestions/randomsecurityquestion';
   /*Chapter Quiz */
   static getChapterQuizListUrl = '/elms-prod-student-chapter-quiz-and-final-quiz/studentquizlaunch';
   static updateChapterStatus = '/elms-prod-student-chapter-status-update/chapterstatusupdate';
   static saveFinalQuizResult = '/elms-prod-student-save-final-quiz/studentfinalquiz';
   static chapter_CourseStatusUpdateUrl = '/elms-prod-student-chapter-course-status-update/statusupdate';
   /*My Orders*/
   static getStudentPurchaseDetails = '/elms-prod-student-purchased-courses/studentpurchasedcourses';
   static changePasswordUrl = '/elms-prod-student-change-password/changepassword';
   static forgotPasswordUrl = '/elms-prod-student-forgotpassword/studentforgotpassword';
   static resetPasswordUrl = '/elms-prod-student-setpassword/studentsetpassword';
   /* completedmessageUrl */
   static studentCourseCompletedMessageUrl = '/elms-prod-student-course-completed-message/studentcoursecompletedmessage';
   /* Discount code check*/
   static getStateWiseDiscountDetailsUrl = '/elms-prod-student-discount/discountCode';
   static giftCertificateUrl = '/elms-prod-student-gift-certificate/giftcertificatecode';
   static sacramentoCourtCourseUrl = '/elms-prod-student-county-courses/countycourse';
   static studentPaymentRefundUrl = '/elms-prod-student-payment-refund/studentpaymentrefund';
   /* chapter or Final Quiz Validation */
   static chapterFinalQuizValidationUrl = '/elms-prod-student-validatequiz/quiz';
   static typingDnaSave_Verify = "/elms-prod-student-typingdnapattern"
   static downloadPaymentReceiptUrl = '/improv-student/student-certificate/receipt';
   static emailPaymentReceiptUrl = "/improv-student/student-certificate/send/payment-receipt";
   /* studentfootprint */
   static studentfootprintUrl = '/elms-prod-student-footprint/studentfootprint';
   /* support ticket */
   static supportTicketUrl = '/elms-prod-student-support-ticket/studentsupportticket';
   /*Document*/
   static getDocumentCatalogItems = '/improv-course-config/catalog/';
   static getDocumentList = '/improv-student/student-course-document/range?';
   static saveDocument = '/improv-student/student-course-document/';
   static saveWebcamUrl = '/improv-student/student-facial-recognition';
   static updateStudentCourseTime = '/improv-student-course-dashboard/student/chapter/time'
   /*evs time spand*/
   static time_managementUrl = '/improv-student/student_course/time_management';
   static universalCheckoutUrl = '/improv-student/student/upsell/';

    // Tilled payment URl
    static createPaymentMethod = '/improv-student-registration-journey/student/course/payment/tilled/paymentMethod/'; //Done

    //certificate download
    static certificateDownloadUrl = '/improv-student-course-dashboard/student/student-course-completed-certificate'
    
}
